<div class="expanded_block_out">
    <div class="expanded_block_in exp_subs exp_channels exp_def">
        <div class="title_nav outlet_title_nav has_tabs">
            <span (click)="log()">
                {{ "Outlet" | translate }} 
                <!-- {{form.get(outletKey).value | json}} - {{form.get('no_channel_id').value | json}} | {{savedForm | json}} - {{noSavedForm | json}} -->
                <!-- <span *ngIf="cstForm && cstForm.get(outletKey)" style="font-size: 10px;">{{form.get(outletKey).value | json}} -- {{cstForm.get(outletKey).value | json}} -- {{savedForm | json}}</span> -->
            </span>

            <div class="out_tabs" *ngIf="activeSections == 'Chats'">
                <span [ngClass]="{'active': outletKey == 'channel_id'}" (click)="setOutletKey('channel_id')">{{"Cards"|translate}}</span>
                <span [ngClass]="{'active': outletKey == 'discussion_channel_id'}" (click)="setOutletKey('discussion_channel_id')">{{"Chats"|translate}}</span>
            </div>
        </div>
        <section class="sub_filter_wrp" [ngClass]="{'has_search_btn': showOutletBtn}">
            <div class="subs" [appDeviceClass]="'custom-scroll'">
                <div class="sub filter_sub" [ngClass]="{'active': outletsSubsControl.value === ''}" (click)="patchControl(outletsSubsControl, '')">
                    <div class="d-f-c">
                        <app-platform [platform_id]="0"></app-platform>
                        <span class="ml-1 text_one_line">
                            {{ "All outlets" | translate }}
                        </span>
                    </div>
                </div>
                <ng-container *ngFor="let item of platforms; let i = index;">
                    <div *ngIf="!!item.id" class="sub filter_sub" [ngClass]="{'active': outletsSubsControl.value == item.id}" (click)="patchControl(outletsSubsControl, item.id)" >
                        <div class="d-f-c">
                            <app-platform [platform_id]="item.id"></app-platform>
                            <span class="ml-1 text_one_line">
                                {{item?.name}}
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="block">
           
                <div class="filter_exp_search">
                    <mat-form-field appearance="legacy" class="filter_form search">
                        <input class="search_inp" [formControl]="searchControl" matInput>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="!switched">
                    <form [formGroup]="needCst ? cstForm : form" class="exp_form" [appDeviceClass]="'custom-scroll'"             
                    infinite-scroll
                    [infiniteScrollDisabled]="isLoad"
                    [scrollWindow]="false"
                    [infiniteScrollUpDistance]="scrollUpDistance"
                    [infiniteScrollThrottle]="throttle"
                    (scrolled)="onDown($event)">
                        <mat-selection-list [multiple]="multiple" formControlName="{{outletKey}}" (selectionChange)="selectChange($event)">
                            <mat-list-option *ngIf="n_a" [value]="0" [ngClass]="{'active': (needCst ? cstForm : form).get(outletKey).value.includes(0)}">
                                <div class="user_div">
                                    <mat-icon style="visibility: none;">user</mat-icon>
                                    <span>
                                        {{ "Outlet not set" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <ng-container *ngFor="let fil of outlets">
                                <mat-list-option class="cst_o flex_text" [value]="fil.id" [ngClass]="{'active': n_a || multiple ? (needCst ? cstForm : form).get(outletKey).value.includes(fil.id) : (needCst ? cstForm : form).get(outletKey).value == fil.id}">
                                    <div class="d-f-c">
                                        <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                        <mat-checkbox *ngIf="n_a" (click)="setChannelMulty($event, fil.id, outletKey)" [disableRipple]="true" class="outlet_fil_check" [checked]="(needCst ? cstForm : form).get(outletKey).value.includes(fil.id)" color="primary"></mat-checkbox>
                                    </div>
                                    <span class="not_assigned" *ngIf="n_a" (click)="noChannel($event, fil.id)">
                                        {{ "n/a" | translate }}
                                        <mat-checkbox *ngIf="n_a" [disableRipple]="true" class="outlet_fil_check" color="primary" [checked]="(needCst ? cstForm : form).get('no_channel_id').value.includes(fil.id)" (click)="noChannelMulty($event, fil.id)"></mat-checkbox>
                                    </span>
                                </mat-list-option>
                            </ng-container>
                        </mat-selection-list>
                    </form>
                </ng-container>

                <div class="search_fil_line" *ngIf="showOutletBtn">
                    <button (click)="searchFilterSubmit(e)">{{"Search" | translate}}</button>
                </div>
            </div>
        </section>
    </div>
</div>