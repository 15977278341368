<div #box class="resizable-draggable no-select-text" 
    [class.cropOn]="part.crop_on"
    [style.width.px]="part.rd.width" 
    [style.height.px]="part.rd.height" 
    [style.left.px]="part.rd.left" 
    [style.top.px]="part.rd.top" 
    [style.transform]="'rotate(' + part.rd.deg + 'deg)'"
    [class.active]="status === 1 || status === 2 || status === 3"
    (mousedown)="part.crop_on ? setStatus($event, 2) : null"
    (window:mouseup)="part.crop_on ? setStatus($event, 0) : null"
>
    <div *ngIf="part.crop_on" class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    <div #rotate class="rotate-action" [ngClass]="{'not-show': !part.crop_on}" (mousedown)="setStatus($event, 3)"></div>
    <!-- <div class="center" *ngIf="part.crop_on"
    [style.left.px]="center.x" 
    [style.top.px]="center.y"></div> -->
    <!-- <div *ngIf="part.crop_on" class="axis x_line" [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'"></div>
    <div *ngIf="part.crop_on" class="axis y_line" [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'"></div> -->
    <!-- <div #pointTopLeft [ngClass]="{'not-show': !part.crop_on}" class="point top left"></div>
    <div #pointTopRight [ngClass]="{'not-show': !part.crop_on}" class="point top right"></div>
    <div #pointBottomLeft [ngClass]="{'not-show': !part.crop_on}" class="point bottom left"></div>
    <div #pointBottomRight [ngClass]="{'not-show': !part.crop_on}" class="point bottom right"></div> -->
    <!-- <span>{{part.rd.width | number:'1.0-0'}}px</span>
    <span>{{part.rd.height | number:'1.0-0'}}px</span>
    <span class="xy">(x:{{part.rd.left}}, y:{{part.rd.top}})</span> -->
    <video 
    #video 
    height="100%" 
    width="100%" 
    [id]="'video_'+part.pId" 
    class="player" 
    [src]="!!part?.blobSrc ? part?.blobSrc : (host + (part?.preview1080 ? part?.preview1080 : part?.original) + '?company_id=' + company_id)"
    (loadedmetadata)="loadedVideo($event)" 
    >
        Your browser doesn't support HTML5 video tag.
    </video>
    <div [ngClass]="{'not-show': !part.rd.deg}" class="black_sq" #black_sq [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'" [style.left.px]="-(max_side - part.rd.width)/2" [style.top.px]="-(max_side - part.rd.height)/2" [style.width.px]="max_side" [style.height.px]="max_side"></div>
    <div class="curr_time">{{video?.currentTime | veTime}}</div>
</div>