import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-set-edit-source',
  templateUrl: './set-edit-source.component.html',
  styleUrls: ['./set-edit-source.component.scss']
})
export class SetEditSourceComponent extends BaseClass implements OnInit, OnDestroy {
  public isSubmit: boolean = false;

  
  public checklistsControl: FormControl = new FormControl();
  public checklists$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public paramControl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public relationTypes: any[] = [
    {
      name: "Related cards",
      id: 0
    },
    {
      name: "Part of cards",
      id: 1
    },
    {
      name: "Consist of cards",
      id: 2
    },
    {
      name: "Current card",
      id: 3
    }
  ]

  public activeRelation: FormControl = new FormControl(0)
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<SetEditSourceComponent>,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log('SetEditSourceComponent', this.data)
    if (this.data.source.is_task_related) {
      this.activeRelation.patchValue(0)
    }
    if (this.data.source.is_task_consist_of) {
      this.activeRelation.patchValue(1)
    }
    if (this.data.source.is_task_part_of) {
      this.activeRelation.patchValue(2)
    }
    if (this.data.source.is_task_parent) {
      this.activeRelation.patchValue(3)
    }

    this.allValues$.next(this.data.allValues.slice())
    this.parameters$.next(this.data.parameters.slice())
    this.checklists$.next(this.data.checklists.slice())
    

    this.attachSubscriptions(
      this.checklistsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChecklists(resp))
    )
    this.attachSubscriptions(
      this.paramControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )
    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )

    this.attachSubscriptions(
      this.activeRelation.valueChanges.subscribe(resp => {
        console.log(resp)
        if (resp == 0) {
          this.data.source.is_task_related = 1;
          this.data.source.is_task_consist_of = 0;
          this.data.source.is_task_part_of = 0;
          this.data.source.is_task_parent = 0;
        } else if (resp == 1) {
          this.data.source.is_task_related = 0;
          this.data.source.is_task_consist_of = 1;
          this.data.source.is_task_part_of = 0;
          this.data.source.is_task_parent = 0;
        } else if (resp == 2) {
          this.data.source.is_task_related = 0;
          this.data.source.is_task_consist_of = 0;
          this.data.source.is_task_part_of = 1;
          this.data.source.is_task_parent = 0;
        } else if (resp == 3) {
          this.data.source.is_task_related = 0;
          this.data.source.is_task_consist_of = 0;
          this.data.source.is_task_part_of = 0;
          this.data.source.is_task_parent = 1;
        }    
      })
    )
  }

  toggleKey(source, key, altKey) {
    source[key] = Number(!source[key]);
    if (!!source[key]) {
      source[altKey] = 0
    }
  }

  onSearchParams(resp) {
    if (!this.data.parameters) {
      return;
    }

    if (!resp) {
      this.parameters$.next(this.data.parameters.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.parameters$.next(
      this.data.parameters.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getChecklistsById(id) {
    if (!this.data.checklists || !this.data.checklists.find(el => el.id == id)) {
      return false;
    }
    return this.data.checklists.find(el => el.id == id)
  }  

  getValuesById(id) {
    return this.data.allValues && this.data.allValues.find(x => x.id == id)
  }

  getParamById(id) {
    return this.data.parameters && this.data.parameters.find(x => x.id == id)
  }

  onSearchValues(resp) {
    if (!this.data.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.data.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.data.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchChecklists(resp) {
    if (!this.data.checklists) {
      return;
    }

    if (!resp) {
      this.checklists$.next(this.data.checklists.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.checklists$.next(
      this.data.checklists.filter(z => z.text.toLowerCase().indexOf(resp) > -1 || (z.group && z.group.name && z.group.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.isSubmit = true;
    this.dialogRef.close({event: "change", data: this.data.source})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
