<mat-drawer-container class="root_page" autosize>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true">
        <mat-nav-list class="menu_list">
            <mat-list-item (click)="goToMenuItem(menuItem)" [ngClass]="{'active_menu': menuItem.tab == activeTab}" *ngFor="let menuItem of menuItems">
                <mat-icon mat-list-icon>{{menuItem.icon}}</mat-icon>
                <h4 mat-line *ngIf="isExpanded">{{ menuItem.name | translate }}</h4>
            </mat-list-item>
        </mat-nav-list>
        <div class="menu_btn">
            <button mat-mini-fab (click)="isExpanded = !isExpanded" color="warn" style="margin: 10px;">
            <mat-icon aria-label="Menu">
                <ng-container *ngIf="isExpanded; else elseTemplatemenu_open">
                    menu_open
                </ng-container>
                <ng-template #elseTemplatemenu_open>
                    menu
                </ng-template>
            </mat-icon>
            </button>
        </div>
    </mat-drawer>
  
    <mat-drawer-content>
        <div class="container">
            <div class="root_content" [ngSwitch]="activeTab">
                <div class="companyes_page" *ngSwitchCase="'companyes'">
                    <mat-nav-list *ngIf="companyes?.length"        
                    class="root_users"  
                    [appDeviceClass]="'custom-scroll custom-scroll-t'"      
                    infinite-scroll
                    [infiniteScrollDisabled]="isLoadComp"
                    [scrollWindow]="false"
                    [infiniteScrollUpDistance]="scrollUpDistance"
                    [infiniteScrollThrottle]="throttle"
                    (scrolled)="onDownCompany($event)">
                        <mat-list-item class="company_item" *ngFor="let comp of companyes" [ngClass]="{'deleted': +comp.delete_at > 0}">
                            <div class="id">
                                {{ comp.id }}
                            </div>
                            <div class="company_block">
                                <ng-container *ngIf="!!comp?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + comp?.avatarFile?.thumbnail" alt="">
                                </ng-container>
                                <ng-template #elseTemplatePartnerAvatar>
                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                </ng-template>
                                <span class="company_name">
                                    {{ comp.name }}
                                </span>
                            </div>

                            <div class="created_date" style="width: 10%;">
                                {{ comp.created_at*1000 | date:'MMM dd, Y':'':activeLang | yearPipe }}
                            </div>

                            <div class="user_block" style="width: 20%;">
                                <ng-container *ngIf="comp.owner.image; else elseTemplatePhoto">
                                    <img [src]="comp.owner.image">
                                </ng-container>
                                <span class="user_name">
                                    {{ comp.owner.display_name }}
                                </span>
                            </div>
                            
                            <div class="unit">
                                {{ comp.price_unit_7d }}
                            </div>
                            <div class="unit">
                                {{ comp.price_unit_14d }}
                            </div>
                            <div class="unit">
                                {{ comp.price_unit_30d }}
                            </div>
                            <div class="unit">
                                {{ comp.price_unit_60d }}
                            </div>
                            <div class="filesize" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ ('Total files size including copies' | translate) + ' ' + (comp?.file_stat_updated_at > 0 ? (comp?.file_stat_updated_at*1000 | date:'hh:mm a, MMM d, y':'':activeLang | yearPipe) : '') }}">
                                {{ comp.filesize | filesize }}
                            </div>
                            
                            <div class="filesize" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ ('Total unique files size' | translate) + ' ' + (comp?.file_stat_updated_at > 0 ? (comp?.file_stat_updated_at*1000 | date:'hh:mm a, MMM d, y':'':activeLang | yearPipe) : '') }}">
                                {{ comp.original_filesize | filesize }}
                            </div>
                            <div class="ytp mr-5">
                                <div class="box_wrp">
                                    <mat-checkbox [disabled]="comp.isGeo" color="primary" (change)="changeGeo($event, comp)" [(ngModel)]="comp.is_geocode">{{"Geolocation" | translate}}</mat-checkbox>
                                </div>
                            </div>
                            <div class="verified">                              
                                <div class="box_wrp">
                                    <mat-checkbox [disabled]="comp.isVerifing" color="primary" (change)="changeVerified($event, comp)" [(ngModel)]="comp.is_verified">{{"Verified" | translate}}</mat-checkbox>
                                </div>
                                <button mat-icon-button color="primary" (click)="translateVerified(comp)">
                                    <mat-icon>translate</mat-icon>
                                </button>
                            </div>
                            <div class="ytp">
                                <div class="box_wrp">
                                    <mat-checkbox [disabled]="comp.isYT" color="primary" (change)="changeYT($event, comp)" [(ngModel)]="comp.is_youtube_api_publish">{{"YT publish" | translate}}</mat-checkbox>
                                </div>
                            </div>
                            <div class="limits">
                                <div class="lim_btn" (click)="openLimits(comp)">
                                    {{"Limits" | translate}}
                                </div>
                            </div>

                            <div class="limit" (click)="+comp.delete_at > 0 ? null : changeLimit(comp)" [ngClass]="{'not_set': !!comp.filesize_limit}">
                                <ng-container *ngIf="!!comp.filesize_limit; else elseTemplateLimit">
                                    {{ comp.filesize_limit | filesize }}
                                </ng-container>
                                <ng-template #elseTemplateLimit>
                                    {{ "unset" | translate }}
                                </ng-template>
                            </div>                    
                        </mat-list-item>
                    </mat-nav-list>
                </div>
                <div class="companyes_page" *ngSwitchCase="'servers'">
                    <div class="cases_page" style="margin-bottom: 4px;">
                        <div class="container">
                          <div class="filter_out">  
                            <div class="filter_values">
                                <div class="d-f-c">

                                    <div class="check_wrp">
                                        <mat-checkbox class="select_all_checkbox" *ngIf="serversCollection" (click)="$event.preventDefault; $event.stopPropagation()" (change)="$event ? masterToggle() : null"
                                          [checked]="serversCollection.hasValue() && isAllSelected()"
                                          [indeterminate]="serversCollection.hasValue() && !isAllSelected()"
                                          #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ checkboxLabel() }}"
                                          [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                    </div>
                                    <div class="task_collections_wrp" style="padding: 0px 10px !important;" *ngIf="serversCollection?.selected?.length">
                                        <span>{{ "Actions with selected" | translate }}</span>
                                        <span>{{ serversCollection?.selected?.length }}</span>
                                        <span>{{ "server(s):" | translate }}</span>
                                    
                                        <div class="collections_btns">
                                          <button type="button" (click)="multiStopAndDeleteServer()">
                                            {{ "Stop and Delete Servers" | translate }}
                                          </button>
                                          <button type="button" (click)="multiPauseServers()">
                                            {{ "Pause Servers" | translate }}
                                          </button>
                                        </div>
                                    </div>
                                </div>
                              <!-- <mat-chip-list class="chips_wrp without_ordered">
                    
                    
                                <mat-chip (removed)="removeValue('start_time_from')" *ngIf="filter.get('start_time_from').value">
                                    {{ filter?.get('start_time_from').value | date:'MMM, y':"+0000":activeLang | yearPipe }}
                                    <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                                </mat-chip>
                    
                                <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')" *ngIf="!withoutFilter && (filter.get('start_time_from').value || filter.get('start_time_to').value)">cancel</mat-icon>
                              </mat-chip-list> -->
                      
                              <div class="stats_wrp">
                                <div class="tasks_counters">
                                  <div class="counter_block" *ngIf="serversPagination && serversPagination['totalCount']">
                                    <div class="counter_top">{{ "Servers" | translate }}</div>
                                    <div class="counter_bottom">{{serversPagination['totalCount']}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div        
                    class="root_users"  
                    [appDeviceClass]="'custom-scroll custom-scroll-t'"      
                    infinite-scroll
                    [infiniteScrollDisabled]="isLoadServ"
                    [scrollWindow]="false"
                    [infiniteScrollUpDistance]="scrollUpDistance"
                    [infiniteScrollThrottle]="throttle"
                    (scrolled)="onDownServers($event)">
                        <div class="server_item_out server_item_out_head">
                            <form [formGroup]="serversFilter" class="server_item">
                                <div class="d-f-c">
                                    <div class="id">
                                        {{ "ID" | translate }}
                                    </div>
                                    <div class="ex_id">
                                        {{ "External ID" | translate }}
                                    </div>
                                    <div class="server">
                                        <div>
                                            {{ "Server" | translate }}
                                        </div>
                                        <input class="name_input" type="text" formControlName="q" (change)="filterData()">
                                    </div>     
                                    <div class="role">
                                        <div>
                                            {{ "Role" | translate }}
                                        </div>
                                        <mat-form-field appearance="outline" class="report_filter">
                                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                                            <mat-select [disableOptionCentering]="true" selectDir formControlName="role" (selectionChange)="filterData()">
                                                <mat-option [value]="''">
                                                    {{ 'All' | translate }}
                                                </mat-option>
                                                <mat-option [value]="'live_stream_broadcasting'">
                                                    {{ 'Steam' | translate }}
                                                </mat-option>
                                                <mat-option [value]="'master'">
                                                    {{ 'Master' | translate }}
                                                </mat-option>
                                                <mat-option [value]="'video_projects_hwaccel_cuda'">
                                                    {{ 'Video processing' | translate }}
                                                </mat-option>
                                                <mat-option [value]="'publishing'">
                                                    {{ 'Publishing' | translate }}
                                                </mat-option>
                                                <mat-option [value]="'archives'">
                                                    {{ 'Archiving' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>     
                                    <div class="region">
                                        {{ "Region" | translate }}
                                    </div>     
                                    <div class="provider">
                                        {{ "Provider" | translate }}
                                    </div>      
                                    <div class="public_ip">
                                        {{ "Public IP" | translate }}
                                    </div>      
                                    <div class="public_ipv6">
                                        {{ "Public IPV6" | translate }}
                                    </div>      
                                    <div class="is_col" style="flex-direction: column;">
                                        <div>
                                            {{ "Active" | translate }}
                                        </div>
                                        <mat-form-field appearance="outline" class="report_filter">
                                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                                            <mat-select [disableOptionCentering]="true" selectDir formControlName="is_active" (selectionChange)="filterData()">
                                                <mat-option [value]="''">
                                                    {{ 'All' | translate }}
                                                </mat-option>
                                                <mat-option [value]="1">
                                                    {{ 'Active' | translate }}
                                                </mat-option>
                                                <mat-option [value]="0">
                                                    {{ 'Not Active' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>     
                                    <div class="is_col" style="flex-direction: column;">
                                        {{ "Delete" | translate }}
                                        <mat-form-field appearance="outline" class="report_filter">
                                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                                            <mat-select [disableOptionCentering]="true" selectDir formControlName="is_delete" (selectionChange)="filterData()">
                                                <mat-option [value]="''">
                                                    {{ 'All' | translate }}
                                                </mat-option>
                                                <mat-option [value]="1">
                                                    {{ 'Delete' | translate }}
                                                </mat-option>
                                                <mat-option [value]="0">
                                                    {{ 'Not Delete' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>     
                                    <div class="is_col" style="flex-direction: column;">
                                        {{ "Deleted" | translate }}
                                        <mat-form-field appearance="outline" class="report_filter">
                                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                                            <mat-select [disableOptionCentering]="true" selectDir formControlName="is_deleted" (selectionChange)="filterData()">
                                                <mat-option [value]="''">
                                                    {{ 'All' | translate }}
                                                </mat-option>
                                                <mat-option [value]="1">
                                                    {{ 'Deleted' | translate }}
                                                </mat-option>
                                                <mat-option [value]="0">
                                                    {{ 'Not Deleted' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>     
                                    <div class="is_col">
                                        {{ "GPU" | translate }}
                                    </div>     
                                    <div class="is_col">
                                        {{ "New" | translate }}
                                    </div>     
                                    <div class="is_col">
                                        {{ "Persistent" | translate }}
                                    </div>     
                                    <div class="is_col">
                                        {{ "Standby" | translate }}
                                    </div>     
                                    <div class="is_col" style="flex-direction: column;">
                                        {{ "Stopped" | translate }}
                                        <mat-form-field appearance="outline" class="report_filter">
                                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                                            <mat-select [disableOptionCentering]="true" selectDir formControlName="is_stopped" (selectionChange)="filterData()">
                                                <mat-option [value]="''">
                                                    {{ 'All' | translate }}
                                                </mat-option>
                                                <mat-option [value]="1">
                                                    {{ 'Stopped' | translate }}
                                                </mat-option>
                                                <mat-option [value]="0">
                                                    {{ 'Not Stopped' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>     
                                    <div class="is_col" style="flex-direction: column;">
                                        {{ "Stop" | translate }}
                                        <mat-form-field appearance="outline" class="report_filter">
                                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                                            <mat-select [disableOptionCentering]="true" selectDir formControlName="is_stop" (selectionChange)="filterData()">
                                                <mat-option [value]="''">
                                                    {{ 'All' | translate }}
                                                </mat-option>
                                                <mat-option [value]="1">
                                                    {{ 'Stop' | translate }}
                                                </mat-option>
                                                <mat-option [value]="0">
                                                    {{ 'Not Stop' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>     
                                    <div class="log_upd">
                                        {{ "Log Updated" | translate }}
                                    </div>     
                                </div>
                                <div class="d-f-c" style="justify-content: flex-end !important;">
                                    <div>
                                        {{ "Log" | translate }}
                                    </div>
                                    <div style="padding-right: 10px;">
                                        {{ "Action" | translate }}
                                    </div>
                                    <div class="check_wrp hidden_log">
                                        <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()"></mat-checkbox>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <ng-container *ngIf="servers?.length">
                            <div class="server_item_out" *ngFor="let item of servers">
                                <div class="server_item">
                                    <div class="d-f-c">
                                        <div class="id">
                                            {{ item.id }}
                                        </div>
                                        <div class="ex_id">
                                            {{ item.external_id }}
                                        </div>
                                        <div class="server">
                                            {{ item.server_id }}
                                        </div>     
                                        <div class="role">
                                            {{ item.role }}
                                        </div>     
                                        <div class="region">
                                            {{ item.region }}
                                        </div>     
                                        <div class="provider">
                                            {{ item.provider }}
                                        </div>      
                                        <div class="public_ip">
                                            {{ item.public_ip }}
                                        </div>  
                                        <div class="public_ipv6">
                                            {{ item.public_ipv6 }}
                                        </div>  
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_active">check</mat-icon>
                                        </div>     
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_delete">check</mat-icon>
                                        </div>     
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_deleted">check</mat-icon>
                                        </div>     
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_gpu">check</mat-icon>
                                        </div>     
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_new">check</mat-icon>
                                        </div>     
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_persistent">check</mat-icon>
                                        </div>     
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_standby">check</mat-icon>
                                        </div>     
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_stopped">check</mat-icon>
                                        </div>   
                                        <div class="is_col">
                                            <mat-icon *ngIf="!!item.is_stop">check</mat-icon>
                                        </div>   
                                        <div class="fl log_upd">
                                            <ng-container *ngIf="item.serverLog && item.serverLog.updated_at">
                                                <div>{{ item.serverLog.updated_at*1000 | date:'MMM dd, Y':'':activeLang | yearPipe }}</div>
                                                <div>{{ item.serverLog.updated_at*1000 | date:'hh:mm, a':'':activeLang }}</div>
                                            </ng-container>
                                        </div>   
                                    </div>
                                    <div class="d-f-c" style="justify-content: flex-end !important;">
                                        <button mat-icon-button style="color: #686868;" [ngClass]="{'hidden_log': !item.serverLog}" (click)="item.isOpen = !item.isOpen">
                                            <mat-icon>
                                                {{ item.isOpen ? 'expand_less' : 'expand_more' }}
                                            </mat-icon>
                                        </button> 
                                        <button mat-icon-button style="color: #686868;" [ngClass]="{'hidden_log': !(item.role == 'live_stream_broadcasting' && !!item.is_active && !item.is_stop && !item.is_stopped && !item.is_delete && !item.is_deleted)}" [matMenuTriggerFor]="menuServer">
                                            <mat-icon>more_vert</mat-icon>
                                        </button> 
                                        <mat-menu #menuServer="matMenu">
                                            <button mat-menu-item (click)="stopAndDeleteServer(item)">
                                                <mat-icon>delete_forever</mat-icon>
                                                <span>{{"Stop and Delete"| translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="pauseServer(item)">
                                                <mat-icon>motion_photos_paused</mat-icon>
                                                <span>{{"Pause"| translate}}</span>
                                            </button>
                                        </mat-menu>
                                        <div class="check_wrp">
                                            <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleServer(item) : null" [checked]='serversCollection.isSelected(item)'></mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="server_item_log" *ngIf="item.isOpen && item.serverLog" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                    <div class="key_and_val d-f-c" *ngIf="item.service_message">
                                        <div class="key">service_message:</div>
                                        <div class="val">{{item.service_message}}</div>
                                    </div>
                                    <div *ngIf="!!item.serverLog.data">
                                        <ng-container *ngFor="let key of keys(item.serverLog.data)">
                                            <ng-container *ngIf="key == 'fs_usage' || key == 'fs_combined_usage'; else elseTemplateNormal">
                                                <div class="key_and_val d-f-c ml-5" style="align-items: flex-start !important;">
                                                    <div class="key">{{key}}:</div>
                                                    <div class="ml-5" style="display: flex; flex-direction: column;">
                                                        <ng-container *ngIf="item.serverLog.data[key]">
                                                            <ng-container *ngFor="let keyin of keys(item.serverLog.data[key])">
                                                                <div class="d-f-c">
                                                                    <div class="key">{{keyin}}:</div>
                                                                    <ng-container [ngSwitch]="keyin">
                                                                        <ng-container *ngSwitchCase="'usage'">
                                                                            <div class="val">{{item.serverLog.data[key][keyin]}}</div>%
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchDefault>
                                                                            <div class="val">{{item.serverLog.data[key][keyin] | filesize}}</div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateNormal>
                                                <div class="key_and_val d-f-c">
                                                    <div class="key">{{key}}:</div>
                                                    <div class="val">{{item.serverLog.data[key]}}</div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </div>
                                    <hr style="margin: 10px 0;">
                                    <h3>{{"Log" | translate}}:</h3>
                                    <div [innerHTML]="item.serverLog.log"></div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="companyes_page" *ngSwitchCase="'locks'">
                    <mat-nav-list *ngIf="locks?.length">
                        <mat-list-item class="company_item" *ngFor="let lock of locks">
                            <div class="company_block">
                                <span class="company_name">
                                    {{ lock.action }} <b>({{ 'Is locked' | translate}}: {{ lock.is_locked }})</b>
                                </span>
                            </div>

                            <div class="btns">
                                <button *ngIf="lock.is_locked" mat-icon-button color="primary" (click)="unLock(lock)">
                                    <mat-icon>lock_open</mat-icon>
                                </button>
                            </div>                    
                        </mat-list-item>
                    </mat-nav-list>
                </div>
                <div class="companyes_page" *ngSwitchCase="'card_types'">
                    <mat-nav-list *ngIf="taskTypes?.length">
                        <mat-list-item class="company_item" [ngClass]="{'g_item': item.company_id == 0}" *ngFor="let item of taskTypes; let i = index;">
                            <div class="company_block">
                                <span class="company_name">
                                    {{ item.name }} <i *ngIf="item.company_id != 0">(<strong>{{item?.company?.name}}</strong> <b>ID: {{item.company_id}}</b>)</i>
                                </span>
                            </div>

                            <div class="btns">
                                <button *ngIf="item.company_id == 0" mat-icon-button (click)="toggleHideRootCardType(item)">
                                    <ng-container *ngIf="item.is_hidden == 1; else elseTemplateHideCard">
                                        <mat-icon>visibility_off</mat-icon>
                                    </ng-container>
                                    <ng-template #elseTemplateHideCard>
                                        <mat-icon>visibility</mat-icon>
                                    </ng-template>
                                </button>
                                <button *ngIf="item.company_id != 0" mat-icon-button color="primary" (click)="publicRootCardType(item)">
                                    <mat-icon>public</mat-icon>
                                </button>
                                <button mat-icon-button color="warn" (click)="deleteRootCardType(item, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>                    
                        </mat-list-item>
                    </mat-nav-list>
                    <button class="add_btn" mat-raised-button color="primary" (click)="addType(true)">
                        <mat-icon>add</mat-icon>
                        {{ "Card type" | translate }}
                    </button>
                </div>
                <div class="companyes_page" *ngSwitchCase="'job_types'">
                    <mat-nav-list *ngIf="jobTypes?.length">
                        <mat-list-item class="company_item" [ngClass]="{'g_item': item.company_id == 0}" *ngFor="let item of jobTypes; let i = index;">
                            <div class="company_block">
                                <span class="company_name">
                                    {{ item.name }} <i *ngIf="item.company_id != 0">(<strong>{{item?.company?.name}}</strong> <b>ID: {{item.company_id}}</b>)</i>
                                </span>
                            </div>

                            <div class="btns">
                                <button *ngIf="item.company_id == 0" mat-icon-button (click)="toggleHideRootJobType(item)">
                                    <ng-container *ngIf="item.is_hidden == 1; else elseTemplateHideJob">
                                        <mat-icon>visibility_off</mat-icon>
                                    </ng-container>
                                    <ng-template #elseTemplateHideJob>
                                        <mat-icon>visibility</mat-icon>
                                    </ng-template>
                                </button>
                                <button *ngIf="item.company_id != 0" mat-icon-button color="primary" (click)="publicRootJobType(item)">
                                    <mat-icon>public</mat-icon>
                                </button>
                                <button mat-icon-button color="warn" (click)="deleteRootJobType(item, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>                   
                        </mat-list-item>
                    </mat-nav-list>
                    <button class="add_btn" mat-raised-button color="primary" (click)="addType()">
                        <mat-icon>add</mat-icon>
                        {{ "Job type" | translate }}
                    </button>
                </div>
                <div class="users_page" *ngSwitchCase="'users'">
                    <mat-nav-list *ngIf="users?.length"        
                    class="root_users"  
                    [appDeviceClass]="'custom-scroll custom-scroll-t'"      
                    infinite-scroll
                    [infiniteScrollDisabled]="isLoad"
                    [scrollWindow]="false"
                    [infiniteScrollUpDistance]="scrollUpDistance"
                    [infiniteScrollThrottle]="throttle"
                    (scrolled)="onDownUsers($event)">
                        <mat-list-item class="user_item">
                            <div class="user_block">
                                {{ "User" | translate }}
                            </div>

                            <div class="ids">
                                <div>
                                    {{ "Bravoreg" | translate }}
                                </div>
                                <div>
                                    {{ "id" | translate }} / {{ "external id" | translate }} 
                                </div>
                            </div>

                            <div class="box_wrp">
                                {{ "Upload type" | translate }}
                            </div>

                            <div class="change_root" style="color: #000;"> 
                                {{ "Super admin" | translate }}
                            </div>
                        </mat-list-item>
                        <mat-list-item class="user_item" *ngFor="let user of users">
                            <div class="user_block">
                                <ng-container *ngIf="user.image; else elseTemplatePhoto">
                                    <img [src]="user.image">
                                </ng-container>
                                <span class="user_name">
                                    {{ user.display_name }}
                                </span>
                            </div>

                            <div class="ids">
                                {{ user?.id }} / {{ user?.external_id }}
                            </div>

                            <div class="box_wrp">
                                <mat-checkbox *ngIf="_user.id == user.id" color="primary" (change)="changeUploadMethod($event)" [formControl]="uploadMethod">{{ "Smart upload" | translate }}</mat-checkbox>
                            </div>

                            <div class="change_root" [ngClass]="{'green': user.is_root == 1}" (click)="changeRoot(user)">
                                <mat-icon>
                                    <ng-container *ngIf="user.is_root == 1; else elseTemplateRoot">
                                        gpp_good
                                    </ng-container>
                                    <ng-template #elseTemplateRoot>
                                        gpp_bad
                                    </ng-template>
                                </mat-icon>
                            </div>
                         
                        </mat-list-item>
                    </mat-nav-list>
                </div>
                <div class="parameters_page" *ngSwitchCase="'parameters'">

                    <div class="search">
                        <div class="check_value_wrp">
                            <mat-form-field appearance="legacy" style="width: 100%;">
                                <mat-label>{{ "Check value in parameters" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir [panelClass]="'check_value_select'" msInfiniteScroll (infiniteScroll)="getNextValues()">
                                    <mat-option>
                                      <ngx-mat-select-search 
                                          placeholderLabel="{{ 'Search' | translate }}" 
                                          noEntriesFoundLabel="{{ 'no matching value found' | translate }}"
                                          [formControl]="valuesControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let value of allValues" [value]="value.id" [disabled]="true">
                                        <div class="d-f-c" style="justify-content: space-between;">
                                            <span style="color: rgba(0,0,0,.87) !important;">
                                              {{ value.value }}
                                            </span>
                                            <span class="parameter_name_in_option">({{ value?.parameter?.name }})</span> 
                                          </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- (chat ID: 20269) -->
                        <mat-button-toggle-group class="view_group" (change)="changeView($event)" [formControl]="originalControl">
                            <mat-button-toggle [value]="''" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Originals & Copies' | translate }}">{{ "All" | translate }}</mat-button-toggle>
                            <mat-button-toggle [value]="'0'" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Workspaces were created personally and also only what the root created personally.' | translate }}">{{ "Originals" | translate }}</mat-button-toggle>
                            <mat-button-toggle [value]="'isset'" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Workspaces were imported from the root itself (it turns out that only User can be here). All System tags are empty.' | translate }}">{{ "Copies" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group> 
                    </div>

                    <div class="reports_dash">
                        <form class="reports_dash_header">
                            <div class="column report_col">
                                <div class="title">
                                    {{"Tag" | translate}}
                                </div>
                                <mat-form-field appearance="outline" class="report_filter">
                                    <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                                    <mat-select [disableOptionCentering]="true" selectDir [formControl]="groupsTagsControl" (selectionChange)="filterParameters()">
                                        <mat-option [value]="''">
                                            {{ 'System and User' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'0'">
                                            {{ 'System' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'isset'">
                                            {{ 'User' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            
                            <div class="column report_col">
                                <div class="title">
                                    {{"Items" | translate}}
                                </div>
                                <mat-form-field appearance="outline" class="report_filter">
                                    <!-- <mat-label>{{ 'Tags values' | translate }}</mat-label> -->
                                    <mat-select [disableOptionCentering]="true" selectDir [formControl]="tagsControl" (selectionChange)="filterParameters()">
                                        <mat-option [value]="''">
                                            {{ 'System and User' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'0'">
                                            {{ 'System' | translate }}
                                        </mat-option>
                                        <mat-option [value]="'isset'">
                                            {{ 'User' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="column report_col">
                                <div class="title">
                                    {{"Tips" | translate}}
                                </div>
                            </div>
                            <div class="column report_col expand" style="align-items: flex-end !important;">
                                <div class="title">
                                    {{"Expand" | translate}}
                                </div>
                            </div>
                        </form>

                        <!--                         infinite-scroll
                        [infiniteScrollDisabled]="isLoad"
                        [scrollWindow]="false"
                        [infiniteScrollUpDistance]="scrollUpDistance"
                        [infiniteScrollThrottle]="throttle"
                        (scrolled)="onDown($event)" -->
                        <div class="reports_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'" >
                            <div class="report" *ngFor="let item of parameters">
                                <div class="report_line">
                                    <div class="column report_col">
                                        <ng-container *ngIf="item.isEddited; else elseTemplateEddited">
                                            <input class="name_input" (focusout)="saveRootParam(item)" type="text" id="param_{{item.id}}" [(ngModel)]="item.name" (change)="editRootParameter(item, $event)">
                                            <mat-icon class="cp ml-1" style="color: #219653;" (click)="saveRootParam(item)">done</mat-icon>
                                        </ng-container>
                                        <ng-template #elseTemplateEddited>
                                            <span class="big" (dblclick)="focusRootParam(item)">
                                                {{ item?.name }}
                                            </span>
                      
                                            <button class="show_on_hover" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuBig" aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menuBig="matMenu">
                                                <button mat-menu-item (click)="focusRootParam(item)">
                                                    <mat-icon>edit</mat-icon>
                                                    <span>{{"Edit"| translate}}</span>
                                                </button>
                                                <button mat-menu-item (click)="deleteRootParam(item, i)">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>{{"Delete"| translate}}</span>
                                                </button>
                                            </mat-menu>
                                        </ng-template>
                                    </div>
                                    <div class="column report_col">
                                        <span class="normal">
                                            {{ item?.company_id != 0 ? ("User" | translate) : ("System" | translate) }}
                                        </span>
                                        <button class="show_on_hover" *ngIf="item.original_id == 0 && item.company_id != 0" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuSmall" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menuSmall="matMenu">
                                            <button mat-menu-item (click)="createParamAsSystem(item)">
                                                <mat-icon>add_moderator</mat-icon>
                                                <span>{{ "Set as system" | translate}}</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div class="column report_col">
                                        <button *ngIf="item?.tips_count > 0" mat-icon-button style="color: #333;" (click)="openTips(item)">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px;"><path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#333"></path></svg>
                                        </button>
                                    </div>
                                    <div class="column report_col expand">
                                        <button mat-icon-button style="color: #686868;" (click)="item.isOpen = !item.isOpen">
                                            <mat-icon>
                                                {{ item.isOpen ? 'expand_less' : 'expand_more' }}
                                            </mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="param_values report_sub_lines" *ngIf="item.isOpen">
                                    <div class="reports_add_line">
                                        <div class="add_line" (click)="addRootParameterValue(item)">
                                            <mat-icon>add_circle</mat-icon>
                                            <span class="ml-1">{{"Add tag value" | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="param_value report_sub_line" *ngFor="let val of item.values; let k = index;">
                                        <div class="column report_col">
                                            <ng-container *ngIf="val.isEddited; else elseTemplateEddited">
                                                <input class="value_input" (focusout)="saveValue(item, val)" type="text" id="val_{{item.id}}_{{val.id}}" [(ngModel)]="val.value" (change)="editRootParameterValue(item, val, $event)">
                                                <mat-icon class="cp ml-1" style="color: #219653;" (click)="saveValue(item, val)">done</mat-icon>
                                            </ng-container>
                                            <ng-template #elseTemplateEddited>
                                                <span class="normal" (dblclick)="focusValue(item, val)">
                                                    {{val.value}}
                                                </span>
                                                <button class="show_on_hover" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuBig" aria-label="Example icon-button with a menu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menuBig="matMenu">
                                                    <button mat-menu-item (click)="focusValue(item, val)">
                                                        <mat-icon>edit</mat-icon>
                                                        <span>{{"Edit"| translate}}</span>
                                                    </button>
                                                    <button mat-menu-item (click)="deleteValue(item, val, k)">
                                                        <mat-icon>delete</mat-icon>
                                                        <span>{{"Delete"| translate}}</span>
                                                    </button>
                                                </mat-menu>
                                            </ng-template>
                                        </div>
                                        <div class="column report_col">
                                            <span class="small">
                                                {{ val?.company_id != 0 ? ("User" | translate) : ("System" | translate) }}
                                            </span>
                                            <div class="value_assigned" *ngIf="val.parameter && (val.parameter.is_file_meta_device == 1 || val.parameter.is_file_meta_orientation == 1)">
                                                <mat-checkbox color="primary" (change)="changeValueAssigned($event, val)" [(ngModel)]="val.is_not_automatically_assigned">{{ "Not Auto-Assigned" | translate }}</mat-checkbox>
                                            </div>
                                            <button class="show_on_hover" *ngIf="val.parameter && val.original_id == 0 && val.parameter.original_id != 0" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuSmall" aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menuSmall="matMenu">
                                                <button mat-menu-item (click)="createValAsSystem(val)">
                                                    <mat-icon>add_moderator</mat-icon>
                                                    <span>{{ "Set as system" | translate}}</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                        <div class="column report_col">
                                            <button *ngIf="val?.tips_count > 0" mat-icon-button style="color: #333;" (click)="openTips(item, val)">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px;"><path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#333"></path></svg>
                                            </button>
                                        </div>
                                        <div class="column report_col expand">
                                        </div>
                                    </div>
              
                                </div>
                            </div>
                        </div>
                        <!-- <div class="reports_dash_footer">
                            <div class="column report_col">
                                <span class="big">
                                    {{ "Total" | translate }}
                                </span>
                            </div>
                            <div class="column day_view_col">
                                <span class="normal">
                                    {{ totalValues.dayTotal | number:'':'fr-FR' }}
                                </span>
                            </div>
                        </div> -->
                    </div>

                    <div>
                        <button class="add_btn" mat-raised-button color="primary" (click)="addRootParameter()">
                            <mat-icon>add</mat-icon>
                            {{ "Root Parameter" | translate }}
                        </button>
                    </div>
                </div>
                <div *ngSwitchDefault>
                
                </div>
            </div>
        </div>
    </mat-drawer-content>
  </mat-drawer-container>
  

<ng-template #elseTemplatePhoto>
    <mat-icon>account_circle</mat-icon>
</ng-template>