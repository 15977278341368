import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { concat, forkJoin, of, ReplaySubject } from 'rxjs';
import { concatAll, debounceTime, finalize, last, map, switchMap, take, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { environment } from 'src/environments/environment';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TargetParametersComponent } from '../target-parameters/target-parameters.component';

@Component({
  selector: 'app-work-multi-add',
  templateUrl: './work-multi-add.component.html',
  styleUrls: ['./work-multi-add.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class WorkMultiAddComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public workEmpl: FormGroup;
  public company_id: any;
  public operations: any;
  public operationStatuses: any;
  public additional: FormGroup;
  public templateForm: FormGroup;
  public employees: any;
  public prioritys = prioritys;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public showOps: boolean = false;
  public showEm: boolean = false;
  public showAddEm: boolean = false;
  public addEmployeeMoreControl: FormControl = new FormControl();
  public addEmployees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);

  
  public parameters: any;
  public allValues: any;

  public templates: any;
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public pCliSwitch: boolean = false;
  public cliSwitch: boolean = false;

  public clients = [];
  public partnerClients = [];
  public clients_ids = [];
  public partnerClients_ids = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkMultiAddComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private scenariosService: ScenariosService,
    private ls: LoadingService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private parametersService: ParametersService,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private languageService: LanguageService,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    this.company_id = this.data.company_id;

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();

    this.workEmpl = this.fb.group({
      company_id: this.company_id,
      task_operation_id: 0,
      rate: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
      price: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
      employee_id: 0,
      is_manager: 1,
      status_id: 0,
    })
    
    this.form = this.fb.group({
      completed_at: '',
      operation_id: ['', Validators.required],
      employee_id: 0,
      status_id: ['', Validators.required],
      name: '',
      comment: '',
      create_parameter_values_to_task: [],
      private_comment: '',
      employee_comment: '',
      priority: 0
    })
    
    this.additional = this.fb.group({
      company_id: this.company_id,
      task_operation_id: '',
      employee_id: [],
      status_id: '',
      is_manager: 0
    })

    this.templateForm = this.fb.group({
      company_id: this.company_id,
      task_operation_id: 0,
      template_id: []
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )
    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.addEmployeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchAddEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();
    this.getPartners(); 
    this.getParameters();
    this.getLangData();
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.data.company.id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parameters = res;
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
        this.parameters.forEach(element => {
          element.activeValues = [];
          if (element.id == 0) {
            element.values = this.allValues;
          } else {
            element.values = this.allValues.filter(u => u.parameter_id = element.id);
          }
        });
      })
    )
  }

  togglePrimary(tag) {
    tag.is_primary = !tag.is_primary;
  }

  onRemoveJobTag(tag, ind) {
    if (this.parameters && this.parameters[0] && this.parameters[0].hasOwnProperty('activeValues')) {
      this.parameters[0].activeValues.splice(ind, 1);
      this.setParams();
    }
  }

  openTargetValues() {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: this.parameters,
      highlightParam: this.data.param_id
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            this.parameters = result.data;
            if (this.parameters && this.parameters[0] && this.parameters[0].hasOwnProperty('activeValues')) {
              this.setParams();
            }
          }
        }
      })
    )
  }

  setParams() {
    if (this.data.param_id) {
      this.form.patchValue({
        create_parameter_values_to_task: this.parameters[0].activeValues.filter(x => x.parameter_id == this.data.param_id).map(u => u.id)
      })
      // this.tabsSwitch = true;
      // setTimeout(() => {
      //   this.tabsSwitch = false
      // }, 0);
    }
  }


  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        this.partners$.next(this.partners.slice())
        console.log("getPartners", this.partners)
      })
    )
  }

  getPartnerById(id) {
    // console.log("partners", id);
    // console.log("partners", this.partners.find(el => el.partner_company_id == id));
    if (!this.partners || this.partners.filter(el => el.partner_company_id == id).length == 0) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  logCli() {
    console.log(this.form.value)
    console.log(this.clients)
    console.log(this.clients_ids)
    console.log(this.partnerClients)
    console.log(this.partnerClients_ids)

    let allClients = [...this.clients, ...this.partnerClients]

    console.log("allClients", allClients)
  }

  includesCli(id) {
    return this.clients.filter(x => x.client_employee_id == id).length > 0;
  }
  
  setCli(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.clients.find(x => x.client_employee_id == id).is_principal = val
  }

  setCliPartn(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.partnerClients.find(x => x.client_partner_company_id == id).is_principal = val
  }

  getCli(id) {
    return this.clients.find(x => x.client_employee_id == id);
  }
  
  getCliPartn(id) {
    return this.partnerClients.find(x => x.client_partner_company_id == id);
  }

  includesCliPartn(id) {
    return this.partnerClients.filter(x => x.client_partner_company_id == id).length > 0;
  }

  toggleSelectClientChip(e) {
    console.log('toggleSelectClientChip', e)

    this.clients.forEach((obj, ind) => {
      if (!this.clients_ids.filter(id => obj.client_employee_id == id).length) {
        this.clients.splice(ind, 1)
      }
    });

    this.clients_ids.forEach((id, ind) => {
      if (!this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.clients_ids);
    console.log(this.clients);
  }

  toggleSelectClientPartnerChip(e) {
    console.log('toggleSelectClientPartnerChip', e)

    this.partnerClients.forEach((obj, ind) => {
      if (!this.partnerClients_ids.filter(id => obj.client_partner_company_id == id).length) {
        this.partnerClients.splice(ind, 1)
      }
    });

    this.partnerClients_ids.forEach((id, ind) => {
      if (!this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.partnerClients_ids);
    console.log(this.partnerClients);
  }

  toggleClientPartnerChip = (chip: any) => {
    console.log("toggleClientPartnerChip", chip)
    let id = !!chip.partner_company_id ? chip.partner_company_id : chip.value
    if (!!id) {
      if (this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.splice(this.partnerClients.indexOf(this.partnerClients.find(x => x.client_partner_company_id == id)), 1)
      } else {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    }
    
    this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);
    // if (!chip.partner_company_id) {
    //   this.pCliSwitch = true;

    //   setTimeout(() => {
    //     this.pCliSwitch = false;
    //   }, 0)
    // }
  }

  toggleClientChip = (chip: any) => {
    console.log("toggleClientChip", chip)
    let id = !!chip.id ? chip.id : null;
    if (!!id) {
      if (this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.splice(this.clients.indexOf(this.clients.find(x => x.client_employee_id == id)), 1)
      } else {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    }

    this.clients_ids = this.clients.map(x => x.client_employee_id);

    // if (!chip.id) {
    //   this.cliSwitch = true;

    //   setTimeout(() => {
    //     this.cliSwitch = false;
    //   }, 0)
    // }
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x || 1, {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.templates = [].concat(...res)
        this.templates$.next(this.templates.slice());
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  toggleChipTemplate = (chip: any) => {
    if (!!this.templateForm.value.template_id && this.templateForm.value.template_id.includes(chip.id)) {
      let val = [...this.templateForm.value.template_id]
      val.splice(this.templateForm.value.template_id.indexOf(chip.id), 1)
      this.templateForm.patchValue({
        template_id: val
      })
    } else {
      if (!!this.templateForm.value.template_id) {
        let val = [...this.templateForm.value.template_id]
        val.push(chip.id)
        this.templateForm.patchValue({
          template_id: val
        })
      } else {
        this.templateForm.patchValue({
          template_id: [chip.id]
        })
      }
    }
  }

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  onSearchAddEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.addEmployees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.addEmployees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  selectType(type) {
    this.form.patchValue({
      operation_id: type.id || type.value
    })
  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  showMoreOps() {
    this.showOps = true;
  }

  showMoreEm() {
    this.showEm = true;
  }

  showMoreAddEm() {
    this.showAddEm = true;
  }

  toggleChip = (chip: any) => {
    this.workEmpl.patchValue({
      employee_id: !!chip.id ? chip.id : chip.value
    })
    if (!!this.additional.value.employee_id && this.additional.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.additional.value.employee_id]
      val.splice(this.additional.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.additional.patchValue({
        employee_id: val
      })
    }
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }
  
  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.company_id).subscribe(resp => {
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice());
  //       this.addEmployees$.next(this.employees.slice());
  //     })
  //   )
  // }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
              this.addEmployees$.next(this.employees.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }
  
  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company_id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice());
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  toggleAddChip = (chip: any) => {
    if (!!this.additional.value.employee_id && this.additional.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.additional.value.employee_id]
      val.splice(this.additional.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.additional.patchValue({
        employee_id: val
      })
    } else {
      if (!!this.additional.value.employee_id) {
        let val = [...this.additional.value.employee_id]
        val.push(!!chip.id ? chip.id : chip.value)
        this.additional.patchValue({
          employee_id: val
        })
      } else {
        this.additional.patchValue({
          employee_id: [!!chip.id ? chip.id : chip.value]
        })
      }
    }

    if ((!!chip.id ? chip.id : chip.value) == this.workEmpl.value.employee_id) {
      this.workEmpl.patchValue({
        employee_id: ''
      })
    }
  }

  multiAddClients(works) {
    this.isSubmit = true;
  
    let sbmtData = [];
    let partsSbmtData = [];
  
    works.forEach(work => {
      this.additional.value.employee_id.forEach(c_id => {
        sbmtData.push({
          "path": `/api/task-client/`,
          "query": {},
          "method": "POST",
          "body": Object.assign({
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          }, {task_id: work.body.task_id, task_operation_id: work.body.id, client_employee_id: c_id})
        });
      })
    })
  
    for (let i = 0; i < Math.ceil(sbmtData.length/5); i++) {
      partsSbmtData.push(sbmtData.slice(i*5, 5 + i*5))
    }
  
    let count = 1;
    return concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
      tap(el => {
        this.ls.requests$.next({
          value: Math.round((100 / partsSbmtData.length) * count),
          target: "Adding clients to works" 
        })
        count++;
      })
    )
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;
    if (String(this.form.value['rate']).indexOf(",") != -1) {
      this.form.value['rate'] = this.form.value['rate'].replace(",", ".");
    }
    if (String(this.form.value['price']).indexOf(",") != -1) {
      this.form.value['price'] = this.form.value['price'].replace(",", ".");
    }
    if (!!this.form.value.completed_at && !!this.form.value.completed_at._d) {
      this.form.value.completed_at = moment(this.form.value.completed_at._d).format("X");
    } else {
      delete this.form.value.completed_at;
    }

    let paramsValues = []
    if (this.parameters && this.parameters.length && this.parameters[0].activeValues) {
      this.parameters[0].activeValues.forEach(val => {
        paramsValues.push({
          parameter_id: val.parameter_id,
          parameter_value_id: val.id,
          is_primary: val.is_primary
        })
      })
    }

    this.form.patchValue({create_parameter_values_to_task: paramsValues})

    let multiData = [];

    this.data.taskCollection.forEach(task_id => {
      multiData.push({
        "path": `/api/task-operation/`,
        "query": {company_id: this.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          task_id: task_id
      }, this.form.value) 
      })
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(multiData).pipe(
        switchMap(val => {
          if (!!this.workEmpl.value.employee_id) {
            let sbmtData = [];
            val.forEach(work => {
              sbmtData.push({
                "path": `/api/task-employee/`,
                "query": {company_id: this.company_id},
                "method": "POST",
                "body": Object.assign({
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                }, {employee_id: this.workEmpl.value.employee_id, task_operation_id: work.body.id, status_id: work.body.status_id, company_id: this.company_id, task_id: work.body.task_id, is_manager: 1})
              })
            })

            return this.taskService.multiRequest(sbmtData).pipe(map(() => val))
          } else {
            of(val)
          }
        }),
        switchMap((val) => {
          let allClients = [...this.clients, ...this.partnerClients];
          if (allClients && allClients.length) {
            let postCliData = [];

            val.forEach(work => {
              allClients.forEach(element => {
                postCliData.push({
                  "path": `/api/task-client/`,
                  "query": {company_id: this.company_id},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    task_id: work.body.task_id,
                    task_operation_id: work.body.id,
                }, element) 
                })
              });
            })

            return this.taskService.multiRequest(postCliData).pipe(map(x => val))
          } else {
            return of(val)
          }
        }),
        switchMap((val) => {
          if (!!this.templateForm.value.template_id && this.templateForm.value.template_id.length) {
            let scenSbmtData = [];

            val.forEach(work => {
              this.templateForm.value.template_id.forEach(element => {
                scenSbmtData.push({
                  "path": `/api/automation-scenario/`,
                  "query": {company_id: this.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    template_id: element,
                    task_operation_id: work.body.id,
                    company_id: this.company_id,
                  }
                })
              });
            })

            return this.taskService.multiRequest(scenSbmtData).pipe(
              switchMap(res => {
                let tryData = [];
                res.forEach(x => {
                  tryData.push({
                    "path": `/api/automation-scenario/execute/${x.body.id}/`,
                    "query": {company_id: this.company_id},
                    "method": "POST",
                    "body": {}
                  })
                });
                return this.taskService.multiRequest(tryData)
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
        switchMap(val => {
          if (!!this.additional.value.employee_id && this.additional.value.employee_id.length) {  
            let sbmtData = [];
            let partsSbmtData = [];
          
            val.forEach(work => {
              this.additional.value.employee_id.forEach(c_id => {
                sbmtData.push({
                  "path": `/api/task-employee/`,
                  "query": {},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  }, {employee_id: c_id, task_operation_id: work.body.id, status_id: work.body.status_id, company_id: this.company_id, task_id: work.body.task_id, is_manager: 0})
                });
              })
            })
          
            for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
              partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
            }
          
            let count = 1;
            return concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
              tap(el => {
                if (partsSbmtData.length > 1) {
                  this.ls.requests$.next({
                    value: Math.round((100 / partsSbmtData.length) * count),
                    target: "Adding advisers to works" 
                  })
                }
                count++;
              }),
              finalize(() => {
                this.isSubmit = false;
                this.dialogRef.close({event: "update", data: val});
              })
            )
          } else {
            this.isSubmit = false;
            this.dialogRef.close({event: "update", data: val});
            return of(val)
          }
        })
      ).subscribe(resp => {
        console.log(resp);
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
