<h2 mat-dialog-title class="dialog_title" *ngIf="!isGlobal">
    <span (click)="log()">
        {{ (!data.task ? 'Workspace' : (!data.task_operation_id ? "Card" : "Job") + " projects") | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="vid_projects" [ngClass]="{'global': isGlobal}">
        <div class="head_wrp">
            <div class="project_values">
                <div class="id">{{"ID" | translate}}</div>
                <div class="card_job card_job_head d-f-c" *ngIf="!data.task">
                    <div>
                        <div>{{"Card" | translate}}</div>
                        <div>{{"Job" | translate}}</div>
                    </div>
                    <div>{{"ID" | translate}}</div>
                </div>
                <div class="name" style="display: flex; align-items: center; justify-content: space-between;">
                    <span>
                        {{"Project Name" | translate}} 
                    </span>
                    <div class="server_btn" *ngIf="!!data?.user?.is_root && !!globalFilter?.is_processing" (click)="openServersTable()">
                        <span>
                          {{"Servers Table" | translate}}
                        </span>
                    </div>
                </div>
                <div class="templates_col">{{"Templates" | translate}}</div>
                <div class="employees_col">{{"Employees" | translate}}</div>
                <div class="created">{{"Created At" | translate}}</div>
                <div class="stage">{{"Stage" | translate}}</div>
                <div class="units">{{"Units" | translate}}</div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Queue time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>more_time</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Processing start time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>schedule</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Processing end time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>access_time_filled</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'How many seconds did it take' | translate }}" [matTooltipPosition]="'above'"><mat-icon>alarm_on</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Preparing' | translate }}" [matTooltipPosition]="'above'"><mat-icon>downloading</mat-icon></div>
            </div>
            
            <div class="company_btns">
                {{"Actions" | translate}}
            </div>
        </div>
        <mat-list 
        role="list" 
        class="scrolled_list" 
        [appDeviceClass]="'custom-scroll custom-scroll-t'"           
        infinite-scroll
        [infiniteScrollDisabled]="isLoad"
        [scrollWindow]="false"
        [infiniteScrollUpDistance]="scrollUpDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onDown($event)">
            <mat-list-item *ngFor="let project of projects; let i = index;" role="listitem" class="company_item proj_list_item">
                <div class="project_out">
                    <div class="project_in">
                        <div class="project_values">
                            <div class="id">
                                <div>{{project.id}}</div>
                                <div class="d-f-c" >
                                    <div class="output_in region" style="font-size: 8px !important; opacity: .8; margin-right: 3.5px;" *ngIf="project.editor_version">
                                        {{project.editor_version}}
                                    </div>
                                    <div class="output_in" [ngClass]="{'show_on_hover': !showAddOptions}" *ngIf="!!data?.user?.is_root" style="opacity: .8; font-size: 8px !important;">
                                        {{ project?.filesize_original | filesize:2:true }}<ng-container *ngIf="project.filesize"> - {{project?.filesize | filesize:2:true }}</ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="card_job" *ngIf="!data.task">
                                <div class="cp" *ngIf="!!project?.task_id" (click)="openTask({id: project?.task_id})">
                                    {{project?.task_id}}
                                    <a (click)="openInNew($event, project, 'task_id')" [routerLink]="['/projects']" [queryParams]="{company_id: company?.id, order_by: 'id_desc', count: '1', task_id: project?.task_id}" class="open_in_new"><mat-icon>open_in_new</mat-icon></a>
                                </div>
                                <div class="cp" *ngIf="!!project?.task_operation_id" (click)="openJob(project?.task_id, project?.task_operation_id, project?.output_location)">
                                    {{project.task_operation_id}}
                                    <a (click)="openInNew($event, project, 'task_operation_id')" [routerLink]="['/projects']" [queryParams]="{company_id: company?.id, order_by: 'id_desc', count: '1', task_operation_id: project?.task_operation_id}" class="open_in_new"><mat-icon>open_in_new</mat-icon></a>
                                </div>
                            </div>
                            <div class="name" (click)="project?.runHistory && project?.runHistory.length ? toggleProject(project) : null" [ngClass]="{'blue': project?.runHistory && project?.runHistory.length}">
                                <div class="name_in text_one_line">{{project.name}}</div>
                                <div class="group_in text_one_line" *ngIf="project?.task?.group?.name">{{project?.task?.group?.name}}</div>
                                <div class="output_in" [ngClass]="{'show_on_hover': !showAddOptions}" *ngIf="!!data?.user?.is_root">{{project.server_id}}<ng-container *ngIf="project.server_id && project?.region">, </ng-container><span class="region" *ngIf="project?.region">{{project?.region}}</span></div>
                            </div>

                            <div class="templates_col">
                                <div *ngFor="let item of project?.templateIds" class="text_one_line">
                                    {{getTemplateById(item)?.name}}
                                </div>
                            </div>
                            
                            <div class="employees_col">
                                <div class="employees" (click)="$event.preventDefault(); $event.stopPropagation(); openExecutors(job)" [ngStyle]="{'width': '98px', 'min-width': '98px'}">
                                    <ng-container *ngIf="project?.employees && project?.employees?.length">
                                        <div class="more_empls">
                                            <ng-container *ngFor="let item of project?.employees; let u = index;">
                                              <ng-container *ngIf="u <= 3">
                                                <ng-container *ngIf="item?.employee?.image; else elseMobWorkTemplate">
                                                  <img 
                                                  #tooltip="matTooltip" 
                                                  [ngClass]="{
                                                    'first': item?.employeeStatus?.status_id == '1',
                                                    'second': item?.employeeStatus?.status_id == '2',
                                                    'third': item?.employeeStatus?.status_id == '3',
                                                    'fours': item?.employeeStatus?.status_id == '4',
                                                    'fives': item?.employeeStatus?.status_id == '5',
                                                    'sixs': item?.employeeStatus?.status_id == '6',
                                                    'nine': item?.employeeStatus?.status_id == '97',
                                                    'seven': item?.employeeStatus?.status_id == '98',
                                                    'eight': item?.employeeStatus?.status_id == '99'}"
                                                  matTooltip="{{item?.employee?.name}}"
                                                  [matTooltipPosition]="'above'" 
                                                  [src]="item?.employee?.image" alt="" [ngStyle]="{'z-index': project?.employees.length - u, 'left': u*17.5 + 'px'}">
                                                </ng-container>
                                                <ng-template #elseMobWorkTemplate>
                                                  <ng-container *ngIf="item?.is_partner; else elsePartner">
                                                    <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                      <img 
                                                      [ngClass]="{
                                                        'first': item?.partnerCompanyStatus?.status_id == '1',
                                                        'second': item?.partnerCompanyStatus?.status_id == '2',
                                                        'third': item?.partnerCompanyStatus?.status_id == '3',
                                                        'fours': item?.partnerCompanyStatus?.status_id == '4',
                                                        'fives': item?.partnerCompanyStatus?.status_id == '5',
                                                        'sixs': item?.partnerCompanyStatus?.status_id == '6',
                                                        'nine': item?.partnerCompanyStatus?.status_id == '97',
                                                        'seven': item?.partnerCompanyStatus?.status_id == '98',
                                                        'eight': item?.partnerCompanyStatus?.status_id == '99'}"
                                                      #tooltip="matTooltip" 
                                                      [ngStyle]="{'z-index': project?.employees.length - u, 'left': u*17.5 + 'px'}"
                                                      matTooltip="{{item?.partnerCompany?.name}}"
                                                      [matTooltipPosition]="'above'" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                                    </ng-container>
                                                    <ng-template #elseTemplatePartnerAvatar>
                                                      <img 
                                                      [ngClass]="{
                                                        'first': item?.partnerCompanyStatus?.status_id == '1',
                                                        'second': item?.partnerCompanyStatus?.status_id == '2',
                                                        'third': item?.partnerCompanyStatus?.status_id == '3',
                                                        'fours': item?.partnerCompanyStatus?.status_id == '4',
                                                        'fives': item?.partnerCompanyStatus?.status_id == '5',
                                                        'sixs': item?.partnerCompanyStatus?.status_id == '6',
                                                        'nine': item?.partnerCompanyStatus?.status_id == '97',
                                                        'seven': item?.partnerCompanyStatus?.status_id == '98',
                                                        'eight': item?.partnerCompanyStatus?.status_id == '99'}"
                                                      #tooltip="matTooltip" 
                                                      [ngStyle]="{'z-index': project?.employees.length - u, 'left': u*17.5 + 'px'}"
                                                      matTooltip="{{item?.partnerCompany?.name}}"
                                                      [matTooltipPosition]="'above'" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                                    </ng-template>
                                                  </ng-container>
                                                  <ng-template #elsePartner>
                                                    <mat-icon 
                                                    #tooltip="matTooltip" 
                                                    [ngStyle]="{'z-index': project?.employees.length - u, 'left': u*17.5 + 'px'}"
                                                    [ngClass]="{
                                                        'first': item?.employeeStatus?.status_id == '1',
                                                        'second': item?.employeeStatus?.status_id == '2',
                                                        'third': item?.employeeStatus?.status_id == '3',
                                                        'fours': item?.employeeStatus?.status_id == '4',
                                                        'fives': item?.employeeStatus?.status_id == '5',
                                                        'sixs': item?.employeeStatus?.status_id == '6',
                                                        'nine': item?.employeeStatus?.status_id == '97',
                                                        'seven': item?.employeeStatus?.status_id == '98',
                                                        'eight': item?.employeeStatus?.status_id == '99'}"
                                                    matTooltip="{{item?.employee?.name}}"
                                                    [matTooltipPosition]="'above'">account_circle</mat-icon>
                                                  </ng-template>
                                                </ng-template>
                                              </ng-container>      
                                            </ng-container>
                                        </div>
                                        <ng-container *ngIf="project?.employees?.length > 4;">
                                          <div class="plus">
                                            +{{project?.employees?.length - 4}}
                                          </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="created">
                                <div class="creator" *ngIf="project?.createdEmployee" [ngClass]="{'show_on_hover': !showAddOptions}" #tooltip="matTooltip" 
                                matTooltip="{{project?.createdEmployee?.name}}"
                                [matTooltipPosition]="'above'">
                                    <app-user [class]="'only_photo'" [employee]="project?.createdEmployee"></app-user>
                                </div>
                                <span #tooltip="matTooltip" matTooltip="{{ project.created_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project.created_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <!-- <div class="time"><mat-icon *ngIf="project.task_operation_id" class="show_on_hover" #tooltip="matTooltip" matTooltip="{{project.output_location}}" [matTooltipPosition]="'above'" (click)="openJob(project?.task_id, project?.task_operation_id, project.output_location)">folder</mat-icon></div> -->
                            <!-- <mat-icon class="cp icon_12" *ngIf="project.task_operation_id" [ngClass]="{'show_on_hover': !project?.is_clicked}" #tooltip="matTooltip" matTooltip="{{project.output_location}}" [matTooltipPosition]="'above'" (click)="$event.preventDefault(); $event.stopPropagation(); openContext($event, project)">folder</mat-icon> -->
                            <div class="stage" style="position: relative;">
                                <mat-icon class="cp icon_12" *ngIf="project.task_operation_id" [ngClass]="{'show_on_hover': !project?.is_clicked}" #tooltip="matTooltip" matTooltip="{{project.output_location}}" [matTooltipPosition]="'above'" (click)="$event.preventDefault(); $event.stopPropagation(); openLiteJob(project, 3, project.output_location)">folder</mat-icon>
                                <span *ngIf="project.is_draft">{{"Draft" | translate}}</span>
                                <span *ngIf="project.is_error" #tooltip="matTooltip" matTooltip="{{ !!data?.user?.is_root ? project.processing_error : '' }}" [matTooltipPosition]="'above'" style="color: #c0392b;">{{"Error" | translate}}</span>
                                <ng-container *ngIf="project.is_processing">
                                    <span *ngIf="project.processing_download_time == 0" style="color: #2980b9;">
                                        {{"Preparing" | translate}}
                                    </span>
                                    <span *ngIf="project.processing_download_time != 0" style="color: #3867d6;">
                                        {{"Processing" | translate}}
                                    </span>
                                </ng-container>
                                <span *ngIf="project.is_ready" style="color: #27ae60;">{{"Ready" | translate}}</span>
                                <span *ngIf="project.is_reset == 1" style="color: #ff5252;">{{"Is Reset" | translate}}</span>
                                <span *ngIf="project.is_to_process" style="color: #f1c40f;">{{"To Process" | translate}}</span>
                            </div>
                            <div class="units">
                                <span *ngIf="project?.total_price_unit">{{project?.total_price_unit}}</span>
                            </div>
                            <div class="time">
                                <span #tooltip="matTooltip" matTooltip="{{ project.processing_queue_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project.processing_queue_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <div class="time">
                                <span #tooltip="matTooltip" matTooltip="{{ project.processing_started_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project.processing_started_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <div class="time">
                                <span #tooltip="matTooltip" matTooltip="{{ project.processing_completed_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project.processing_completed_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <div class="time">
                                <span>{{project.processing_time | timeFormat}}</span>
                            </div>
                            <div class="time">
                                <span>{{project.processing_download_time | timeFormat}}</span>
                            </div>
                        </div>
                        
                        <div class="company_btns d-f-c">
                            <button [ngClass]="{'blue_dots': !!project.history_count}"  style="color: #ccc;" mat-icon-button [matMenuTriggerFor]="projDotsMenu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #projDotsMenu="matMenu" xPosition="before">
                                <div class="select_wrp_out d-f-c" mat-menu-item (click)="$event.preventDefault(); $event.stopPropagation(); priority.toggle()">
                                    <div class="select_wrp" style="position: relative; height: 48px;">
                                        <div>
                                            {{ "Priority" | translate }} :
                                            <b>
                                                <ng-container [ngSwitch]="project.waiting_time_limit">
                                                    <ng-container *ngSwitchCase="1">
                                                        {{ "Now" | translate }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="300">
                                                        {{ "Immediately" | translate }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="1200">
                                                        {{ "Urgent" | translate }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="14400">
                                                        {{ "High" | translate }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="43200">
                                                        {{ "Middle" | translate }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="86400">
                                                        {{ "Low" | translate }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="172800">
                                                        {{ "No priority" | translate }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        {{ "Priority" | translate }}
                                                    </ng-container>
                                                </ng-container>
                                            </b>
                                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                <mat-select disableOptionCentering #priority [(ngModel)]="project.waiting_time_limit" (selectionChange)="editProjPriority(project)">
                                                    <mat-option value="1" *ngIf="!!data?.user?.is_root" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 1}">
                                                        {{ "Now" | translate }}
                                                    </mat-option>
                                                    <mat-option value="300" *ngIf="!!data?.user?.is_root" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 300}">
                                                        {{ "Immediately" | translate }} (x3 VPU) 
                                                    </mat-option>
                                                    <mat-option value="1200" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 1200}">
                                                        {{ "Urgent" | translate }} (x2 VPU)
                                                    </mat-option>
                                                    <mat-option value="14400" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 14400}">
                                                        {{ "High" | translate }} (x1.2 VPU)
                                                    </mat-option>
                                                    <mat-option value="43200" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 43200}">
                                                        {{ "Middle" | translate }} (x1.2 VPU)
                                                    </mat-option>
                                                    <mat-option value="86400" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 86400}">
                                                        {{ "Low" | translate }}
                                                    </mat-option>
                                                    <mat-option value="172800" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 172800}">
                                                        {{ "No priority" | translate }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div (click)="editProjPriority(project)">
                                        {{"Edit" | translate}}
                                    </div> -->
                                </div>
                                <mat-divider></mat-divider>
                                <ng-container *ngIf="!!project?.is_to_process">
                                    <button (click)="editProjStatus(project, 'is_draft')" mat-menu-item>
                                        <mat-icon class="mr-1">edit_square</mat-icon>
                                        {{ 'Set Draft status' | translate }}
                                    </button>
                                    <mat-divider></mat-divider>
                                </ng-container>
                                
                                <button [matMenuTriggerFor]="menuAsNew" *ngIf="!!project.history_count" mat-menu-item>
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'Duplicate' | translate }}
                                </button>
                                <button [matMenuTriggerFor]="menu" *ngIf="!!project.history_count" mat-menu-item>
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'Open' | translate }}
                                </button>
                                <button *ngIf="!(project.is_processing || project.is_to_process || project.is_reset == 1)" mat-menu-item (click)="refreshProject(project, i)">
                                    <mat-icon class="mr-1">refresh</mat-icon>
                                    {{ 'Export' | translate }}
                                </button>
                                <button *ngIf="!!data?.user?.is_root" mat-menu-item (click)="deleteProject(project, i)">
                                    <mat-icon class="mr-1">delete</mat-icon>
                                    {{ 'Delete' | translate }}
                                </button>
                                <mat-divider></mat-divider>
                                <button [matMenuTriggerFor]="oldMenu" *ngIf="!!project.history_count" mat-menu-item>
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'Old' | translate }}
                                </button>
                            </mat-menu>

                            <mat-menu #oldMenu="matMenu" xPosition="before">
                                <button [matMenuTriggerFor]="oldMenuDubpl" mat-menu-item>
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'Duplicate' | translate }}
                                </button>
                                <button [matMenuTriggerFor]="oldMenuOpen" mat-menu-item>
                                  <mat-icon class="mr-1">movie_edit</mat-icon>
                                  {{ 'Open' | translate }}
                              </button>
                            </mat-menu>

                            <mat-menu #oldMenuDubpl="matMenu" xPosition="before">
                                <button  mat-menu-item (click)="videoEditorV2(project, i, true)">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V2' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV4(project, i, true)">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V2.1' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV3(project, i, true)">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V3' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV5(project, i, true)">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V4' | translate }}
                                </button>
                            </mat-menu>

                            <mat-menu #oldMenuOpen="matMenu" xPosition="before">
                                <button  mat-menu-item (click)="videoEditorV2(project, i)">
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'V2' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV4(project, i)">
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'V2.1' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV3(project, i)">
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'V3' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV5(project, i)">
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'V4' | translate }}
                                </button>
                            </mat-menu>

                            <mat-menu #menuAsNew="matMenu" xPosition="before">
                                <button  mat-menu-item (click)="V5VideoEditor(project, i, true)">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V5' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV6(project, i, true)">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V5.1' | translate }}
                                </button>
                            </mat-menu>
    
                            <mat-menu #menu="matMenu" xPosition="before">
                                <button  mat-menu-item (click)="V5VideoEditor(project, i)">
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'V5' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV6(project, i)">
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'V5.1' | translate }}
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="project_history" *ngIf="project?.isOpen">
                        <div class="history_item" *ngFor="let item of project?.runHistory">
                            <div class="project_values">
                                <div class="id">
                                    <div>&nbsp;</div>
                                    <div class="d-f-c">
                                        <div class="output_in region" style="opacity: .8; margin-right: 5px;">
                                            {{item.editor_version}}
                                        </div>
                                    </div>
                                </div>
                                <div class="card_job" *ngIf="!data.task"></div>
                                <div class="name">
                                    <app-user [class]="'user_div'" [employee]="item.employee"></app-user>
                                    <div class="output_in" [ngClass]="{'show_on_hover': !showAddOptions}" *ngIf="!!data?.user?.is_root">{{item.server_id}}<ng-container *ngIf="item.server_id && item?.region">, </ng-container><span class="region" *ngIf="item?.region">{{item?.region}}</span></div>
                                </div>
                                <div class="templates_col"></div>
                                <div class="employees_col"></div>
                                <div class="created">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.created_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{item.created_at | reportsDate:data.activeLang}}</span>
                                </div>
                                <div class="stage">
                                    <mat-icon *ngIf="!!data?.user?.is_root && item?.processing_error" #tooltip="matTooltip" matTooltip="{{ item?.processing_error }}" [matTooltipPosition]="'above'" style="color: #c0392b;">warning</mat-icon>
                                </div>
                                <div class="units">
                                    <span *ngIf="item?.price_unit">{{item?.price_unit}}</span>
                                </div>
                                <div class="time">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.processing_queue_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{item.processing_queue_at | reportsDate:data.activeLang}}</span>
                                </div>
                                <div class="time">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.processing_started_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{item.processing_started_at | reportsDate:data.activeLang}}</span>
                                </div>
                                <div class="time">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.processing_completed_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{item.processing_completed_at | reportsDate:data.activeLang}}</span>
                                </div>
                                <div class="time">
                                    <span>{{item.processing_time | timeFormat}}</span>
                                </div>
                                <div class="time">
                                    <span>{{item.processing_download_time | timeFormat}}</span>
                                </div>
                            </div>
                            <div class="company_btns">

                            </div>
                        </div>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!isGlobal">
    <div class="d-f-c" style="padding: 15px 0;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="d-f-c ml-1" mat-raised-button color='primary' (click)="refreshProjects()">
            {{ "Refresh" | translate }}
        </button>
    </div>
</mat-dialog-actions>


<ng-template #liteFmMenu let-item>
    <app-lite-file-manager 
    (CloseContext)="closeContext()" 
    [target]="item" 
    [is_mobile]="data.is_mobile" 
    [user]="data.user" 
    [activeLang]="data.activeLang" 
    [company]="data.company" 
    [imgRoute]="data.imgRoute" 
    [host]="data.host"></app-lite-file-manager>
</ng-template>