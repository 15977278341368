import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, scan } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpRestService } from '../common/http-rest.service';
import { StorageManagerService } from '../common/storage-manager.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  public clearTrash$: Subject<any> = new Subject();
  public checkRejectFolder$: Subject<any> = new Subject();
  public projectsRefresh$: Subject<any> = new Subject();
  public projectsData$: Subject<any> = new Subject();
  public changeJob$: Subject<any> = new Subject();
  public drawClear$: Subject<any> = new Subject();
  public drawData$: Subject<any> = new Subject();
  public drawDataValues$: Subject<any> = new Subject();
  public mobFolders$: Subject<any> = new Subject();
  public projectFiles$: Subject<any> = new Subject();
  public projData$: Subject<any> = new Subject();
  public selectAllFiles$: Subject<any> = new Subject();
  public files$: Subject<any> = new Subject();
  public reserveFiles$: Subject<any> = new Subject();
  public url$: BehaviorSubject<any> = new BehaviorSubject('');
  public crumbs$: BehaviorSubject<any> = new BehaviorSubject({location: '/'});

  constructor(
    private http: HttpClient,
    private sm: StorageManagerService,
    private httpRest: HttpRestService
  ) { }

  
  getRejectFolder() {
    return this.checkRejectFolder$.asObservable();
  }

  getClearTrash() {
    return this.clearTrash$.asObservable();
  }

  getSelectAllFilesStream() {
    return this.selectAllFiles$.asObservable();
  }
  
  getProjectsRefreshData() {
    return this.projectsRefresh$.asObservable();
  }

  getProjectsData() {
    return this.projectsData$.asObservable();
  }

  getChangeJob() {
    return this.changeJob$.asObservable();
  }

  getDrawData() {
    return this.drawData$.asObservable();
  }

  getDrawClear() {
    return this.drawClear$.asObservable();
  }

  getDrawDataValues() {
    return this.drawDataValues$.asObservable();
  }
  
  getProjectFiles() {
    return this.projectFiles$.asObservable();
  }

  getfiles() {
    return this.files$.asObservable();
  }

  getReserveFiles() {
    return this.reserveFiles$.asObservable();
  }

  getProjDataStream() {
    return this.projData$.asObservable();
  }

  getUrl() {
    return this.url$.asObservable();
  }

  getCrumbs() {
    return this.crumbs$.asObservable();
  }

  getMobFoldersStream() {
    return this.mobFolders$.asObservable();
  }

  getSrc(src) {
    return this.http.get(src, {
      params: new HttpParams().set("download_url", "1")
    })
  }

  downloadFile(file) {
    return this.http.get(environment.host + file.original, {
      params: new HttpParams().set("download_url", "1")
    })
  }

  getPdfSrc(file, company_id) {
    return this.http.get(environment.host + file.original, {
      params: new HttpParams().set("download_url", "1").set("company_id", company_id)
    })
  }

  detectFiles(loc, is_dir, company_id, task_operation_id) {
    return this.httpRest.executeGet<any>('/api/file/', true, {
      params: new HttpParams().set("company_id", company_id).set("task_operation_id", task_operation_id).set("location_all", loc).set("is_dir", is_dir)
    })
  }

  getMigrations(company_id, file_id) {
    return this.httpRest.executeGet<any>('/api/file-transfer-task/', true, {
      params: new HttpParams().set("company_id", company_id).set("file_id", file_id)
    })
  }

  addMigration(company_id, data) {
    return this.httpRest.executePost<any>('/api/file-transfer-task/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }
  
  deleteMigration(company_id, id) {
    return this.httpRest.executeDelete<any>(`/api/file-transfer-task/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getStorages(company_id) {
    return this.httpRest.executeGet<any>('/api/file-transfer-task/file-storage-options/', true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }
  
  getBatchMigrations(company_id, filter?) {
    let actions_params = new HttpParams().set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(element => {
        actions_params = actions_params.set(element, filter[element]);
      });
    }
    
    return this.httpRest.executeGet<any>('/api/file-transfer-batch/', true, {
      params: actions_params
    })
  }

  addBatchMigration(company_id, data) {
    return this.httpRest.executePost<any>('/api/file-transfer-batch/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }
  
  deleteBatchMigration(company_id, id) {
    return this.httpRest.executeDelete<any>(`/api/file-transfer-batch/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }


  downloadFileJD(company_id, id) {
    return this.httpRest.executeGet<any>('/file/download/', true, {
      params: new HttpParams().set("company_id", company_id).set("id", id).set("type", "crawljob")
    })
  }

  getUploadedFiles(company_id, page, loc) {
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("page", page).set("location_all", loc).set("is_my", "1").set("order_by", "created_at_desc").set("per-page", "20").set("is_dir", '0').set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,task,taskOperation,discussion,user")
    })
  }

  getFileChats(company_id, original_file_id, per_page = '100') {
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("original_file_id", original_file_id).set("location_all", '/').set("per-page", per_page).set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,document,archives,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform")
    })
  }

  getReminderFilesForOpenTask(company_id, task_operation_id, per_page = '3') {
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("task_operation_id", task_operation_id).set("operation_reminder_id", '0').set("location_all", '/').set("per-page", per_page).set("is_dir", '0').set("expand","createdEmployee").set("exclude_root_dirs", "reject,sources,trash")
    })
  }

  getFilesForOpenTask(company_id, task_operation_id, loc, per_page = '3') {
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("task_operation_id", task_operation_id).set("location_all", loc).set("per-page", per_page).set("is_dir", '0').set("expand","createdEmployee")
    })
  }

  getFilesForCheck(company_id, task_operation_id, operation_reminder_id, loc, per_page = '3') {
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("task_operation_id", task_operation_id).set("operation_reminder_id", operation_reminder_id).set("location_all", loc).set("per-page", per_page).set("is_dir", '0').set("expand","createdEmployee")
    })
  }

  getFilesForChat(company_id, filter, loc) {
    let actions_params = new HttpParams().set("company_id", company_id).set("location_all", loc).set("per-page", '3').set("is_dir", '0').set("expand","createdEmployee")

    if (filter) {
      Object.keys(filter).forEach(element => {
        actions_params = actions_params.set(element, filter[element]);
      });
    }
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  downloadFileFromUrl(url, file) {
    const initialState: Download = { state: 'PENDING', progress: 0, is_end: false, size_is_downloaded: 0, content: null}
    const calculateState = (
      download: Download,
      event: HttpEvent<any>
    ): Download => {
      if (isHttpProgressEvent(event)) {
        return {
          progress: event.total
            ? Math.round((100 * event.loaded) / event.total)
            : download.progress,
          state: 'IN_PROGRESS',
          is_end: false,
          size_is_downloaded: event.loaded,
          content: null
        }
      }
      if (isHttpResponse(event)) {
        return {
          progress: 100,
          state: 'DONE',
          is_end: true,
          size_is_downloaded: file.filesize,
          content: event.body
        }
      }
      return download
    }

    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
      headers: {"Content-Type": "multipart/form-data"},
    }).pipe(
      scan(calculateState, initialState),
      distinctUntilChanged((a, b) => a.state === b.state
        && a.progress === b.progress
        && a.content === b.content
      )
    )
  }

  getFilesArchives(company_id, file_ids, filter?) {
    let actions_params = new HttpParams().set("page", "1").set("file_id", file_ids.join(",")).set("company_id", company_id).set("per-page", "200").set("expand","archives")
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        actions_params = actions_params.set(element, filter[element]);
      });
    }

    return this.httpRest.executeGet<any>('/api/file/', true, {
      params: actions_params
    })
  }

  getFilesProgress(company_id, file_ids, filter?) {
    let actions_params = new HttpParams().set("page", "1").set("file_id", file_ids.join(",")).set("company_id", company_id).set("per-page", "200").set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,document,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform")
    console.log("getFilesProgress file_ids", file_ids)
    console.log("getFilesProgress filter", filter)
    if (filter) {
      Object.keys(filter).forEach(element => {
        actions_params = actions_params.set(element, filter[element]);
      });
    }

    return this.httpRest.executeGet<any>('/api/file/', true, {
      params: actions_params
    })
  }

  getFilesWithTags(company_id, file_ids) {
    let actions_params = new HttpParams().set("page", "1").set("file_id", file_ids.join(",")).set("company_id", company_id).set("per-page", "200").set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,document,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue")
    
    return this.httpRest.executeGet<any>('/api/file/', true, {
      params: actions_params
    })
  }

  getFilesPreview(company_id, file_ids) {
    let actions_params = new HttpParams().set("page", "1").set("file_id", file_ids.join(",")).set("company_id", company_id).set("per-page", "200").set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,document,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform")
    
    return this.httpRest.executeGet<any>('/api/file/', true, {
      params: actions_params
    })
  }

  getGlobalFileData(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page",page).set("company_id", company_id).set("per-page", per_page).set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,document,archives,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue").set("exclude_root_dirs", "approved,project,reject,ready_files,sources,to_approve,trash")

    // console.log("getGlobalFileData", filter)
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          if (element == 'file_parameter_value_id') {
            let val = JSON.parse(JSON.stringify(filter[element]));
            if (filter[element].includes(-2)) {
              val.splice(val.indexOf(-2), 1);
              actions_params = actions_params.set('is_document', '1');
              actions_params = actions_params.set('content_type', 'reports-json-auto-post');
            } 
            if (filter[element].includes(-3)) {
              val.splice(val.indexOf(-3), 1);
              actions_params = actions_params.set('is_document', '1');
              actions_params = actions_params.set('content_type', '');
            }
            if (filter[element].includes(-4)) {
              val.splice(val.indexOf(-4), 1);
              actions_params = actions_params.set('is_live_stream', '1');
            }
            if (filter[element].includes(-1)) {
              val.splice(val.indexOf(-1), 1);
              actions_params = actions_params.set('is_document', '1');
            }
            if (val && val.length) {
              actions_params = actions_params.set(element, val);
            }
          } else {
            if (element.indexOf("_from") != -1 || element.indexOf("_to") != -1) {
              actions_params = actions_params.set(element, moment(filter[element]._d).format("X"));
            } else {
              actions_params = actions_params.set(element, filter[element]);
            }
          }
        }
      });
    }
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  getFileDataWithTags(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page",page).set("company_id",company_id).set("per-page", per_page).set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,document,archives,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue")

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
          let start = +filter[element].trim().split('-')[0];
          let end = +filter[element].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          actions_params = actions_params.set(element, val.join(","));
        } else if (filter[element] !== '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: actions_params
    })
  }
  
  getFileCopiesData(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page)

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
          let start = +filter[element].trim().split('-')[0];
          let end = +filter[element].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          actions_params = actions_params.set(element, val.join(","));
        } else if (filter[element] !== '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  getFileData(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page).set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,document,archives,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue")

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
          let start = +filter[element].trim().split('-')[0];
          let end = +filter[element].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          actions_params = actions_params.set(element, val.join(","));
        } else if (filter[element] !== '') {
          actions_params = actions_params.set(element, filter[element]);
        } else if (element == 'content_type') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }
    return this.httpRest.executeGet<any>('/api/file/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  getTargetFile(id, company_id) {
    return this.httpRest.executeGet<any>(`/api/file/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id).set("expand","liveStreamForOriginalFiles,fileMetaUpdateQueue,fileOriginalData,document,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform")
    })
  }

  getFileDevices(company_id) {
    return this.httpRest.executeGet<any>(`/api/file/devices/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }
  
  getPublicFiles(acm) {
    return this.httpRest.executeGet<any>(`/file/public-file/`, true, {
      params: new HttpParams().set("acm", acm)
    })
  }

  downloadCreatedArchive(id, company_id) {
    return this.httpRest.executeGet<any>(`/api/file-archive/${id}/`, true, {
      params: new HttpParams().set("expand", 'download_url').set("company_id", company_id)
    })
  }

  fileMetaUpdate(data, company_id) {
    return this.httpRest.executePost<any>(`/api/file/meta-update-queue/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  downloadArchive(data, company_id) {
    return this.httpRest.executePost<any>(`/api/file-archive/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  createArchive(data, company_id) {
    return this.httpRest.executePost<any>(`/api/file-archive/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteArchive(id, company_id) {
    return this.httpRest.executeDelete<any>(`/api/file-archive/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  createAndDownloadArchive(data, company_id) {
    return this.httpRest.executePost<any>(`/api/file-archive/`, data, true, {
      params: new HttpParams().set("expand", 'download_url').set("company_id", company_id)
    })
  }

  deleteFile(id) {
    return this.httpRest.executeDelete<any>(`/api/file/${id}/`)
  }

  editFile(id, data, company_id) {
    return this.httpRest.executePost<any>(`/api/file/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  copyFile(data, company_id) {
    return this.httpRest.executePost<any>('/api/file/copy/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  uploadFile(data, company_id) {
    return this.httpRest.executePost<any>('/api/file/', data, true, {
      params: new HttpParams().set("company_id", company_id).set('expand', 'document')
    })
  }


  // video editor

  getVideoEditorHistory(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page)

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }

    return this.httpRest.executeGet<any>('/api/video-project-history/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  saveVideoEditorHistory(data, company_id) {
    return this.httpRest.executePost<any>(`/api/video-project-history/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getVideoProject(id, company_id) {
    let actions_params = new HttpParams().set("company_id", company_id)

    return this.httpRest.executeGet<any>(`/api/video-project/${id}/`, true, {
      params: actions_params
    })
  }
  
  getCardProjects(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page).set('order_by', 'id_desc')

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }

    return this.httpRest.executeGet<any>('/api/task-create-project/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  getVideoProjects(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page).set('order_by', 'id_desc').set("expand","createdEmployee,updatedEmployee")

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }

    return this.httpRest.executeGet<any>('/api/video-project/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  createVideoProject(data, company_id) {
    return this.httpRest.executePost<any>(`/api/video-project/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  resetVideoProject(id, data, company_id) {
    return this.httpRest.executePost<any>(`/api/video-project/reset/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editVideoProject(id, data, company_id) {
    return this.httpRest.executePost<any>(`/api/video-project/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteVideoProject(id, company_id) {
    return this.httpRest.executeDelete<any>(`/api/video-project/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  createCardProject(data, company_id) {
    return this.httpRest.executePost<any>(`/api/task-create-project/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editCardProject(id, data, company_id) {
    return this.httpRest.executePost<any>(`/api/task-create-project/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteCardProject(id, company_id) {
    return this.httpRest.executeDelete<any>(`/api/task-create-project/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  createCardProjectItem(data, company_id) {
    return this.httpRest.executePost<any>(`/api/task-create-project-item/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editCardProjectItem(id, data, company_id) {
    return this.httpRest.executePost<any>(`/api/task-create-project-item/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteCardProjectItem(id, company_id) {
    return this.httpRest.executeDelete<any>(`/api/task-create-project-item/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getVideoProjectFiles(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page)

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }

    return this.httpRest.executeGet<any>('/api/video-project-file/', true, {
      observe: 'response',
      params: actions_params
    })
  }
  
  getVideoProjectRunHistory(page, company_id, filter = null, per_page = '200') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page).set('expand', 'company,videoProject,employee')

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }

    return this.httpRest.executeGet<any>('/api/video-project-run-history/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  getVideoProjectTaskFile(company_id, filter = null, per_page = '200') {
    let actions_params = new HttpParams().set("company_id", company_id).set("per-page", per_page).set('expand', 'company,videoProject,employee')

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }

    return this.httpRest.executeGet<any>('/api/video-project-task-create/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  createVideoProjectTaskFile(data, company_id) {
    return this.httpRest.executePost<any>(`/api/video-project-task-create/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  addParameterValueToVideoProjectFile(data, company_id) {
    return this.httpRest.executePost<any>(`/api/parameter-value-to-video-project-file/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  createVideoProjectFile(data, company_id) {
    return this.httpRest.executePost<any>(`/api/video-project-file/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editVideoProjectFile(id, data, company_id) {
    return this.httpRest.executePost<any>(`/api/video-project-file/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteVideoProjectFile(id, company_id) {
    return this.httpRest.executeDelete<any>(`/api/video-project-file/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

    // file connections
  getFilePartition(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page).set("expand", "file.taskChannelFiles.taskChannel.channel.platform,consistOfFile.taskChannelFiles.taskChannel.channel.platform")

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }

    return this.httpRest.executeGet<any>('/api/file-partition/', true, {
      params: actions_params
    })
  }
  
  getFileRelations(page, company_id, filter = null, per_page = '100') {
    let actions_params = new HttpParams().set("page", page).set("company_id", company_id).set("per-page", per_page).set("expand", "file,relatedFile")

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '') {
          actions_params = actions_params.set(element, filter[element]);
        }
      });
    }

    return this.httpRest.executeGet<any>('/api/file-relation/', true, {
      params: actions_params
    })
  }
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
  return event.type === HttpEventType.DownloadProgress 
      || event.type === HttpEventType.UploadProgress
}

export interface Folder {
  name: string,
  id: number,
  location: string,
  over: boolean,
  rootDir: boolean
}

export interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
  progress: number,
  is_end: boolean,
  size_is_downloaded: number,
  content: Blob | null;
}