import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { el } from 'date-fns/locale';
import { mimeTypes } from 'mime-wrapper';
import { forkJoin, fromEvent, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { AddAdditionalEmployeesComponent } from 'src/app/components/additionalEmpl/add-additional-employees/add-additional-employees.component';
import { AdditionalEmployeesComponent } from 'src/app/components/additionalEmpl/additional-employees/additional-employees.component';
import { CheckListComponent } from 'src/app/components/atTasksDialog/check-list/check-list.component';
import { CreateOperationChatComponent } from 'src/app/components/atTasksDialog/create-operation-chat/create-operation-chat.component';
import { EditOperationChatComponent } from 'src/app/components/atTasksDialog/edit-operation-chat/edit-operation-chat.component';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { OperationChatsComponent } from 'src/app/components/atTasksDialog/operation-chats/operation-chats.component';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { WorkAddComponent } from 'src/app/components/atTasksDialog/work-add/work-add.component';
import { WorkCommentEditComponent } from 'src/app/components/atTasksDialog/work-comment-edit/work-comment-edit.component';
import { WorkCompletedAtEditComponent } from 'src/app/components/atTasksDialog/work-completed-at-edit/work-completed-at-edit.component';
import { WorkEditComponent } from 'src/app/components/atTasksDialog/work-edit/work-edit.component';
import { WorkEmplAddComponent } from 'src/app/components/atTasksDialog/work-empl-add/work-empl-add.component';
import { WorkEmplEditComponent } from 'src/app/components/atTasksDialog/work-empl-edit/work-empl-edit.component';
import { WorkEmplStatusEditComponent } from 'src/app/components/atTasksDialog/work-empl-status-edit/work-empl-status-edit.component';
import { WorkEmployeeCommentEditComponent } from 'src/app/components/atTasksDialog/work-employee-comment-edit/work-employee-comment-edit.component';
import { WorkEmployeeEditComponent } from 'src/app/components/atTasksDialog/work-employee-edit/work-employee-edit.component';
import { WorkOperationIdEditComponent } from 'src/app/components/atTasksDialog/work-operation-id-edit/work-operation-id-edit.component';
import { WorkRateEditComponent } from 'src/app/components/atTasksDialog/work-rate-edit/work-rate-edit.component';
import { WorkStatusEditComponent } from 'src/app/components/atTasksDialog/work-status-edit/work-status-edit.component';
import { ChatsComponent } from 'src/app/components/chats/chats.component';
import { TaskClientsComponent } from 'src/app/components/clients/task-clients/task-clients.component';
import { JobEditCompletedAtComponent } from 'src/app/components/mob-job/job-edit-completed-at/job-edit-completed-at.component';
import { JobEditTypeComponent } from 'src/app/components/mob-job/job-edit-type/job-edit-type.component';
import { JobEditComponent } from 'src/app/components/mob-job/job-edit/job-edit.component';
import { MobAddJobComponent } from 'src/app/components/mob-job/mob-add-job/mob-add-job.component';
import { JobAutomationComponent } from 'src/app/components/workspace-pages/jobs/dialogs/job-automation/job-automation.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { MobChatOptionsComponent } from 'src/app/shared/global_components/chat/dialogs/mob-chat-options/mob-chat-options.component';
import { MobPriorityComponent } from 'src/app/shared/global_components/chat/dialogs/mob-priority/mob-priority.component';
import { MobTitleComponent } from 'src/app/shared/global_components/chat/dialogs/mob-title/mob-title.component';
import { MoveToOtherJobComponent } from 'src/app/shared/global_components/chat/dialogs/move-to-other-job/move-to-other-job.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { InterfaceComponent } from 'src/app/shared/global_components/file-manager/dialog/interface/interface.component';
import { VideoViewerComponent } from 'src/app/shared/global_components/file-manager/dialog/video-viewer/video-viewer.component';
import { MobFmViewComponent } from 'src/app/shared/global_components/mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { MobQuestionComponent } from 'src/app/shared/global_components/mob-question/mob-question.component';
import { NotesComponent } from 'src/app/shared/global_components/notes/notes.component';
import { NotificationsBar } from 'src/app/shared/global_components/notifications-bar/notifications-bar';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { PermissionsPipe } from 'src/app/shared/pipes/permissions.pipe';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { MinimizeService } from 'src/app/shared/services/rest/minimize.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { environment } from 'src/environments/environment';
import { AddChatToBoardComponent } from '../../../chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { ChatConnectionsComponent } from '../../../chats-page/dialogs/chat-connections/chat-connections.component';
import { AddJobToBoardComponent } from '../../../jobs/dialogs/add-job-to-board/add-job-to-board.component';
import { ExecutorsComponent } from '../executors/executors.component';
import { SetEmplComponent } from '../members/set-empl/set-empl.component';
import { MoveJobsComponent } from '../move-jobs/move-jobs.component';
import { MobChangePersonalStatusComponent } from '../open-task/dialogs/mob-change-personal-status/mob-change-personal-status.component';
import { OpenTaskComponent } from '../open-task/open-task.component';
import { StatusReasonComponent } from '../status-reason/status-reason.component';
import { ToApproveStepperComponent } from '../to-approve-stepper/to-approve-stepper.component';
import { MobPushSettingsComponent } from 'src/app/shared/global_components/chat/dialogs/mob-push-settings/mob-push-settings.component';
import { FolderArchivesComponent } from 'src/app/shared/global_components/file-manager/dialog/folder-archives/folder-archives.component';
import { DropFilesLimitComponent } from 'src/app/shared/global_components/drop-files-limit/drop-files-limit.component';
import { FilePropertiesComponent } from 'src/app/shared/global_components/file-interface/dialogs/file-properties/file-properties.component';
import { MobAssignmentsComponent } from 'src/app/components/mob-job/mob-assignments/mob-assignments.component';
import { CreateFolderComponent } from 'src/app/shared/global_components/file-manager/dialog/create-folder/create-folder.component';
import { FileSettingsComponent } from 'src/app/shared/global_components/file-manager/dialog/file-settings/file-settings.component';
import { MigrateTargetFilesComponent } from 'src/app/shared/global_components/migrate-target-files/migrate-target-files.component';
import * as moment from 'moment';
import { CardClipboardComponent } from '../open-task/dialogs/card-clipboard/card-clipboard.component';
import { ChatStatusesComponent } from 'src/app/shared/global_components/chat/dialogs/chat-statuses/chat-statuses.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-open-job',
  templateUrl: './open-job.component.html',
  styleUrls: ['./open-job.component.scss']
})
export class OpenJobComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chatMenu') chatMenu: TemplateRef<any>;
  @ViewChild('outFileMenu') outFileMenu: TemplateRef<any>;
  @ViewChild('uploaderFolder') uploaderFolder: ElementRef<HTMLInputElement>;
  @ViewChild('uploader') uploader: ElementRef<HTMLInputElement>;
  @ViewChild('mobileScroll') mobileScroll: ElementRef;
  @ViewChild('jobContent') jobContent: ElementRef;
  @ViewChild('mainDesk') mainDesk: ElementRef;
  @ViewChild('fullTextMenu') fullTextMenu: TemplateRef<any>;
  public is_mobile:boolean = false;
  public company_id:any;
  public company:any;
  public todoChatsGet:boolean = false;
  public doneChatsGet:boolean = false;
  public is_scheme_editing: boolean = false;
  public todoChatsCount:any = 0;
  public todoChats:any;
  public doneChatsCount:any = 0;
  public doneChats:any;
  public bookmarks: any;
  public user: any;
  public origin = window.location.origin;
  public host: any = environment.host;

  public overlayRef: OverlayRef | null;
  public sub: Subscription;

  public overlayRefFolder: OverlayRef | null;
  public subFolder: Subscription;
  public refreshSub: Subscription;

  public foldersPerm: any[] = [];
  public folders: any[] = [
    {
      name: "Root",
      id: -1,
      location: "/",
      cant_read: false,
      cant_write: false,
      color: "#A3A3A3"
    },
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      cant_read: false,
      cant_write: false,
      color: "#85A4CE"
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      cant_read: false,
      cant_write: false,
      color: "#F99A65"
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      cant_read: false,
      cant_write: false,
      color: "#219653"
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      cant_read: false,
      cant_write: false,
      color: "#9B51E0"
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      cant_read: false,
      cant_write: false,
      color: "#cec985"
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      cant_read: false,
      cant_write: false,
      color: "#ff00004a"
    }
  ]

  public activeLang: any;
  public sortValue: string;
  public tab: number = 0;
  public groups: any;
  public task: any = !!this.data.task ? this.data.task : undefined;
  public activeJob: any = !!this.task && this.task.operations && this.task.operations.length ? this.task.operations.find(x => x.id == this.data.task_operation_id) : (this.data.job ? this.data.job : undefined);  
  public chatPage: number = 1;
  public chatPagination: any;
  public imgRoute: any;
  public selectedJob: any;
  public selectedIndex: FormControl = new FormControl(0);

  public taskStatuses: any;
  public operationsValues: any;
  public statuses: any;

  public templates: any;
  public templatesControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public timeZone = new Date().getTimezoneOffset();
  public today = moment().set({hour:0,minute:0,second:0}).unix();

  
  public overlayRefChatMenu: OverlayRef | null;
  public subChatMenu: Subscription;

  public copiedFiles: any;
  public isSmallHead: boolean = false;
  public nameOpened: boolean = false;
  public uploadLimit: boolean = false;
  public disableTemplate: boolean = false;
  public searchIsOpen: boolean = false;
  public isManager:boolean = false;
  public chatsStatsControl: FormControl = new FormControl(0);
  public addExecutorSub: Subject<boolean> = new Subject();
  public updateChatSub: Subject<boolean> = new Subject();
  public chatsControl: FormControl = new FormControl();
  public chats$: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OpenJobComponent>,
    private taskService: TaskService,
    private minimizeService: MinimizeService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private sm: StorageManagerService,
    private bottomSheet: MatBottomSheet,
    private chatService: ChatService,
    private refreshService: RefreshService,
    private layoutService: LayoutService,
    private taskBarService: TaskBarService,
    private fileService: FileService,
    public overlay: Overlay,
    private uploadService: UploadService,
    private membersService: MembersService,
    public viewContainerRef: ViewContainerRef,
    private router: Router,
    private alldialogRef: MatDialog,
    private scenariosService: ScenariosService,
    private auth: AuthService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute
  ) { super() }

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    this.data.isJobDialog = true;
    console.log("OpenJobComponent", this.data)
    console.log("job", this.activeJob)
    if (this.data.cancelLoad) {
      this.data.cancelLoad()
    }
    if (this.sm.localStorageGetItem("copiedFiles")) {
      this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
    }
    if (this.data.fast_mode) {
      if (this.data.job) {
        this.data.task.operations = [this.data.job]
      }
    }
    this.getUser();

    this.attachSubscriptions(
      this.fileService.getChangeJob().subscribe(resp => {
        console.log("getChangeJob", resp)
        if (this.task.operations.filter(x => x.id == resp.id).length > 0) {
          this.selectJob(this.task.operations.find(x => x.id == resp.id))
        }
      })
    )

    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    if (this.data.initCompanyId) {
      this.company_id = this.data.initCompanyId
    }
    
    if (this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.isJobDialog).length > 1) {
      this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.isJobDialog)[0].close()
    }
    // if (this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.task_operation_id == this.data.task_operation_id).length > 1) {
    //   this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.task_operation_id == this.data.task_operation_id)[0].close()
    // }

    if (!!this.activeJob && !!this.activeJob.chatsInfo && this.activeJob.chatsInfo.length > 0) {
      this.chats$.next(this.activeJob.chatsInfo.slice())
    }

    if (this.data.loc) {
      this.selectedIndex.patchValue(3);
      this.changeTab(2)
    }

    this.getCompany();
    if (this.data.fast_mode) {
      if (this.data.loc) {
        this.getTask(false, this.data.loc, false)
      } else {
        this.getTask(false, undefined, false)
      }
    } else {
      if (!!this.data.task && !!this.data.task.operations) {
        this.data.task.operations.forEach(element => {
          element.mobEmployees = [];
          let allEmployees = element.employees.filter(x => x.task_operation_id == element.id);
          allEmployees.forEach(empl => {
            if (!element.mobEmployees.find(x => x.employee_id == empl.employee_id)) {
              element.mobEmployees.push(empl)
            }
          });
  
          let allAddEmployee = element.employees.filter(x => x.task_operation_id == element.id && (x.is_manager == 0 || (x.is_manager == 1 && x.company_id != element.company_id)));
          element.uniqueAddEmployee = []
          allAddEmployee.forEach(empl => {
            if (!element.uniqueAddEmployee.find(x => x.employee_id == empl.employee_id)) {
              element.uniqueAddEmployee.push(empl)
            }
          });
        })
      }

      if (this.data.loc) {
        this.getTask(false, this.data.loc, false)
      } else {
        this.getTask(false, undefined, false)
      }
    }

    this.getOperationsStatus();
    this.checkIsMobile();
    this.getLangData();

    if (!this.is_mobile) {
      this.dialogRef.addPanelClass('full_h_modal')
    }
    
    this.getImgRoute();
    this.getTaskStatus();
    this.getGroupsCompany();
    this.getAutomationTemplates();
    if (this.data.hasOwnProperty('selectedIndex') && this.data.selectedIndex !== undefined) {
      this.selectedIndex.patchValue(this.data.selectedIndex)
      if (this.is_mobile) {
        if (this.data.selectedIndex == 0) {
          this.tab = 1;
        }
        if (this.data.selectedIndex == 3) {
          this.tab = 2;
        }
      }
    }
    this.refresh();
    this.refreshFiles();

    this.attachSubscriptions(
      this.refreshService.getTaskCard().subscribe(resp => {
        if (resp.company_id == this.company.id && this.data.task.id == resp.task_id) {
          this.getTask()
        }
      })
    )


    this.attachSubscriptions(
      this.chatsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChats(resp))
    )
    this.attachSubscriptions(
      this.templatesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )

    this.attachSubscriptions(
      this.chatsStatsControl.valueChanges.subscribe((resp) => {
        this.onFilterByStatusChats(resp)
      })
    )
  }

  ngAfterViewInit(): void {
    console.log('this.dialogRef', this.dialogRef)
    // console.log('this.dialogRef', this.dialogRef['_overlayRef']['_host'])
    // this.dialogRef['_overlayRef']['_host']['classList'].add('testic')
    this.onScroll();
    if (this.mobileScroll) {
      console.log("ngAfterViewInit", this.mobileScroll.nativeElement.scrollTop)
      if (this.is_mobile) {
        this.layoutService.overflowHidden();
      }
      this.mobileScroll.nativeElement.scroll(0,0)
    }
  }

  addToBoardChat(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddChatToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }


  openInNew(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    console.log("openInNew", chat);
    window.open(`${this.origin}/chat/${chat.acm}`, '_blank')
  }

  onScroll() {
    if (!this.mobileScroll) {
      return
    }
    
    this.attachSubscriptions(
      fromEvent(this.mobileScroll.nativeElement, "scroll").pipe(
        filter(() => this.tab != 2),
        tap((e:any) => {
          if (e.target.scrollTop < 50) {
            if (!!this.isSmallHead) {
              e.preventDefault()
            }
            this.isSmallHead = false;
          } else {
            if (!this.isSmallHead) {
              e.preventDefault()
            }
            this.isSmallHead = true;
            this.closeName();
          }
        }),
        // debounceTime(300)
      ).subscribe(() => {
        
      })
    )
  }

  closeChatMenu() {
    this.subChatMenu && this.subChatMenu.unsubscribe();
    if (this.overlayRefChatMenu) {
      this.overlayRefChatMenu.dispose();
      this.overlayRefChatMenu = null;
    }
  }

  openChatMenu({ x, y }: MouseEvent, chat) {
    this.closeChatMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefChatMenu = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });


    this.overlayRefChatMenu.attach(new TemplatePortal(this.chatMenu, this.viewContainerRef, {
      $implicit: {
        chat: chat
      }
    }));
    
    this.subChatMenu = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefChatMenu && !this.overlayRefChatMenu.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeChatMenu())

  }

  getDash() {
    console.log("getDash", this.task);
    console.log("getDash", this.activeJob);

    this.getDashChats()

    this.foldersPerm = [this.folders[0]];
    this.foldersPermissions()
  }

  getDashChats() {
    this.getDashTodoChats()
    this.getDashDoneChats()
  }

  getDashTodoChats() {
    this.attachSubscriptions(
      this.chatService.getChatsDyn('1', this.company_id, this.task.id, {task_operation_id: this.activeJob.id, status_id: [1,2,5]}).pipe(
        tap(el => {
          this.todoChatsCount = el.headers.get('x-pagination-total-count')
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.todoChats = resp;
        this.todoChatsGet = true;
      })
    )
  }

  getDashDoneChats() {
    this.attachSubscriptions(
      this.chatService.getChatsDyn('1', this.company_id, this.task.id, {task_operation_id: this.activeJob.id, status_id: [3,4,6,97,98,99]}).pipe(
        tap(el => {
          this.doneChatsCount = el.headers.get('x-pagination-total-count')
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.doneChats = resp;
        this.doneChatsGet = true;
      })
    )
  }

  canShow(file) {
    if (this.foldersPerm.length == 0 || this.folders.filter(x => x.location == file.location+file.filename).length == 0) {
      return true
    } else {
      return false
    }
  }

  onFileDropped(e, folder:any = false, file:any = false) {
    e.preventDefault();
    e.stopPropagation();
    console.log("onFileDropped", e, folder);

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      console.log("this.company", this.company)
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          this.company.filesize = this.company.filesize + files[index].size
        }
      }
    })

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }

    if (!e.dataTransfer.files.length) {
      return false;
    }
    

    let locationVal:any = false;
    if (file) {
      locationVal = file.location + "/" + file.filename
    }

    if (folder) {
      locationVal = folder.location
    }
    
    locationVal = locationVal.replaceAll('//', '/')

    if (folder.cant_write) {

      let x = "You can't upload files here."
      let y = ''

      if (this.foldersPerm.filter(u => !u.cant_write).length) {
        y = y + "You are allowed upload in folders: ";

        this.foldersPerm.filter(u => !u.cant_write).forEach((b, k) => {
          let name = b.name;
          if (k == this.foldersPerm.filter(u => !u.cant_write).length - 1) {
            name = name + ' or other nonsystem folders.'
          } else {
            name = name + ', '
          }
          y += name
        })
      }

      this.layoutService.showSnackBar({name: x}, marker(y), SnackBarItem)
      return
    }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {

        console.log("DropFilesLimitComponent", files);
        console.log("DropFilesLimitComponent", files.length);

        if (files.length >= 100) {
          const dialogRef = this.dialog.open(DropFilesLimitComponent, {
            panelClass: 'file_properties',
            autoFocus: false,
            data: {
              company: this.company,
              user: this.user,
            }
          });
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              if (!!result) {
                this.selectedJob = this.activeJob;
                this.selectedJob.upload_loc = locationVal;
                if (result.event == 'upload') {
                  console.log(this.uploader);
                  let el: HTMLElement = this.uploader.nativeElement;
                  el.click();
                } else if (result.event == 'upload_folder') {
                  console.log(this.uploaderFolder);
                  let el: HTMLElement = this.uploaderFolder.nativeElement;
                  el.click();
                }
                // this.files.push(result.data)
              }
            })
          )
          return
        }
        console.log("getFilesDataTransferItems",files);
        if ((folder || file) && this.activeJob && this.activeJob.checklists && this.activeJob.checklists.length && ((folder || file).location.indexOf('to_approve') != -1 || (folder || file).location.indexOf('approved') != -1 || (folder || file).location.indexOf('ready_files') != -1)) {
          const dialogRef = this.dialog.open(FilePropertiesComponent, {
            panelClass: 'file_properties',
            autoFocus: false,
            disableClose: true,
            data: {
              is_set_propperties: true,
              filesNeedToSetProperties: files,
              company: this.company,
              user: this.user,
              work: this.activeJob,
              files: folder && folder.files ? folder.files : [],
            }
          });
          // this.attachSubscriptions(
          //   dialogRef.afterClosed().subscribe(resp => {
          //     // console.log(resp)
          //   })
          // )
        }
        for (let index = 0; index < files.length; index++) {
          this.putFile(files[index], !!files[index].filepath || !!files[index].webkitRelativePath, locationVal, this.activeJob, folder && folder.files ? folder.files : [], folder);
        }
      }
    });
    
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  foldersPermissions() {
    console.log("getDash foldersPermissions", this.company, this.data.company)
    if (this.company && this.company.employees && this.task && !this.company.permissions.includes('owner') && !this.company.permissions.includes('manger') && !this.company.permissions.includes('admin')) {
        // FOR JOB
        
      let parsedData;
      if (this.company.employees[0].employeeSetting && this.company.employees[0].employeeSetting.file_manager) {
        parsedData = JSON.parse(this.company.employees[0].employeeSetting.file_manager);
      } else if (this.company.employees[0].employeeToDepartmentPositions && this.company.employees[0].employeeToDepartmentPositions.length) {
        let arr = [];
        this.company.employees[0].employeeToDepartmentPositions.forEach(depPos => {
          let x;
          if (depPos.departmentPosition.employee_setting) {
            x = JSON.parse(depPos.departmentPosition.employee_setting)
          } else if (depPos.department.employee_setting) {
            x = JSON.parse(depPos.department.employee_setting)
          }
          console.log("depPos", x, depPos)
          if (!!x) {
            arr.push(...x)
          }
        });
        parsedData = arr
      }
      console.log("FOL PERM", parsedData)
      if (parsedData) {
        // filter card types
        parsedData.filter(x => x.task_statuses_ids.includes(this.task.status_id) || x.task_statuses_ids.includes(-2)).forEach(pData => {
          // filter job types
          if (pData.task_operation_operations_ids.length == 0 || pData.task_operation_operations_ids.includes(this.activeJob.operation_id) || pData.task_operation_operations_ids.includes(-2)) {
            // filter job statuses
            if (pData.task_operation_statuses_ids.length == 0 || pData.task_operation_statuses_ids.includes(-2) || this.task.operations.filter(x => pData.task_operation_statuses_ids.includes(x.status_id)).length > 0) { 
              if (pData.is_manager == 2 || this.activeJob.employees.filter(x => x.employee_id == this.company.employees[0].id && x.is_manager == pData.is_manager).length > 0) {
                if (this.foldersPerm.length == 1) {
                  this.foldersPerm.push(...pData.folders)
                } else {
                  pData.folders.forEach(dir => {
                    if (dir.cant_write == true && this.foldersPerm.find(x => x.id == dir.id).cant_write == false) {
                      this.foldersPerm.find(x => x.id == dir.id).cant_write = dir.cant_write
                    }
                    if (dir.cant_read == true && this.foldersPerm.find(x => x.id == dir.id).cant_read == false) {
                      this.foldersPerm.find(x => x.id == dir.id).cant_read = dir.cant_read
                    }
                  });
                }
              }
            }
          }
        });
        

        this.foldersPerm = this.foldersPerm;

        console.log("getDash JOB foldersPermissions parsedData", parsedData)
        console.log("getDash JOB foldersPermissions this.folders", this.folders)
        console.log("getDash JOB foldersPermissions this.foldersPerm", this.foldersPerm)
        // }
      } else {
        this.foldersPerm = [this.folders[0]];
      }

    } else {
      this.foldersPerm = [this.folders[0]];
    }

    this.attachSubscriptions(
      forkJoin(this.foldersPerm.map(x => this.fileService.getFileData(1, this.company.id, {task_id: this.task.id, task_operation_id: this.activeJob.id, location: x.location}, '200').pipe(
        map(u => u.body),
        tap(res => {

          if (x.id == -1) {
            if (this.foldersPerm.length > 1) {
              res.filter(p => !!p.is_dir).forEach(dir => {
                if (this.foldersPerm.find(q => q.location == '/'+dir.filename)) {
                  this.foldersPerm.find(q => q.location == '/'+dir.filename).original = dir;
                }
              });
            }
            x.files = res.filter(file => this.foldersPerm.length == 1 || file.is_dir == 0 || this.canShow(file));
          } else {
            x.files = res;
          }
        })
      ))).subscribe(resp => {
        console.log("getDash ForkJoin", resp)
        console.log("getDash ForkJoin this.foldersPerm", this.foldersPerm)
      })
    )
  }

  canShowFolder(fol) {
    if (this.foldersPerm && this.foldersPerm[0] && !!this.foldersPerm[0].hasOwnProperty('files')) {
      if (this.foldersPerm[0].files.filter(x => x.location + x.filename == fol.location).length == 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  openFolderArchives(folder) {
    console.log(folder)
    const dialogRef = this.dialog.open(FolderArchivesComponent, {
      data: {
        company: this.company,
        folder: folder,
        target_company_id: this.task.company_id,
        company_id: this.company_id,
        task_id: this.task.id, 
        task_operation_id: this.activeJob.id,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {

      })
    )
  }

  someOfPerm(file) {
    if (file && this.foldersPerm && this.foldersPerm.length > 1) {
      if (this.foldersPerm.filter(x => x.location == '/' + file.filename).length > 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  closeName() {
    this.nameOpened = false;
  }

  openName() {
    this.nameOpened = true;
  }

  onFilterByStatusChats(resp) {
    if (!this.activeJob.chatsInfo) {
      return;
    }

    if (!resp) {
      this.chats$.next(this.activeJob.chatsInfo.slice());
      return;
    }

    this.chats$.next(this.activeJob.chatsInfo.filter(z => z.status_id == resp));
  }

  onSearchChats(resp) {
    if (!this.activeJob.chatsInfo) {
      return;
    }

    if (!resp) {
      this.chats$.next(this.activeJob.chatsInfo.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.chats$.next(this.activeJob.chatsInfo.filter(z => z.name && z.name.toLowerCase().indexOf(resp) > -1));
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshTasks().pipe(
        concatMap(res => {
          if (!!res.fromFileManager && this.task.id == res.task_id && this.company_id == res.company_id) {
            this.getTask();
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshJOB CARD", resp)
      })
    )
  }

  scenChangeOpen(e, job) {
    console.log(e, job);
    job.scen_visible = e;
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }

  selectJob(job, loc?) {
    this.activeJob = job;
    if (this.jobContent) {
      this.jobContent.nativeElement.scroll(0,0)
    }
    this.getTask(false, loc)
    this.scrollToTop()
  }

  scrollToTop() {
    if (!this.is_mobile) {
      this.mainDesk.nativeElement.scroll(0,0)
    }
  }

  executorsAssignments(job?) {
    if (job) {
      this.activeJob = job;
      this.scrollToTop()
    }
    setTimeout(() => {
      this.selectedIndex.patchValue(1);
    }, 0)
  }

  openFiles(job, loc) {
    console.log("openFiles", job, loc)
    if (this.activeJob.id != job.id) {
      this.selectJob(job, loc);
    } else {
      this.data.loc = loc;
      this.selectedIndex.patchValue(3);
      this.changeTab(2)
    }
  }

  toggleMobSearch() {
    this.searchIsOpen = !this.searchIsOpen
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  
  mobEditPriority(chat) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        chat: chat,
        target: 'chat'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == "back") {
            this.mobOptions(chat);
          } else {
            this.getTask();
            this.initRefreshBoard()
          }
        } else {
          this.getTask();
          this.initRefreshBoard()
        }
      })
    )
  }

  mobEditTitle(chat) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        chat: chat,
        type: 'Title',
        type_prop: 'name',
        title: 'Edit chat',
        target: 'chat'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == "back") {
            this.mobOptions(chat);
          } else {
            this.getTask();
            this.initRefreshBoard()
          }
        } else {
          this.getTask();
          this.initRefreshBoard()
        }
      })
    )
  }

  openConnectionLiteChats(chat) {
    const goToChatCon = this.dialog.open(ChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        chat: chat,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        operationsValues: this.operationsValues,
        host: this.host,
        activeLang: this.activeLang,
      }
    });

    // this.attachSubscriptions(
    //   goToChatCon.afterClosed().subscribe(result => {
    //     this.updateDiscussion(this.chat_id)
    //     this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
    //     this.initRefreshBoard()
    //   })
    // )
  }

  toPersonalStatuses(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(ChatStatusesComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }

  openConnectionChats(chat) {
    const goToChatCon = this.dialog.open(ChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.checkIsManager(this.task, this.company, this.user),
        chat: chat,
        chats: this.task.chatsInfo,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        operationsValues: this.operationsValues,
        host: this.host,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      goToChatCon.afterClosed().subscribe(result => {
        this.getTask();
        this.initRefreshBoard()
      })
    )
  }

  chatAdditionalEmployees(chat) {
    const dialogRef = this.dialog.open(AdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: this.task,
        work: chat.taskOperation,
        discussion: chat,
        initialAdd: true,
        empl_status: this.company?.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user)
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask();
        this.initRefreshBoard()
      })
    )
  }

  mobOptions(chat) {
    const dialogRef = this.dialog.open(MobChatOptionsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        chat: chat,
        task: this.task,
        user: this.user,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("afterClosed", result)
        if (!!result && !!result.data) {
          switch (result.data) {
            case 'copy link':
              console.log("copy link");
              // this._clipboardService.copy(this.host + '/chat/' + chat.acm)
              // this.copyLink("Chat")
              break;
            case 'delete':
              this.deleteChat(chat)
              break;
            case 'move':
              this.moveChat(chat)
              break;
            case 'notes':
              this.openNotes(chat, 'chat')
              break;
            case 'priority':
              this.mobEditPriority(chat)
              break;
            case 'search':
              console.log("search");
              break;
            case 'search':
              console.log("info");
              break;
            case 'edit title':
              this.mobEditTitle(chat)
              break;
            case 'assignments':
              this.chatAdditionalEmployees(chat)
              break;
            case 'conns':
              this.openConnectionChats(chat)
              break;
            case 'board':
              this.addToBoard(chat)
              break;
            case 'parameters':
              this.openTargetValues([chat.task_id, chat.task_operation_id, chat.id], chat)
              break;
            case 'push':
              this.mobPushSettigns(chat)
              break;
            default:
              console.log("Default")
              break;
          }
        }
      })
    )
  }
  
  mobPushSettigns(chat) {
    const pushSett = this.dialog.open(MobPushSettingsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang, 
        chat: chat,
        task: this.task,
        job: this.activeJob,
        statuses: this.statuses,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
        type: 'notifications',
        type_prop: 'notifications',
        title: 'Notifications settings',
        target: 'Subscribe for notifications for new messages in this chat'
      }
    });

    this.attachSubscriptions(
      pushSett.afterClosed().subscribe(result => {
      })
    )
  }
  
  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  оpenJobFiles(job, loc) {
    // if (this.activeJob.id != job.id) {
    //   this.selectJob(job);
    // }
    // this.data.loc = loc;
    // this.selectedIndex.patchValue(3);
  }

  changeJobTab(val) {
    this.selectedIndex.patchValue(val)
  }

  changeTab(num) {
    this.tab = num
    if (this.mobileScroll) {
      this.mobileScroll.nativeElement.scroll(0,0)
    }
    if (num == 2) {
      this.isSmallHead = true;
    } else {
      this.isSmallHead = false;
    }
  }

  tellAddExecutor() {
    this.addExecutorSub.next(true);
  }

  tellUpdateChat() {
    this.updateChatSub.next(true);
  }

  changeIndex(e) {
    this.selectedIndex.patchValue(e);
    switch (e) {
      case 0:
        console.log("need get chats")
        break;
      case 1:
        console.log("need get assigments")
        break;
      case 2:
        console.log("need get schedule")
        break;
      case 3:
        console.log("need get files")
        break;
      case 4:
        console.log("need get dash");
        this.getDash();
        break;
    }
    console.log(e);
  }

  addAdditionalEmplToWork(task, work) {
    const dialogRef = this.dialog.open(AddAdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: task,
        work: work,
        discussion: false
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  deleteEmployee(job) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job employee")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.membersService.deleteMember(job.employee.id, this.company_id).subscribe(resp => {
            this.getTask(true);
          })
        )
      }
    });
  }

  deleteWork(task, work) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteWork(work.id).subscribe(resp => {
            if (this.activeJob.id == work.id) {
              if (this.task.operations.filter(x => x.id != this.activeJob.id).length == 0) {
                this.closeJob();
                return;
              }
              this.activeJob = this.task.operations.filter(x => x.id != this.activeJob.id)[0];
              this.scrollToTop()
            }
            this.getTask(true);
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.data.task_id})
            this.initRefreshBoard()

          })
        )
      }
    });

  }
  
  moveWork(task, work) {
    const dialogRef = this.dialog.open(MoveJobsComponent, {
      data: {
        company: this.company,
        task: task,
        work: work,
        user: this.user,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.getTask(true);
          if (result.task) {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: result.task.id})
            this.initRefreshBoard()
          }
        }
      })
    )
  }

  editEmloyeeWork(element, work) {
    const dialogRef = this.dialog.open(WorkEmplEditComponent, {
      disableClose: true,
      data: {
        company: this.company,
        task: element,
        work: work,
        user: this.user
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'update') {
          this.getTask(true);
        }
      })
    )
  }

  additionalEmployees(task, work) {
    const dialogRef = this.dialog.open(AdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: task,
        work: work,
        discussion: false
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  editWork(element, work) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(WorkEditComponent, {
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          task: element,
          work: work,
          operationsValues: this.operationsValues
        }
      });
  
      this.attachSubscriptions(
  
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'update') {
            this.getTask(true);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(JobEditComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          task: element,
          work: work,
          operationsValues: this.operationsValues,
          activeLang: this.activeLang
        }
      });
  
      this.attachSubscriptions(
  
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result) {

            switch (result.event) {
              case 'update':
                this.getTask(true);
                break;
              case 'status':
                this.changeMobJobStatus(work);
                break;
              case 'private_comment':
                this.mobJobEditTitle(work, 'private_comment', "Hidden notes");
                break;
              case 'comment':
                this.mobJobEditTitle(work, 'comment', "Brief Description");
                break;
              case 'name':
                this.mobJobEditTitle(work, 'name', "Title");
                break;
              case 'type':
                this.mobJobEditType(work);
                break;
              case 'priority':
                this.mobEditJobPriority(work);
                break;
              case 'completed_at':
                this.mobJobEditCompletedAt(work);
                break;
              default:
                break;
            }
          }
        })
      )
    }
  }
    
  mobEditJobPriority(work) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        work: work,
        user: this.user,
        target: 'work'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, work)
          } else if (result.event == 'update') {
            this.getTask(true);
            this.initRefreshBoard()
          }
        } else {
          this.getTask(true);
          this.initRefreshBoard()
        }
      })
    )
  }
  
  mobJobEditCompletedAt(work) {
    const dialogRef = this.dialog.open(JobEditCompletedAtComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, work)
          } else if (result.event == 'update') {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }

  mobJobEditType(work) {
    const dialogRef = this.dialog.open(JobEditTypeComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, work)
          } else if (result.event == 'update') {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }

  mobJobEditTitle(work, type_prop, type) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
        type: type,
        type_prop: type_prop,
        title: 'Edit job',
        target: 'work'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, work)
          } else if (result.event == 'update') {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }

  toShortCut(e, item, type) {
    if (type == 'job') {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.task_id, task_operation_id: item.id, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.id, task_operation_id: 0, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    }
  }
  
  dblEditWork(e, task, work, comp, status, is_client: boolean = false) {
    console.log(e);
    e.stopPropagation();
    if (!status || is_client) {
      return
    }
    let component = this.needDialog(comp)

    const dialogRef = this.dialog.open(component, {
      data: {
        company_id: this.company_id,
        company: this.company,
        task: task,
        comp: comp,
        work: work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.getTask(true);
        }
      })
    )
  }

  migrateTargetFiles(task, job?) {
    const dialogRef = this.dialog.open(MigrateTargetFilesComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        target: {
          task_id: task.id,
          task_operation_id: job ? job.id : 0
        },
        user: this.user,
        host: this.host,
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  checkWorkActions(e, task, work) {
    if (!this.is_mobile) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.attachSubscriptions(
      this.taskService.getActions(null, 1, this.task.company_id, null, work.id).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            data: {
              notifications: resp.body,
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              task: task,
              work: work,
              header: false,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  showFilesInterface(task, work?, loc?) {
    const dialogRef = this.dialog.open(InterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      data: {
        company: this.company,
        task: task,
        work: work != undefined ? work : 0,
        user: this.user,
        loc: loc != undefined ? loc : ""
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  chatAdded(e) {
    console.log("chatAdded chatAdded chatAdded chatAdded", e)
    this.activeJob.unClosedChats = this.activeJob.unClosedChats + 1;
    this.task.unClosedChats = this.task.unClosedChats + 1;

    // if (this.activeJob.status_id == 3 && (this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user))) {
    //   this.attachSubscriptions(
    //     this.taskService.editWork(this.activeJob.id, {status_id: 5}, this.company_id).subscribe(resp => {
    //       this.getTask(true);
    //     })
    //   )
    // } else {
    //   this.getTask(true);
    // }
    this.getTask(true);
    this.tellUpdateChat()
  }
  
  createActiveChat() {
    const dialogRef = this.dialog.open(CreateOperationChatComponent, {
      disableClose: true,
      data: {
        is_create_chat_component: true,
        company: this.company,
        task: this.task,
        work: this.activeJob,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'create') {
          this.activeJob.unClosedChats = this.activeJob.unClosedChats + 1;
          this.task.unClosedChats = this.task.unClosedChats + 1;

          // if (this.activeJob.status_id == 3 && (this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user))) {
          //   this.attachSubscriptions(
          //     this.taskService.editWork(this.activeJob.id, {status_id: 5}, this.company_id).subscribe(resp => {
          //       this.getTask(true);
          //     })
          //   )
          // } else {
          //   this.getTask(true);
          // }
          this.getTask(true);
          this.tellUpdateChat()
        }
      })
    )
    
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.company_id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.company_id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getAutomationTemplates", this.templates)
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }

  openNotes(item, type?) {
    console.log(item);
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        type: type || 'job',
        item: item,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  scenIsSelected(job, template) {
    return !!job.automationScenarios && !!job.automationScenarios.length && job.automationScenarios.filter(x => x.template_id == template.id).length > 0
  }

  activeScan(job, template) {
    return !!job.automationScenarios && !!job.automationScenarios.length && job.automationScenarios.find(x => x.template_id == template.id) && job.automationScenarios.find(x => x.template_id == template.id).is_active == 0
  }

  allDone(job) {
    return !!job.automationScenarios && job.automationScenarios.length == job.automationScenarios.filter(x => x.is_active == 0).length
  }

  someDone(job) {
    return !!job.automationScenarios && job.automationScenarios.filter(x => x.is_active == 0).length != 0
  }

  findScen(job, template) {
    if (!job.automationScenarios) {
      return undefined
    } else {
      return job.automationScenarios.find(x => x.template_id == template.id)
    }
  }

  tryScen(e, job, template) {
    e.preventDefault();
    e.stopPropagation();
    this.disableTemplate = true;
    this.attachSubscriptions(
      this.scenariosService.tryScenario(this.findScen(job,template).id).subscribe(result => {
        console.log('tryScenario', result);
        this.disableTemplate = false;
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  openMobScenarios(job) {
    job.scenarioOpen = !job.scenarioOpen;
  }

  tryDuplicateScen(e, job, template, q) {
    e.preventDefault();
    e.stopPropagation();
    this.disableTemplate = true;
    this.attachSubscriptions(
      this.scenariosService.tryScenario(job.automationScenarios.filter(x => x.template_id == template.id)[q].id).subscribe(result => {
        console.log('tryScenario', result);
        this.disableTemplate = false;
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  activeDuplicateScan(job, template, index) {
    if (!!job.automationScenarios.length && job.automationScenarios.filter(x => x.template_id == template.id).length) {
      return job.automationScenarios.filter(x => x.template_id == template.id)[index].is_active == 0
    } else {
      return false
    }
    // return false;
    // return !!job.automationScenarios.length && job.automationScenarios.find(x => x.template_id == template.id) && job.automationScenarios.find(x => x.template_id == template.id).is_active == 0
  }

  duplicatesScenCount(job, template) {
    return job.automationScenarios.filter(x => x.template_id == template.id)
  }

  toggleDuplicateScen = (chip: any, job, q) => {
    let id = job.automationScenarios.filter(x => x.template_id == (chip.id))[q].id
    this.attachSubscriptions(
      this.scenariosService.deleteAutomationScenario(id).subscribe(resp => {
        job.automationScenarios.splice(job.automationScenarios.findIndex(t => t.id == id), 1)
        this.disableTemplate = false;

        if (job.automationScenarios && job.automationScenarios.length) {
          job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
        } else {
          job.selectedScenIds = [];
        }
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: 'You do not have permission to do this.'}, `${error}`, SnackBarItem)
      })
    )
  }

  toggleChipTemplate = (chip: any, job) => {
    this.disableTemplate = true;
    if (this.scenIsSelected(job, chip)) {
      console.log("Selected");
      let id = job.automationScenarios.find(x => x.template_id == (chip.id)).id
      this.attachSubscriptions(
        this.scenariosService.deleteAutomationScenario(id).subscribe(resp => {
          job.automationScenarios.splice(job.automationScenarios.indexOf(job.automationScenarios.find(x => x.template_id == (chip.id))), 1)
          this.disableTemplate = false;

          if (job.automationScenarios && job.automationScenarios.length) {
            job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
          } else {
            job.selectedScenIds = [];
          }
        }, error => {
          this.disableTemplate = false;
          this.layoutService.showSnackBar({name: 'You do not have permission to do this.'}, `${error}`, SnackBarItem)
        })
      )
    }else {
      console.log("NO Selected")
      this.attachSubscriptions(
        this.scenariosService.addAutomationScenario({
          company_id: this.company_id,
          task_operation_id: job.id,
          template_id: chip.id
        }, this.company_id).pipe(
          switchMap(res => {
            return this.scenariosService.getAutomationTemplate(res.template_id).pipe(tap(result => res.template = result), map(() => res))
          })
        ).subscribe(resp => {
          console.log("addAutomationScenario", resp);
          job.automationScenarios.push(resp);
          this.disableTemplate = false;
          if (job.automationScenarios && job.automationScenarios.length) {
            job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
          } else {
            job.selectedScenIds = [];
          }
          console.log("JOB", job)
        })
      )
    }
  }

  createChat(task, work) {
    const dialogRef = this.dialog.open(CreateOperationChatComponent, {
      disableClose: true,
      data: {
        is_create_chat_component: true,
        company: this.company,
        task: task,
        work: work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'create') {
          this.getTask(true);
          // work.unClosedChats = work.unClosedChats + 1;
          // task.unClosedChats = task.unClosedChats + 1;
          
          // const dialogRefInside = this.dialog.open(OperationChatsComponent, {
          //   panelClass: ['chats_dialog'],
          //   disableClose: true,
          //   data: {
          //     company: this.company,
          //     task: task,
          //     work: work,
          //     user: this.user,
          //     empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
          //     operationsValues: this.operationsValues,
          //     checkIsManager: this.checkIsManager
          //   }
          // });
        
          // if (work.status_id == 3 && (this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user))) {
          //   this.attachSubscriptions(
          //     this.taskService.editWork(work.id, {status_id: 5}, this.company_id).subscribe(resp => {
          //       this.getTask(true);
          //     })
          //   )
          // } else {
          //   this.getTask(true);
          // }
        }
      })
    )
  }

  goToClients(job?) {
    const dialogRef = this.dialog.open(TaskClientsComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        task: this.task,
        user: this.user,
        clients: this.task.clients,
        work: !!job ? job : ''
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  openChecklist(task, work) {
    console.log(task, work);
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company_id, {task_operation_id: work.id}).subscribe(resp => {
        const dialogRef = this.dialog.open(CheckListComponent, {
          data: {
            company: this.company,
            task: task,
            work: work,
            user: this.user,
            checklist: resp.body,
            operationsValues: this.operationsValues
          }
        });

        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            this.getTask(true);
          })
        )
      })
    )
  }

  getColumnName(id){
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id) && this.operationsValues.find(el => el.id == id).translation ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  needDialog(value) {
    let returnDialog;
    switch (value) {
      case 'employee':
        returnDialog = WorkEmployeeEditComponent
        break
      case 'CompletedAt':
        returnDialog = WorkCompletedAtEditComponent;
        break
      case 'OperationId':
        returnDialog = WorkOperationIdEditComponent;
        break
      case 'Status':
        returnDialog = WorkStatusEditComponent;
        break
      case 'StatusEmpl':
        returnDialog = WorkEmplStatusEditComponent;
        break
      case 'Rate':
        returnDialog = WorkRateEditComponent;
        break
      case 'Comment':
        returnDialog = WorkCommentEditComponent;
        break
      case 'Name':
        returnDialog = WorkCommentEditComponent;
        break
      case 'EmployeeComment':
        returnDialog = WorkEmployeeCommentEditComponent;
        break
    }
    return returnDialog
  }

  openActiveExecutors() {
    this.selectedIndex.patchValue(1);
    console.log(this.selectedIndex.value)

  }

  sort(val) {
    this.sortValue = val
    switch (val) {
      case 'priority_desc':
        this.task.operations.sort((a,b) => {
          return b.priority - a.priority;
        })
        break;
      case 'priority':
        this.task.operations.sort((a,b) => {
          return a.priority - b.priority;
        })
        break;
      case 'updated_desc':
        this.task.operations.sort((a,b) => {
          return +b.updated_at - +a.updated_at;
        })
        break;
      case 'updated':
        this.task.operations.sort((a,b) => {
          return +a.updated_at - +b.updated_at;
        })
        break;
      case 'id_desc':
        this.task.operations.sort((a,b) => {
          return b.id - a.id;
        })
        break;
      case 'id':
        this.task.operations.sort((a,b) => {
          return a.id - b.id;
        })
        break;
    }

    this.onSearchChats(this.chatsControl.value)

  }

  openExecutors(work) {
    const dialogRef = this.dialog.open(ExecutorsComponent, {
      panelClass: 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        task: this.task,
        activeLang: this.activeLang,
        job: work,
        statuses: this.statuses,
        sortValue: 'updated_desc',
        host: this.host,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }


  createFolder(folder) {
    this.closeFolderContext();

    const dialogRef = this.dialog.open(CreateFolderComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        target_company_id: this.activeJob.company_id,
        task_id: this.task.id,
        work_id: this.activeJob.id,
        files: folder.files,
        location: folder.location,
        is_global: false
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
        }
      })
    )
  }

  settings(folder) {
    this.closeFolderContext();
    let _task = this.task;
    let _work = this.activeJob;

    const dialogRef = this.dialog.open(FileSettingsComponent, {
      data: {
        company_id: this.company_id,
        target_company_id: this.activeJob.company_id,
        company: this.company,
        file: folder.original,
        user: this.user,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        host: this.host,
        location: folder.original.location,
        task: _task,
        work: _work,
        operationsValues: this.operationsValues
      }
    });



    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == 'create') {
    //       this.files.push(result.data)
    //     }
    //   })
    // )
  }

  paste(folder) {
    this.closeFolderContext();
    let location = folder.location;

    this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
    
    this.attachSubscriptions(
      forkJoin(this.copiedFiles.map(x => this.fileService.copyFile({
        location: location,
        task_id: this.task.id,
        task_operation_id: this.activeJob.id,
        id: x.id, 
        company_id: this.activeJob.company_id
      }, this.company.id)
      .pipe(
        switchMap((res) => {
          if (!!folder) {
            return this.fileService.getTargetFile(res.id, this.company.id || x.company_id).pipe(
              tap(b => {
                this.user.name = this.user.display_name
                b.user = this.user
                b.createdEmployee = this.user
                b.taskChannelFiles = x.taskChannelFiles
                console.log("paste FILE",b)
                folder.files.push(b)
              }),
              map(() => res)
            )
          } else {
            return of(res)
          }
        }),
        catchError(err => {
          this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
          return of(err)
        }),
      ))).subscribe(resp => {
        this.layoutService.showSnackBar({name: ''}, marker("File copied successfully!"), SnackBarItem)
      })
    )


    console.log("this.copiedFiles", this.copiedFiles)
  }



  closeFolderContext() {
    this.subFolder && this.subFolder.unsubscribe();
    if (this.overlayRefFolder) {
      this.overlayRefFolder.dispose();
      this.overlayRefFolder = null;
    }
  }

  openFolderContext({ x, y }: MouseEvent, folder) {
    this.closeFolderContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);


      console.log("openFolderContext", folder)
    this.overlayRefFolder = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    if (!folder.hasOwnProperty('reports_type')) {
      if (document.body.offsetWidth - x > 650) {
        folder.open_to_left = false
      } else {
        folder.open_to_left = true
      }
    }

    this.overlayRefFolder.attach(new TemplatePortal(this.outFileMenu, this.viewContainerRef, {
      $implicit: folder
    }));

    
    this.subFolder = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefFolder && !this.overlayRefFolder.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeFolderContext())

  }
  
  checkActions(e, task) {
    if (!this.is_mobile) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.attachSubscriptions(
      this.taskService.getActions(task.id, 1, this.task.company_id).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            data: {
              notifications: resp.body,
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              task: task,
              header: false,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('owner') || company?.permissions.includes('manager'); 
  }

  pinChat(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.chatService.pinChat({
        discussion_id: chat.id,
        order: 0
      }, this.company.id).subscribe(resp => {
        chat.pin = resp;
      })
    )
  }

  unPinChat(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.chatService.deletePinnedChat(chat.pin.id, this.company.id).subscribe(resp => {
        chat.pin = null;
      })
    )
  }

  goToChanelLink(task, chanel) {
    if (!!chanel.content_url) {
      window.open(chanel.content_url, '_blank');
    }
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: type + " link"}, marker("Copied"), SnackBarItem)
  }

  copyName(type) {
    this.layoutService.showSnackBar({name: type + " name"}, marker("Copied"), SnackBarItem)
  }

  copyId(type) {
    this.layoutService.showSnackBar({name: type}, marker("Copied"), SnackBarItem)
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp;
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
        }),
        switchMap(() => this.taskService.getOperations(this.company_id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        console.log("operationStatuses", resp)
        this.statuses = resp.slice();
      })
    )
  }

  getOperationByID(id) {
    if (!this.statuses || this.statuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.statuses.find(el => el.id == id)
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  whoAmI() {
    if (this.company.permissions.includes("owner") || this.company.permissions.includes("admin") || this.company.permissions.includes("manager")) {
      this.isManager = true;
      return
    }
    if (this.task?.managers.filter(x => x.id == this.company.employees[0].id).length > 0 || this.task?.group?.managers.filter(x => x.id == this.company.employees[0].id).length > 0) {
      this.isManager = true;
      return
    }
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).pipe(
        map(x => x[0]),
        tap(resp => {
          this.companyService.company$.next(resp);
          this.company = resp;
        }),
        switchMap(x => this.uploadService.getUploadLimit().pipe(
          tap(z => {
            if (z || this.company.filesize >= this.company.filesize_limit) {
              this.uploadLimit = true;
            }
          })
        ))
      ).subscribe(resp => {
        this.whoAmI();
        this.getDash();
        this.getBookmarks();
        console.log("getCompany", resp);
      })
    )
  }


  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }
  
  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  editChat(chat) {
    const editChat = this.dialog.open(EditOperationChatComponent, {
      disableClose: true,
      data: {
        company: this.company,
        is_create_chat_component: true,
        chat: chat,
        user: this.user
      }
    });

    this.attachSubscriptions(
      editChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'edit') {
          this.getTask(true)
        }
      })
    )
  }
  deleteChat(chat) {
    if (!this.is_mobile) {
      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
        data: {
          targetVal: chat,
          target: marker("chat")
        }
      });
  
      deleteAlert.afterDismissed().subscribe( data => {
        if (data && data.message == 'no') {
          console.log("no");
          return
        } else if (data && data.message == 'yes') {
          this.attachSubscriptions(
            this.chatService.deleteChat(chat.id, this.company.id).subscribe(resp => {
              this.taskBarService.removeItems$.next({
                task_id: chat.task_id,
                task_operation_id: chat.task_operation_id,
                discussion_id: chat.id,
                company_id: chat.company_id
              })
              this.getTask(true)
            })
          )
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobQuestionComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          dialog_title: "Delete chat",
          title: "Do you really want to delete the chat?",
          msg1: "All files, messages will be deleted if you proceed.",
          msg2: "This can`t be undone.",
          btn_yes: "Yes. Delete chat.",
          btn_no: "No. I want te keep this chat.",
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result) {
            if (result.action == 'yes') {
              this.attachSubscriptions(
                this.chatService.deleteChat(chat.id, this.company.id).subscribe(resp => {
                  this.taskBarService.removeItems$.next({
                    task_id: chat.task_id,
                    task_operation_id: chat.task_operation_id,
                    discussion_id: chat.id,
                    company_id: chat.company_id
                  })
                  this.getTask(true)
                })
              )
            } else {
              this.mobOptions(chat)
            }
          }
        })
      )
    }
  }

  neededTaskData(task) {
    let arr = [];
    arr.push(this.taskService.getTaskClients(task.id, this.company_id).pipe(
      tap(res => {
        task.clients = res
      }),
      catchError(err => {
        return err
      })
    ))
    arr.push(this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
      tap(res => {
        task['operations'].map(o_el => {
          o_el['chatsInfo'] = [];
          o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
          o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
          o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
        });
        task['chatsInfo'] = [];
        task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
        task['unReadChats'] = res.filter(z => z.is_read != '1').length;
        task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
      })
    ))
    return forkJoin(arr)
  }


  addToBoard(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddChatToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            this.getTask(true)
          })
        )
      })
    )
  }

  moveChat(chat) {
    const dialogRef = this.dialog.open(MoveToOtherJobComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: this.task,
        work: chat.taskOperation,
        chat: chat,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: chat.id})
      })
    )
  }

  goToChat(chat, job) {
    console.log("goToChat", chat, job)
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
      position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
      autoFocus: this.is_mobile ? false : true,
      data: {
        company: this.company,
        task: this.task,
        work: job,
        dialogType: 'operation_chat',
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        chat: chat,
        chats: job?.chatsInfo,
        closeFull: true,
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        this.getTask(true)
      })
    )
  }

  openChat(_chat) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company_id, {id: _chat.id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            dialogType: 'global_chat',
            activeLang: this.activeLang, 
            chat: chat
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(res => {
            this.getTask(true)
          })
        )
      })
    )
  }

  initRefreshBoard() {
    this.refreshService.refreshBoard$.next('refresh')
  }

  ifJobClientModerator(job) {
    if (!this.task.clients || this.task.clients.length == 0 || !this.company) {
      return false;
    }
    // console.log("ifJobClient", this.task.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && x.is_principal && (!x.task_operation_id || x.task_operation_id == job.id)).length)
    if (this.task.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && !!x.is_principal && (!x.task_operation_id || x.task_operation_id == job.id)).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  ifJobClient(job) {
    if (!this.task.clients || this.task.clients.length == 0 || !this.company) {
      return false;
    }
    // console.log("ifJobClient", this.task.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && x.is_principal && (!x.task_operation_id || x.task_operation_id == job.id)).length)
    if (this.task.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && (!x.task_operation_id || x.task_operation_id == job.id)).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getJobClients(job) {
    let arr = [];

    if (this.task && this.company) {
      if (this.task.clients) {
        arr = this.task.clients.filter(x => (!x.task_operation_id || x.task_operation_id == job.id))
      }
    }

    return arr
  }

  neededData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    // if (task && task.group_id && this.company && (this.company.permissions.includes('owner') || this.checkIsManager(task, this.company, this.user))) {
    //   arr.push(
    //     this.scenariosService.getAutomationTemplates('1', {company_id: this.company_id, group_id: task.group_id}, '200').pipe(
    //       catchError(error => {
    //         return of(error)
    //       }),
    //       map(el => el.body),
    //       tap(scenarios => {
    //         task.operations.forEach(job => {
    //           if (scenarios && scenarios.length) {
    //             job.scenarioTab = true;
    //             job.groupScenarios = scenarios;
    //           } else {
    //             job.groupScenarios = [];
    //           }
    //         })
    //       })
    //     )
    //   )
    // }

    if (task && task.group_id && this.company) {
      arr.push(
        this.scenariosService.getAutomationTemplates('1', {company_id: this.company_id, group_id: task.group_id, is_task_operation: 1}, '1').pipe(
          catchError(error => {
            return of(error)
          }),
          switchMap(el => {
            if ((typeof el == 'string' && el.indexOf('Not accessible') != -1) || +el.headers.get('x-pagination-total-count') == 0) {
              return of(null);
            } else {     
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.company_id, group_id: task.group_id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  let allVals = [].concat(...values)
                  task.operations.forEach(job => {
                    if (allVals && allVals.length) {
                      job.scenarioTab = true;
                      job.groupScenarios = allVals;
                    } else {
                      job.groupScenarios = [];
                    }
                  })
                }),
              )
            }
          }),
        )
      )
    }

    arr.push(
      this.taskService.getTaskClients(task.id, this.company_id).pipe(
        tap(res => {
          task.clients = res
        }),
        catchError(err => {
          return err
        })
      )
    )
    
    if (new PermissionsPipe().transform(this.company, 'see_files')) {
      console.log("task.operations", task.operations)
      arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.company_id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
        tap(val => {
          x.filesCount = val.headers.get('x-pagination-total-count')
          x.files = val.body
        })
      )))
    }

    return forkJoin(arr)
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        let currencyData = resp.slice()
        currencyData.unshift({
          created_at: 0,
          display_format: "{value}",
          id: 8,
          iso_name: "",
          name: "",
          rate: "0.0000",
          short_name: "",
          updated_at: 0
        })
        currencyData.push({
          created_at: 0,
          display_format: "PTS {value}",
          id: 0,
          iso_name: "PTS",
          name: "Points",
          rate: "0.0000",
          short_name: "pts",
          updated_at: 0
        })
        currencyData.map(z => z.summary = 0);
        this.task.operations.map(x => {
          if (x.id == this.activeJob.id) {
            this.activeJob.emplSummary = JSON.parse(JSON.stringify(currencyData));
          
            this.activeJob.employees.map(y => {
              this.activeJob.emplSummary[!!y.price_currency_id ? y.price_currency_id : 0].summary = Number(this.activeJob.emplSummary[!!y.price_currency_id ? y.price_currency_id : 0].summary) + Number(y.price);
              this.activeJob.emplSummary[currencyData.length - 1].summary = Number(this.activeJob.emplSummary[currencyData.length - 1].summary) + Number(y.rate);
            })
            this.activeJob.emplSummCount = this.activeJob.emplSummary.filter(t => t.summary > 0).length
          }
          x.emplSummary = JSON.parse(JSON.stringify(currencyData));
          
          x.employees.map(y => {
            x.emplSummary[!!y.price_currency_id ? y.price_currency_id : 0].summary = Number(x.emplSummary[!!y.price_currency_id ? y.price_currency_id : 0].summary) + Number(y.price);
            x.emplSummary[currencyData.length - 1].summary = Number(x.emplSummary[currencyData.length - 1].summary) + Number(y.rate);
          })
          x.emplSummCount = x.emplSummary.filter(t => t.summary > 0).length
        });
      })
    )
  }

  isMainEmpl(job) {
    if (job && this.company && job?.employees && (job?.employees.filter(x => !x.discussion_id && this.company && this.company.employees[0] && (x.employee_id == this.company.employees[0].id || x.partner_employee_id == this.company.employees[0].id) && x.is_manager == 1).length > 0 || (job.company_id != this.company.id && this.company.permissions.includes("owner")))) {
      return true;
    } else {
      return false;
    }
  }

  openMobAssignments(task, work) {
    const dialogRef = this.dialog.open(MobAssignmentsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: task,
        work: work,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        host: this.host,
        statuses: this.statuses,
        operationsValues: this.operationsValues,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          this.getTask(true);
        }
      })
    )
  }

  update() {
    this.getTask(true)
  }

  getChatsForTask(task) {
    this.attachSubscriptions(
      this.chatService.getTaskChats(this.company_id, [task.id], this.chatPage).pipe(
        tap(el => {
          if (el.headers.get('x-pagination-current-page') == 1) {
            task.discussion_count = el.headers.get('x-pagination-total-count');
            this.chatPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
            task['chatsInfo'] = [];
            task['operations'].map(z => z.chatsInfo = [])
          }
        }),
        map(x => x.body),
        tap(res => {
          task['operations'].forEach(o_el => {
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
          });
         
          task['chatsInfo'].push(...res);
          
          task.read_discussion_count = res.filter(z => z.is_read == '1').length;
        })
      ).subscribe(res => {
        this.chatPage = this.chatPage + 1;
        if (this.chatPage <= this.chatPagination.pageCount) {
          this.getChatsForTask(task);
        }
      })
    )
  }

  addEmployee(job) {
    const dialogRef = this.dialog.open(SetEmplComponent, {
      data: {
        company: this.company,
        user: this.user,
        task: this.task,
        work: job
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getTask(true);
        }
      })
    )
  }

  addToBoardJob(job) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, job.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == job.id);
        work.employees = work.employees.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && job.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = job.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddJobToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            employees: job.employees,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            this.getTask(true)
          })
        )
      })
    )
  }
  
  openChats(task, work) {
    const dialogRef = this.dialog.open(OperationChatsComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        task: task,
        work: work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        host: this.host,
        operationsValues: this.operationsValues,
        checkIsManager: this.checkIsManager
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  getTask(refresh:boolean = false, loc?, get_info:boolean = true) {
    if (refresh) {
      this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.data.task_id})
      this.initRefreshBoard()
    }
    this.attachSubscriptions(
      this.taskService.getSmartOneTaskExpand(this.company_id, this.data.task_id, false, 1).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        resp.operations.forEach(element => {
          if (element.automationScenarios && element.automationScenarios.length) {
            element.selectedScenIds = element.automationScenarios.map(x => x.template_id);
          } else {
            element.selectedScenIds = [];
          }
          element.mobEmployees = [];
          let allEmployees = element.employees.filter(x => x.task_operation_id == element.id);
          allEmployees.forEach(empl => {
            if (!element.mobEmployees.find(x => x.employee_id == empl.employee_id)) {
              element.mobEmployees.push(empl)
            }
          });

          let allAddEmployee = element.employees.filter(x => x.task_operation_id == element.id && (x.is_manager == 0 || (x.is_manager == 1 && x.company_id != element.company_id)));
          element.uniqueAddEmployee = []
          allAddEmployee.forEach(empl => {
            if (!element.uniqueAddEmployee.find(x => x.employee_id == empl.employee_id)) {
              element.uniqueAddEmployee.push(empl)
            }
          });
        })
        this.task = resp;
        console.log('this.task', this.task)
        this.activeJob = this.task.operations.find(x => x.id == (this.activeJob ? this.activeJob.id : this.data.task_operation_id));
        this.chats$.next(this.activeJob.chatsInfo.slice())
        console.log("getTask", this.task, loc)
        this.getCurrencyList();
        this.getChatsForTask(this.task);
        
        if (get_info) {
          this.getDash()
        }
        if (loc) {
          this.data.loc = loc;
          this.selectedIndex.patchValue(3);
          this.changeTab(2)
        }
      })
    )
  }

  getJobManagers() {
    let arr = [];

    if (this.task) {
      if (this.task.managers) {
        this.task.managers.forEach(item => {
          if (arr.filter(x => x.vuser_id == item.vuser_id).length == 0) {
            arr.push(item)
          }
        })
      }

      if (this.task.group && this.task.group.managers) {
        this.task.group.managers.forEach(item => {
          if (arr.filter(x => x.vuser_id == item.vuser_id).length == 0) {
            arr.push(item)
          }
        })
      }
    }

    return arr
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  download(file) {
    // window.open(this.host + file.original + '?company_id=' + this.company.id, '_blank');
    // new method
    var a = document.createElement('a');
    a.setAttribute('href', this.host + file.original + '?company_id=' + this.company.id + `&filename=${file.filename}`);
    a.setAttribute('download','download');
    // a.target = "_blank"
    a.click();
    // a.remove();
  }
  

  previewVideo(file, job) {

    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(VideoViewerComponent, {
        panelClass: 'video_viewer',
        autoFocus: false,
        data: {
          file: file,
          task: this.task,
          work: job,
          files: job.files,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          isTaskCard: true,
          initCompanyId: this.company_id
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobFmViewComponent, {
        backdropClass: ['mob_video_viewer_backdrop'],
        panelClass: 'mob_video_viewer',
        autoFocus: false,
        data: {
          file: file,
          task: this.task,
          work: job,
          files: job.files,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          isTaskCard: true,
          initCompanyId: this.company_id
        }
      });
    }
  }

  uploadFileBtn(is_file, job) {
    if (!job.hasOwnProperty('files')) {
      job.files = [];
    }
    this.selectedJob = job
    if (is_file) {
      this.uploader.nativeElement.click()
    } else {
      this.uploaderFolder.nativeElement.click()
    }
  }


  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      this.selectedJob.filesCount = (this.selectedJob.filesCount ? this.selectedJob.filesCount : 0) + event.target.files.length
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        this.putFile(element, false, this.selectedJob.upload_loc ? this.selectedJob.upload_loc : (this.selectedJob.status_id == 3 ? "/to_approve" : (this.selectedJob.status_id == 98 ? "/ready_files" : "")));
      }
    } 
  }

  uploadFolder(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      this.selectedJob.filesCount = (this.selectedJob.filesCount ? this.selectedJob.filesCount : 0) + event.target.files.length
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        this.putFile(element, true, this.selectedJob.upload_loc ? this.selectedJob.upload_loc : (this.selectedJob.status_id == 3 ? "/to_approve" : (this.selectedJob.status_id == 98 ? "/ready_files" : "")));
      }
    }
    console.log(event.target.files);
  }

  
  copyScheme(item) {
    this.layoutService.showSnackBar({name: item.name + ' scheme: ' + this.getSchemeContent(item)}, marker("Copied"), SnackBarItem)
  }
  
  toggleSchemeEditing(e) {
    e.preventDefault();
    e.stopPropagation();
    this.is_scheme_editing = !this.is_scheme_editing;
  }

  editScheme(e, trigger:MatMenuTrigger, scheme, task) {
    e.preventDefault();
    e.stopPropagation();

    console.log("trigger", trigger)
    
    trigger.closeMenu();

    console.log("createNewOpenCardClipboard", task)

    const dialogRef = this.dialog.open(CardClipboardComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: task,
        scheme: scheme
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.getBookmarks();
        }
      })
    )
  }

  deleteScheme(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    trigger.closeMenu();
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: scheme,
        target: marker("Card Clipboard scheme")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(scheme.id).subscribe(res => {
            this.bookmarks.splice(this.bookmarks.findIndex(p => p.id == scheme.id), 1);
            console.log("deleteScheme", this.bookmarks);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
          })
        )
      }
    });
  }


  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Card", type: "Clipboard"}).subscribe(res => {
        this.bookmarks = res;
        console.log("open card bookmarks", this.bookmarks);
      })
    )
  }

  createNewOpenCardClipboard(e, task) {
    e.preventDefault();

    console.log("createNewOpenCardClipboard", task)

    const dialogRef = this.dialog.open(CardClipboardComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'save') {
          this.getBookmarks();
        }
      })
    )
  }
  
  getSchemeContent(item) {
    if (item.settings && item.settings.devScheme) {
      let string = ``;

      item.settings.devScheme.forEach((el,i) => {
        if (el.name == 'Separator') {
          if (!el.not_use) {
            string += `${el.value}`
          }
        } else if (el.name == 'Card') {
          if (el.value == 'group') {
            if (this.task.group && this.task.group.name) {
              string += `${this.task.group.name}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'link') {
            if (this.task.acm) {
              string += `${this.origin}/task/${this.task.acm}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'firstConsistOfName') {
            if (this.task.consistOfTasks && this.task.consistOfTasks.length) {
              string += `${this.task.consistOfTasks[0].name}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'custom_id') {
            if (this.task.custom_id) {
              string += `${this.task.custom_id}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else {
            string += `${this.task[el.value]}`
          }
        }
      });
      
      return string
    }
  }

  refreshFiles() {
    this.refreshSub = this.refreshService.getRefreshFiles().pipe(
      switchMap(resp => {

        if (resp.task_id == this.task.id && resp.work_id == this.activeJob.id && resp.folder) {
          console.log("getRefreshFiles for OpenJob file DATA -------------------------", resp)
  
          let folderNamePaths = resp.folder.path.split('/');
          console.log('folderNamePaths', folderNamePaths)
          let folderName = folderNamePaths[folderNamePaths.length - 1]
          console.log('folderName', folderName)
          
          if (resp.folderInfo && resp.initPath && resp.folderInfo.location == resp.initPath && resp.files.filter(x => x.filename == folderName).length == 0) {
            return this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {task_id: this.task.id, task_operation_id: resp.work_id, location: resp.initPath, is_dir: "1"}, "200")
            .pipe(
              map(b => b.body),
              tap(res => {
                console.log("getRefreshFolder +++++++++++++++++", res)
                res.forEach(dir => {
                  if (resp.files.filter(x => x.id == dir.id).length == 0 && this.folders.map(x => x.location.replace('/', '')).filter(p => p == dir.filename).length == 0) {
                    resp.files.push(dir);
                  }
                })
              }),
              map(b => resp),
            )
          } else {
            if (resp.files.filter(x => !x.is_uploaded && x.filename == resp.data.filename).length != 0) {
              return this.fileService.getTargetFile(resp.data.upload_url.id, resp.company_id).pipe(
                tap(xFile => {
                  resp.user.name = resp.user.display_name
                  xFile.user = resp.user;
                  xFile.createdEmployee = resp.user;
                  if (xFile.parameterValuesToTask) {
                    xFile.primaryTags = xFile.parameterValuesToTask.filter(x => !!x.is_primary)
                  } else {
                    xFile.primaryTags = []
                  }
                  resp.files.splice(resp.files.indexOf(resp.files.find(y => y.id == resp.data.upload_url.id)), 1, xFile)
                }),
                map(b => resp)
              )
            } else {
              return of(resp)
            }
          }
        } else {
          return of(resp)
        }
      })
    ).subscribe(resp => {
      console.log("getRefreshFiles file RESULT =======================", resp)
    })
    
  }

  putFile(file, is_folder:boolean = false, initPath:any = false, job:any = false, files:any = false, folderInfo?) {
    let path = ''
    if (initPath) {
      path = initPath;
    }

    if (is_folder) {
      console.log("isFOLDER", path)
      path = (path[path.length - 1] == '/' ? path : path + "/") + file.webkitRelativePath.replace(`/${file.name}`, "")
    }

    this.fileService.uploadFile({
      company_id: this.company_id,
      task_id: this.data.task_id,
      task_operation_id: job ? job.id : this.task.operations.find(x => x.id == this.selectedJob.id).id,
      filesize: file.size,
      filename: file.name,
      content_type: file.type ? file.type : mimeTypes.getType(file.name),
      location: path,
      is_dir: 0
    }, this.company.id).subscribe(resp => {
      console.log("putFile", job ? job : this.task.operations.find(x => x.id == this.selectedJob.id))
      this.fileService.files$.next({
        place: "file_manager",
        url: window.location.href,
        data: resp,
        folder: {
          is_folder: is_folder,
          path: path
        },
        initPath: initPath,
        folderInfo: folderInfo,
        files: files ? files : this.task.operations.find(x => x.id == this.selectedJob.id).files,
        location: path,
        target: file,
        task: this.task,
        work: job ? job : this.task.operations.find(x => x.id == this.selectedJob.id),
        activeLang: this.activeLang,
        operationsValues: this.operationsValues,
        task_id: this.data.task_id,
        work_id: job ? job.id : this.task.operations.find(x => x.id == this.selectedJob.id).id,
        company_id: this.company_id,
        company: this.company,
        user: this.user
      })
      // this.selectedJob = undefined
    })
  }

  applyStats(job, initialStatus:any = false) {
    console.log("job.empl_status", job)
    if (job.empl_status.filter(x => x.selected).length == 0) {
      return
    }

    if ([3,4,98].includes(job.status_id) && [1,2,5,6].includes(initialStatus ? initialStatus.id : job.newStatusFor.id)) {
      const dialogRef = this.dialog.open(StatusReasonComponent, {
        panelClass: 'open_task_dialog',
        data: {
          company: this.company,
          user: this.user,
          task: this.task,
          activeLang: this.activeLang,
          job: job,
          status: initialStatus ? initialStatus : job.newStatusFor,
          statuses: this.statuses,
          groups: this.groups,
          imgRoute: this.imgRoute,
          is_mobile: this.is_mobile,
          host: this.host,
          empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
          operationsValues: this.operationsValues,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (result.change) {
            forkJoin(job.empl_status.filter(x => x.selected).map(empl => {
              if (!!empl.is_partner) {
                if (!!empl.partnerCompanyStatus?.id) {
                  return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.partnerCompany?.name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addPartnerStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    partner_company_id: empl.partner_company_id,
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.partnerCompany?.name)
                      return of(false)
                    })
                  )
                }
              } else if (!!empl.is_partner_employee) {
                if (!!empl.employeeStatus?.id) {
                  return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addPartnerEmployeeStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    partner_company_id: empl.partner_company_id,
                    partner_employee_id: empl.partner_employee_id
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                }
              } else {
                if (!!empl.employeeStatus?.id) {
                  return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addTaskEmployeeStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    employee_id: empl.employee_id,
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                }
              }
            })).subscribe(resp => {
              this.getTask(true);
            })
          } else {
            this.getTask(true);
          }
        })
      )
    } else if ((initialStatus ? initialStatus.id : job.newStatusFor.id) == 3) {
      
      const dialogRef = this.dialog.open(ToApproveStepperComponent, {
        panelClass: 'to_appove_dialog',
        data: {
          company: this.company,
          user: this.user,
          task: this.task,
          activeLang: this.activeLang,
          job: job,
          status: initialStatus ? initialStatus : job.newStatusFor,
          statuses: this.statuses,
          imgRoute: this.imgRoute,
          is_mobile: this.is_mobile,
          host: this.host,
          empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
          operationsValues: this.operationsValues,
          getTask: () => {this.getTask(true)}
        }
      });
    } else {
      forkJoin(job.empl_status.filter(x => x.selected).map(empl => {
        if (!!empl.is_partner) {
          if (!!empl.partnerCompanyStatus?.id) {
            return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.partnerCompany?.name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              partner_company_id: empl.partner_company_id,
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.partnerCompany?.name)
                return of(false)
              })
            )
          }
        } else if (!!empl.is_partner_employee) {
          if (!!empl.employeeStatus?.id) {
            return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerEmployeeStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              partner_company_id: empl.partner_company_id,
              partner_employee_id: empl.partner_employee_id
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          }
        } else {
          if (!!empl.employeeStatus?.id) {
            return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addTaskEmployeeStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              employee_id: empl.employee_id,
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          }
        }
      })).subscribe(resp => {
        this.getTask(true);
      })
    }
  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.company,
      [!!info[2] ? 'chat' : (!!info[1] ? 'job' : 'task')]: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }

  openAutomation(work) {
    const dialogRef = this.dialog.open(JobAutomationComponent, {
      data: {
        company: this.company,
        work: work,
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.getTask();
          this.initRefreshBoard()
        }
      })
    )
  }

  addEmplWork() {
    const dialogRef = this.dialog.open(WorkEmplAddComponent, {
      disableClose: true,
      data: {
        company: this.company,
        task: this.task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "update") {
          this.getTask(true);
          this.initRefreshBoard()
        }
      })
    )
  }

  addWork() {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(WorkAddComponent, {
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          task: this.task
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "update") {
            this.getTask(true);
            this.initRefreshBoard()
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddJobComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          task: this.task
        }
      });
      
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "update") {
            this.getTask(true);
            this.initRefreshBoard()
          }
        })
      )
    }
  }

  goToLink(val) {
    window.open(val, '_blank').focus();
  }

  openTask() {
    let taskData:any = {
      task_id: this.task.id,
      company: this.company,
      initCompanyId: this.company_id,
    }
    if (this.task && this.task.hasOwnProperty("operations")) {
      taskData.task = this.task
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  changeMobJobStatus(job) {   
    const dialogRef = this.dialog.open(MobChangePersonalStatusComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        work: job,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        statuses: this.statuses,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, job)
          } else if (result.event == 'update') {
            this.getTask(true);
            this.initRefreshBoard();
          }
        } else {
          this.getTask(true);
          this.initRefreshBoard();
        }
      })
    )
  }
  
  closeJob() {
    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      this.dialogRef.removePanelClass('animate__slideInUp')
      this.dialogRef.addPanelClass('animate__slideOutDown')
      setTimeout(()=>{this.dialogRef.close({event: "close", data: false})}, 300);
    } else {
      this.dialogRef.close({event: "close", data: false})
    }
  }

  selectEmpl(job, empl, initial = false, initialStatus:any = false) {
    if (!!empl.initial_selected) {
      return
    }
    if (!!empl.selected) {
      empl.selected = false;
    } else {
      empl.selected = true;
    } 
  }

  
  showStatusError(error, user?) {
    this.layoutService.showSnackBar({name: user}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  chooseEmplStatus(job, status) {
    console.log("chooseEmplStatus", status)
    let empl = job.employees.find(x => ((x.employee_id == this.company.employees[0].id) || (x.partner_employee_id == this.company.employees[0].id)) && !x.discussion_id)
    if ((status.id) == 3) {
      empl.selected = true;
      job.empl_status = [empl]
      const dialogRef = this.dialog.open(ToApproveStepperComponent, {
        panelClass: 'to_appove_dialog',
        data: {
          company: this.company,
          user: this.user,
          task: this.task,
          activeLang: this.activeLang,
          job: job,
          status: status,
          statuses: this.statuses,
          imgRoute: this.imgRoute,
          is_mobile: this.is_mobile,
          host: this.host,
          empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
          operationsValues: this.operationsValues,
          getTask: () => {this.getTask(true)}
        }
      });
      return
    }
    
    if (!!empl?.is_partner) {
      if (!!empl.partnerCompanyStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
             this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: job.task_id,
          task_operation_id: job.id,
          partner_company_id: empl.partner_company_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerStatus(val, this.company_id).subscribe(resp => {
             this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      }
    } else if (!!empl.is_partner_employee) {
      if (!!empl.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
             this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: job.task_id,
          partner_company_id: empl.partner_company_id,
          partner_employee_id: empl.employee_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerEmployeeStatus(val, this.company_id).subscribe(resp => {
             this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      }
    } else {
      if (!!empl.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
             this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: job.task_id,
          task_operation_id: job.id,
          employee_id: empl.employee_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus(val, this.company_id).subscribe(resp => {
             this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      }
    }

  }
  
  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }

  applyJobStats(job, initialStatus:any = false) {
    this.attachSubscriptions(
      this.taskService.editWork(job.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).subscribe(resp => {
        this.getTask(true);
      })
    )
  }

  chooseStatus(job, status) {
    console.log("chooseStatus");
    // job.empl_status = job.employees.filter(x => !x.discussion_id)
    job.empl_status = job.employees.filter(x => !x.discussion_id && (this.company_id != job.company_id && !(this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user)) ? !x.is_partner && x.employee.company_id == this.company_id : true));
    job.empl_status.map(x => {
      if (x?.employeeStatus?.status_id == status.id) {
        x.initial_selected = true;
      }
    })
    if (this.isManager && [4,98,99].includes(status.id) && status.id > job.status_id) {
      this.attachSubscriptions(
        this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
          this.getTask(true);
        })
      )
    } else {

  
      if (job.empl_status.length == 1) {
        job.empl_status[0].selected = true;
        if (job.mainEmployees.length == 0) {
          this.attachSubscriptions(
            this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
              this.applyStats(job, status)
            })
          )
        } else {
          this.applyStats(job, status)
        }
        // this.selectEmpl(job, job.empl_status[0], true, status)
      } else if (job.empl_status.length == 0) {
        this.attachSubscriptions(
          this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
            this.getTask(true);
          })
        )
      } else {
        job.newStatusFor = status;
      }

    }
  }

  openEmplStatus(job) {
    if (job.employees.filter(x => x.employee_id == this.company.employees[0].id && !x.discussion_id).length == 0) {
      return
    }
    if (!job.open) {
      this.task.operations.map(x => {
        x.open = false
        x.statuses = [];
      })
      job.open = true;
      let jobStatuses = [];
      if (!!job.employeeStatus) {
        this.statuses.forEach(el => {
          if (job.employeeStatus.status_id != el.id && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
      } else {
        this.statuses.forEach(el => {
          if (job.status_id != el.id && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
      }

      job.statuses = jobStatuses;
    } else {
      job.open = false;
      job.statuses = [];
      this.getTask(true);
    }
    console.log(job);
  }

  openStatus(job) {
    if (!job.open) {
      this.task.operations.map(x => {
        x.open = false
        x.statuses = [];
        x.newStatusFor = null
      })
      if (this.isMainEmpl(job) && !(this.isManager || this.ifJobClientModerator(job))) {
        if (job.status.is_lock == 1) {
          return;
        }

        job.open = true;
        let jobStatuses = [];
        // jobStatuses.push(job.status);
        this.statuses.forEach(el => {
          if (el.id != job.status_id && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
        job.statuses = jobStatuses;
      } else {
        job.open = true;
        let jobStatuses = [];
        // jobStatuses.push(job.status);
        this.statuses.forEach(el => {
          if (el.id != job.status_id) {
            jobStatuses.push(el)
          }
        })
        job.statuses = jobStatuses;
      }
    } else {
      job.open = false;
      job.statuses = [];
      job.newStatusFor = null;
      this.getTask(true);
    }
    console.log(job);
  }

  openFullText({ x, y }: MouseEvent, val) {
    console.log("{ x, y }: MouseEvent, val", { x, y }, val)
    this.closeFullText();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.sub = fromEvent<MouseEvent>(document, 'click')
    .pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
        console.log("clickTarget", clickTarget)
        console.log("!!this.overlayRef", !!this.overlayRef)
        console.log("this.overlayRef.overlayElement", this.overlayRef.overlayElement)
        console.log("!this.overlayRef.overlayElement.contains(clickTarget);", !this.overlayRef.overlayElement.contains(clickTarget))
        console.log("!!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);", !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget))
        return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
      }),
      take(1)
    ).subscribe(() => this.closeFullText())

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.fullTextMenu, this.viewContainerRef, {
      $implicit: val
    }));
    


  }

  closeFullText() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
    const isVisible = $event.target.visibilityState === 'visible';
    // console.log("&&&&", isVisible)
    if (isVisible) {
      if (this.sm.localStorageGetItem("copiedFiles")) {
        this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
      } else {
        this.copiedFiles = undefined;
      }
        // tab is visible
    } else {
        // tab is not-visible
    }
  }

  ngOnDestroy(): void {
    if (this.is_mobile) {
      this.layoutService.overflowAuto();
    }
    if (this.refreshSub) {
      this.refreshSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
