<div class="cases_page tasks_page">
    <div class="container">
      <div class="filter_out">  
        <div class="filter_values">
          <mat-chip-list class="chips_wrp without_ordered start_page_chips">
  
            <mat-chip (removed)="removeValue('q')" *ngIf="filter.get('q').value">
                {{ "Search" | translate }}: {{filter.get('q').value}}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip class="user_chip" (removed)="removeValue('operation_partner_company_id')" *ngIf="filter.get('partner_company_id').value">
                {{ "Partner Workspace" | translate }}:
                <app-user [class]="'user_div ml-1'" [companyEmployee]="getPartnerById(filter.get('partner_company_id').value)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip class="user_chip" (removed)="removeValue('platform_id')" *ngIf="filter.get('platform_id').value">
                <div class="platform_wrp">
                  <app-platform [platform_id]="filter.get('platform_id').value"></app-platform>
                  {{ getPlatformById(filter.get('platform_id').value)?.name }}
                </div>
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('group_id')" *ngIf="filter?.get('group_id')?.value">
                {{ "Group" | translate }}: {{ getGroupById(filter?.get('group_id')?.value)?.name }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <ng-container *ngIf="filter.get('parameter_value_id').value.length">
              <span class="chip_out_label">{{ "Tag for everywhere" | translate }}</span>
              <mat-chip *ngFor="let forTaskParamValueId of filter.get('parameter_value_id').value" (removed)="removeValueFromMultiple('parameter_value_id', forTaskParamValueId)">
                 {{ getValueById(forTaskParamValueId)?.value }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </ng-container>
  
            <mat-icon *ngIf="filter.get('q').value || filter?.get('partner_company_id')?.value || filter.get('parameter_value_id').value.length || filter?.get('platform_id')?.value || filter?.get('group_id')?.value" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
          </mat-chip-list>
  
          <div class="stats_wrp ml-1">
            <div class="tasks_counters">
              
              <!-- <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
                <div class="simple_filter_snippet" (click)="presetFilter.open()">
                  <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                    <span class="name">
                      {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                    </span>
                  </ng-container>
                  <ng-template #elseTemplatePreset>
                    <span class="name">
                      {{ "Bookmark" | translate }}
                    </span>
                  </ng-template>
                  
  
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2474_38987)">
                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2474_38987">
                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
  
                <mat-form-field appearance="legacy" class="filter_form preset_filter">
                  <mat-label>{{ "Bookmark" | translate }}</mat-label>
                  <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                      <mat-option *ngIf="bookmarks.length > 7">
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                            [formControl]="bookmarksControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                        {{ bookmark.name | translate }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </form> -->
  
              <div class="counter_block" *ngIf="pagination && pagination['totalCount']">
                <div class="counter_top">{{ "Outlets" | translate }}</div>
                <div class="counter_bottom">{{pagination['totalCount']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="lists_wrp">
      </div>   
    </div>
</div>
<div class="container centred_page">

    <div class="step">
        <!-- <h4>{{ 'Outlets' | translate }}: {{ pagination?.totalCount }}</h4>  -->
    
        <div class="table_container" [appDeviceClass]="'custom-scroll custom-scroll-t'" #tableContainer>
            <mat-list role="list">
                <mat-list-item *ngFor="let profile of profiles; let i = index;" role="listitem" class="company_item">
                    <div class="company_name">
                        <ng-container *ngIf="profile.avatarFile; else elseTemplateNoPhoto">
                            <div class="channel_avatar">
                                <img [src]="host + profile.avatarFile.original + '?company_id=' + company_id" alt="">
                                <div class="channel_avatar_platform">
                                    <app-platform [platform_id]="profile?.platform_id"></app-platform>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateNoPhoto>      
                            <div class="platform_icon">
                                <app-platform [platform_id]="profile?.platform_id"></app-platform>
                            </div>
                        </ng-template>
                        
                        <div class="id">{{profile?.id}}</div>
   
                        {{ profile?.name || "no_name" }}

                        <div class="ml-1 d-f-c small_val" *ngIf="!!profile?.channel_account_id">
                            <mat-icon class="mr-1">
                                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.25 2.5L8.75 5H10.625C10.625 5.99456 10.2299 6.94839 9.52665 7.65165C8.82339 8.35491 7.86956 8.75 6.875 8.75C6.25 8.75 5.64375 8.59375 5.125 8.3125L4.2125 9.225C4.98125 9.7125 5.89375 10 6.875 10C8.20108 10 9.47285 9.47322 10.4105 8.53553C11.3482 7.59785 11.875 6.32608 11.875 5H13.75L11.25 2.5ZM3.125 5C3.125 4.00544 3.52009 3.05161 4.22335 2.34835C4.92661 1.64509 5.88044 1.25 6.875 1.25C7.5 1.25 8.10625 1.40625 8.625 1.6875L9.5375 0.775C8.76875 0.2875 7.85625 0 6.875 0C5.54892 0 4.27715 0.526784 3.33947 1.46447C2.40178 2.40215 1.875 3.67392 1.875 5H0L2.5 7.5L5 5" fill="#1877F2"/>
                                </svg>                         
                            </mat-icon>
                            <span>{{profile?.channelAccount?.name}}({{profile?.channelAccount?.type}})</span>
                        </div>
                    </div>
                    <div class="company_btns">
                        <div *ngIf="!!profile?.groups.length" class="d-f-c">
                            <mat-icon class="mr-1" style="color: #000">groups</mat-icon>
                            <span class="group_name" *ngFor="let group of profile?.groups; let j = index;">
                                {{ group.name }}<ng-container *ngIf="j != profile?.groups.length - 1">, </ng-container>
                            </span>
                        </div>
                        <a class="icon_out" (click)="editProfile($event, profile, group)" *ngIf="!filter.get('partner_company_id').value">
                            <mat-icon style="color: green;">edit</mat-icon>
                        </a>
                        <mat-icon style="color: #e67e22;" (click)="openChannelToEmployee(profile)">people</mat-icon>
                        <mat-icon *ngIf="!filter.get('partner_company_id').value" style="color: rgb(78, 7, 7);" (click)="openPlaylist(profile)">video_library</mat-icon>
                        <mat-icon  *ngIf="!filter.get('partner_company_id').value" style="color: red;" (click)="deleteProfileCompany(profile?.id, i)">delete</mat-icon>
                        <mat-icon  *ngIf="!filter.get('partner_company_id').value && (company?.permissions.includes('owner') || (company?.permissions.includes('manage_channel') && !profile?.channel_account_id) || !company?.permissions.includes('manage_channel'))" style="color: blue;" (click)="connectWithYouTube(profile)">share</mat-icon>
                        <a class="icon_out" *ngIf="profile.url" [href]="profile.url" target="_blank" #tooltip="matTooltip" matTooltip="{{ !!profile?.channel_account_id ? ('Link to authorized channel' | translate) : ('Link to not authorized channel' | translate) }}" [matTooltipPosition]="'above'">
                            <mat-icon [ngStyle]="{'color': !!profile?.channel_account_id ? '#1877F2' : '#686868'}">open_in_new</mat-icon>
                        </a>
                        <div class="chan_tags" [ngClass]="{'has': profile.tags && profile.tags.length}">
                            <svg class="cp" (click)="openTargetValues(profile)" style="width: 20px; height: 16px;" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 5C3.89782 5 4.27936 4.84196 4.56066 4.56066C4.84196 4.27936 5 3.89782 5 3.5C5 3.10218 4.84196 2.72064 4.56066 2.43934C4.27936 2.15804 3.89782 2 3.5 2C3.10218 2 2.72064 2.15804 2.43934 2.43934C2.15804 2.72064 2 3.10218 2 3.5C2 3.89782 2.15804 4.27936 2.43934 4.56066C2.72064 4.84196 3.10218 5 3.5 5ZM15.41 7.58C15.77 7.94 16 8.44 16 9C16 9.55 15.78 10.05 15.41 10.41L10.41 15.41C10.05 15.77 9.55 16 9 16C8.45 16 7.95 15.78 7.58 15.41L0.59 8.42C0.22 8.05 0 7.55 0 7V2C0 0.89 0.89 0 2 0H7C7.55 0 8.05 0.22 8.41 0.58L15.41 7.58ZM11.54 1.71L12.54 0.71L19.41 7.58C19.78 7.94 20 8.45 20 9C20 9.55 19.78 10.05 19.42 10.41L14.04 15.79L13.04 14.79L18.75 9L11.54 1.71Z" fill="black"/>
                            </svg>
                            <div class="tags_wrp" *ngIf="profile.tags && profile.tags.length">
                                <ng-container *ngFor="let item of profile.tags; let last = last">
                                    <span>{{item?.parameterValue?.value}}<ng-container *ngIf="!last">, </ng-container></span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
  
        <div class="btns_line" *ngIf="!filter.get('partner_company_id').value">
            <a mat-raised-button (click)="goBack($event)">
                {{ "Back" | translate }}
            </a>
            <a class="ml-1" (click)="addProfile($event)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Add publication outlet" | translate }}
            </a>
        </div>
  
  
    </div>
  </div>