import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, fromEvent, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ChannelEditDescriptionComponent } from 'src/app/components/connected-apps/dialogs/channel-edit-description/channel-edit-description.component';
import { ConnectedAppChannelsComponent } from 'src/app/components/connected-apps/dialogs/connected-app-channels/connected-app-channels.component';
import { EditChannelAccountComponent } from 'src/app/components/connected-apps/dialogs/edit-channel-account/edit-channel-account.component';
import { ytLangs } from 'src/app/shared/consts/ytLangs';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TimeFormatPipe } from 'src/app/shared/pipes/timeFormat';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { environment } from 'src/environments/environment';
import { LinkAndCreateOutletComponent } from './dialogs/link-and-create-outlet/link-and-create-outlet.component';

@Component({
  selector: 'app-manage-auth',
  templateUrl: './manage-auth.component.html',
  styleUrls: ['./manage-auth.component.scss']
})
export class ManageAuthComponent extends BaseClass implements OnInit, OnDestroy {
  @Input() user: any;
  @Input() company: any;
  @Input() company_id: any;
  @Input() imgRoute: any;
  
  public host: any = environment.host;
  throttle = 100;
  scrollUpDistance = 3.5;
  public isExpanded: boolean = true;
  public subsctiptions: any;
  public profiles: any;
  public channels: any;
  public is_active_yt: number = 1;
  public isLoad: boolean = false;
  public is_mobile: boolean = false;
  public tikTokChannels: any;
  public activeLang: any;
  public metaChannels: any;
  public snapChannels: any;
  public showFilter: boolean = !!this.sm.localStorageGetItem('showFilter')
  public videoWithotWorkspaceID: boolean = !!this.sm.localStorageGetItem('videoWithotWorkspaceID')
  public defaultPlayer: boolean = !!this.sm.localStorageGetItem('defaultPlayer')
  public md5hash: boolean = !!this.sm.localStorageGetItem('md5hash')
  public shortcuts: boolean = !!this.sm.localStorageGetItem('shortcuts')
  public user_id: any;
  
  public ytPagination: any;
  public ytPage: number = 1;

  public metaPagination: any;
  public metaPage: number = 1;
  
  public metaTab: number = 0;

  public ttPagination: any;
  public ttPage: number = 1;

  public snapPagination: any;
  public snapPage: number = 1;

  public ytLangs: any = ytLangs;

  public types: any[] = [
    {
      icon: 'youtube',
      name: 'YouTube Channels',
      tab: 'youtube',
      type: "youtube"
    },
    {
      icon: 'tiktok',
      name: 'TikTok Channels',
      tab: 'tiktok',
      type: "tiktok"
    },
    {
      icon: 'meta',
      name: 'Meta',
      tab: 'meta',
      type: "facebook"
    },
    {
      icon: 'snapchat',
      name: 'Snapchat',
      tab: 'snapchat',
      type: "snapchat"
    }
  ]

  public selectedIndex: FormControl = new FormControl(0);

  public filter: FormGroup;
  
  constructor(
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private connectedAppsService: ConnectedAppsService,
    private bottomSheet: MatBottomSheet,
    private auth: AuthService,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    super()
   }

  ngOnInit(): void {

    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(resp => {
        this.company_id = +this.activatedRoute.snapshot.queryParamMap.get('company_id');
        this.getCompany();
        this.filter = this.fb.group({
          type: this.activatedRoute.snapshot.queryParamMap.get('type') || 'youtube',
          q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
          company_id: this.company_id
        })
        this.selectedIndex.patchValue(this.types.findIndex(u => u.type == this.activatedRoute.snapshot.queryParamMap.get('type')) || 0)

        switch (this.filter.value.type) {
          case 'youtube':
            console.log('Youtube')
            this.ytPage = 1;
            this.getChannelAccounts(this.ytPage);
            break;
          case 'tiktok':
            console.log('TikTok')
            this.ttPage = 1;
            this.getChannels(this.ttPage);
            break;
          case 'facebook':
            console.log('facebook')
            // this.getMetaChannelAccounts();
            this.metaPage = 1;
            this.getChannels(this.metaPage);
            break;
          case 'snapchat':
            console.log('snapchat')
            // this.getSnapChannelAccounts();
            this.snapPage = 1;
            this.getChannels(this.snapPage);
            break;
          case 'users':
            // this.getRootUsers()
            break;
        
          default:
            break;
        }
        console.log("this.activatedRoute.queryParams selectedIndex", this.selectedIndex.value)
        console.log("this.activatedRoute.queryParams filter", this.filter.value)
      })
    )
  }

  removeValue(val) {
    if (val == "all") {
      this.filter.patchValue({
        q: '',
      })
    } else {
      this.filter.patchValue({
        [val]: ''
      })
      this.filterManageAuth()
    }
  }

  filterManageAuth() {
    let filterData = {...this.filter.value};

    // this.sm.localStorageSetItem("activeStatus", filterData.operation_status_id);
    
    let params:any = {
      company_id: this.company_id
    };

    // if (is_btn) {
    //   this.filter.removeControl('id');
    // }
    
    Object.keys(filterData).forEach(element => {
      if (!!filterData[element]) {
        params[element] = filterData[element]
      }
    });

    this.router.navigate(['/settings'], { queryParams: Object.assign({
      page: 'manage-auth',
    }, params) });
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  changeTab(e) {
    this.selectedIndex.patchValue(e);

    let params = {...this.activatedRoute.queryParamMap.source['_value']};
    params.type = this.types[e].type;
    this.router.navigate(['/settings'], {queryParams: params});
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  openAccount(channel, key) {
    console.log(channel, key)
    const dialogRef = this.dialog.open(ConnectedAppChannelsComponent, {
      disableClose: true,
      data: {
        channel: channel,
        user: this.user,
        activeLang: this.activeLang,
        company_id: this.company_id,
        company: this.company,
        fromCompany: true,
        key: key
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result && result.event == "add") {

      }
    })
  }

  linkAndCreate(account, exChan?) {
    console.log("linkAndCreate", account)
    const dialogRef = this.dialog.open(LinkAndCreateOutletComponent, {
      disableClose: true,
      data: {
        account: account,
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        activeLang: this.activeLang,
        exChan: exChan
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result && result.event == "add") {
        switch (this.filter.value.type) {
          case 'youtube':
            console.log('Youtube')
            this.ytPage = 1;
            this.getChannelAccounts(this.ytPage);
            break;
          case 'tiktok':
            console.log('TikTok')
            this.ttPage = 1;
            this.getChannels(this.ttPage);
            break;
          case 'facebook':
            console.log('facebook')
            // this.getMetaChannelAccounts();
            this.metaPage = 1;
            this.getChannels(this.metaPage);
            break;
          case 'snapchat':
            console.log('snapchat')
            // this.getSnapChannelAccounts();
            this.snapPage = 1;
            this.getChannels(this.snapPage);
            break;
          case 'users':
            // this.getRootUsers()
            break;
        
          default:
            break;
        }
      }
    })
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  goToMenuItem(item) {
    this.router.navigate(['user/connected-apps'], { queryParams: {tab: item.tab}});
  }

  playerChange(e) {
    console.log(e, this.defaultPlayer);
    if (this.defaultPlayer) {
      this.sm.localStorageSetItem("defaultPlayer", '1')
    } else {
      localStorage.removeItem("defaultPlayer")
    }
  }
  
  md5Change(e) {
    console.log(e, this.md5hash);
    if (this.md5hash) {
      this.sm.localStorageSetItem("md5hash", '1')
    } else {
      localStorage.removeItem("md5hash")
    }
  }
  
  barItemsChange(e) {
    console.log(e, this.shortcuts);
    if (this.shortcuts) {
      this.sm.localStorageSetItem("shortcuts", '1')
    } else {
      localStorage.removeItem("shortcuts")
    }
  }

  updateVideosInChannelAccount(item) {
    if (item.is_active == 0) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to authorize your profile. Click on the refresh icon."), SnackBarItem)
      return
    }
    console.log(item);
    this.attachSubscriptions(
      this.connectedAppsService.editChannelAccount(item.id, {is_update_data: 1}).subscribe(resp => {
        item.is_update_data = resp.is_update_data;
        console.log(resp)
      }, error => {
        let msg = error;
        if (error == "You can update data once per day, please wait.") {
          msg = msg + " Remaining: " + new TimeFormatPipe().transform(item.can_update_at)
        }
        this.layoutService.showSnackBar({name: ''}, msg, SnackBarItem)
      })
    )
  }

  // dontShowPushModal() {
  //   this.sm.localStorageSetItem("showPushModal", '1');
  // }

  // getSubscription() {
  //   console.log(Notification.permission);

  //   if (!("Notification" in window)) {
  //     this.dontShowPushModal();
  //   }
  //   if (Notification.permission === 'default') {
  //     console.log("default");
  //     Notification.requestPermission().then(() => {
  //       this.requestSubscription();
  //     }).catch(() => {
  //       // show permission denied error
  //       console.log("catch");
  //     });
  //   } else if (Notification.permission === 'denied') {
  //     console.log("denied");
  //     this.dontShowPushModal();
  //     // show permission is denied, please allow it error
  //   } else {
  //     this.requestSubscription();
  //   }
  // }

  // async requestSubscription() {
  //   try {
  //     console.log("requestSubscription object ", 'try');
  //     const sub = await this.swPush.requestSubscription({ serverPublicKey: this.user.web_push_public_key});
  //     console.log("requestSubscription object ", sub);
  //     // this.companyService.sendSubs({ subscription: sub }).subscribe(resp => {
  //     //   this.sm.localStorageSetItem("showPushModal", '1')
  //     // }, error => {
  //     //   if (error == 'Subscription already exists.') {
  //     //     this.sm.localStorageSetItem("showPushModal", '1');
  //     //   } else {
  //     //     this.sm.localStorageSetItem("showPushModal", '0');
  //     //   }
  //     // })
  //   } catch (e) {
  //     console.log("requestSubscription catch ", e);
  //     this.sm.localStorageSetItem("showPushModal", '0');
  //   }
  // }

  // subscribePlatform() {
  //   console.log("subscribePlatform");

  //   this.getSubscription();
  // }

  workspaceIdChange(e) {
    console.log(e, this.videoWithotWorkspaceID);
    if (this.videoWithotWorkspaceID) {
      this.sm.localStorageSetItem("videoWithotWorkspaceID", '1')
    } else {
      localStorage.removeItem("videoWithotWorkspaceID")
    }
  }

  filterPanelChange(e) {
    console.log(e, this.showFilter);
    if (this.showFilter) {
      this.sm.localStorageSetItem("showFilter", '1')
    } else {
      localStorage.removeItem("showFilter")
    }
  }

  // getProfiles() {
  //   this.attachSubscriptions(
  //     this.companyService.getProfiles(this.company_id, '1').pipe(
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       console.log("getProfiles", resp)
  //       this.profiles = resp;
  //     })
  //   )
  // }

  reconectChannelAccountAuthUrl(item) {
    item.isRefreshed = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("youtube", window.location.href, item.id, this.company_id).subscribe(resp => {
        console.log(resp);
        item.isRefreshed = false;
        window.open(this.host + resp, "_self")
      })
    )
  }

  getChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("youtube", window.location.href, 0, this.company_id).subscribe(resp => {
        console.log(resp);
        console.log(this.host + resp);
        window.open(this.host + resp, "_self")
      })
    )
  }

  reconectTikTokChannelAccountAuthUrl(item) {
    item.isRefreshed = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("tiktok", window.location.href, item.id, this.company_id).subscribe(resp => {
        console.log(resp);
        item.isRefreshed = false;
        window.open(this.host + resp, "_self")
      })
    )
  }

  reconectMetaChannelAccountAuthUrl(item) {
    item.isRefreshed = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("facebook", window.location.href, item.id, this.company_id).subscribe(resp => {
        console.log(resp);
        item.isRefreshed = false;
        window.open(this.host + resp, "_self")
      })
    )
  }

  reconectSnapChannelAccountAuthUrl(item) {
    item.isRefreshed = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("snapchat", window.location.href, item.id, this.company_id).subscribe(resp => {
        console.log(resp);
        item.isRefreshed = false;
        window.open(this.host + resp, "_self")
      })
    )
  }

  getTikTokChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("tiktok", window.location.href, 0, this.company_id).subscribe(resp => {
        console.log(resp);
        window.open(this.host + resp, "_self")
      })
    )
  }

  getMetaChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("facebook", window.location.href, 0, this.company_id).subscribe(resp => {
        console.log(resp);
        window.open(this.host + resp, "_self")
      })
    )
  }
  getSnapChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("snapchat", window.location.href, 0, this.company_id).subscribe(resp => {
        console.log(resp);
        window.open(this.host + resp, "_self")
      })
    )
  }

  editChannelAccountDescription(item) {
    console.log(item);
    const dialogRef = this.dialog.open(ChannelEditDescriptionComponent, {
      disableClose: true,
      data: {
        channel: item,
        exChannel: item.exChannels[0],
        user: this.user,
        activeLang: this.activeLang
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result && result.event == "add") {

      }
    })
  }

  onDownConApps(e) {
    console.log("SCROLL DOWN", e);
    switch (this.filter.value.type) {
      case 'youtube':
        console.log('onDownConApps Youtube')
        console.log('onDownConApps Youtube ytPagination pageCount', this.ytPagination['pageCount'])
        console.log('onDownConApps Youtube this.ytPage', this.ytPage)
        if (this.ytPagination['pageCount'] >= this.ytPage) {
          this.getChannelAccounts(this.ytPage);
        }
        break;
      case 'tiktok':
        console.log('onDownConApps TikTok')
        if (this.ttPagination['pageCount'] >= this.ttPage) {
          this.getChannels(this.ttPage);
        }
        break;
        
      case 'facebook':
        console.log('onDownConApps facebook')
        if (this.metaPagination['pageCount'] >= this.metaPage) {
          this.getChannels(this.metaPage);
        }
        break;

      case 'snapchat':
        console.log('onDownConApps snapchat')
        if (this.snapPagination['pageCount'] >= this.snapPage) {
          this.getChannels(this.snapPage);
        }
        break;
    
      default:
        break;
    }
  }

  changeFilterYt(val) {
    if (this.is_active_yt == val) {
      return
    }
    this.is_active_yt = val;
    this.ytPage = 1;
    this.channels = [];
    this.getChannelAccounts(this.ytPage)
  }

  goToChannelSchedule(item, val?) {
    if (val) {
      this.router.navigate(['regular-publications'], { queryParams: {company_id: this.company_id, channel_id: val.id}});
    } else {
      this.router.navigate(['regular-publications'], { queryParams: {company_id: this.company_id, channel_id: item.channels[0].id}});
    }
  }

  getChannelAccounts(page) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountsScrollFilter(page, 'youtube', {company_id: this.company_id, is_active: this.is_active_yt, q: this.filter.value.q}).pipe(
        tap(el => {
          if (page == 1) {
            this.ytPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map((el) => el.body),
        switchMap(res => {
          if (res && res.length) {
            return forkJoin(res.map(x => this.connectedAppsService.getChannelAccountsChannels(null, x.id, x.type, null, this.company_id).pipe(
              tap(u => {
                u.map(b => b.avatarFile = b.imageFile)
                x.exChannels = u;
              })
            ))).pipe(
              map(() => res)
            )
          } else {
            return of(res)
          }
        })
      ).subscribe(resp => {
        if (page == 1) {
          this.channels = resp;
        } else {
          this.channels.push(...resp)
        }
        console.log("getYT-" + this.ytPage, this.channels);
        this.ytPage++;
        this.isLoad = false;
      })
    )
  }

  getChannels(page) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountsScroll(page, this.filter.value.type, this.filter.value.q, null, this.company_id).pipe(
        tap(el => {
          if (page == 1) {
            let x = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
            switch (this.filter.value.type) {
              case 'tiktok':
                this.ttPagination = x;
                break;
              case 'snapchat':
                this.snapPagination = x;
                break;
              case 'facebook':
                this.metaPagination = x;
                break;
            
              default:
                break;
            }
          }
        }),
        map((el) => el.body),
        switchMap(res => {
          if (this.filter.value.type == 'tiktok' && res && res.length) {
            return forkJoin(res.map(x => this.connectedAppsService.getChannelAccountsChannels(null, x.id, x.type, null, this.company_id).pipe(
              tap(u => {
                u.map(b => b.avatarFile = b.imageFile)
                x.exChannels = u;
              })
            ))).pipe(
              map(() => res)
            )
          } else {
            return of(res)
          }
        })
      ).subscribe(resp => {
        console.log("getChannels", resp)
        if (page == 1) {
          switch (this.filter.value.type) {
            case 'tiktok':
              this.tikTokChannels = resp;
              break;
            case 'snapchat':
              this.snapChannels = resp;
              break;
            case 'facebook':
              resp.forEach(element => {
                element.tab = 0
              });
              this.metaChannels = resp;
              break;
          
            default:
              break;
          }
        } else {
          switch (this.filter.value.type) {
            case 'tiktok':
              this.tikTokChannels.push(...resp)
              break;
            case 'snapchat':
              this.snapChannels.push(...resp)
              break;
            case 'facebook':
              resp.forEach(element => {
                element.tab = 0
              });
              this.metaChannels.push(...resp)
              break;
          
            default:
              break;
          }
        }
        page++;
        this.isLoad = true;
      })
    )
  }

  ifIsItemChannel(item, exChan) {
    if (item.channels && item.channels.filter(x => x.external_id == exChan.external_id).length) {
      return true
    } else {
      return false
    }
  }

  ifIsChannelActive(item, chan) {
    if (item.exChannels && item.exChannels.filter(x => x.external_id == chan.external_id && x.is_active == 1).length) {
      return true
    } else {
      return false
    }
  }

  expandItem(item) {
    item.isOpen = !item.isOpen;
    console.log("expandItem", item);
    if (!item.exChannels) {
      item.exChannels = [];
    }
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountsChannels(null, item.id, item.type, null, this.company_id).subscribe(resp => {
        item.exChannels = resp;
      })
    )
  }

  logItem(item) {
    console.log('logItem', item)
  }
  // getTikTokChannelAccounts() {
  //   this.attachSubscriptions(
  //     this.connectedAppsService.getChannelAccounts('tiktok').subscribe(resp => {
  //       console.log("getTikTokChannelAccounts", resp)
  //       this.tikTokChannels = resp
  //     })
  //   )
  // }
  
  // getMetaChannelAccounts() {
  //   this.attachSubscriptions(
  //     this.connectedAppsService.getChannelAccounts('facebook').subscribe(resp => {
  //       console.log("getMetaChannelAccounts", resp)
  //       this.metaChannels = resp
  //     })
  //   )
  // }

  // getSnapChannelAccounts() {
  //   this.attachSubscriptions(
  //     this.connectedAppsService.getChannelAccounts('snapchat').subscribe(resp => {
  //       console.log("getMetaChannelAccounts", resp)
  //       this.snapChannels = resp
  //     })
  //   )
  // }

  changeItemTab(item, val) {
    item.tab = val;
  }


  editChannelAccount(channel) {
    const dialogRef = this.dialog.open(EditChannelAccountComponent, {
      disableClose: true,
      data: channel
    });
  }


  deleteChannelAccount(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("authorization")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.connectedAppsService.deleteChannelAccount(id).subscribe(resp => {
            this.channels.splice(i,1)
          })
        )
      }
    });
  }

  // getCompany() {
  //   this.attachSubscriptions(
  //     this.companyService.getCompany(this.company_id).subscribe(resp => {
  //       this.companyService.company$.next(resp[0]);
  //       this.company = resp[0];
  //     })
  //   )
  // }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}