import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { SetEditCardComponent } from '../set-edit-card/set-edit-card.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { TaskService } from '../../services/rest/task.service';
import { CompanyService } from '../../services/rest/company.service';
import { environment } from 'src/environments/environment';
import { CreateTaskComponent } from 'src/app/components/atTasksDialog/create-task/create-task.component';
import { MobAddTaskComponent } from 'src/app/components/mob-task/mob-add-task/mob-add-task.component';
import { EmplCreateTaskComponent } from 'src/app/components/atTasksDialog/empl-create-task/empl-create-task.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GlobalDataService } from '../../services/common/global-data.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-compilations-wizard',
  templateUrl: './compilations-wizard.component.html',
  styleUrls: ['./compilations-wizard.component.scss']
})
export class CompilationsWizardComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public selectedTask: any;
  public taskStatuses: any;
  public origin = window.location.origin;
  public host: any = environment.host;
  
  public profileStatuses: any;
  public generations: any;
  public groups: any;
  public flags: any = ['is_repeats', 'is_side_repeats', 'is_order_repeats', "no_conditions", "is_compilation_repeats"]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CompilationsWizardComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private globalDataService: GlobalDataService,
    private taskService: TaskService,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
  ) { super() }

  ngOnInit(): void {
    console.log("CompilationsWizardComponent", this.data)
    this.getGroupsCompany();
    this.getTaskStatus();
    this.getProfilesStatus();
    this.form = this.fb.group({
      company_id: this.data.company_id,
      partner_company_id: 0,
      initial_task_ids: "",
      task_ids: this.data.tasks.map(x => x.id).join(','),
      count: 3,
      suggestions: 10,
      flag: 'is_order_repeats'
    })

    // this.attachSubscriptions(
    //   this.form.valueChanges.subscribe(res => {
    //     if (typeof res.count != 'number') {
    //       this.form.patchValue({count: 3}, {emitEvent: false})
    //     } else if (res.count > 20) {
    //       this.form.patchValue({count: 20}, {emitEvent: false})
    //     } else if (res.count < 1) {
    //       this.form.patchValue({count: 1}, {emitEvent: false})
    //     }

    //     if (typeof res.suggestions != 'number') {
    //       this.form.patchValue({suggestions: 10}, {emitEvent: false})
    //     } else if (res.suggestions > 10) {
    //       this.form.patchValue({suggestions: 10}, {emitEvent: false})
    //     } else if (res.suggestions < 1) {
    //       this.form.patchValue({suggestions: 1}, {emitEvent: false})
    //     }
    //   })
    // )
  }

  selectRadio(e, key) {
    console.log(e);
    e.preventDefault();
    e.stopPropagation();
    // if (this.form.value.flag == key) {
    //   this.form.patchValue({
    //     flag: ''
    //   })
    // }

  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
        this.taskStatuses = resp;
      })
    )
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  setEditCard() {
    const dialogRef = this.dialog.open(SetEditCardComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        activeLang: this.data.activeLang,
        only: true,
        selectedTask: this.selectedTask
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addScheduledPost", result)
        if (result && result.event == 'select') {
          this.selectedTask = result.data;
          if (!this.selectedTask.hasOwnProperty('wiz_channels')) {
            this.selectedTask.wiz_channels = this.selectedTask.channels.filter(x => !!x.content_url);
            this.selectedTask.wiz_channels.sort((a,b) => {
              return b.content_views_count - a.content_views_count;
            })
            this.selectedTask.wiz_views_count = this.selectedTask.wiz_channels.map(n => n.content_views_count).reduce((acc, number) => acc + number, 0)
          }
          if (this.selectedTask) {
            this.form.patchValue({
              initial_task_ids: this.selectedTask.id
            })
          }
        }
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }  

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        company: this.data.company,
        initCompanyId: this.data.company_id,
      }
    });
  }

  changeInp() {
    let res = this.form.value;
    if (typeof res.count != 'number') {
      this.form.patchValue({count: 3}, {emitEvent: false})
    } else if (res.count > 100) {
      this.form.patchValue({count: 100}, {emitEvent: false})
    } else if (res.count < 1) {
      this.form.patchValue({count: 1}, {emitEvent: false})
    }

    if (typeof res.suggestions != 'number') {
      this.form.patchValue({suggestions: 10}, {emitEvent: false})
    } else if (res.suggestions > 10) {
      this.form.patchValue({suggestions: 10}, {emitEvent: false})
    } else if (res.suggestions < 1) {
      this.form.patchValue({suggestions: 1}, {emitEvent: false})
    }
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  log() {
    console.log("form", this.form.value)
    console.log("loc", this.activatedRoute.queryParams)
    console.log("loc", window.location)
    this.activatedRoute.queryParams.subscribe(res => {
      console.log("loc res", res)
    })
  }

  goToChanelLink(task, chanel) {
    window.open(chanel.content_url, '_blank');
  }

  generateCompilations(is_deep:boolean = false) {
    let sendData = {...this.form.value};
    delete sendData.flag;

    this.flags.forEach(key => {
      if (this.form.value.flag == 'no_conditions') {
        if (key != 'no_conditions') {
          sendData[key] = 1;
        } 
      } else {
        if (key != 'no_conditions') {
          if (this.form.value.flag == key) {
            sendData[key] = 0;
          } else {
            sendData[key] = 1;
          }
        }
      }
    });

    if (is_deep) {
      delete sendData.task_ids;

      this.activatedRoute.queryParams.subscribe(res => {
        sendData.task_filters = res
      })
      // sendData.task_filters = 
    }


    console.log("sendData", sendData)
    this.attachSubscriptions(
      this.taskService.generateCompilations(sendData).pipe(
        tap(res => {
          this.generations = [];
          console.log("generateCompilations", res)
          if (!is_deep) {
            res.compilations.forEach(arr => {
              let tasksArr = []
              arr.forEach(id => {
                if (this.data.tasks.filter(x => x.id == id).length != 0) {
                  let task = this.data.tasks.find(x => x.id == id);
                  if (!task.hasOwnProperty('wiz_channels')) {
                    task.wiz_channels = task.channels.filter(x => !!x.content_url);
                    task.wiz_channels.sort((a,b) => {
                      return b.content_views_count - a.content_views_count;
                    })
                    task.wiz_views_count = task.wiz_channels.map(n => n.content_views_count).reduce((acc, number) => acc + number, 0)
                  }
                  tasksArr.push(task)
                } else {
                  tasksArr.push(this.selectedTask)
                }
              })
              this.generations.push(tasksArr)
            })
          }
        }),
        switchMap(gen => {
          if (is_deep) {
            return forkJoin([...gen.compilations.map(genIds => this.taskService.getCardsExpand('1', this.data.company.id, {id: genIds.join(',')}, genIds.length, 0, 1).pipe(
              tap(u => {
                let tasksArr = [];
                console.log("genIds", genIds)
                console.log("u", u)
                genIds.forEach(id => {
                  if (u.filter(x => x.id == id).length != 0) {
                    let task = u.find(x => x.id == id);
                    if (!task.hasOwnProperty('wiz_channels')) {
                      task.wiz_channels = task.channels.filter(x => !!x.content_url);
                      task.wiz_channels.sort((a,b) => {
                        return b.content_views_count - a.content_views_count;
                      })
                      task.wiz_views_count = task.wiz_channels.map(n => n.content_views_count).reduce((acc, number) => acc + number, 0)
                    }
                    tasksArr.push(task)
                  } else {
                    tasksArr.push(this.selectedTask)
                  }
                })
                this.generations.push(tasksArr)
              })
            ))])
          } else {
            return of(gen);
          }
        })
      ).subscribe(resp => {
        console.log("gen", resp)
        console.log("generations", this.generations)
      })
    )
  }

  createTaskBasedCompilation(item) {
    let file_ids = [];
    console.log('file_ids', file_ids)
    let task_ids = [];
    item.forEach(task => {
      if (!task_ids.includes(task.id)) {
        task_ids.push(task.id)
      }
    })
    if ((this.data.company.permissions.includes("owner") || this.data.company.permissions.includes("manager") || this.data.company.permissions.includes("admin")) && this.data.company.id == this.data.company_id) {
      this.createTask(this.data.company, file_ids, this.data.company_id, task_ids);
    } else {
      this.emplCreateTask(this.data.company, file_ids, this.data.company_id, task_ids)
    }
  }

  createTask(company, file_ids, f_c_id?, task_ids?) {
    if (!this.data.is_mobile) {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: company.id,
          company: company,
          file_ids: file_ids,
          imgRoute: this.data.imgRoute,
          host: this.host,
          user: this.data.user,
          f_c_id: f_c_id,
          related: [0, 0, 0],
          relatedArrs: [task_ids, 0, 0]
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: company.id,
          company: company,
          file_ids: file_ids,
          imgRoute: this.data.imgRoute,
          host: this.host,
          user: this.data.user,
          f_c_id: f_c_id,
          related: [0, 0, 0],
          relatedArrs: [task_ids, 0, 0]
        }
      });

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
        })
      )
    }

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  emplCreateTask(company, file_ids, f_c_id?, task_ids?) {
    const dialogRef = this.dialog.open(EmplCreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: company.id,
        company: company,
        file_ids: file_ids,
        imgRoute: this.data.imgRoute,
        host: this.host,
        user: this.data.user,
        f_c_id: f_c_id,
        related: [0, 0, 0],
        relatedArrs: [task_ids, 0, 0]
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
