import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-mob-task-edit-group',
  templateUrl: './mob-task-edit-group.component.html',
  styleUrls: ['./mob-task-edit-group.component.scss']
})

export class MobTaskEditGroupComponent extends BaseClass implements OnInit, OnDestroy {
  throttle = 300;
  scrollUpDistance = 3.5;
  public form: FormGroup;
  public array$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searchControl: FormControl = new FormControl();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobTaskEditGroupComponent>,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("MobTaskEditGroupComponent", this.data)

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )

    this.array$.next(this.data.array.slice())

    this.attachSubscriptions(
      this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp))
    )

    this.form = this.fb.group({
      [this.data.control]: this.fb.control([this.data.task[this.data.control]]) 
    })

    console.log(this.form)
  }

  onSearch(resp) {
    if (!this.data.array) {
      return;
    }

    if (!resp) {
      this.array$.next(this.data.array.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.array$.next(
      this.data.array.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  back() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: 'back'})}, 300);
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply', data: {[this.data.control]: this.form.value[this.data.control][0]}})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
