<div class="container centred_page">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    
        <h2>{{ 'Setting up new Workspace' | translate }}</h2>

        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ 'Workspace name' | translate }}</mat-label>
            <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
        </mat-form-field>
        

        <div class="btns_line">
            <button mat-raised-button type="button" (click)="goBack()">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1 d-f-btn" type="submit" mat-raised-button color="primary" [disabled]="!form.valid || isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Create_company" | translate }}
            </button>
        </div>
    </form>
</div>