import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of, ReplaySubject, Subscription } from 'rxjs';
import { MY_FORMATS } from 'src/app/components/workspace-settings/finances/statements/statements.component';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EditApproveComponent } from '../edit-approve/edit-approve.component';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { prioritys } from 'src/app/shared/consts/prioritys';

@Component({
  selector: 'app-add-card-automation-template',
  templateUrl: './add-card-automation-template.component.html',
  styleUrls: ['./add-card-automation-template.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AddCardAutomationTemplateComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public operations: any;
  public profileStatuses: any;
  public automationType: any;
  public operationStatuses: any;
  public values: any;
  public parameters: any;
  public parametersCtrl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public switched: boolean = false;
  public opened: boolean = false;

  public templates: any;
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public paramDataSub: Subscription;
  public jobs: any[] = [];
  public newJobs: any[] = [];

  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public profiles: any = [];
  
  public prioritys = prioritys;

  public contentPage: number = 1;
  public contentPagination: any;
  public allContentTypes: any;
  public contentTypes: any[] = [];
  public contentTypesControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public platformsMoreControl: FormControl = new FormControl();
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platforms: any;

  public regulars: any;
  public regularsControl: FormControl = new FormControl();
  public regularItemsControl: FormControl = new FormControl();
  public regulars$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public regularItems$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partnerSwitch: boolean = false;
  public emplSwitch: boolean = false;

  public pCliSwitch: boolean = false;
  public cliSwitch: boolean = false;

  public clients = [];
  public partnerClients = [];
  public clients_ids = [];
  public partnerClients_ids = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddCardAutomationTemplateComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private parametersService: ParametersService,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private languageService: LanguageService,
    private globalDataService: GlobalDataService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,

  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass('has_cst_outlets_fil')
    console.log("AddCardAutomationTemplateComponent", this.data)
    window.scroll(0,0);

    this.company_id = this.data.company.id;
    this.task_id = 0;

    this.getCompany();
    this.getImgRoute();
    this.getPlatforms();
    this.getCsrf();


    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.parametersCtrl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )
    this.attachSubscriptions(
      this.regularsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchRegs(resp))
    )
    this.attachSubscriptions(
      this.regularItemsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchRegItems(resp))
    )
    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
    this.getPartners();
    this.getRegulars();
    this.getEmployees();

    this.form = this.fb.group({
      company_id: this.company_id,
      name: ['', Validators.required],
      scenario_name: ['', Validators.required],
      scenario_data: this.fb.group({
        channels_ids: [[]],
        channel_platforms_ids: [[]],
        channel_content_types_ids: [[]]
      }),
      is_task: 1,
    })

    console.log(this.form.get('skip_task_channel_status'))

    this.getContentTypes();
    this.getProfilesStatus();

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.contentTypesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )

    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )

    this.attachSubscriptions(
      this.form.get('scenario_name').valueChanges.subscribe(resp => {
        if (resp == 'TaskContentPlanChain') {
          if (!this.form.get('scenario_data').get("create_task_operation")) {
            (this.form.get('scenario_data') as FormGroup).addControl('create_task_operation', this.fb.group({
              operation_id: this.fb.control(''),
              priority: this.fb.control(0),
              name: this.fb.control(''),
              statuses_ids: this.fb.control([]),
              parameters_ids: this.fb.control([]),
              is_force_create: this.fb.control(0)
            }));
            (this.form.get('scenario_data') as FormGroup).addControl('create_discussion', this.fb.group({
              priority: this.fb.control(0),
              name: this.fb.control(''),
              statuses_ids: this.fb.control([]),
              is_force_create: this.fb.control(0)
            }));
            (this.form.get('scenario_data') as FormGroup).addControl('employees_ids', this.fb.control([[]]));
            (this.form.get('scenario_data') as FormGroup).addControl('partner_companies_ids', this.fb.control([[]]));
          }
        } else {
          if (!!this.form.get('scenario_data').get("create_task_operation")) {
            (this.form.get('scenario_data') as FormGroup).removeControl('create_task_operation');
            (this.form.get('scenario_data') as FormGroup).removeControl('create_discussion');
            (this.form.get('scenario_data') as FormGroup).removeControl('employees_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('partner_companies_ids');
          }
        }

        if (resp == 'TaskContentPlan') {
          if (!this.form.get('scenario_data').get("content_schedules_ids")) {
            (this.form.get('scenario_data') as FormGroup).addControl('content_schedules_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('content_schedule_items_ids', this.fb.control([]));
          }
        } else {
          if (!!this.form.get('scenario_data').get("content_schedules_ids")) {
            (this.form.get('scenario_data') as FormGroup).removeControl('content_schedules_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('content_schedule_items_ids');
          }
        }

        if (resp == 'TaskChain') {
          if (this.jobs && this.jobs.length && !this.data.tmpl) {
            this.jobs.forEach(job => {
                job.employees_ids = [];
                job.partner_companies_ids = [];
                job.parameter_values_ids = [];
                delete job.parameters_ids;
            })
          }
          if (!this.form.get('scenario_data').get("create_task")) {
            (this.form.get('scenario_data') as FormGroup).addControl('create_task', this.fb.group({
              name: '',
              task_template_id: ''
            }));
            (this.form.get('scenario_data') as FormGroup).addControl('is_new_task_related_to_source_task', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_new_task_part_of_source_task', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_copy_approved_files', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_copy_ready_files', this.fb.control(0));
          }
          if (!!this.form.get('scenario_data').get('channels_ids')) {
            (this.form.get('scenario_data') as FormGroup).removeControl('channels_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('channel_platforms_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('channel_content_types_ids');
          }
        } else {
          if (this.jobs && this.jobs.length && !this.data.tmpl) {
            this.jobs.forEach(job => {
              job.parameters_ids = [];

              delete job.employees_ids;
              delete job.partner_companies_ids;
              delete job.parameter_values_ids;
            })
          }
          if (!!this.form.get('scenario_data').get("create_task")) {
            (this.form.get('scenario_data') as FormGroup).removeControl('create_task');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_new_task_related_to_source_task');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_new_task_part_of_source_task');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_approved_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_ready_files');
          }
          
          if (!this.form.get('scenario_data').get('channels_ids')) {
            (this.form.get('scenario_data') as FormGroup).addControl('channels_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('channel_platforms_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('channel_content_types_ids', this.fb.control([]));
          }

        }
        
        this.form.updateValueAndValidity();
      })
    )

    if (this.data.tmpl) {
      this.form.patchValue({
        scenario_name: this.data.tmpl.scenario_name,
      })
    }

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
    this.getTaskTemplates();
    this.getParameters();
    this.getCardScenarioTypes();
    this.getOperations();
    this.getOperationsStatus();
    this.getLangData();
    this.getGroupProfiles();
    
    if (this.data.tmpl) {
      this.getTmpl();
    }
  }

  chooseTemplate(template) {
    this.form.get('scenario_data').get('create_task').get('task_template_id').patchValue(template.id)
  }

  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.data.company.id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.data.company.id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getTaskTemplates", this.templates)
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  toggleJobEmpl = (chip: any, job) => {
    if (!!job.employees_ids && job.employees_ids.includes(chip.id)) {
      let val = [...job.employees_ids]
      val.splice(job.employees_ids.indexOf(chip.id), 1)
      job.employees_ids = val
    } else {
      if (!!job.employees_ids) {
        let val = [...job.employees_ids]
        val.push(chip.id)
        job.employees_ids = val
      } else {
        job.employees_ids = [chip.id]
      }
    }

    console.log("#### JOB",job)
  }

  toggleJobPartner = (chip: any, job) => {
    if (!!job.partner_companies_ids && job.partner_companies_ids.includes(chip.partner_company_id)) {
      let val = [...job.partner_companies_ids]
      val.splice(job.partner_companies_ids.indexOf(chip.partner_company_id), 1)
      job.partner_companies_ids = val
    } else {
      if (!!job.partner_companies_ids) {
        let val = [...job.partner_companies_ids]
        val.push(chip.partner_company_id)
        job.partner_companies_ids = val
      } else {
        job.partner_companies_ids = [chip.partner_company_id]
      }
    }

    console.log("#### JOB",job)
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }
  
  includesCli(id) {
    return this.clients.filter(x => x.client_employee_id == id).length > 0;
  }
  
  setCli(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.clients.find(x => x.client_employee_id == id).is_principal = val
  }

  setCliPartn(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.partnerClients.find(x => x.client_partner_company_id == id).is_principal = val
  }

  getCli(id) {
    return this.clients.find(x => x.client_employee_id == id);
  }
  
  getCliPartn(id) {
    return this.partnerClients.find(x => x.client_partner_company_id == id);
  }

  includesCliPartn(id) {
    return this.partnerClients.filter(x => x.client_partner_company_id == id).length > 0;
  }

  toggleSelectClientChip(e) {
    console.log('toggleSelectClientChip', e)

    this.clients.forEach((obj, ind) => {
      if (!this.clients_ids.filter(id => obj.client_employee_id == id).length) {
        this.clients.splice(ind, 1)
      }
    });

    this.clients_ids.forEach((id, ind) => {
      if (!this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.clients_ids);
    console.log(this.clients);
  }

  toggleSelectClientPartnerChip(e) {
    console.log('toggleSelectClientPartnerChip', e)

    this.partnerClients.forEach((obj, ind) => {
      if (!this.partnerClients_ids.filter(id => obj.client_partner_company_id == id).length) {
        this.partnerClients.splice(ind, 1)
      }
    });

    this.partnerClients_ids.forEach((id, ind) => {
      if (!this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.partnerClients_ids);
    console.log(this.partnerClients);
  }

  toggleClientPartnerChip = (chip: any) => {
    console.log("toggleClientPartnerChip", chip)
    let id = !!chip.partner_company_id ? chip.partner_company_id : chip.value
    if (!!id) {
      if (this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.splice(this.partnerClients.indexOf(this.partnerClients.find(x => x.client_partner_company_id == id)), 1)
      } else {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    }
    
    this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);
    // if (!chip.partner_company_id) {
    //   this.pCliSwitch = true;

    //   setTimeout(() => {
    //     this.pCliSwitch = false;
    //   }, 0)
    // }
  }

  toggleClientChip = (chip: any) => {
    console.log("toggleClientChip", chip)
    let id = !!chip.id ? chip.id : null;
    if (!!id) {
      if (this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.splice(this.clients.indexOf(this.clients.find(x => x.client_employee_id == id)), 1)
      } else {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    }

    this.clients_ids = this.clients.map(x => x.client_employee_id);

    // if (!chip.id) {
    //   this.cliSwitch = true;

    //   setTimeout(() => {
    //     this.cliSwitch = false;
    //   }, 0)
    // }
  }


  getRegulars() {
    this.attachSubscriptions(
      this.companyService.getRegularsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getRegularsDyn(x, this.company_id).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.regulars = [].concat(...values)
              console.log("getRegulars", this.regulars)
            }),
          )
        }),
      ).subscribe(resp => {
        this.regulars.forEach(reg => {
          reg.items.forEach(element => {
            element.timeInFormat = new Date(element.time * 1000).toISOString().slice(11, 16)
          });
        });

        this.regulars$.next(this.regulars);
        this.regularItems$.next(this.regulars);

      }, error => {
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        this.partners$.next(this.partners.slice())
        console.log("getPartners", this.partners)
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  setMultiStatuses(fg, stat) {
    let arr = JSON.parse(JSON.stringify(fg.get('statuses_ids').value))
    if (arr.includes(stat.id)) {
      arr.splice(arr.indexOf(stat.id), 1)
    } else {
      arr.push(stat.id)
    }
    fg.patchValue({
      statuses_ids: arr
    })
  }

  toggleAddPartnerChip = (chip: any) => {
    if (!!chip.partner_company_id) {
      if (!!this.form.get('scenario_data').value.partner_companies_ids && this.form.get('scenario_data').value.partner_companies_ids.includes(chip.partner_company_id)) {
        let val = [...this.form.get('scenario_data').value.partner_companies_ids]
        val.splice(this.form.get('scenario_data').value.partner_companies_ids.indexOf(chip.partner_company_id), 1)
        this.form.get('scenario_data').patchValue({
          partner_companies_ids: val
        })
      } else {
        if (!!this.form.get('scenario_data').value.partner_companies_ids) {
          let val = [...this.form.get('scenario_data').value.partner_companies_ids]
          console.log("chip.partner_company_id", chip.partner_company_id, chip)
          val.push(chip.partner_company_id)
          this.form.get('scenario_data').patchValue({
            partner_companies_ids: val
          })
        } else {
          console.log("chip.partner_company_id", chip.partner_company_id, chip)
          this.form.get('scenario_data').patchValue({
            partner_companies_ids: [chip.partner_company_id]
          })
        }
      }
    }

    console.log("chip.partner_company_id", chip.partner_company_id, chip)

    if (!chip.partner_company_id) {
      this.partnerSwitch = true;

      setTimeout(() => {
        this.partnerSwitch = false;
      }, 0)
    }
  }

  toggleEmplChip = (chip: any) => {
    if (!!chip.id) {
      if (!!this.form.get('scenario_data').value.employees_ids && this.form.get('scenario_data').value.employees_ids.includes(chip.id)) {
        let val = [...this.form.get('scenario_data').value.employees_ids]
        val.splice(this.form.get('scenario_data').value.employees_ids.indexOf(chip.id), 1)
        this.form.get('scenario_data').patchValue({
          employees_ids: val
        })
      } else {
        if (!!this.form.get('scenario_data').value.employees_ids) {
          let val = [...this.form.get('scenario_data').value.employees_ids]
          val.push(chip.id)
          this.form.get('scenario_data').patchValue({
            employees_ids: val
          })
        } else {
          this.form.get('scenario_data').patchValue({
            employees_ids: [chip.id]
          })
        }
      }
    }

    if (!chip.id) {
      this.emplSwitch = true;

      setTimeout(() => {
        this.emplSwitch = false;
      }, 0)
    }
  }

  patchCtrl(fg, ctrl, val) {
    fg.patchValue({
      [ctrl]: val
    })
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {

        this.allContentTypes = resp;

        let arr = [];

        resp.forEach(el => {
          if (arr.filter(x => x.channel_platform_id == el.channel_platform_id).length == 0) {
            arr.push({
              channel_platform_id: el.channel_platform_id,
              types: [el]
            })
          } else {
            arr.find(x => x.channel_platform_id == el.channel_platform_id).types.push(el)
          }
        })

        this.contentTypes.push(...arr);
        this.contentTypes$.next(this.copyContentTypesGroups(this.contentTypes));
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes();
        }
      })
    )
  }

  onSearchRegItems(resp) {
    console.log("onSearchRegItems", resp);
    const regularItemsCopy = this.copyRegularItemsGroups(this.regulars);
    if (!resp) {
      this.regularItems$.next(regularItemsCopy);
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    // this.regularItems$.next(
    //   this.regularItems.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    // );

    // filter the banks
    console.log("RESP", resp, regularItemsCopy)
    this.regularItems$.next(
      regularItemsCopy.filter(item => {
        item.items = item.items.filter(t => t.name.toLowerCase().indexOf(resp) > -1);
        return item.items.length > 0;
      })
    );

    this.regularItems$.asObservable().subscribe(res => {
      console.log("search", res)
    });
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    const contentTypesCopy = this.copyContentTypesGroups(this.contentTypes);
    if (!resp) {
      this.contentTypes$.next(contentTypesCopy);
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    // this.contentTypes$.next(
    //   this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    // );

    // filter the banks
    this.contentTypes$.next(
      contentTypesCopy.filter(type => {
        type.types = type.types.filter(t => t.name.toLowerCase().indexOf(resp) > -1);
        return type.types.length > 0;
      })
    );

    this.contentTypes$.asObservable().subscribe(res => {
      console.log("search", res)
      console.log(this.contentTypes)
    });
  }

  protected copyContentTypesGroups(contentTypes: any) {
    const contentTypesCopy = [];
    contentTypes.forEach(type => {
      contentTypesCopy.push({
        channel_platform_id: type.channel_platform_id,
        types: type.types.slice()
      });
    });
    return contentTypesCopy;
  }

  protected copyRegularItemsGroups(arr: any) {
    const arrCopy = [];
    arr.forEach(reg => {
      arrCopy.push({
        name: reg.name,
        items: reg.items.slice()
      });
    });
    return arrCopy;
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        console.log("this.platforms", this.platforms)
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  addJob() {
    let newJob:any = {
      status_ids: [1],
      operation_ids: [this.operations[0].id],
    }
    if (this.form.get('scenario_name').value == 'TaskChain') {
      newJob.employees_ids = [];
      newJob.partner_companies_ids = [];
      newJob.parameter_values_ids = [];
    } else {
      newJob.parameters_ids = []
    }

    this.jobs.push(newJob)
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getGroupProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getGroupProfiles resp", resp);
        console.log("getGroupProfiles profiles", this.profiles);
      })
    )
  }

  searchJobsTags(event, job) {
    console.log("searchJobsTags", event, job)
  }

  changeCreateDiscussionFlag(e) {
    console.log(e);
    let scenarioData = this.form.controls.scenario_data as FormGroup;
    if (e.checked) {
      scenarioData.addControl("discussion_name_template", this.fb.control("Publish to {channel_name} ({platform_name})"))
    } else {
      scenarioData.removeControl("discussion_name_template")
    }
    this.form.updateValueAndValidity();

    console.log("changeCreateDiscussionFlag", this.form.value)
  }

  getParamById(id) {
    return this.parameters && this.parameters.find(x => x.id == id)
  }

  getTmpl() {
    this.form.patchValue({
      name: this.data.tmpl.name,
      scenario_name: this.data.tmpl.scenario_name,
      is_task: this.data.tmpl.is_task
    })

    this.form.get('scenario_data').patchValue({
      channels_ids: this.data.tmpl.scenario_data.channels_ids,
      channel_platforms_ids: this.data.tmpl.scenario_data.channel_platforms_ids,
      channel_content_types_ids: this.data.tmpl.scenario_data.channel_content_types_ids
    })

    if (this.data.tmpl.scenario_data.task_operations) {
      this.jobs = this.data.tmpl.scenario_data.task_operations
    }

    if (this.data.tmpl.scenario_name == 'TaskChain') {
      this.form.get('scenario_data').get('create_task').patchValue({
        name: this.data.tmpl.scenario_data.create_task.name,
        task_template_id: this.data.tmpl.scenario_data.create_task.task_template_id
      });

      this.form.get('scenario_data').patchValue({
        is_new_task_related_to_source_task: this.data.tmpl.scenario_data.is_new_task_related_to_source_task,
        is_new_task_part_of_source_task: this.data.tmpl.scenario_data.is_new_task_part_of_source_task,
        is_copy_approved_files: this.data.tmpl.scenario_data.is_copy_approved_files,
        is_copy_ready_files: this.data.tmpl.scenario_data.is_copy_ready_files,
      });
    } else {

    }

    if (this.data.tmpl.scenario_name == 'TaskContentPlanChain') {
      this.form.get('scenario_data').get('create_task_operation').patchValue({
        operation_id: this.data.tmpl.scenario_data.create_task_operation.operation_id,
        priority: this.data.tmpl.scenario_data.create_task_operation.priority,
        name: this.data.tmpl.scenario_data.create_task_operation.name,
        statuses_ids: this.data.tmpl.scenario_data.create_task_operation.statuses_ids,
        parameters_ids: this.data.tmpl.scenario_data.create_task_operation.parameters_ids ? this.data.tmpl.scenario_data.create_task_operation.parameters_ids : [],
        is_force_create: this.data.tmpl.scenario_data.create_task_operation.is_force_create
      });
      this.form.get('scenario_data').patchValue({
        employees_ids: this.data.tmpl.scenario_data.employees_ids,
        partner_companies_ids: this.data.tmpl.scenario_data.partner_companies_ids,
      });

      if (this.data.tmpl.scenario_data.task_operation_clients && this.data.tmpl.scenario_data.task_operation_clients.length) {
        this.data.tmpl.scenario_data.task_operation_clients.forEach(obj => {
          if (!!obj.hasOwnProperty('client_employee_id')) {
            this.clients.push(obj);
          } else {
            this.partnerClients.push(obj);
          }
        })
      } else {
        if (this.data.tmpl.scenario_data.client_employees_ids && this.data.tmpl.scenario_data.client_employees_ids.length) {
          this.data.tmpl.scenario_data.client_employees_ids.forEach(id => {
            this.clients.push({
              client_employee_id: id,
              is_principal: 0
            });
          })
        }
        if (this.data.tmpl.scenario_data.client_partner_companies_ids && this.data.tmpl.scenario_data.client_partner_companies_ids.length) {
          this.data.tmpl.scenario_data.client_partner_companies_ids.forEach(id => {
            this.partnerClients.push({
              client_partner_company_id: id,
              is_principal: 0
            });
          })
        }
      }
  
      this.clients_ids = this.clients.map(x => x.client_employee_id);
      this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);

      
      this.form.get('scenario_data').get('create_discussion').patchValue({
        priority: this.data.tmpl.scenario_data.create_discussion.priority,
        name: this.data.tmpl.scenario_data.create_discussion.name,
        statuses_ids: this.data.tmpl.scenario_data.create_discussion.statuses_ids,
        is_force_create: this.data.tmpl.scenario_data.create_discussion.is_force_create
      });
    }
    if (this.data.tmpl.scenario_name == 'TaskContentPlan') {
      this.form.get('scenario_data').patchValue({
        content_schedules_ids: !!this.data.tmpl.scenario_data.content_schedules_ids ? this.data.tmpl.scenario_data.content_schedules_ids : [],
        content_schedule_items_ids: !!this.data.tmpl.scenario_data.content_schedule_items_ids ? this.data.tmpl.scenario_data.content_schedule_items_ids : [],
      });
    }

    this.form.updateValueAndValidity();

    console.log("this.data", this.data.tmpl)
    console.log("this.form", this.form.value)
  }

  logJobs() {
    console.log("this.jobs", this.jobs)
  }

  onSearchRegs(resp) {
    if (!this.regulars) {
      return;
    }

    if (!resp) {
      this.regulars$.next(this.regulars.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.regulars$.next(
      this.regulars.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchParams(resp) {
    console.log("onSearchParams", resp)
    if (!this.parameters) {
      return;
    }

    if (!resp) {
      this.parameters$.next(this.parameters.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.parameters$.next(
      this.parameters.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectChange(e) {
    console.log("selectChange", e)
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectAutomationType(type) {
    this.form.patchValue({
      scenario_name: type
    })
  }

  log() {
    console.log("FORM", this.form.value);
    console.log("JOBS", this.jobs);
  }

  selectProfileStatus(typeId) {
    console.log(typeId)
    // console.log(this.form.get('scenario_data').value.skip_task_channel_status)
    // console.log(this.form.get('scenario_data').value.skip_task_channel_status.includes(typeId))
    // return;
    if (!!this.form.get('scenario_data').value.skip_task_channel_status && this.form.get('scenario_data').value.skip_task_channel_status.includes(typeId)) {
      let val = [...this.form.get('scenario_data').value.skip_task_channel_status]
      val.splice(this.form.get('scenario_data').value.skip_task_channel_status.indexOf(typeId), 1)
      this.form.get('scenario_data').patchValue({
        skip_task_channel_status: val
      })
    } else {
      let val = [...this.form.get('scenario_data').value.skip_task_channel_status]
      val.push(typeId)
      this.form.get('scenario_data').patchValue({
        skip_task_channel_status: val
      })
    }

    console.log(this.form.get('scenario_data').value.skip_task_channel_status)
  }

  selectType(job, type) {
    if (job.operation_ids.includes(type.id)) {
      job.operation_ids.splice(job.operation_ids.indexOf(type.id), 1)
    } else {
      job.operation_ids.push(type.id)
    }
    this.switched = true;
    setTimeout(() => {
      this.switched = false;
    }, 0)
  }

  selectJobMultyChip(job, id, key) {
    if (job[key].includes(id)) {
      job[key].splice(job[key].indexOf(id), 1)
    } else {
      job[key].push(id)
    }
    job['switch_'+key] = true;
    setTimeout(() => {
      job['switch_'+key] = false;
    }, 0)
  }

  selectStatus(job, status) {
    if (job.status_ids.includes(status.id)) {
      job.status_ids.splice(job.status_ids.indexOf(status.id), 1)
    } else {
      job.status_ids.push(status.id)
    }
  }


  toggleAddChip = (chip: any) => {
    if (!!this.form.get('scenario_data').value.employees_ids && this.form.get('scenario_data').value.employees_ids.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.form.get('scenario_data').value.employees_ids]
      val.splice(this.form.get('scenario_data').value.employees_ids.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.form.get('scenario_data').patchValue({
        employees_ids: val
      })
    } else {
      if (!!this.form.get('scenario_data').value.employees_ids) {
        let val = [...this.form.get('scenario_data').value.employees_ids]
        val.push(!!chip.id ? chip.id : chip.value)
        this.form.get('scenario_data').patchValue({
          employees_ids: val
        })
      } else {
        this.form.get('scenario_data').patchValue({
          employees_ids: [!!chip.id ? chip.id : chip.value]
        })
      }
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company.id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }
  
  getCardScenarioTypes() {
    this.attachSubscriptions(
      this.scenariosService.getCardScenarioTypes().subscribe(resp => {
        this.automationType = resp;
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  deleteJob(i) {
    this.jobs.splice(i,1);
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  this.allValues$.next(this.allValues.slice())
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.data.company.id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parametersService.filesParamsData$.next(res);
          this.parameters = res;
          this.parameters$.next(this.parameters.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
        this.parameters.forEach(element => {
          if (element.id == 0) {
            element.values = this.allValues;
          } else {
            element.values = this.allValues.filter(u => u.parameter_id = element.id);
          }
          if (this.data.tmpl && this.data.tmpl.scenario_data.task_operation_parameter_values_ids) {
            element.activeValues = element.values.filter(x => this.data.tmpl.scenario_data.task_operation_parameter_values_ids.includes(x.id))
          } else {
            element.activeValues = []
          }
        });
      })
    )
  }

  onRemove(tagID, ind) {
    this.parameters[0].activeValues.splice(ind, 1)
  }

  onRemoveJobTag(job, tag, ind) {
    job.parameters[0].activeValues.splice(ind, 1)
  }

  openTargetValues(job?) {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: !!job ? job.parameters : this.parameters,
      no_primary: true
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            if (!!job) {
              job.parameters = result.data
            } else {
              this.parameters = result.data
            }
          }
        }
      })
    )
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.isSubmit = true;
    let formData = {...this.form.value};

    this.jobs.forEach(job => {
      delete job.switch_employees_ids;
      delete job.switch_partner_companies_ids;
    })

    formData.scenario_data.task_operations = this.jobs;


    console.log(formData);

    if (!this.data.tmpl) {
      // CREATE

      if (formData.scenario_name == 'TaskContentPlanChain') {

        let allClients = [...this.clients, ...this.partnerClients]
    
        console.log("clients", this.clients)
        console.log("partnerClients", this.partnerClients)
        console.log("allClients", allClients)
        formData.scenario_data.task_operation_clients = allClients;
      }

      console.log("CREATE", formData)
      this.attachSubscriptions(
        this.scenariosService.addAutomationTemplates(formData).subscribe(resp => {   
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        })
      )
    } else {
      // EDIT

      let editformData = {...formData}
      
      delete editformData.company_id;
      delete editformData.scenario_name;

      let count = 0;

      if (this.data.tmpl.scenario_name == 'TaskContentPlanChain') {

        let allClients = [...this.clients, ...this.partnerClients]
    
        console.log("clients", this.clients)
        console.log("partnerClients", this.partnerClients)
        console.log("allClients", allClients)
        formData.scenario_data.task_operation_clients = allClients;
      }

      // console.log('editformData this.parameters[0].activeValues', this.parameters[0].activeValues)
      console.log("editformData", editformData)
      // return
      this.attachSubscriptions(
        this.scenariosService.getAutomationScenarios(this.data.company.id, {template_id: this.data.tmpl.id, is_active: 1, 'per-page': 1}).pipe(
          tap(el => {
            count = +el.headers.get('x-pagination-total-count')
          }),
          switchMap(() => {
            console.log("COunt", count)
            if (count == 0) {
              return this.scenariosService.editAutomationTemplates(this.data.tmpl.id, editformData).pipe(map(() => true))
            } else {
              return of(false)
            }
          })
          
        ).subscribe(resp => {
          console.log("resp", resp)
          if (resp) {
            // В случае если не используется в задачах count === 0 

            this.isSubmit = false;
            this.dialogRef.close({event: "update", data: resp});
          } else {

            // Создай новый или замени этот но возможны конфликты
            let deleteAlert =  this.bottomSheet.open(EditApproveComponent, {
              data: {
                count: count
              }
            });
        
            deleteAlert.afterDismissed().subscribe( data => {
              if (data && data.message == 'no') {
                this.attachSubscriptions(
                  this.scenariosService.addAutomationTemplates(formData).subscribe(resp => {
                    this.isSubmit = false;
                    this.dialogRef.close({event: "update", data: resp});
                  })
                )
              } else if (data && data.message == 'yes') {
                this.attachSubscriptions(
                  this.scenariosService.editAutomationTemplates(this.data.tmpl.id, editformData).subscribe(resp => {
                    this.isSubmit = false;
                    this.dialogRef.close({event: "update", data: resp});
                  })
                )
              }
            });
          }
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
