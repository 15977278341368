import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-outlet-filter',
  templateUrl: './outlet-filter.component.html',
  styleUrls: ['./outlet-filter.component.scss']
})
export class OutletFilterComponent extends BaseClass implements OnInit, OnDestroy {
  @Input() company_id: any;
  @Input() company: any;
  @Input() profiles: any;
  // @Input() bufferProfiles: any;
  @Input() platforms: any;
  @Input() activeSections: any;
  @Input() outletKey: any;
  @Input() form: FormGroup;
  @Input() needCst: boolean = false;
  @Input() multiple: boolean = false;
  @Input() showOutletBtn: boolean = false;
  @Input() n_a: boolean = false;
  @Output() SelectOutletTab = new EventEmitter<any>();
  @Output() SelectChange = new EventEmitter<any>();
  
  throttle = 100;
  scrollUpDistance = 3.5;

  public searchControl: FormControl = new FormControl('')
  public switched: boolean = false;
  
  public isLoad: boolean = false;
  public outletsSubsControl: FormControl = new FormControl('')
  public outletsPage: number = 1;
  public outletsPagination: any;
  public outlets: any;
  public savedForm: any;
  public noSavedForm: any;

  public cstForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
  ) { super() }

  ngOnInit(): void {
    console.log("this.form", this.form);
    console.log("this.form.value", this.form.value);
    console.log("this.form.value[this.outletKey]", this.form.value[this.outletKey]);
    if (this.needCst) {
      this.cstForm = this.fb.group({
        [this.outletKey]: [!!this.form.value[this.outletKey] ? (this.multiple ? [...this.form.value[this.outletKey]] : [this.form.value[this.outletKey]]) : []]
      })
      if (this.multiple) {
        this.savedForm = JSON.parse(JSON.stringify(this.cstForm.value[this.outletKey]));
      }
    } else {
      this.savedForm = JSON.parse(JSON.stringify(this.form.value[this.outletKey]));
      this.noSavedForm = JSON.parse(JSON.stringify(this.form.value.no_channel_id));
      this.onRouteChange();
    }
    console.log("this.cstForm", this.cstForm);

    if (this.profiles) {
      this.outlets = JSON.parse(JSON.stringify(this.profiles))
    } else {
      this.getProfiles(this.outletsPage)
    }

    this.attachSubscriptions(
      this.outletsSubsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onFilterByPlatforms(resp))
    )

    this.attachSubscriptions(
      this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onFilterBySearch(resp))
    )
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(res => {
        this.showOutletBtn = false;
        this.savedForm = JSON.parse(JSON.stringify(this.form.value[this.outletKey]));
        this.noSavedForm = JSON.parse(JSON.stringify(this.form.value.no_channel_id));
      })
    )
  } 

  log() {
    console.log(this.form.value);

    if (this.cstForm) {
      console.log(this.cstForm.value);
    }
  }

  onDown(e) {
    console.log("onDown", e?.target?.scrollTop)
    if (this.outletsPagination && this.outletsPage <= this.outletsPagination['pageCount']) {
      console.log("#########onDown")
      this.getProfiles(this.outletsPage);
    }
  }

  noChannel(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.form.patchValue({
      no_channel_id: id,
    })

    this.SelectChange.emit(e);
    // jobTypeSelect.close();
  }

  multiSelect(e, fil) {
    e.preventDefault();
    e.stopPropagation();

    console.log('multiSelect', fil)
  }

  selectChange(e) {
    if (this.needCst) {
      if (this.multiple) {
        let allValues = []
        let triggerId = e.option._value;
        console.log("triggerId", triggerId)

        this.savedForm.forEach(id => {
          if (!allValues.includes(id)) {
            allValues.push(id)
          }
        })

        if (!allValues.includes(triggerId)) {
          allValues.push(triggerId)
        } else {
          allValues.splice(allValues.indexOf(triggerId), 1)
        }

        this.cstForm.patchValue({
          [this.outletKey]: allValues
        })

        
        this.savedForm = JSON.parse(JSON.stringify(this.cstForm.value[this.outletKey]));

      }

      this.form.patchValue({
        [this.outletKey]: (this.multiple ? this.cstForm.value[this.outletKey] : this.cstForm.value[this.outletKey][0]) || ''
      })
      if (!this.multiple) {
        this.SelectChange.emit(this.getProfileById(this.cstForm.value[this.outletKey][0]));
      } else {
        this.SelectChange.emit(e);
      }
    } else {

      this.SelectChange.emit(e);
    }

    console.log("selectChange", this.form, this.cstForm)
  }

  searchFilterSubmit(e) {
    this.SelectChange.emit(e);
  }

  noChannelMulty(e, id) {
    e.preventDefault();
    e.stopPropagation();
    let arr = this.form.get('no_channel_id').value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    this.form.patchValue({
      no_channel_id: arr,
    })
    
    if (!this.needCst) {
      this.noSavedForm = JSON.parse(JSON.stringify(this.form.value.no_channel_id));
    }
    this.showOutletBtn = true;
    // this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  setChannelMulty(e, id, key) {
    e.preventDefault();
    e.stopPropagation();
    let arr = this.form.get(key).value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    this.form.patchValue({
      [key]: arr,
    })
    if (!this.needCst) {
      this.savedForm = JSON.parse(JSON.stringify(this.form.value[key]));
    }
    if (this.multiple) {
      this.savedForm = JSON.parse(JSON.stringify(this.cstForm.value[this.outletKey]));
    }
    this.showOutletBtn = true;
    // this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  getProfileById(id) {
    return this.outlets.find(x => x.id == id)
  }

  getProfiles(page, q: string = '', platform_id:any = '') {
    this.isLoad = true;
    this.attachSubscriptions(
      this.companyService.getFilteredProfilesDyn(this.company_id, page, {q, platform_id}).pipe(
        tap(el => {
          if (page == 1) {
            this.outletsPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)

        if (page == 1) {
          this.outlets = resp
        } else {
          resp.forEach(profile => {
            if (this.outlets.filter(x => x.id == profile.id).length == 0) {
              this.outlets.push(...resp);
            }
          })
        }

        // this.bufferProfiles = [...this.outlets];

        this.isLoad = false;
        if (!this.needCst) {
          setTimeout(() => {
            this.form.patchValue({
              [this.outletKey]: this.savedForm,
              no_channel_id: this.noSavedForm
            })
          },0)
        } else {
          if (this.multiple) {
            setTimeout(() => {
              this.cstForm.patchValue({
                [this.outletKey]: this.savedForm
              })
            }, 0)
          }
        }

        this.outletsPage = this.outletsPage + 1;
      })
    )
  }

  onFilterBySearch(resp) {
    console.log("searchProfiles", resp);
    this.outletsPage = 1;
    this.getProfiles(this.outletsPage, resp, this.outletsSubsControl.value)
  }

  onFilterByPlatforms(resp) {
    console.log("searchProfiles", resp);
    this.outletsPage = 1;
    this.getProfiles(this.outletsPage, this.searchControl.value, resp)
  }

  patchControl(control:FormControl, val) {
    control.patchValue(val)
  }

  setOutletKey(val) {
    this.outletKey = val
    this.switched = true;
    this.SelectOutletTab.emit(this.outletKey)
    setTimeout(() => {
      this.switched = false;
    }, 0)
    
  }

  // setOutletKey(key) {
  // }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
