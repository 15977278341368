<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Notes" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="">
        <mat-list role="list" class="notes_list" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <mat-list-item *ngFor="let note of notes; let i = index;" role="listitem" class="company_item ">
                <div class="company_name">
                    <span class="cp" (click)="editNote(note)">
                        {{note.name}}
                    </span>
                </div>


                
                <!-- <div class="company_btns">
                    <button mat-icon-button (click)="editNote(note)" style="color: green;">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="deleteNote(note, i)" style="color: brown;">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div> -->
                <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="!data.is_shared" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="noteRelations(note)">
                    <mat-icon>call_split</mat-icon>
                    <span>{{"Relations" | translate}}</span>
                </button>
                <button mat-menu-item (click)="editNote(note)">
                    <mat-icon>edit</mat-icon>
                    <span>{{"Edit" | translate}}</span>
                </button>
                <button mat-menu-item (click)="deleteNote(note, i)">
                    <mat-icon>delete</mat-icon>
                    <span>{{"Delete" | translate}}</span>
                </button>
                <mat-divider></mat-divider>
                <div mat-menu-item class="mat_check_menu_item" (click)="$event.stopPropagation()">
                    <mat-checkbox [(ngModel)]="note.is_public" color="primary" (change)="toggleNote($event, note, 'is_public')">
                        {{ "Public" | translate }}
                    </mat-checkbox>
                </div>
                <div mat-menu-item class="mat_check_menu_item" (click)="$event.stopPropagation()">
                    <mat-checkbox [(ngModel)]="note.is_public_registered" color="primary" (change)="toggleNote($event, note, 'is_public_registered')">
                        {{ "Public registred" | translate }}
                    </mat-checkbox>
                </div>
                <div mat-menu-item class="mat_check_menu_item" (click)="$event.stopPropagation()">
                    <mat-checkbox [(ngModel)]="note.is_deleted" color="primary" (change)="toggleNote($event, note, 'is_deleted')">
                        {{ "Is Deleted" | translate }}
                    </mat-checkbox>
                </div>
                </mat-menu>
                  
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-f-c" style="padding: 15px 0;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="d-f-c ml-1" mat-raised-button color='primary' (click)="addNote()">
            <mat-icon class="mr-1">playlist_add_circle</mat-icon>
            {{ "Add note" | translate }}
        </button>
    </div>
</mat-dialog-actions>
