<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Tags" | translate }}:
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container parameters_target_wrp" [appDeviceClass]="'custom-scroll'">
            <ng-container *ngFor="let param of parameters">
    
                <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!param?.hasValues" [ngClass]="{'highlight': data.highlightParam === param.id}">
                    <mat-label>{{ param.name }}</mat-label>
        
                    <mat-chip-list 
                        class="without_ordered"
                        #paramsChipList
                        [(ngModel)]="param.activeValues"
                    >
                        <mat-chip 
                            class="param_chip"
                            *ngFor="let val of param.activeValues; let k = index;"
                            [removable]="true" 
                            color="primary" 
                            (removed)="onRemove(val, param, k)">
                            <mat-icon class="verified" *ngIf="!data.no_primary && (data.auto || data.target)" [ngClass]="{'cst_primary': !!val.is_primary}" (click)="togglePrimary(val, param, k)">verified</mat-icon>
                                {{ !!val?.parameterValue ? val.parameterValue?.value : (!data.auto ? (getValueById(val.parameter_value_id)?.value) : val.value )}}
                            <mat-icon matChipRemove *ngIf="!data.selectedFiles">cancel</mat-icon>
                        </mat-chip>
        
                        <input
                        id="param_input_{{param.id}}"
                        class="auto_complete_input"
                        placeholder="{{ 'Search' | translate }}"
                        #paramsInput
                        [formControl]="param.search"
                        [matAutocomplete]="paramsAuto"
                        [matChipInputFor]="paramsChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="tokenEnd($event, param)">
                    </mat-chip-list>
                    <mat-autocomplete autoActiveFirstOption #paramsAuto="matAutocomplete" (optionSelected)="selected($event, param)" (optionsScroll)="onScroll(param)">
                        <mat-option [value]="{type: 'add', value: param.search.value}" *ngIf="(data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin')) && (!data?.target || !data?.target?.partner_company_id) && noValues(param)">
                            <div class="d-f-c" style="justify-content: space-between;">
                              <span>
                                {{ "Add as new Tag" }}
                              </span>
                            </div>
                        </mat-option>
                        <ng-container *ngFor="let val of param.values">
                            <mat-option *ngIf="noOption(param.activeValues, val.id) && withSearch(param?.search?.value, val)" [value]="val">
                                <div class="d-f-c" style="justify-content: space-between;">
                                  <span>
                                    {{ val.value }}
                                  </span>
                                  <span class="parameter_name_in_option" *ngIf="param.id == 0 && getParameterById(val.parameter_id)">({{ getParameterById(val.parameter_id).name }})</span> 
                                </div>
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

    <div class="btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" (click)="data?.auto ? save() : close()" mat-raised-button cdkFocusInitial color="primary">
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
