import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private fileService: FileService,
    private taskService: TaskService,
    private sm: StorageManagerService,
    public dialogRef: MatDialogRef<CreateFolderComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("CreateFolderComponent")
    this.form = this.fb.group({
      company_id: this.data.target_company_id,
      task_id: this.data.is_global ? (this.data.task_id || this.data.globalFilterFmFiles.task_id) : this.data.task_id,
      task_operation_id: this.data.is_global ? (this.data.work_id || this.data.globalFilterFmFiles.task_operation_id) : this.data.work_id,
      filename: ["", Validators.required],
      location: this.data.location,
      is_dir: 1
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.attachSubscriptions(
      this.fileService.uploadFile(this.form.value, this.data.company_id).pipe(
        switchMap(folder => {
          if (this.data.file_ids && this.data.file_ids.length) {
            let moveData = [];
            this.data.file_ids.forEach(f_id => {
              moveData.push(
                {
                  "path": `/api/file/${f_id}/`,
                  "query": {company_id: this.data.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    location: folder.location == "/" ? folder.location + folder.filename : folder.location + "/" + folder.filename
                  }
                }
              )
            });

            return this.taskService.multiRequest(moveData).pipe(map(() => folder))
          } else {
            return of(folder)
          }
        })
      ).subscribe(resp => {
        this.data.files.push(resp)
        this.dialogRef.close({event: "create", data: resp})
      }, error => {
        console.log("CreateFolderComponent error", error)
        if (error && error[0] && error[0].message && error[0].message == 'The directory or file exists' && error[1]) {
          this.form.patchValue({
            name: error[1].message
          })
          this.submitForm();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
