<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Import files" | translate }}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <div class="silver_wrp">
                <mat-form-field appearance="standard" class="full_width_form h_cst_t">
                    <mat-label>{{ "Select publication outlet" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir #profilesContainer formControlName="channel_id" required>
                        <!-- <mat-select-trigger>
                            <app-outlet-line [channel]="getProfile(form.get('channel_id').value)" [company_id]="data.company_id"></app-outlet-line>
                        </mat-select-trigger> -->
                        <mat-select-trigger class="multi_users">
                            <ng-container *ngIf="form.get('channel_id').value && form.get('channel_id').value.length">
                                <ng-container *ngFor="let id of form.get('channel_id').value">
                                    <span class="multi_item"><app-outlet-line [channel]="getProfile(id)" [company_id]="data.company_id"></app-outlet-line></span>
                                </ng-container>
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching outlet found' | translate }}"
                            [formControl]="profilesMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let profile of profiles$ | async" [value]="profile.id" class="cst_o">
                            <app-outlet-line [channel]="profile" [company_id]="data.company_id"></app-outlet-line>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Job type" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="operation_id">
                        <mat-option *ngFor="let item of operations" [value]="item.id">
                            {{ item.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Job tag" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="parameter_value_id">
                        <mat-select-trigger class="multi_users">
                            <ng-container *ngIf="form.get('parameter_value_id').value; else elseTemplateIgnore">
                                <span>{{ getValuesById(form.get('parameter_value_id').value)?.value }}</span>
                            </ng-container>
                            <ng-template #elseTemplateIgnore>
                                <span>{{ "Ignore tag" | translate }}</span>
                            </ng-template>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                            [formControl]="allValuesControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of allValues$ | async" [value]="''">
                            <div class="d-f-c" style="justify-content: space-between;">
                                <span>{{ "Ignore tag" | translate }}</span>
                                <span></span>
                            </div>
                        </mat-option>
                        <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                            <div class="d-f-c" style="justify-content: space-between;">
                                <span>{{ item.value }}</span>
                                <span>{{ item?.parameter?.name }}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Download to folder' | translate }}</mat-label>
                    <input formControlName="location" matInput placeholder="{{ 'Download to folder' | translate }}">
                </mat-form-field>

                <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!data?.user?.is_root">
                    <mat-label>{{ 'Storage' | translate }}</mat-label>
                    <mat-select disableOptionCentering #storageSelect formControlName="storage_code">
                        <mat-option [value]="item.storage_code" [ngClass]="{'cst_active_select_option': form.get('storage_code').value == item.storage_code}" *ngFor="let item of storages">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container formGroupName="params">
                    <div class="box_wrp d-f-c" style="height: 40px;">
                        <mat-checkbox color="primary" formControlName="thumbnail">{{ "Save thumbnail" | translate }}</mat-checkbox>
                    </div>
                    <div class="box_wrp d-f-c" style="height: 40px;">
                        <mat-checkbox color="primary" formControlName="file">{{ "Save content file" | translate }}</mat-checkbox>
                    </div>
                </ng-container>
                <div class="box_wrp d-f-c" style="height: 40px;">
                    <mat-checkbox color="primary" formControlName="is_prefix_filename">{{ "Add cID-wID in the beginning of file name" | translate }}</mat-checkbox>
                </div>
                <div class="box_wrp d-f-c" style="height: 40px;">
                    <mat-checkbox color="primary" formControlName="is_save_auto_post_data">{{ "Save post document" | translate }}</mat-checkbox>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
