import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { concat, forkJoin, from, fromEvent, interval, Observable, of, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, last, map, retry, retryWhen, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseClass } from '../../models/base-class';
import { LanguageService } from '../../services/common/language.service';
import { LayoutService } from '../../services/common/layout.service';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { AuthService } from '../../services/rest/auth.service';
import { CompanyService } from '../../services/rest/company.service';
import { ConnectedAppsService } from '../../services/rest/connected-apps.service';
import { FileService, Folder } from '../../services/rest/file.service';
import { RefreshService } from '../../services/rest/refresh.service';
import { TaskService } from '../../services/rest/task.service';
import { UploadService } from '../../services/rest/upload.service';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { CreateFolderComponent } from './dialog/create-folder/create-folder.component';
import { FileSettingsComponent } from './dialog/file-settings/file-settings.component';
import { RenameComponent } from './dialog/rename/rename.component';
import { SelectContentComponent } from './dialog/select-content/select-content.component';
import { VideoViewerComponent } from './dialog/video-viewer/video-viewer.component';
import * as moment from 'moment-timezone';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { ApproveJobComponent } from './dialog/approve-job/approve-job.component';
import { SelectChecklistComponent } from './dialog/select-checklist/select-checklist.component';
import { casesModel } from '../../functions/casesModel';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ScenariosService } from '../../services/rest/scenarios.service';
import { SelectTaskTemplateComponent } from './dialog/select-task-template/select-task-template.component';
import { FilePrivacyComponent } from './dialog/file-privacy/file-privacy.component';
import { ClipboardService } from 'ngx-clipboard';
import { CreateTaskComponent } from 'src/app/components/atTasksDialog/create-task/create-task.component';
import { EmplCreateTaskComponent } from 'src/app/components/atTasksDialog/empl-create-task/empl-create-task.component';
import { COMMA, E, ENTER } from '@angular/cdk/keycodes';
import { ParametersService } from '../../services/rest/parameters.service';
import { MobAddTaskComponent } from 'src/app/components/mob-task/mob-add-task/mob-add-task.component';
import { FilePropertiesComponent } from '../file-interface/dialogs/file-properties/file-properties.component';
import { AskPasteFilesComponent } from '../ask-paste-files/ask-paste-files.component';
import { CreateArchiveComponent } from './dialog/create-archive/create-archive.component';
import { StatusReasonComponent } from 'src/app/components/workspace-pages/cases/dialogs/status-reason/status-reason.component';
import { ChatService } from '../../services/rest/chat.service';
import { SelectStatusAndEmployeeComponent } from 'src/app/components/workspace-pages/cases/dialogs/select-status-and-employee/select-status-and-employee.component';
import { MembersService } from '../../services/rest/members.service';
import { TaskBarService } from '../../services/rest/task-bar.service';
import { MinimizeService } from '../../services/rest/minimize.service';
import { DropFilesLimitComponent } from '../drop-files-limit/drop-files-limit.component';
import { AddEditNoteComponent } from '../add-edit-note/add-edit-note.component';
import { NotesComponent } from '../notes/notes.component';
import { newArray } from '@angular/compiler/src/util';
import { SameFilesComponent } from '../file-interface/dialogs/same-files/same-files.component';
import { CreateDocumentComponent } from './dialog/create-document/create-document.component';
import { canTranscribe, transcribe } from '../../consts/transcribe.extensions';
import { FileSpeachToTextComponent } from './dialog/file-speach-to-text/file-speach-to-text.component';

import { saveAs } from 'file-saver';
import { FolderArchivesComponent } from './dialog/folder-archives/folder-archives.component';
import { Md5Service } from '../../services/common/media/md5.service';
import { TimeFormatPipe } from '../../pipes/timeFormat';
import { PublishToOutletComponent } from './dialog/publish-to-outlet/publish-to-outlet.component';
import { VideoEdtiorComponent } from './dialog/video-edtior/video-edtior.component';
import { MainVideoEditorComponent } from './dialog/main-video-editor/main-video-editor.component';
import { ProjectsComponent } from './dialog/main-video-editor/dialog/projects/projects.component';
import { BetaVideoEditorComponent } from './dialog/beta-video-editor/beta-video-editor.component';
import { GlobalDataService } from '../../services/common/global-data.service';
import { AlphaVideoEditorComponent } from './dialog/alpha-video-editor/alpha-video-editor.component';
import { el } from 'date-fns/locale';
import { PrimaryTagForFilesComponent } from './dialog/primary-tag-for-files/primary-tag-for-files.component';
import { VideoEditorTestComponent } from './dialog/video-editor-test/video-editor-test.component';
import { CardProjectComponent } from './dialog/cards-projects-list/dialog/card-project/card-project.component';
import { CardsProjectsListComponent } from './dialog/cards-projects-list/cards-projects-list.component';
import { VideoEditorV5Component } from './dialog/video-editor-v5/video-editor-v5.component';
import { ProjectsInterfaceComponent } from './dialog/main-video-editor/dialog/projects-interface/projects-interface.component';
import { MultiMigrateComponent } from './dialog/multi-migrate/multi-migrate.component';
import { LoadingService } from '../../services/rest/loading.service';
import { JobAutomationComponent } from 'src/app/components/workspace-pages/jobs/dialogs/job-automation/job-automation.component';
import { AddEditPostDocumentComponent } from '../add-edit-post-document/add-edit-post-document.component';
import { DeleteTagFromSelectedComponent } from '../delete-tag-from-selected/delete-tag-from-selected.component';
import { VideoFfmpegEditorComponent } from './dialog/video-ffmpeg-editor/video-ffmpeg-editor.component';
@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})


export class FileManagerComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filesContainer') filesContainer:SelectContainerComponent;
  @ViewChild('uploader') uploader: ElementRef<HTMLElement>;
  @ViewChild('uploaderFolder') uploaderFolder: ElementRef<HTMLElement>;
  public form: FormGroup = this.fb.group({
    location: ''
  })
  public selectedFiles = new SelectionModel(
    true,
    []
  );
  public boxFiles = new SelectionModel(
    true,
    []
  );
  public statusForm: FormGroup = this.fb.group({
    privacy_status: '',
    status_block: '',
  })
  throttle = 100;
  scrollUpDistance = 3.5;
  public isBufferLoad: boolean = false;
  public isCleared: boolean = false;
  public done_all: boolean = false;
  public origin = window.location.origin;
  public host: any = environment.host;
  public company_id: any;
  public target_company_id: any;
  public task_id: any;
  public timeout: any;
  public imgRoute: any;
  public task_operation_id: any;
  public linkBefore: {} = {};
  public preview: any;
  public statuses: any;
  public user: any;
  public uploadLimit: boolean = false;
  public timezones: any;
  public cardSearch: FormControl = new FormControl(false);
  public timezoneFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
    /** list of bank groups filtered by search keyword for option groups */
  public filteredTimezonesGroups: ReplaySubject<any> = new ReplaySubject<any>(1);
  public fmPing = interval(5000).pipe(
    filter(x => this.files.length > 0 && this.files.filter(x => x.is_uploaded == 0 && moment(x.updated_at*1000).isSameOrAfter(moment().startOf('day'))).length > 0)
  );


  @ViewChild('channelsMenu') channelsMenu: TemplateRef<any>;
  @ViewChild('relationsMenu') relationsMenu: TemplateRef<any>;
  @ViewChild('fileMenu') fileMenu: TemplateRef<any>;
  @ViewChild('outFileMenu') outFileMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  filterOverlayRef: OverlayRef | null;
  @Input() is_global: boolean = false;
  @Input() isGlobal: boolean = false;
  @Input() useOut: boolean = false;
  @Input() useFromJob: boolean = false;
  @Input() disableId: any;
  @Input() selectedMode: boolean = false;
  @Input() initLocation: any;
  @Input() initData: any;
  @Input() id: any;
  @Input() task: any;
  @Input() originalFile: any;
  @Input() initCompanyId: any;
  @Input() selectedFileId: number;
  @Input() sortValue: any = 'filename';
  @Input() selectedFileIds: number[] = [];
  @Input() unSelectedFileIds: number[] = [];
  @Input() work: any;
  @Input() company: any;
  @Input() globalFilterFmFilesForm: any;
  @Input() globalFilterFmFiles: any;
  @Input() only: any;
  @Input() tasks: any;
  @Output() HideMenu = new EventEmitter<any>();
  @Output() onSelectFile = new EventEmitter<any>();
  public files: any = [];

  overlayFileChannels: OverlayRef | null;
  overlayRelations: OverlayRef | null;
  public relationsSub: Subscription;
  public fileChannelsSub: Subscription;
  overlayParameterRefFilter: OverlayRef | null;
  public parameterFilterSub: Subscription;

  @ViewChild('filesFilterParameterMenu') filesFilterParameterMenu: TemplateRef<any>;
  public channelFiles: any = [];
  public folders: any[] = [
    {
      name: "Root",
      id: -1,
      location: "/",
      over: false,
      is_sys: true,
      rootDir: true
    },
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      is_sys: true,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      is_sys: true,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      is_sys: true,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      is_sys: true,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      is_sys: true,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      is_sys: true,
      rootDir: true
    }
  ]
  public _mimeTypes = mimeTypes;
  public isLoad: boolean = false;
  public isLoadTasks: boolean = false;
  public page: number = 1;
  public foldersCount: number = 1;
  public searchTasks: any[] = [];
  public tasksPage: number = 1;
  public profiles: any;
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public filesCount: number = 1;
  public filesFilesize: number = 0;
  public folderFilesize: number = 0;
  public pagination: any;
  public boxMode: boolean = false;
  public isDragged: boolean = false;
  public usedFilter: boolean = false;
  public is_mobile: boolean = false;
  public crumbs: any[] = [{
    name: "Root",
    location: "/"
  }];
  public initCounter: number = 1;
  public systemDirs = ['sources', 'to_approve', 'ready_files', 'approved', 'project', 'trash']
  public timeZone = new Date().getTimezoneOffset();
  public sub: Subscription;
  public createdArchForDownSub: Subscription;
  public uploadProgress: Subscription;
  public activeLang: any;
  public operationsValues: any;
  public is_list: boolean = this.sm.localStorageGetItem('is_list') == "true" ? true : false;
  public fileRoute: FormGroup = this.fb.group({
    company_id: '',
    task_id: '',
    task_operation_id: '',
    profile_id: ''
  });
  public fmFilter: FormGroup = this.fb.group({
    q: '',
    meta_device: '',
    group_id: '',
    is_dir: '',
    task_channel_id: '',
    related_count: '',
    part_of_count: '',
    consist_of_count: '',
    operation_id: '',
    file_parameter_value_id: this.is_global ? [[]] :[],
    is_ready_if_approved: '',
    operation_reminder_id: this.is_global ? [[]] :[],
  })

  public subFilterMenu: Subscription;
  public tasksSub: Subscription;
  public taskForm: FormGroup = this.fb.group({
    q: ''
  })

  public is_filter_open: boolean = false;
  public hasMore: boolean = true;

  public specialParametersPage: number = 1;
  public specialParametersPagination: any;
  public specialParameters: any;
  public prevRoute: any;
  public allSpecialValues: any;
  public parameters: any;
  
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public specialParamDataSub: Subscription
  public paramDataSub: Subscription
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  
  public transcribe: any = transcribe;
  public canTranscribe: Function = canTranscribe;
  public devices: any;
  public devices$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public devicesMoreControl: FormControl = new FormControl();

  public subOne: Subscription;
  public subTwo: Subscription;
  public subThird: Subscription;
  public subFour: Subscription;
  public subFiles: Subscription;
  public subFoldersCount: Subscription;
  public subFilesCount: Subscription;
  public refreshSub: Subscription;
  public refreshFileTagsSub: Subscription;
  public copiedFiles: any;
  public putFilesID = this.generateRandomId();

  selectedDocuments: Array<any> = [];

  public groups: any;
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public foldersPerm: any[] = [];

  public taskTemplates: any;
  public filesBufferSub: Subscription;
  public foldersExpandSub: Subscription;
  public checklists: any;
  public profileStatuses: any;
  public checklistsControl: FormControl = new FormControl();
  public checklists$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fileService: FileService,
    private connectedAppsService: ConnectedAppsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private _clipboardService: ClipboardService,
    private taskService: TaskService,
    private uploadService: UploadService,
    private scenariosService: ScenariosService,
    private ls: LoadingService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private parametersService: ParametersService,
    private md5Service: Md5Service,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public membersService: MembersService,
    private chatService: ChatService,
    private layoutService: LayoutService,
    private refreshService: RefreshService,
    private router: Router,
    private sm: StorageManagerService,
    private taskBarService: TaskBarService,    
    private minimizeService: MinimizeService,    
    private languageService: LanguageService,    
    private bottomSheet: MatBottomSheet,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    ) { super() }

  ngOnInit(): void {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();

    this.attachSubscriptions(
      this.taskForm.get('q').valueChanges.pipe(debounceTime(300)).subscribe(res => {
        console.log(res)
        this.tasksPage = 1;
        this.getTasks(this.tasksPage)
      })
    )

    this.attachSubscriptions(
      this.fileService.getSelectAllFilesStream().subscribe(resp => {
        if (!!resp) {
          this.files.filter(x => x.is_dir == 0).forEach(file => {
            this.onSelectFile.emit(file)
          });
        }
      })
    )

    this.getProfilesStatus();
    this.getProfiles();

    this.attachSubscriptions(
      this.fileService.getReserveFiles().pipe(
        filter(x => !!x && x.id == this.putFilesID),
        concatMap(fileParams => {
          let path = this.crumbs[this.crumbs.length - 1].location;
          if (fileParams.initPath) {
            path = fileParams.initPath;
          }
      
          if (fileParams.is_folder) {
            path = (path[path.length - 1] == '/' ? path : path + "/") + (!!fileParams.file.filepath ? fileParams.file.filepath : fileParams.file.webkitRelativePath.replace("/" + fileParams.file.name, ''));
          }
      
          let _task = this.task || fileParams.file.task;
          let _work;
          if (_task && _task.operations) {
            _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
          }
          
          console.log("_file", fileParams.file)
          console.log("_task", _task)
          console.log("_work", _work)
      
          if (!!_work) {
            if ((_work.status_id == 98 || _work.status_id == 4) && path.indexOf("to_approve") != -1) {
              this.layoutService.showSnackBar({name: ''}, marker("Work in completed state, you can't upload files to 'To Approve' folder. Upload to any other folder."), SnackBarItem)
              return
            }
          }
          let needDelete = [];
          let test_1 = this.files.filter(x => x.filename == fileParams.file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1);
          let test_2 = this.files.filter(x => x.filename == fileParams.file.name);
          let test_3 = this.files.filter(x => x.is_uploaded == 0);
          let test_4 = this.files.filter(x => x.location.indexOf('/trash') == -1);
          console.log("test_1", test_1);
          console.log("test_2", test_2);
          console.log("test_3", test_3);
          console.log("test_4", test_4);
          if (this.files.filter(x => x.filename == fileParams.file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1).length > 0) {
            needDelete = this.files.filter(x => x.filename == fileParams.file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1);
          }
          console.log("needDelete", needDelete)
          if (needDelete.length > 0) {
            return forkJoin(needDelete.map(x => this.fileService.editFile(x.id, {"location": '/trash'}, this.company.id))).pipe(
              last(),
              switchMap(() => {
                needDelete.forEach(x => {
                  if (this.files.find(u => u.id == x.id)) {
                    this.files.splice(this.files.indexOf(this.files.find(u => u.id == x.id)), 1)
                  }
                })
                return this.fileService.uploadFile({
                  company_id: this.target_company_id,
                  task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
                  task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
                  filesize: fileParams.file.size,
                  filename: fileParams.file.name,
                  content_type: fileParams.file.type ? fileParams.file.type : mimeTypes.getType(fileParams.file.name),
                  location: path,
                  is_dir: 0
                }, this.company.id)
              }),
              tap(res => {
                fileParams.file.reportsFile = res
                let x = {
                  place: "file_manager",
                  url: window.location.href,
                  data: res,
                  files: this.files,
                  folder: {
                    is_folder: fileParams.is_folder,
                    path: path
                  },
                  task: _task,
                  work: _work,
                  activeLang: this.activeLang,
                  operationsValues: this.operationsValues,
                  location: this.crumbs[this.crumbs.length - 1].location,
                  target: fileParams.file,
                  user: this.user,
                  task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
                  task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
                  company: this.company,
                  company_id: this.company.id,
                  target_company_id: this.target_company_id
                }
                this.fileService.files$.next(x)
                let beforeUploadFile = x.target.reportsFile;
                beforeUploadFile.allData = x;
                if (path == x.location && this.files.filter(u => u.id == beforeUploadFile.id).length == 0) {
                  this.files.push(beforeUploadFile)
                }
              }),
              catchError((error) => {
                if (error == "Company limit exceeded") {
                  this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
                } else {
                  this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
                }
                return of(false)
              })
            )
          } else {
            return this.fileService.uploadFile({
              company_id: this.target_company_id,
              task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
              task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
              filesize: fileParams.file.size,
              filename: fileParams.file.name,
              content_type: fileParams.file.type ? fileParams.file.type : mimeTypes.getType(fileParams.file.name),
              location: path,
              is_dir: 0
            }, this.company.id).pipe(
              tap(res => {
                fileParams.file.reportsFile = res
                let x = {
                  place: "file_manager",
                  url: window.location.href,
                  data: res,
                  files: this.files,
                  folder: {
                    is_folder: fileParams.is_folder,
                    path: path
                  },
                  task: _task,
                  work: _work,
                  activeLang: this.activeLang,
                  operationsValues: this.operationsValues,
                  location: this.crumbs[this.crumbs.length - 1].location,
                  target: fileParams.file,
                  user: this.user,
                  task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
                  task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
                  company: this.company,
                  company_id: this.company.id,
                  target_company_id: this.target_company_id
                }
                this.fileService.files$.next(x)
                let beforeUploadFile = x.target.reportsFile;
                beforeUploadFile.allData = x;
                if (path == x.location && this.files.filter(u => u.id == beforeUploadFile.id).length == 0) {
                  this.files.push(beforeUploadFile)
                }
              }),
              catchError((error) => {
                if (error == "Company limit exceeded") {
                  this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
                } else {
                  this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
                }
                return of(false)
              })
            )
          }
        })
      ).subscribe(res => {

      })
    )

    this.refreshFileTags();
    if (this.is_global) {
      this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
      this.target_company_id = this.company_id

      this.fileService.getClearTrash().subscribe(resp => {
        console.log("getClearTrash", resp)
        if (resp.id == this.id) {
          this.clearFolder(resp.is_delete, resp.is_delete_copies)
        }
      })

      this.getUser();
      this.getLangData();
      this.getOperations();
      this.getTimeZones();
      this.getImgRoute();

      this.getGlobalFiles();
      this.getGlobalFilesCount();
      this.getGlobalFoldersCount();
      this.getTaskTemplates();      

      this.getGroupsCompany();
      this.specialParametersPage = 1;
      this.getSpecialParameters();

      if (this.specialParamDataSub) {
        this.specialParamDataSub.unsubscribe();
      }
      this.specialParamDataSub = this.parametersService.getGlobalFilesParamsData().pipe(
        concatMap(params => {
          return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
        }),
      ).subscribe(resp => console.log("-----getGlobalFilesParamsData-----",resp));


      this.attachSubscriptions(
        this.globalFilterFmFilesForm.get('file_parameter_value_id').valueChanges.pipe(debounceTime(300), filter(() => this.specialParameters.length > 0)).subscribe((resp) => {
   
          if (this.specialParamDataSub) {
            this.specialParamDataSub.unsubscribe();
          }
          this.specialParamDataSub = this.parametersService.getGlobalFilesParamsData().pipe(
            concatMap(params => {
              return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
            }),
          ).subscribe(resp => console.log("-----getGlobalFilesParamsData-----",resp));
          this.parametersService.globalFilesParamsData$.next(this.specialParameters)
        })
      )

      this.attachSubscriptions(
        this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
      )

      this.attachSubscriptions(
        this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
      )

      this.attachSubscriptions(
        this.checklistsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChecklists(resp))
      )
    } else {

      if (!!this.activatedRoute.snapshot.queryParamMap.get('company_id') || !!this.initCompanyId) {
        this.getCompany()
      } 
      if (this.sm.localStorageGetItem("copiedFiles")) {
        this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
      }
      // if (this.initData && this.initData.task) {
      //   this.company_id = this.initData.company ? this.initData.company.id : this.initData.task.company_id
      // }

      if (this.initData && this.initData.company && this.initData.company.id) {
        this.company_id = this.initData.company.id
      }

      if (this.initData && this.initData.task) {
        this.target_company_id = this.initData.task.company_id
      }

      this.attachSubscriptions(
        this.fileService.getRejectFolder().pipe(
          switchMap(res => {
            return this.fileService.getFileData(1, this.company_id, {task_id: this.task_id, task_operation_id: this.task_operation_id, location: '/', is_dir: "1"}).pipe(
              map(el => el.body),
              tap(fdrs => {
                fdrs.forEach(element => {
                  if (this.crumbs[this.crumbs.length - 1].location == '/') {
                    if (this.files.filter(x => x.id == element.id).length == 0) {
                      this.files.push({...element});
                    }
                  }
                  if (!this.folders.find(x => x.location.indexOf(element.filename) != -1)) {
                    element.name = element.filename;
                    element.location = "/" + element.filename;
                    element.rootDir = true;
                    delete element.is_dir;
                    this.folders.push(element)
                  }
                });
              }),
              tap(fdrs => {this.fileService.mobFolders$.next(this.folders)}),
            )
          })
        ).subscribe(resp => {
          console.log("getRootDirs", this.folders)
        })
      )
  
      console.log("FIRST", this.company_id)
      this.subFour = this.form.get("location").valueChanges.subscribe(resp => {
        console.log('location change foldersPermissions location', resp)
        console.log("location change foldersPermissions this.crumbs", this.crumbs)
        console.log("location change foldersPermissions this.foldersPerm", this.foldersPerm)
        this.onLocationChange(resp)
      })
      // console.log( (document.getElementsByTagName("app-root")[0] as Element))
      // let root = document.getElementsByTagName("app-root")[0] as HTMLElement
      // root.style.display = "none";
      this.getUser();
      this.getImgRoute();
      this.getLangData();
      
      this.company = this.initData.company;
      console.log("TEST", this.company.filesize >= this.company.filesize_limit)
  
      this.subThird = this.uploadService.getUploadLimit().subscribe(resp => {
        console.log("getUploadLimit", resp)
        if (resp || this.company.filesize >= this.company.filesize_limit) {
          this.uploadLimit = true;
        }
      })
  
      this.subOne = this.timezoneFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTimezoneGroups();
      });
  
      this.getOperations();
      this.getTimeZones();
      
      this.subTwo = this.fileRoute.valueChanges.subscribe(resp => {
        console.log("RESP", resp)
        // this.company_id = resp.company_id;
        this.task_id = resp.task_id;
        this.task_operation_id = resp.task_operation_id;  

        if (this.task_id) {
          this.getTask();
        }
        if (this.uploadProgress) {
          this.uploadProgress.unsubscribe()
        }
        if (this.fileRoute.value.profile_id) {
          this.getChannelContent();
          return
        }
  
        if (this.initLocation && this.initCounter == 1) {
          if (this.initLocation == '/') {
            this.crumbs = [{
              name: "Root",
              location: "/"
            }]
          } else {
            this.crumbs = [];
            this.initLocation.split('/').forEach(val => {
              if (val === '') {
                this.crumbs.push({
                  name: "Root",
                  location: "/"
                })
              } else {
                this.crumbs.push({
                  name: val,
                  location: (this.crumbs[this.crumbs.length - 1].location == '/' ? this.crumbs[this.crumbs.length - 1].location : this.crumbs[this.crumbs.length - 1].location + '/') + val
                })
              }
            })
          }

          // if (this.crumbs.filter(x => x.location == this.initLocation).length == 0) {
          //   this.crumbs.push({
          //     name: this.initLocation.replace("/", ""),
          //     location: this.initLocation
          //   })
          // }
        }
        
        this.form.patchValue({
          location: !!this.initLocation && this.initCounter == 1 ? this.initLocation : '/'
        })
      })
  
      this.fileRoute.patchValue({
        company_id:  this.initData && this.initData.task ? this.initData.task.company_id : this.initData.company.id,
        task_id: this.initData && this.initData.task ? this.initData.task.id : 0,
        task_operation_id: !!this.initData.work ? this.initData.work.id : 0,
        profile_id: 0
      })
  
      this.getRootDirs();
      this.attachSubscriptions(
        this.devicesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchDevices(resp))
      )
      this.getAllApiParameterValues();
    }

    this.attachSubscriptions(
      this.fmPing.subscribe(count => {
        console.log("fmPing", count)
        let filterProgress:any = {}
        if (!!this.task_id) {
          filterProgress.task_id = this.task_id
        }
        if (!!this.task_operation_id) {
          filterProgress.task_operation_id = this.task_operation_id
        }
        let filesNeedRefresh = this.files.filter(x => x.is_document != 1 && (x.is_dir == 1 || x.is_uploaded == 0) && moment(x.updated_at*1000).isSameOrAfter(moment().set({hour: 0, minute: 0, second:0})))
        console.log('this.target_company_id', this.target_company_id)
        console.log('filesNeedRefresh.map(b => b.id)', [...filesNeedRefresh.map(b => b.id)])
        console.log('filterProgress', filterProgress)
        this.fileService.getFilesProgress(this.company_id, [...filesNeedRefresh.map(b => b.id)], filterProgress).subscribe(files => {
          console.log("fmPing files", files)
          files.forEach(file => {
            if (this.files.find(u => u.id == file.id)) {
              let target = this.files.find(u => u.id == file.id);
              
              target.is_uploaded = file.is_uploaded
              target.meta_creation_time = file.meta_creation_time
              target.meta_creation_time_offset = file.meta_creation_time_offset
              target.is_rev_transcribe = file.is_rev_transcribe
              target.updated_at = file.updated_at
              target.preview1080 = file.preview1080
              target.preview1080_filesize = file.preview1080_filesize
              target.thumbnail = file.thumbnail
              target.user_upload_progress = file.user_upload_progress
              target.user_upload_speed_mbit = file.user_upload_speed_mbit
              target.copies_count = file.copies_count
              target.files_count = file.files_count

              console.log("fmPing target", target)
            }
          });
        })
      })
    )

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )

    // this.attachSubscriptions(
    //   this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    // )

    this.getFileDevices();
    this.getChecklists();
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  addCollectorForJob() {
    const dialogRef = this.dialog.open(JobAutomationComponent, {
      data: {
        company: this.company,
        task_id: this.task_id,
        work: {id: this.task_operation_id},
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
        scenario_name: "TaskOperationFilesCollector"
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
 
        }
      })
    )
  }

  // addCollectorForJob() {
  //   const dialogRef = this.dialog.open(MultiMigrateComponent, {
  //     data: {
  //       company: this.company,
  //       user: this.user,
  //       task_id: this.task_id,
  //       task_operation_id: this.task_operation_id
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {

  //     })
  //   )
  // }
  
  onSearchValues(resp) {
    console.log("onSearchValues resp", resp);
    console.log("onSearchValues this.allValues", this.allValues);
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter && z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  generateRandomId(): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = 10;
    let randomId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
  
    return randomId;
  }

  isWriteFolder(route?) {
    let location;

    if (!!route) {
      location = route;
    } else {
      location = this.form.get('location').value;
    }
    location = "/" + location.split('/')[1];
    if (this.foldersPerm.length == 0 || this.folders.filter(x => !!x.is_sys && x.location.indexOf(location) != -1).length == 0 || this.foldersPerm.filter(x => x.location == location && x.cant_write == false).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
  }

  patchControl(val) {
    this.cardSearch.patchValue(val)
    this.taskForm.patchValue({
      q: ''
    })
    this.tasksPage = 1;
  }

  toggleCard(e, card) {
    e.preventDefault();
    e.stopPropagation();
    card.open = !card.open
  }

  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.hasMore) {
      console.log("getNextCards")
      this.getTasks(this.tasksPage);
    }
  }

  getTasks(n) {
    this.isLoadTasks = true;
    this.tasksSub = this.taskService.getSearchCards(n, this.company_id, {...this.taskForm.value}, '60').pipe(
      tap(arrTasks => {
        console.log("arrTasks", arrTasks)
        if (arrTasks.length < 60) {
          this.hasMore = false
        } else {
          this.hasMore = true;
        }
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      if (this.tasksPage == 1) {
        this.searchTasks = resp
      } else {
        this.searchTasks.push(...resp)
      }
      this.tasksPage = this.tasksPage + 1;
      this.isLoadTasks = false;
    },
    error => {
      this.isLoadTasks = false;
    })
    
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }  

  onSearchChecklists(resp) {
    if (!this.checklists) {
      return;
    }

    if (!resp) {
      this.checklists$.next(this.checklists.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.checklists$.next(
      this.checklists.filter(b => b.text.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  publishTo(file) {
    file.is_publish_open = !file.is_publish_open
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchDevices(resp) {
    if (!this.devices) {
      return;
    }

    if (!resp) {
      this.devices$.next(this.devices.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.devices$.next(
      this.devices.filter(z => z.toLowerCase().indexOf(resp) > -1)
    );
  }

  getFileDevices() {
    this.attachSubscriptions(
      this.fileService.getFileDevices(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId).subscribe(resp => {
        console.log("getFileDevices", resp)
        this.devices = resp;
        this.devices$.next(this.devices.slice())
      })
    )
  }

  getPathForLink(val) {
    return val.replaceAll('//', '%2F').replaceAll('/', '%2F').replaceAll(' ', '%20')
  }

  neededParametersData(param) {
    let arr = []
    param.page = 1
    arr.push(
      this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1").pipe(
        tap(x => {
          param.pagination = {
            'pageCount': x.headers.get('x-pagination-page-count'),
            'perPage': x.headers.get('x-pagination-per-page'),
            'totalCount': x.headers.get('x-pagination-total-count'),
            'currentPage': x.headers.get('x-pagination-current-page'),
          }
          param.page = 2
          param.values = x.body;
          if (!!param.is_file_type) {
            param.values.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -2,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -2,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "RePost Docs"
            })

            param.values.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -3,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -3,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "ClaRe docs"
            })

            param.values.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -1,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -1,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "Re docs"
            })
            if (this.user.is_root) {
              param.values.unshift({
                company_id: this.company_id,
                created_at: 0,
                created_user_id: 0,
                id: -4,
                is_not_automatically_assigned: 0,
                order: 0,
                original_company_id: this.company_id,
                original_id: -4,
                original_user_id: 0,
                parameter: null,
                parameter_id: 0,
                parent_value_id: 0,
                system_value_id: 0,
                task_acm: "",
                task_id: 0,
                tips_count: 0,
                updated_at: 0,
                updated_user_id: 0,
                value: "Live stream"
              })
            }
          }
          if (x.body.length > 0) {
            param.hasValues = true;
          }
          this.getActiveValuesForParam(param)
        })
      )
    )

    return forkJoin(arr)
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }
  
  noOption(arr, id) {
    return arr.filter(x => x.id == id).length > 0 ? false : true;
  }

  getActiveValuesForParam(param) {
    let paramValues;
    if (param.id != 0) {
      paramValues = this.allValues.filter(b => b.parameter_id == param.id);
    } else {
      paramValues = this.allValues.slice();
    }

    param.activeValues = []
    this.globalFilterFmFilesForm.value.file_parameter_value_id.forEach(x => {
      if (x == -1 && !!param.is_file_type) {
        param.activeValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })
      } else if (x == -3 && !!param.is_file_type) {
        param.activeValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe docs"
        })
      } else if (x == -2 && !!param.is_file_type) {
        param.activeValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })
      } else if (x == -4 && !!param.is_file_type) {
        param.activeValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -4,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -4,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Live stream"
        })
      } else {
        param.activeValues.push(...paramValues.filter(y => y.id == x))
      }
    })
  }

  keySwitcher(key) {
    switch (key) {
      case 'task_parameter_value_id':
        return "activeTaskValues"
        break;
      case 'task_operation_parameter_value_id':
        return "activeJobValues"
        break;
      case 'discussion_parameter_value_id':
        return "activeDiscussionValues"
        break;
      case 'file_parameter_value_id':
        return "activeValues"
        break;
    }
  }
  
  onRemove(value, param, key) {
    console.log("onRemove", this.globalFilterFmFilesForm.value)
    let val = this.globalFilterFmFilesForm.value[key];
    val.splice(val.indexOf(value.id), 1);
    this.globalFilterFmFilesForm.patchValue({
      [key]: val
    })
    this.getActiveValuesForParam(param);
    let params = {...this.activatedRoute.queryParamMap.source['_value']}

    params.count = params.count ? +params.count + 1 : 1;
    params.file_parameter_value_id = this.globalFilterFmFilesForm.value.file_parameter_value_id.join(',');
    this.router.navigate(['/files'], { queryParams: params })
  }

  selectConnectionFilter(e, key) {
    console.log('selectConnectionFilter', e)
    let params = {...this.activatedRoute.queryParamMap.source['_value']}

    params.count = params.count ? +params.count + 1 : 1;
    params[key] = e.value
    this.router.navigate(['/files'], { queryParams: params })
  }


  selected(e, param, key) {
    console.log("selected", e)
    let val = this.globalFilterFmFilesForm.value[key];
    val.push(e.option.value.id);
    this.globalFilterFmFilesForm.patchValue({
      [key]: val
    })
    param.search.patchValue("")
    if (document.getElementById(`param_file_input_${param.id}`)) {
      (document.getElementById(`param_file_input_${param.id}`) as HTMLInputElement).value = ''
    }
    this.getActiveValuesForParam(param);

    let params = {...this.activatedRoute.queryParamMap.source['_value']}

    params.count = params.count ? +params.count + 1 : 1;
    params.file_parameter_value_id = this.globalFilterFmFilesForm.value.file_parameter_value_id.join(',');
    this.router.navigate(['/files'], { queryParams: params })
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  getParameterById(id) {
    if (!this.parameters || this.parameters.length == 0) {
      return null
    }
    return this.parameters.find(x => x.id == id)
  }

  getSpecialParameters() {
    this.attachSubscriptions(
      this.parametersService.getFilteredApiParameters(this.company_id, {special: 'is_file_type,is_file_meta_orientation,is_file_meta_device'}).pipe(
        switchMap(res => {
          return this.parametersService.getPaginationAllApiParameterValues(this.specialParametersPage, this.company_id)
          .pipe(
            tap(el => {
              if (el.headers.get('x-pagination-current-page') == 1) {
                this.specialParametersPagination = {
                  'pageCount': el.headers.get('x-pagination-page-count'),
                  'perPage': el.headers.get('x-pagination-per-page'),
                  'totalCount': el.headers.get('x-pagination-total-count'),
                  'currentPage': el.headers.get('x-pagination-current-page'),
                }
                this.allValues = el.body;
                this.allValues.unshift({
                  company_id: this.company_id,
                  created_at: 0,
                  created_user_id: 0,
                  id: -2,
                  is_not_automatically_assigned: 0,
                  order: 0,
                  original_company_id: this.company_id,
                  original_id: -2,
                  original_user_id: 0,
                  parameter: null,
                  parameter_id: 0,
                  parent_value_id: 0,
                  system_value_id: 0,
                  task_acm: "",
                  task_id: 0,
                  tips_count: 0,
                  updated_at: 0,
                  updated_user_id: 0,
                  value: "RePost Docs"
                })

                this.allValues.unshift({
                  company_id: this.company_id,
                  created_at: 0,
                  created_user_id: 0,
                  id: -3,
                  is_not_automatically_assigned: 0,
                  order: 0,
                  original_company_id: this.company_id,
                  original_id: -3,
                  original_user_id: 0,
                  parameter: null,
                  parameter_id: 0,
                  parent_value_id: 0,
                  system_value_id: 0,
                  task_acm: "",
                  task_id: 0,
                  tips_count: 0,
                  updated_at: 0,
                  updated_user_id: 0,
                  value: "ClaRe Docs"
                })
    
                this.allValues.unshift({
                  company_id: this.company_id,
                  created_at: 0,
                  created_user_id: 0,
                  id: -1,
                  is_not_automatically_assigned: 0,
                  order: 0,
                  original_company_id: this.company_id,
                  original_id: -1,
                  original_user_id: 0,
                  parameter: null,
                  parameter_id: 0,
                  parent_value_id: 0,
                  system_value_id: 0,
                  task_acm: "",
                  task_id: 0,
                  tips_count: 0,
                  updated_at: 0,
                  updated_user_id: 0,
                  value: "Re docs"
                })

                if (this.user.is_root) {
                  this.allValues.unshift({
                    company_id: this.company_id,
                    created_at: 0,
                    created_user_id: 0,
                    id: -4,
                    is_not_automatically_assigned: 0,
                    order: 0,
                    original_company_id: this.company_id,
                    original_id: -4,
                    original_user_id: 0,
                    parameter: null,
                    parameter_id: 0,
                    parent_value_id: 0,
                    system_value_id: 0,
                    task_acm: "",
                    task_id: 0,
                    tips_count: 0,
                    updated_at: 0,
                    updated_user_id: 0,
                    value: "Live stream"
                  })
                }
              }
              console.log("getAllApiParameterValues", this.allValues)
            }),
            switchMap(el => {
              let pages = [] 
              for (let index = 2; index <= +this.specialParametersPagination.pageCount; index++) {
                pages.push(index)
              }

              console.log("special pages", pages)
              if (pages.length > 0) {
                return forkJoin(pages.map(x => this.parametersService.getPaginationAllApiParameterValues(x, this.company_id))).pipe(
                  tap(val => {
                    val.forEach(p => {
                      this.allValues.push(...p.body)
                    })
                  })
                )
              } else {
                return of(res)
              }

            }),
            map(() => res)
          )
        }),
        tap(res => {
          this.parametersService.globalFilesParamsData$.next(res)
          res.forEach(n => {
            n.search = new FormControl('');
            n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n));
          })

          this.specialParameters = res;
        }),
      ).subscribe(resp => {
        console.log("this.specialParameters", this.specialParameters)
      })
    )
  }

  deleteFileAndCopies(file, i) {
    this.close();
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) { 
      let lenSelection = this.selectedFiles.selected.length;
      let count = 0;
      let alertData = {
        targetVal: {},
        msg: marker(`Are you sure you want to start delete file and file copies logic for ${lenSelection} files.`),
        msg_1: marker("Proceed ?")
      }
   
  
      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
        data: alertData
      });

      deleteAlert.afterDismissed().pipe(
        switchMap(data => {
          if (data && data.message == 'no') {
            console.log("no");
            return of(false)
          } else if (data && data.message == 'yes') {

            this.ls.requests$.next({
              value: 0,
              target: `Deleting ${lenSelection} files and their copies`
            })

            return from(this.selectedFiles.selected).pipe(
              concatMap((fileID:any) => {
                let exFile;
                if (this.files.find(b => b.id == fileID)) {
                  exFile = this.files.find(b => b.id == fileID);
                } else if (this.boxFiles.selected.find(b => b.id == fileID)) {
                  exFile = this.boxFiles.selected.find(b => b.id == fileID)
                }
                if (!exFile) {
                  return of(null)
                }
      
                let is_delete = exFile.location.indexOf('/trash') != -1;

                return this.fileService.getFileData("1", this.company_id, {'location_all': "/", task_id: 0, original_file_id : exFile.original_file_id || exFile.id}).pipe(
                  tap(el => {
                    exFile.countSameFiles = el.headers.get('x-pagination-total-count');
                    exFile.sameFiles = el.body;
          
                    if (exFile.original_file_id) {            
                      if (exFile.sameFiles.find(x => x.id == exFile.id)) {
                        exFile.countSameFiles = exFile.countSameFiles - 1;
                        exFile.sameFiles.splice(exFile.sameFiles.findIndex(x => x.id == exFile.id), 1);
                      }
                    }
                  }),
                  switchMap(() => {
                    if (is_delete) {
                      return this.fileService.deleteFile(exFile.id).pipe(
                        switchMap(() => {
                          if (!exFile.sameFiles || !exFile.sameFiles.length) {
                            return of(null)
                          }
                          return forkJoin(exFile.sameFiles.map(sFile => this.fileService.deleteFile(sFile.id))).pipe(
                            tap(x => {
                              exFile.sameFiles.forEach(element => {
                                if (this.files.filter(k => k.id == element.id)) {
                                  this.files.splice(this.files.findIndex(p => p.id == element.id), 1)
                                }
                              });
                              if (this.files.filter(k => k.id == exFile.id)) {
                                this.files.splice(this.files.findIndex(p => p.id == exFile.id), 1)
                              }
                            })
                          )
                        })
                      )
                    } else {
                      return this.fileService.editFile(exFile.id, {location: '/trash'}, this.company_id).pipe(
                        switchMap(() => {
                          if (!exFile.sameFiles || !exFile.sameFiles.length) {
                            return of(null)
                          }
                          return forkJoin(exFile.sameFiles.map(sFile => this.fileService.editFile(sFile.id, {location: '/trash'}, this.company_id))).pipe(
                            tap(x => {
                              if (this.is_global) {
                                exFile.location = '/trash';
                                exFile.sameFiles.forEach(element => {
                                  if (this.files.filter(k => k.id == element.id)) {
                                    this.files.find(p => p.id == element.id).location = '/trash';
                                  }
                                });
                              } else {
                                exFile.sameFiles.forEach(element => {
                                  if (this.files.filter(k => k.id == element.id)) {
                                    this.files.splice(this.files.findIndex(p => p.id == element.id), 1)
                                  }
                                });
                                if (this.files.filter(k => k.id == exFile.id)) {
                                  this.files.splice(this.files.findIndex(p => p.id == exFile.id), 1)
                                }
                              }
                            })
                          )
                        })
                      )
                    }
                  })
                )
              })
            )
          }
        })
      ).subscribe({
        next: (next) => {
          console.log("next deleteFileAndCopies", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Deleting ${lenSelection} files and their copies`
          })
          count++;
        },
        complete: () => {
          console.log("complete deleteFileAndCopies");
          this.selectedFiles.clear();
        },
        error: (error) => {
          console.log("error deleteFileAndCopies", error)
        }
      })

    } else {

      let alertData;
      let is_delete = file.location.indexOf('/trash') != -1;
      if (is_delete) {
        alertData = {
          targetVal: {},
          msg: marker(`Are you sure you want to delete file and file copies its can't be restored anymore.`),
          msg_1: marker("Proceed with files delete?")
        }
      } else {
        alertData = {
          targetVal: {},
          msg: marker(`Are you sure you want to move file and file copies to the trash?`),
          msg_1: marker("Proceed with files moving?")
        }
      }
  
      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
        data: alertData
      });
  
      deleteAlert.afterDismissed().pipe(
        switchMap(data => {
          if (data && data.message == 'no') {
            console.log("no");
            return of(false)
          } else if (data && data.message == 'yes') {
            this.ls.requests$.next({
              value: 0,
              target: is_delete ? "Deleting file and copies" : "Move to trash file and copies"
            })

            return this.fileService.getFileData("1", this.company_id, {'location_all': "/", task_id: 0, original_file_id : file.original_file_id || file.id}).pipe(
              tap(el => {
                file.countSameFiles = el.headers.get('x-pagination-total-count');
                file.sameFiles = el.body;
      
                if (file.original_file_id) {            
                  if (file.sameFiles.find(x => x.id == file.id)) {
                    file.countSameFiles = file.countSameFiles - 1;
                    file.sameFiles.splice(file.sameFiles.findIndex(x => x.id == file.id), 1);
                  }
                }
                this.ls.requests$.next({
                  value: 25,
                  target: is_delete ? "Deleting file and copies" : "Move to trash file and copies"
                })
              }),
              switchMap(() => {
                if (is_delete) {
                  return this.fileService.deleteFile(file.id).pipe(
                    switchMap(() => {
                      this.ls.requests$.next({
                        value: 50,
                        target: is_delete ? "Deleting file and copies" : "Move to trash file and copies"
                      })
                      return forkJoin(file.sameFiles.map(sFile => this.fileService.deleteFile(sFile.id))).pipe(
                        tap(x => {
                          this.ls.requests$.next({
                            value: 100,
                            target: is_delete ? "Deleting file and copies" : "Move to trash file and copies"
                          })
                          file.sameFiles.forEach(element => {
                            if (this.files.filter(k => k.id == element.id)) {
                              this.files.splice(this.files.findIndex(p => p.id == element.id), 1)
                            }
                          });
                          if (this.files.filter(k => k.id == file.id)) {
                            this.files.splice(this.files.findIndex(p => p.id == file.id), 1)
                          }
                        })
                      )
                    })
                  )
                } else {
                  return this.fileService.editFile(file.id, {location: '/trash'}, this.company_id).pipe(
                    switchMap(() => {
                      this.ls.requests$.next({
                        value: 50,
                        target: is_delete ? "Deleting file and copies" : "Move to trash file and copies"
                      })
                      return forkJoin(file.sameFiles.map(sFile => this.fileService.editFile(sFile.id, {location: '/trash'}, this.company_id))).pipe(
                        tap(x => {
                          this.ls.requests$.next({
                            value: 100,
                            target: is_delete ? "Deleting file and copies" : "Move to trash file and copies"
                          })
                          if (this.is_global) {
                            file.location = '/trash';
                            file.sameFiles.forEach(element => {
                              if (this.files.filter(k => k.id == element.id)) {
                                this.files.find(p => p.id == element.id).location = '/trash';
                              }
                            });
                          } else {
                            file.sameFiles.forEach(element => {
                              if (this.files.filter(k => k.id == element.id)) {
                                this.files.splice(this.files.findIndex(p => p.id == element.id), 1)
                              }
                            });
                            if (this.files.filter(k => k.id == file.id)) {
                              this.files.splice(this.files.findIndex(p => p.id == file.id), 1)
                            }
                          }
                        })
                      )
                    })
                  )
                }
              })
            )
          }
        })
      ).subscribe(resp => {
        console.log(resp)
        this.ls.requests$.next({
          value: 100,
          target: is_delete ? "Deleting file and copies" : "Move to trash file and copies"
        })
      })
    }
  }

  openSameFiles(file) {
    this.attachSubscriptions(
      this.fileService.getFileData("1", this.company_id, {'location_all': "/", task_id: 0, original_file_id : file.original_file_id || file.id}).pipe(
        tap(el => {
          file.countSameFiles = el.headers.get('x-pagination-total-count');
          file.sameFiles = el.body;

          if (file.original_file_id) {            
            if (file.sameFiles.find(x => x.id == file.id)) {
              file.countSameFiles = file.countSameFiles - 1;
              file.sameFiles.splice(file.sameFiles.findIndex(x => x.id == file.id), 1);
            }
          }
        })
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(SameFilesComponent, {
          data: {
            company_id: this.company_id || file.company_id,
            file: file,
            no_btns: true
          }
        });
      })
    )

  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })

        this.allValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe docs"
        })
        this.allValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })

        if (this.user.is_root) {
          this.allValues.unshift({
            company_id: this.company_id,
            created_at: 0,
            created_user_id: 0,
            id: -4,
            is_not_automatically_assigned: 0,
            order: 0,
            original_company_id: this.company_id,
            original_id: -4,
            original_user_id: 0,
            parameter: null,
            parameter_id: 0,
            parent_value_id: 0,
            system_value_id: 0,
            task_acm: "",
            task_id: 0,
            tips_count: 0,
            updated_at: 0,
            updated_user_id: 0,
            value: "Live stream"
          })
        }
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.company.id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.company.id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company.id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values);
                  this.allValues.unshift({
                    company_id: this.company_id,
                    created_at: 0,
                    created_user_id: 0,
                    id: -2,
                    is_not_automatically_assigned: 0,
                    order: 0,
                    original_company_id: this.company_id,
                    original_id: -2,
                    original_user_id: 0,
                    parameter: null,
                    parameter_id: 0,
                    parent_value_id: 0,
                    system_value_id: 0,
                    task_acm: "",
                    task_id: 0,
                    tips_count: 0,
                    updated_at: 0,
                    updated_user_id: 0,
                    value: "RePost Docs"
                  })
      
                  this.allValues.unshift({
                    company_id: this.company_id,
                    created_at: 0,
                    created_user_id: 0,
                    id: -3,
                    is_not_automatically_assigned: 0,
                    order: 0,
                    original_company_id: this.company_id,
                    original_id: -3,
                    original_user_id: 0,
                    parameter: null,
                    parameter_id: 0,
                    parent_value_id: 0,
                    system_value_id: 0,
                    task_acm: "",
                    task_id: 0,
                    tips_count: 0,
                    updated_at: 0,
                    updated_user_id: 0,
                    value: "ClaRe docs"
                  })

                  this.allValues.unshift({
                    company_id: this.company_id,
                    created_at: 0,
                    created_user_id: 0,
                    id: -1,
                    is_not_automatically_assigned: 0,
                    order: 0,
                    original_company_id: this.company_id,
                    original_id: -1,
                    original_user_id: 0,
                    parameter: null,
                    parameter_id: 0,
                    parent_value_id: 0,
                    system_value_id: 0,
                    task_acm: "",
                    task_id: 0,
                    tips_count: 0,
                    updated_at: 0,
                    updated_user_id: 0,
                    value: "Re docs"
                  })

                  if (this.user.is_root) {
                    this.allValues.unshift({
                      company_id: this.company_id,
                      created_at: 0,
                      created_user_id: 0,
                      id: -4,
                      is_not_automatically_assigned: 0,
                      order: 0,
                      original_company_id: this.company_id,
                      original_id: -4,
                      original_user_id: 0,
                      parameter: null,
                      parameter_id: 0,
                      parent_value_id: 0,
                      system_value_id: 0,
                      task_acm: "",
                      task_id: 0,
                      tips_count: 0,
                      updated_at: 0,
                      updated_user_id: 0,
                      value: "Live stream"
                    })
                  }
                  this.allValues$.next(this.allValues.slice());
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.company_id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parametersService.filesParamsData$.next(res)
          res.forEach(n => {
            n.search = new FormControl('');
            n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
          })
          this.parameters = res
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  convertDocToPost(file) {
    this.close();
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) { 
      let lenSelection = this.selectedFiles.selected.length;
      let count = 0;

      this.ls.requests$.next({
        value: 0,
        target: "Convertation to post doc",
      })

      from(this.selectedFiles.selected).pipe(
        concatMap((fileID:any) => {
  
          let exFile;
          if (this.files.find(b => b.id == fileID)) {
            exFile = this.files.find(b => b.id == fileID);
          } else if (this.boxFiles.selected.find(b => b.id == fileID)) {
            exFile = this.boxFiles.selected.find(b => b.id == fileID)
          }
          return this.fileService.editFile(exFile.id, {content_type: 'reports-json-auto-post'}, this.company_id).pipe(
            switchMap(editedFile => {
              let editData:any = {
                text: JSON.stringify({
                  name: exFile.document.text ? this.extractPlainText(exFile.document.text.replaceAll('<br>', '\n').replaceAll('<BR>', '\n').replaceAll('</br>', '\n').replaceAll('</BR>', '\n')).name : '',
                  desc: exFile.document.text ? this.extractPlainText(exFile.document.text.replaceAll('<br>', '\n').replaceAll('<BR>', '\n').replaceAll('</br>', '\n').replaceAll('</BR>', '\n')).text.replaceAll('\n\n\n\n', '\n\n').replaceAll('\n\n\n', '\n\n') : ''
                }),
                name: exFile.document.name
              }
              let target:any = {};
              target.task_id = exFile.task_id;
              target.task_operation_id = exFile.task_operation_id;
              target.discussion_id = exFile.discussion_id || 0;
              target.file_id = exFile.id;
    
              return this.companyService.editNote(exFile.document.id, editData, this.company_id, target).pipe(
                tap(editedDoc => {
                  
                  exFile.content_type = editedFile.content_type;
                  exFile.document.name = editedDoc.name;
                  exFile.document.text = editedDoc.text;
                  console.log("exFile", exFile)
                }),
                map(editedDoc => {
                  return {
                    editedFile: editedFile,
                    editedDoc: editedDoc
                  }
                })
              );
            }),
            catchError(error => {
              return of(error)
            })
          )
        })
      ).subscribe({
        next: (next) => {
          console.log("next multiconvertDocToPost", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: "Convertation to post doc",
          })
          count++;
        },
        complete: () => {
          console.log("complete multiconvertDocToPost");
          this.selectedFiles.clear();
        },
        error: (error) => {
          console.log("error multiconvertDocToPost", error)
        }
      })
    } else {
      console.log("convertDocToPost", file);
      this.attachSubscriptions(
        this.fileService.editFile(file.id, {content_type: 'reports-json-auto-post'}, this.company_id).pipe(
          switchMap(editedFile => {
            let editData:any = {
              text: JSON.stringify({
                name: this.extractPlainText(file.document.text.replaceAll('<br>', '\n').replaceAll('<BR>', '\n').replaceAll('</br>', '\n').replaceAll('</BR>', '\n')).name || file.document.name,
                desc: this.extractPlainText(file.document.text.replaceAll('<br>', '\n').replaceAll('<BR>', '\n').replaceAll('</br>', '\n').replaceAll('</BR>', '\n')).text.replaceAll('\n\n\n\n', '\n\n').replaceAll('\n\n\n', '\n\n')
              }),
              name: file.document.name
            }
            let target:any = {};
            target.task_id = file.task_id;
            target.task_operation_id = file.task_operation_id;
            target.discussion_id = file.discussion_id || 0;
            target.file_id = file.id;
  
            return this.companyService.editNote(file.document.id, editData, this.company_id, target).pipe(
              map(editedDoc => {
                return {
                  editedFile: editedFile,
                  editedDoc: editedDoc
                }
              })
            );
          })
        ).subscribe(resp => {
          console.log(resp);
          file.content_type = resp.editedFile.content_type;
          file.document.name = resp.editedDoc.name;
          file.document.text = resp.editedDoc.text;
          this.layoutService.showSnackBar({name: 'Convertation'}, 'successful!', SnackBarItem)
        }, error => {
          this.layoutService.showSnackBar({name: 'Failed to convert'}, error, SnackBarItem)
        })
      )
    }
  }
  


  extractPlainText(htmlText: string): any {
    const doc = new DOMParser().parseFromString(htmlText, 'text/html');
    let name = '';
    let text = '';
    let level = 0;
    if (doc.body.childNodes && doc.body.childNodes[0]) {
      if (doc.body.childNodes[0].nodeName.toLowerCase() == 'b') {
        level = 1;
        name = doc.body.childNodes[0].textContent;
      } else {
        if (doc.body.childNodes[0].nodeName.toLowerCase() == 'div') {
          if (doc.body.childNodes[0].childNodes && doc.body.childNodes[0].childNodes[0] && doc.body.childNodes[0].childNodes[0].nodeName.toLowerCase() == 'b') {
            level = 2;
            name = doc.body.childNodes[0].childNodes[0].textContent;
          }
        }
      }
    }

    if (name) {
      text = (doc.body.innerText || '').replace(name, '')
    } else {
      text = doc.body.innerText || '';
    }

    return {name: name.trim(), text: text.trim(), doc: doc};
  }

  onSearch(searchText, param) {
    console.log(searchText, param)
    param.page = 1;
    this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '').subscribe(resp => {
      param.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      }
      param.page = 2
      param.values = resp.body;
      if (!!param.is_file_type || param.id == 0) {
        param.values.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })
     
        param.values.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe Docs"
        })
        param.values.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })

        if (this.user.is_root) {
          param.values.unshift({
            company_id: this.company_id,
            created_at: 0,
            created_user_id: 0,
            id: -4,
            is_not_automatically_assigned: 0,
            order: 0,
            original_company_id: this.company_id,
            original_id: -4,
            original_user_id: 0,
            parameter: null,
            parameter_id: 0,
            parent_value_id: 0,
            system_value_id: 0,
            task_acm: "",
            task_id: 0,
            tips_count: 0,
            updated_at: 0,
            updated_user_id: 0,
            value: "Live stream"
          })
        }
      }
      console.log(resp.body)
      console.log(this.parameters)
    })
  }

  onScrollParams(param) {
    console.log(param.page, param.pagination.pageCount)
    if (param.pagination.pageCount >= param.page) {
      console.log("SCROLL !")
      this.parametersService.getApiParameterValues(this.company_id, param.id, "20", param.page, !!param.search.value ? param.search.value : '').pipe(take(1), map((x) => x.body)).subscribe(resp => {
        param.page++;
        param.values.push(...resp)
      })
    }
  }

  onLocationChange(resp) {
    this.usedFilter = false;
    this.initCounter++
    this.files = [];
    if (this.subFiles) {
      this.subFiles.unsubscribe()
    }
    if (this.subFilesCount) {
      this.subFilesCount.unsubscribe()
    }
    if (this.subFoldersCount) {
      this.subFoldersCount.unsubscribe()
    }
    if (this.refreshSub) {
      this.refreshSub.unsubscribe()
    }
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    this.folderFilesize = 0
    this.getFiles(this.page, resp);
    this.getFilesCount(resp);
    this.getFoldersCount(resp);
    this.getTaskTemplates();
    this.paramDataSub = this.parametersService.getFilesParamsStream().pipe(
      concatMap(params => {
        return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
      }),
    ).subscribe(resp => console.log("-----getFilesParamsStream-----",resp));

    
    // this.getParameters()
    this.refresh();
  }

  add(e) {
    console.log(e)
  }
  
  filterFmFiles(uFilter:boolean = true) {
    this.usedFilter = uFilter;
    this.page = 1;
    this.files = [];
    this.getFilteredFiles(this.page, this.form.value.location);
  }

  indicator() {

    let paramsData = {...this.globalFilterFmFiles}
    delete paramsData.location_all;
    delete paramsData.count;
    delete paramsData.location;
    delete paramsData.operation_status_id;
    delete paramsData.task_id;
    delete paramsData.task_operation_id;
    delete paramsData.order_by;
    delete paramsData.group_by;

    console.log("paramsData", paramsData);
    console.log("Object.values(paramsData)", Object.values(paramsData));
    console.log("Object.values(paramsData).filter(x => x != '' && !!x)", Object.values(paramsData).filter(x => x != "" && !!x));
    return Object.values(paramsData).filter(x => x != "" && !!x).length > 0
  }

  getGlobalFiles() {
    console.log("getGlobalFiles")
    if (this.subFiles) {
      this.subFiles.unsubscribe()
    }

    let params = {...this.globalFilterFmFiles}
    delete params.operation_status_id;
    // if (!!params.operation_reminder_id) {
    //   if (params.operation_reminder_id.length > 0) {
    //     params.operation_reminder_id = params.operation_reminder_id.join(",")
    //   } else {
    //     delete params.operation_reminder_id
    //   }
    // }



    if (!this.indicator()) {
      params.location = params.location_all;

      delete params.location_all;
    }

    console.log("params", params);


    this.isLoad = true;
    this.subFiles = this.fileService.getGlobalFileData(this.page, this.company_id, Object.assign(params , {summary: 1, order_by: this.sortValue})).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        
        if (this.pagination['currentPage'] == 1) {
          if (this.filesContainer && this.filesContainer['hostElementRef'] && this.filesContainer['hostElementRef'].nativeElement) {
            this.filesContainer['hostElementRef'].nativeElement.scroll(0,0)
          }
          this.files = [];
          this.filesFilesize = el.headers.get('x-summary-filesize');
        }
      }),
      map(el => el.body),
      tap(arr => {
        arr.forEach(file => {
          if (file.parameterValuesToTask) {
            file.primaryTags = file.parameterValuesToTask.filter(x => !!x.is_primary)
          } else {
            file.primaryTags = []
          }
        })
      })
    ).subscribe(resp => {
      console.log("getGlobalFiles", resp)
      // this.folderFilesize = this.folderFilesize + resp.map(n => n.filesize).reduce((acc, number) => acc + number, 0)
      this.files.push(...resp);
      this.page = this.page + 1;
      this.isLoad = false;
    })
  }

  getFilteredFiles(page, location) {
    if (this.subFiles) {
      this.subFiles.unsubscribe()
    }

    let params = {...this.fmFilter.value}
    console.log("params", params)
    if (params.operation_reminder_id && params.operation_reminder_id.length > 0) {
      params.operation_reminder_id = params.operation_reminder_id.join(",")
    } else {
      delete params.operation_reminder_id
    }
    if (params.file_parameter_value_id && params.file_parameter_value_id.length > 0) {
      let val = JSON.parse(JSON.stringify(params.file_parameter_value_id));
      if (params.file_parameter_value_id.includes(-2)) {
        val.splice(val.indexOf(-2), 1);
        params.is_document = 1;
        params.content_type = "reports-json-auto-post";
      } 
      if (params.file_parameter_value_id.includes(-3)) {
        val.splice(val.indexOf(-3), 1);
        params.is_document = 1;
        params.content_type = "";
      }
      if (params.file_parameter_value_id.includes(-4)) {
        val.splice(val.indexOf(-4), 1);
        params.is_live_stream = 1;
      }
      if (params.file_parameter_value_id.includes(-1)) {
        val.splice(val.indexOf(-1), 1);
        params.is_document = 1;
      }
      if (val && val.length) {
        params.file_parameter_value_id = val.join(',')
      }
    } else {
      delete params.file_parameter_value_id
    }

    params.order_by = this.sortValue

    console.log("params", params)

    this.isLoad = true;
    this.subFiles = this.fileService.getFileData(page, this.company ? this.company.id : this.company_id, Object.assign({task_id: this.task_id, task_operation_id: this.task_operation_id, [(!this.is_global && location != '/') || Object.values(params).filter(x => x.toString() != '').length == 1 ? 'location' : 'location_all']: (!this.is_global && location) != '/' ? location : '/'}, params)).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(el => el.body),
      tap(arr => {
        arr.forEach(file => {
          if (file.parameterValuesToTask) {
            file.primaryTags = file.parameterValuesToTask.filter(x => !!x.is_primary)
          } else {
            file.primaryTags = []
          }
        })
      })
    ).subscribe(resp => {
      console.log("filterFmFiles", resp)
      // this.folderFilesize = this.folderFilesize + resp.map(n => n.filesize).reduce((acc, number) => acc + number, 0)
      this.files.push(...resp);
      this.page = this.page + 1;
      this.isLoad = false;
    })
  }

  removeValueFromMultiple(key, value) {
    let arr = this.fmFilter.value[key];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    this.fmFilter.patchValue({
      [key]: arr
    })
    this.filterFmFiles()
  }

  filterClose() {
    this.subFilterMenu && this.subFilterMenu.unsubscribe();
    if (this.filterOverlayRef) {
      this.filterOverlayRef.dispose();
      this.filterOverlayRef = null;
    }
  }

  filterOpen({ x, y }: MouseEvent) {
    this.is_filter_open = !this.is_filter_open;
    // console.log("filterOpen", {x,y})
    // this.filterClose();
    // const positionStrategy = this.overlay.position()
    //   .flexibleConnectedTo({ x, y })
    //   .withPositions([
    //     {
    //       originX: 'start',
    //       originY: 'bottom',
    //       overlayX: 'start',
    //       overlayY: 'top',
    //     }
    //   ]);

    // this.filterOverlayRef = this.overlay.create({
    //   positionStrategy,
    //   scrollStrategy: this.overlay.scrollStrategies.close()
    // });


    // this.filterOverlayRef.attach(new TemplatePortal(this.filterMenu, this.viewContainerRef, {
    //   $implicit: {}
    // }));
   
    
    // this.subFilterMenu = fromEvent<MouseEvent>(document, 'click')
    //   .pipe(
    //     filter(event => {
    //       const clickTarget = event.target as HTMLElement;
    //       return !!this.filterOverlayRef && !this.filterOverlayRef.overlayElement.contains(clickTarget);
    //     }),
    //     take(1)
    //   ).subscribe(() => this.filterClose())

  }

  removeValue(val) {
    if (val == "all") {
      this.fmFilter.patchValue({
        q: '',
        meta_device: '',
        group_id: '',
        is_dir: '',
        task_channel_id: '',
        related_count: '',
        part_of_count: '',
        consist_of_count: '',
        operation_id: '',
        file_parameter_value_id: this.is_global ? [[]] :[],
        is_ready_if_approved: '',
        operation_reminder_id: this.is_global ? [[]] :[],
      })
      // this.filterFmFiles()
      this.page = 1;
      this.onLocationChange(this.form.value.location);
    } else {
      this.fmFilter.patchValue({
        [val]: ''
      })
      // this.filterFmFiles()
      this.page = 1;
      this.onLocationChange(this.form.value.location);
    }
  }

  resetFmFilter() {
    this.usedFilter = false;
    this.fmFilter.patchValue({
      q: '',
      meta_device: '',
      group_id: '',
      is_dir: '',
      task_channel_id: '',
      related_count: '',
      part_of_count: '',
      consist_of_count: '',
      operation_id: '',
      file_parameter_value_id: this.is_global ? [[]] :[],
      is_ready_if_approved: '',
      operation_reminder_id: this.is_global ? [[]] :[],
    })
    this.filterFmFiles(false);
  }

  consoleFiles() {
    console.log(this.files);
    console.log("this.fmFilter.value", this.fmFilter.value);
    console.log(this.globalFilterFmFilesForm.value);
    console.log(this.prevRoute);
    console.log(this.fileRoute);
    
  }

  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.company ? this.company.id : this.company_id}, '200').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getAutos", resp);
        this.taskTemplates = resp;
      })
    )
  }

  getPercentProgress(part, full) {
    return Math.round( (part/full)*100 )
  }

  getTimeEnd(file) {
    return Math.ceil((file?.filesize - file.user_upload_progress) / file?.user_upload_speed_mbit)
  }

  multiMigrate(file) {
    this.close();
    let allFiles = [];
    this.selectedFiles.selected.forEach(id => {
      let exFile;
      if (this.files.find(b => b.id == id)) {
        exFile = this.files.find(b => b.id == id);
      } else if (this.boxFiles.selected.find(b => b.id == id)) {
        exFile = this.boxFiles.selected.find(b => b.id == id)
      }
      if (exFile && exFile.is_dir == 0) {
        allFiles.push(exFile)
      }
    })

    const dialogRef = this.dialog.open(MultiMigrateComponent, {
      data: {
        company: this.company,
        user: this.user,
        selectedFiles: allFiles
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.deSelectAll()
      })
    )
  }

  createTaskBasedFilesV1(file) {
    this.close();
    let file_ids = [];
    if (this.selectedFiles.isSelected(file.id)) {
      file_ids = this.selectedFiles.selected.slice()
    } else {
      file_ids.push(file.id)
    }
    console.log('file_ids', file_ids)

    let task_ids = [];
    if (!!this.task_id) {
      task_ids.push(this.task_id)
    }
    file_ids.forEach(id => {
      if (!task_ids.includes(this.files.find(x => x.id == id).task.id)) {
        task_ids.push(this.files.find(x => x.id == id).task.id)
      }
    })

    const dialogRef = this.dialog.open(SelectTaskTemplateComponent, {
      data: {
        company: this.company,
        user: this.user,
        file_ids: file_ids,
        taskTemplates: this.taskTemplates,
        related: [0, (!task_ids || !task_ids.includes(this.task_id)) ? this.task_id : 0, 0],
        relatedArrs: [[], task_ids, []]
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.deSelectAll()
      })
    )
  }

  createCardsAndPublish(file) {
    this.close();

    let _task = this.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    let allFiles = [];
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
      this.selectedFiles.selected.forEach(id => {
        let exFile;
        if (this.files.find(b => b.id == id)) {
          exFile = this.files.find(b => b.id == id);
        } else if (this.boxFiles.selected.find(b => b.id == id)) {
          exFile = this.boxFiles.selected.find(b => b.id == id)
        }
        if (exFile) {
          allFiles.push(exFile)
        }
      })
    } else {
      allFiles.push(file)
    }

    const dialogRef = this.dialog.open(CardProjectComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      data: {
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        task: _task,
        work: _work,
        allFiles: allFiles
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  openCardProjects() {
    let _task = this.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    const dialogRef = this.dialog.open(CardsProjectsListComponent, {
      panelClass: 'projects_dialog',
      disableClose: true,
      data: {
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        task_id: this.task_id,
        task_operation_id: this.task_operation_id,
        task: _task,
        work: _work,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  createTaskBasedFiles(file) {
    this.close();

    let file_ids = [];
    if (this.selectedFiles.isSelected(file.id)) {
      file_ids = this.selectedFiles.selected.slice()
    } else {
      file_ids.push(file.id)
    }
    console.log('file_ids', file_ids)
    let task_ids = [];
    if (!!this.task_id) {
      task_ids.push(this.task_id)
    }
    file_ids.forEach(id => {
      if (!task_ids.includes(this.files.find(x => x.id == id).task.id)) {
        task_ids.push(this.files.find(x => x.id == id).task.id)
      }
    })
    if ((this.company.permissions.includes("owner") || this.company.permissions.includes("manager") || this.company.permissions.includes("admin")) && file.company_id == this.company_id) {
      this.createTask(this.company, file_ids, file.company_id, task_ids);
      this.close();
    } else {
      this.emplCreateTask(this.company, file_ids, file.company_id, task_ids)
      this.close();
    }
  }

  createTask(company, file_ids, f_c_id?, task_ids?) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: company.id,
          company: company,
          file_ids: file_ids,
          imgRoute: this.imgRoute,
          host: this.host,
          user: this.user,
          f_c_id: f_c_id,
          related: [0, (!task_ids || !task_ids.includes(this.task_id)) ? this.task_id : 0, 0],
          relatedArrs: [0, task_ids, 0]
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.deSelectAll()
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: company.id,
          company: company,
          file_ids: file_ids,
          imgRoute: this.imgRoute,
          host: this.host,
          user: this.user,
          f_c_id: f_c_id,
          related: [0, (!task_ids || !task_ids.includes(this.task_id)) ? this.task_id : 0, 0],
          relatedArrs: [0, task_ids, 0]
        }
      });

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.deSelectAll()
        })
      )
    }

  }

  emplCreateTask(company, file_ids, f_c_id?, task_ids?) {
    const dialogRef = this.dialog.open(EmplCreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: company.id,
        company: company,
        file_ids: file_ids,
        imgRoute: this.imgRoute,
        host: this.host,
        user: this.user,
        f_c_id: f_c_id,
        related: [0, (!task_ids || !task_ids.includes(this.task_id)) ? this.task_id : 0, 0],
        relatedArrs: [0, task_ids, 0]
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.deSelectAll()
      })
    )
  }


  copyLinkToFolder() {
    console.log("copyLinkToFolder")
    console.log(this.crumbs);
    // host + '/file/' + task?.acm + '?path=' + getPathForLink(form.get('location').value)
    if (this.crumbs[this.crumbs.length - 1].target && this.crumbs[this.crumbs.length - 1].target.acm) {
      console.log("copyLinkToFolder 1")
      this._clipboardService.copy(this.origin + '/file/' + this.crumbs[this.crumbs.length - 1].target.acm + '?path=' + this.getPathForLink(this.form.get('location').value))
      this.copyLink("folder");
    } else if (this.files.length != 0) {
      console.log("copyLinkToFolder 2")
      this.attachSubscriptions(
        this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {is_dir: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: this.crumbs.length > 1 ? this.crumbs[this.crumbs.length - 2].location : '/'}).pipe(
          map(x => x.body)
        ).subscribe(res => {
          if (!!res.length && res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)) {
            let targetFolder = res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)
            this._clipboardService.copy(this.origin + '/file/' + targetFolder?.acm + '?path=' + this.getPathForLink(this.form.get('location').value))
            this.copyLink("folder");
          }
        })
      )
    } else {
      console.log("copyLinkToFolder 3")
      let folderData = {
        company_id: this.target_company_id,
        task_id: this.task_id,
        task_operation_id: this.task_operation_id,
        filename: this.crumbs[this.crumbs.length - 1].location.split("/")[this.crumbs[this.crumbs.length - 1].location.split("/").length - 1],
        location: '/',
        is_dir: 1
      }
      this.attachSubscriptions(
        this.fileService.uploadFile(folderData, this.company.id).subscribe(resp => {
          this.crumbs[this.crumbs.length - 1].target = resp
          this._clipboardService.copy(this.origin + '/file/' + resp?.acm + '?path=' + this.getPathForLink(resp.location + '/' + resp.filename))
          this.copyLink("folder");
        }, error => {
          this.layoutService.showSnackBar({name: marker("Folder link")}, marker("Doesn't exist (The folder is not yet in the file manager)"), SnackBarItem)
        })
      )
    }
  }

  refresh() {
    this.refreshSub = this.refreshService.getRefreshFiles().pipe(
      switchMap(resp => {
        console.log("getRefreshFiles resp", resp);
        console.log("getRefreshFiles resp", this.fileRoute.value);
        if (resp.task_id == this.fileRoute.value.task_id && resp.task_operation_id == this.fileRoute.value.task_operation_id) {
          console.log("getRefreshFiles file DATA -------------------------", resp)
          console.log("getRefreshFiles crumbs ----------------------------", this.crumbs)
          console.log('getRefreshFiles location --------------------------', this.form.value.location)
  
          let folderNamePaths = resp.folder.path.split('/');
          console.log('folderNamePaths', folderNamePaths)
          let folderName = folderNamePaths[folderNamePaths.length - 1]
          console.log('folderName', folderName)
          
          if (this.form.value.location != resp.folder.path && this.files.filter(x => x.filename == folderName).length == 0) {
            return this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {task_id: this.task_id, task_operation_id: this.task_operation_id, location: this.form.value.location, is_dir: "1"}, "200")
            .pipe(
              map(b => b.body),
              tap(res => {
                console.log("getRefreshFolder +++++++++++++++++", res)
                res.forEach(dir => {
                  if (this.files.filter(x => x.id == dir.id).length == 0) {
                    this.files.push(dir);
                  }
                })
              }),
              map(b => resp),
            )
          } else {
            if (this.files.filter(x => !x.is_uploaded && x.filename == resp.data.filename).length != 0) {
              return this.fileService.getTargetFile(resp.data.upload_url.id, resp.company_id).pipe(
                tap(xFile => {
                  resp.user.name = resp.user.display_name
                  xFile.user = resp.user;
                  xFile.createdEmployee = resp.user;
                  if (xFile.parameterValuesToTask) {
                    xFile.primaryTags = xFile.parameterValuesToTask.filter(x => !!x.is_primary)
                  } else {
                    xFile.primaryTags = []
                  }
                  this.files.splice(this.files.indexOf(this.files.find(y => y.id == resp.data.upload_url.id)), 1, xFile)
                }),
                map(b => resp)
              )
            } else {
              return of(resp)
            }
          }
        } else {
          return of(resp)
        }
      })
    ).subscribe(resp => {
      console.log("getRefreshFiles file RESULT =======================", resp)

      // this.fileService.getFileData(page, this.company ? this.company.id : this.company_id, {task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: location}).pipe(
      //   tap(el => {
      //     this.pagination = {
      //       'pageCount': el.headers.get('x-pagination-page-count'),
      //       'perPage': el.headers.get('x-pagination-per-page'),
      //       'totalCount': el.headers.get('x-pagination-total-count'),
      //       'currentPage': el.headers.get('x-pagination-current-page'),
      //     }
      //   }),
      //   map(el => el.body),
      // ).subscribe(resp => {
      //   console.log("getFiles", resp)
      //   this.files.push(...resp);
      //   this.page = this.page + 1;
      //   this.isLoad = false;
      // })
    })
    
  }

  

  refreshFileTags() {
    this.refreshFileTagsSub = this.refreshService.getRefreshFileTags().pipe(
      concatMap(files => {
        return concat(...files.map(file => {
          let tagsFilter:any = {task_id: file.task_id, task_operation_id: file.task_operation_id ? file.task_operation_id : 0, discussion_id: file.discussion_id ? file.discussion_id : 0, file_id: file.id}

          if (file.company_id != this.company_id) {
            tagsFilter.partner_company_id = file.company_id
          }

          return this.parametersService.getTargetParameters(this.company_id, tagsFilter).subscribe(res => {
            file.parameterValuesToTask = res;
            if (file.parameterValuesToTask) {
              file.primaryTags = file.parameterValuesToTask.filter(x => !!x.is_primary)
            } else {
              file.primaryTags = []
            }
            console.log("refreshFileTags", file.tags)
          })
        })).pipe(last(),map(x => files))
      }),
    ).subscribe(resp => console.log("-----refreshFileTagsSub-----",resp));
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id') ? this.activatedRoute.snapshot.queryParamMap.get('company_id') : this.initCompanyId).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.is_global) {
      if (this.initCompanyId) {
        this.company_id = this.initCompanyId
      }
      if (this.initData && this.initData.task) {
        this.target_company_id = this.initData.task.company_id
      }
    }

    if (this.is_global) {
      console.log("FILE MANAGER changes !!!!", changes)
      if (changes.company && changes.company.currentValue) {
        this.company = changes.company.currentValue
      }
      if (changes.globalFilterFmFiles && changes.globalFilterFmFiles.currentValue && this.company_id) {
        this.page = 1;
        this.pagination = undefined;
        this.getGlobalFiles();
        this.getGlobalFilesCount();
        this.getGlobalFoldersCount();
      }
    } else if (this.useOut) {
      if (Object.keys(changes).length == 1 && changes.hasOwnProperty('selectedFileIds')) {
        return
      }
      // console.log("FILE MANAGER changes !!!!", changes)
      if ((!!changes.initData && !!changes.initData.previousValue && (!changes.initData.previousValue.work || changes.initData.previousValue.work.id != changes.initData.currentValue.work.id))) {
        this.initCounter = 1;
        this.systemDirs = ['sources', 'to_approve', 'ready_files', 'approved', 'project', 'trash'];
        this.crumbs = [{
          name: "Root",
          location: "/"
        }];
        this.fileRoute.patchValue({
          company_id:  this.initData && this.initData.task ? this.initData.task.company_id : this.initData.company.id,
          task_id: this.initData && this.initData.task ? this.initData.task.id : 0,
          task_operation_id: !!this.initData.work ? this.initData.work.id : 0,
          profile_id: 0
        })
        this.getRootDirs();
      } else if (changes.initData && changes.initData.previousValue && (!changes.initData.previousValue.work || changes.initData.previousValue.work.status_id != changes.initData.currentValue.work.status_id || changes.initData.previousValue.work.operation_id != changes.initData.currentValue.work.operation_id)) {
        console.log("IN ASDQWEQWE!@#!#!@#")
        this.getTask();
        if (this.work) {
          this.work = changes.initData.currentValue.work
        }
      } else if (changes.initLocation && changes.initLocation.previousValue && changes.initLocation.previousValue != changes.initLocation.currentValue) {
        this.initCounter = 1;
        this.systemDirs = ['sources', 'to_approve', 'ready_files', 'approved', 'project', 'trash']
        // this.crumbs = [{
        //   name: "Root",
        //   location: "/"
        // }];
        // if (this.crumbs.filter(x => x.location == this.initLocation).length == 0) {
        //   this.crumbs.push({
        //     name: this.initLocation.replace("/", ""),
        //     location: this.initLocation
        //   })
        // }
        if (this.initLocation == '/') {
          this.crumbs = [{
            name: "Root",
            location: "/"
          }]
        } else {
          this.crumbs = []
          this.initLocation.split('/').forEach(val => {
            if (val === '') {
              this.crumbs.push({
                name: "Root",
                location: "/"
              })
            } else {
              this.crumbs.push({
                name: val,
                location: (this.crumbs[this.crumbs.length - 1].location == '/' ? this.crumbs[this.crumbs.length - 1].location : this.crumbs[this.crumbs.length - 1].location + '/') + val
              })
            }
          })
          this.form.patchValue({
            location: !!this.initLocation ? this.initLocation : '/'
          })
        }
        this.getRootDirs();
      }
    }
  }

  getChannelContent() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelContent(this.company_id, this.task_id, this.fileRoute.value.profile_id).pipe(
        switchMap(results => {
          console.log(results);
          if (results.filter(x => x.is_uploaded == 1 && !!x.channelAccount).length > 0) {
            return forkJoin(results.filter(x => x.is_uploaded == 1 && !!x.channelAccount).map(u => this.connectedAppsService.getYouTubeContentData(u.id).pipe(
              tap(value => {
                console.log(value)
                u.youTubeData = value
              })
            ))).pipe(map(() => results))
          } else {
            return of(results)
          }
        })
      ).subscribe(resp => {
        this.channelFiles = resp;
        if (resp.filter(x => x.uploading_error == "No YouTube `ChannelAccount` to upload to").length > 0) {
          this.layoutService.showSnackBar({name: ''}, marker("Upload is failed. Check channel authorization in the Reports App."), SnackBarItem)
        }
        this.checkUploadProgress();
        console.log("this.channelFiles", this.channelFiles)
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getYouTubeData(item) {
    this.attachSubscriptions(
      this.connectedAppsService.getYouTubeContentData(item.id).subscribe(resp => {
        console.log("getYouTubeData single", resp)
        item.youTubeData = resp
      })
    )
  }

  checkUploadProgress() {
    this.uploadProgress = interval(2000).pipe(
      switchMap(() => {
        let arr = [];
        if (this.fileRoute.value.profile_id != 0) {
          arr.push(...this.channelFiles.filter(x => x.is_ready_to_upload == 1 && x.is_uploaded == 0 && x.uploading_error == ''))
        }
        return arr.length ? forkJoin(arr.map(u => this.connectedAppsService.getChannelContentItem(u.id))) : of(false)
      }),
      takeWhile(it => it != false)
    ).subscribe((resp:any) => {
      console.log(resp)
      if (resp) {
        resp.forEach(item => {
          this.channelFiles.find(b => b.id == item.id).is_uploaded = item.is_uploaded;
          this.channelFiles.find(b => b.id == item.id).uploading_error = item.uploading_error;
          this.channelFiles.find(b => b.id == item.id).uploading_progress = item.uploading_progress;
          this.channelFiles.find(b => b.id == item.id).is_uploading = item.is_uploading;
          if (item.is_uploaded == 1) {
            this.getYouTubeData(this.channelFiles.find(b => b.id == item.id))
          }
        });
      }
    })
  }

  
  checkIsManager(task, company, _user) { 
    return (task && task.managers && task.managers.filter(x => x.user_id == _user.id).length > 0) || (task && task.group && task.group.managers.filter(x => x.user_id == _user.id).length > 0) || company.permissions.includes('admin') || company?.permissions.includes('manager'); 
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id || this.company.id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  itemSelected(e) {
    console.log("itemSelected", e)
    if (e.filename != 'sources' && e.filename != 'to_approve' && e.filename != 'ready_files' && e.filename != 'project' && e.filename != 'trash') {
      this.selectedFiles.select(e.id);
      console.log("this.selectedFiles itemSelected", this.selectedFiles.selected)
    }
  }
  
  itemDeselected(e) {
    // if (!e.dragged) {
    //   this.selectedFiles.deselect(e.id);
    // }
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  dtsMouseDown(e) {
    console.log(e.target.className)
    if (e.target && e.which == 1 && typeof e.target.className == "string" && e.target.className.indexOf("files_wrp") != -1) {
      console.log(e,"dtsMouseDown");
      this.deSelectAll();
    }
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    console.log(this.filesContainer['hostElementRef'].nativeElement);
    this.attachSubscriptions(
      fromEvent(this.filesContainer['hostElementRef'].nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop <=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
        if (this.is_global) {
          console.log("SCROLL",this.page)
          console.log("SCROLL",this.pagination['pageCount'])
          if (this.page <= this.pagination['pageCount']) {
            this.getGlobalFiles();
          }
        } else {
          if (!this.usedFilter) {
            if (this.page <= this.pagination['pageCount']) {
              this.getFiles(this.page, this.form.value.location);
            }
          } else {
            if (this.page <= this.pagination['pageCount']) {
              this.getFilteredFiles(this.page, this.form.value.location);
            }
          }
        }
      })
    )
  }


  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._ngx_adapter.setLocale(resp.active)
        this._adapter.setLocale(resp.active);
      })
    )
  }

  toggleMode() {
    this.is_list = !this.is_list
    this.sm.localStorageSetItem('is_list', this.is_list)
  }
  
  selectSchedule(e:MatRadioChange) {
    console.log(e);
  }

  openProjects() {
    let _task = this.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    const dialogRef = this.dialog.open(ProjectsInterfaceComponent, {
      panelClass: 'projects_dialog',
      disableClose: true,
      data: {
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        is_mobile: this.is_mobile,
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        task_id: this.task_id,
        task_operation_id: this.task_operation_id,
        task: _task,
        work: _work,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  

  outletClick(outlet, file) {
    console.log("outletClick", outlet, file);
    this.close();

    // if (outlet.channel.platform_id != 4) {
    //   this.attachSubscriptions(
    //     this.connectedAppsService.addChannelContent({
    //       company_id: this.company_id,
    //       task_id: this.task.id,
    //       channel_id: outlet.channel.id,
    //       content_file_id: file.id,
    //     }).subscribe(res => {
    //       console.log(res)
    //     })
    //   )
    //   return
    // }


    if ((outlet.content_status_id == 1 || outlet.content_status_id == 13) && !outlet.content_url && !outlet.w8publish) {
      console.log('create in selected')
      let _task = this.task || file.task;
      let _work;
      if (_task && _task.operations) {
        _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
      }
      const dialogRef = this.dialog.open(PublishToOutletComponent, {
        disableClose: true,
        data: {
          host: this.host,
          imgRoute: this.imgRoute,
          activeLang: this.activeLang,
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          target_company_id: this.target_company_id,
          profile: outlet,
          file: file,
          task: _task,
          work: _work,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
          outlet.w8publish = true;
        })
      )
      
    } else {
      console.log('create in new outlet same as selected')
      this.attachSubscriptions(
        this.taskService.addProfile({
          task_id: this.task_id,
          channel_id: outlet.channel.id,
          content_type_id: outlet.content_type_id,
          content_status_id: 1
        }, this.company_id).pipe(
          switchMap(res => {
            return this.taskService.getTaskForManager(this.company ? this.company.id : this.company_id, this.task_id).pipe(
              map(el => {
                if (el[0]) {
                  if (this.task_operation_id != "0") {
                    el[0].operations.find(x => x.id == this.task_operation_id).is_active = true;
                    el[0].is_open = true;
                  } else {
                    el[0].is_open = false;
                  }
                  return casesModel(el, [], 'update').arr[0]
                } else {
                  return false
                }
              }),
              switchMap(resp => {
                let expPermFilter:any = {task_id: this.task_id}
                if (this.company_id != this.target_company_id) {
                  expPermFilter.partner_company_id = this.target_company_id
                }
                return this.taskService.getExpandPermUrls('1', this.company_id, expPermFilter, '100').pipe(
                  tap(rslt => {
                    resp.uniqueChannels = [];
                    rslt.sort((a, b) => {
                      if (a.content_status_id === 13 && b.content_status_id !== 13) {
                        return -1;
                      } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                        return -1;
                      } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                        return 1;
                      } else {
                        return 0;
                      }
                    });
                    rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
                      if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                        if (resp.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                          resp.uniqueChannels.push(channel)
                        } else {
                          if (!([1, 13].includes(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                            resp.uniqueChannels.splice(resp.uniqueChannels.indexOf(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                          }
                        }
                      }
                    });
                    // getProfile
                    console.log("resp.uniqueChannels", resp.uniqueChannels)
                  }),
                  map(() => resp)
                )
              }),
              tap(resp => {
                this.task = resp;
                this.target_company_id = this.task.company_id;
                this.work = this.task.operations.filter(z => z.id == this.fileRoute.value.task_operation_id).length > 0 ? this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : undefined
              }),
              map((resp) => resp.channels.find(x => x.id == res.id))
            )
          })
        ).subscribe(resOutlet => {
  
          let _task = this.task || file.task;
          let _work;
          if (_task && _task.operations) {
            _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
          }
          const dialogRefDialog = this.dialog.open(PublishToOutletComponent, {
            disableClose: true,
            data: {
              host: this.host,
              imgRoute: this.imgRoute,
              activeLang: this.activeLang,
              user: this.user,
              company: this.company,
              company_id: this.company_id,
              target_company_id: this.target_company_id,
              profile: resOutlet,
              file: file,
              task: _task,
              work: _work,
            }
          });
      
          // this.attachSubscriptions(
          //   dialogRefDialog.afterClosed().subscribe(result => {
          //     console.log(result)
          //   })
          // )
        })
      )

    }
  }

  open({ x, y }: MouseEvent, file) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    console.log("open 1 this.task", this.task)

    if (this.task && this.task.uniqueChannels && this.task.uniqueChannels.length && this.profiles && file && file.is_dir == 0 && file.parameterValuesToTask && file.parameterValuesToTask.length) {
      this.task.uniqueChannels.sort((a,b) => {
        let aTags = this.getProfile(a.channel_id).parameterValuesToChannel;
        let bTags = this.getProfile(b.channel_id).parameterValuesToChannel;
        let fileTags = file.parameterValuesToTask;

        if (fileTags.filter(u => aTags.filter(x => x.parameter_value_id == u.parameter_value_id).length).length && !fileTags.filter(u => bTags.filter(x => x.parameter_value_id == u.parameter_value_id).length).length) {
          return -1;
        } 

        if (fileTags.filter(u => bTags.filter(x => x.parameter_value_id == u.parameter_value_id).length).length && !fileTags.filter(u => aTags.filter(x => x.parameter_value_id == u.parameter_value_id).length).length) {
          return 1;
        } 
        
        return 0;
      })
    }

    console.log("open 2 this.task", this.task)

    if (!file.hasOwnProperty('reports_type')) {
      if (document.body.offsetWidth - x > 650) {
        file.open_to_left = false
      } else {
        file.open_to_left = true
      }
    }

    if (file.task_operation_id) {
      this.attachSubscriptions(
        this.taskService.getOperationReminder(this.company_id, {task_operation_id: file.task_operation_id}).subscribe(resp => {
          file.checklist = resp.body;
        })
      )
    }

    if (file.reports_type && file.reports_type == 'outFiles') {
      this.overlayRef.attach(new TemplatePortal(this.outFileMenu, this.viewContainerRef, {
        $implicit: {}
      }));
    } else {
      this.overlayRef.attach(new TemplatePortal(this.fileMenu, this.viewContainerRef, {
        $implicit: file
      }));
    }
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  getChecklists() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId).subscribe(resp => {
        this.checklists = resp.body;
        this.checklists$.next(this.checklists.slice());

        console.log("getChecklists", this.checklists)
      })
    )
  }
  
  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id) && this.operationsValues.find(el => el.id == id).translation ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  toggleTask(e, task) {
    e.preventDefault();
    e.stopPropagation();
    task.is_open = !task.is_open;
  }

  getTask() {
    this.attachSubscriptions(
      this.taskService.getTaskForManager(this.company ? this.company.id : this.company_id, this.task_id)
      .pipe(
        map(el => {
          if (el[0]) {
            if (this.task_operation_id != "0") {
              el[0].operations.find(x => x.id == this.task_operation_id).is_active = true;
              el[0].is_open = true;
            } else {
              el[0].is_open = false;
            }
            return casesModel(el, [], 'update').arr[0]
          } else {
            return false
          }
        }),
        switchMap(resp => {
          if (resp && resp.channels && resp.channels.length) {
            let expPermFilter:any = {task_id: this.task_id}
            if (this.company_id != this.target_company_id) {
              expPermFilter.partner_company_id = this.target_company_id
            }
            return this.taskService.getExpandPermUrls('1', this.company_id, expPermFilter, '100').pipe(
              tap(rslt => {
                resp.uniqueChannels = [];
                rslt.sort((a, b) => {
                  if (a.content_status_id === 13 && b.content_status_id !== 13) {
                    return -1;
                  } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                    return -1;
                  } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                    return 1;
                  } else {
                    return 0;
                  }
                });
                rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
                  if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                    if (resp.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                      resp.uniqueChannels.push(channel)
                    } else {
                      if (!([1, 13].includes(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                        resp.uniqueChannels.splice(resp.uniqueChannels.indexOf(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                      }
                    }
                  }
                });
                console.log("resp.uniqueChannels", resp.uniqueChannels)
              }),
              map(() => resp),
            )
          } else {
            return of(resp)
          }
        }),
        tap(resp => {
          console.log("@RESP", resp)
          this.task = resp;
          this.target_company_id = this.task.company_id;
          this.work = this.task.operations.filter(z => z.id == this.fileRoute.value.task_operation_id).length > 0 ? this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : undefined
        }),
        switchMap(res => {
          let arr = [];

          if (res.consist_of_count) {
            arr.push(
              this.taskService.getCardsExpand('1', this.company_id, {part_of_task_id: this.task.id}, '100').pipe(
                tap(tasksRes => {
                  this.task.partOfTasks = [...tasksRes];
                })
              )
            )
          } else {
            this.task.partOfTasks = []
          }
      
          if (this.task.part_of_count) {
            arr.push(
              this.taskService.getCardsExpand('1', this.company_id, {consist_of_task_id: this.task.id}, '100').pipe(
                tap(tasksRes => {
                  this.task.consistOfTasks = [...tasksRes];
                })
              )
            )
          } else {
            this.task.consistOfTasks = []
          }
          if (this.task.related_count) {
            arr.push(
              this.taskService.getCardsExpand('1', this.company_id, {related_task_id: this.task.id}, '100').pipe(
                tap(tasksRes => {
                  this.task.relatedTasks = [...tasksRes];
                })
              )
            )
          } else {
            this.task.relatedTasks = []
          }
          if (arr.length) {
            return forkJoin([...arr]).pipe(map(() => res))
          } else {
            return of(res)
          }
        }),
        switchMap(resp => {
          // && (this.form.value.location.indexOf('to_approve') != -1 || this.form.value.location.indexOf('approved') != -1 || this.form.value.location.indexOf('ready_files') != -1)
          // if (this.work && this.work.mainEmployees && this.work.mainEmployees && this.work.mainEmployees.filter(x => x.employee && x.employee.user_id == this.user.id).length > 0) {
            return this.taskService.getOperationReminder(this.company_id, {task_operation_id: this.work.id}).pipe(
              map(x => x.body),
              switchMap(val => {
                let tagsFilter:any = {task_id: this.work.task_id, task_operation_id: this.work.id}

                if (this.task.company_id != this.company_id) {
                  tagsFilter.partner_company_id = this.task.company_id
                }
                return this.parametersService.getTargetParameters(this.company_id || this.task.company_id, tagsFilter).pipe(
                  tap(b => {
                      let _checklist = val
                      let _jobParameters = b
                      this.work.checklists = [];
                      _checklist.forEach(element => {
                        if (element.exceptParameterValues.length > 0) {
                          if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !_jobParameters.map(m => m.parameter_value_id).includes(n))) {
                            this.work.checklists.push(element)
                          }
                        } else if (element.toParameterValues.length > 0) {
                          if (element.toParameterValues.map(b => b.parameter_value_id).some(n => _jobParameters.map(m => m.parameter_value_id).includes(n))) {
                            this.work.checklists.push(element)
                          }
                        } else {
                          this.work.checklists.push(element)
                        }
                      });
                  
                      // if (this.work.checklists.length == 0 && this.work.checklists.filter(b => !!b.status).length == this.work.checklists.length) {
                      //   this.work.hasChecklistError = true;
                      // } else {
                      //   if (!!this.work.data.operation_reminder_id && this.work.checklists.find(x => x.id == this.work.data.operation_reminder_id)) {
                      //     this.work.selectedCheck = this.work.checklists.find(x => x.id == this.work.data.operation_reminder_id)
                      //   }
                      // }
                      // if (this.work.checklists.length) {
                      //   this.work.canReady = true;
                  
                      //   // if (this.dialog.openDialogs.filter(x => x.componentInstance.data.is_set_propperties).length == 0) {
                      //   //   this.dialog.open(FilePropertiesComponent, {
                      //   //     panelClass: 'file_properties',
                      //   //     autoFocus: false,
                      //   //     data: {
                      //   //       is_set_propperties: true,
                      //   //       filesNeedToSetProperties: this.filesNeedToSetProperties,
                      //   //       company: this.company,
                      //   //       user: this.user,
                      //   //     }
                      //   //   });
                      //   // }
                      // }
                  }),
                  map(() => resp)
                )
              }),
              catchError(error => {
                return of(error);
              }),
            )
          // } else {
          //   return of(resp)
          // }
        })
      ).subscribe(resp => {
        console.log("this.task", this.task)
        console.log("this.work NEW", this.work)
        this.foldersPermissions();
        
      }, error => {
        console.log("this.task error", this.task)
        console.log("this.work NEW error", this.work)
        this.foldersPermissions();
      })
    )
  }

  canShow(folder, file) {
    if (folder) {
      if (this.foldersPerm.filter(x => x.location == folder.location && x.cant_read == false).length > 0 || (folder.location && folder.filename && this.folders.filter(x => !!x.is_sys && x.location == folder.location+folder.filename).length == 0)) {
        return true
      } else {
        return false
      }
    } else if (file) {
      if (this.foldersPerm.filter(x => x.location == file.location+file.filename && x.cant_read == false).length > 0 || this.folders.filter(x => !!x.is_sys && x.location == file.location+file.filename).length == 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  foldersPermissions() {
    if (!this.is_global && this.company && this.company.employees && this.task && !this.company.permissions.includes('owner') && !this.company.permissions.includes('manger') && !this.company.permissions.includes('admin')) {
      if (!!this.work) {
        // FOR JOB
        let parsedData;
        if (this.company.employees[0].employeeSetting && this.company.employees[0].employeeSetting.file_manager) {
          parsedData = JSON.parse(this.company.employees[0].employeeSetting.file_manager);
        } else if (this.company.employees[0].employeeToDepartmentPositions && this.company.employees[0].employeeToDepartmentPositions.length) {
          let arr = [];
          this.company.employees[0].employeeToDepartmentPositions.forEach(depPos => {
            let x;
            if (depPos.departmentPosition.employee_setting) {
              x = JSON.parse(depPos.departmentPosition.employee_setting)
            } else if (depPos.department.employee_setting) {
              x = JSON.parse(depPos.department.employee_setting)
            }
            console.log("depPos", x, depPos)
            if (!!x) {
              arr.push(...x)
            }
          });
          parsedData = arr
        }
        console.log("parsedData #tag", parsedData)

        // check job types and card types 
        // if (parsedData.filter(x => x.task_statuses_ids.includes(this.task.status_id) || x.task_statuses_ids.includes(-2)).length > 0 && parsedData.filter(x => x.task_operation_operations_ids.includes(this.work.operation_id) || x.task_operation_operations_ids.includes(-2)).length > 0 && parsedData.filter(x => x.directories.length).length > 0) {
        
        if (parsedData) {
          // filter card types
          parsedData.filter(x => x.task_statuses_ids.includes(this.task.status_id) || x.task_statuses_ids.includes(-2)).forEach(pData => {
            // filter job types
            if (pData.task_operation_operations_ids.length == 0 || pData.task_operation_operations_ids.includes(this.work.operation_id) || pData.task_operation_operations_ids.includes(-2)) {
              // filter job statuses
              if (pData.task_operation_statuses_ids.length == 0 || pData.task_operation_statuses_ids.includes(-2) || this.task.operations.filter(x => pData.task_operation_statuses_ids.includes(x.status_id)).length > 0) { 
                if (pData.is_manager == 2 || this.work.employees.filter(x => x.employee_id == this.company.employees[0].id && x.is_manager == pData.is_manager).length > 0) {
                  if (this.foldersPerm.length == 0) {
                    this.foldersPerm = pData.folders
                  } else {
                    pData.folders.forEach(dir => {
                      if (dir.cant_write == true && this.foldersPerm.find(x => x.id == dir.id).cant_write == false) {
                        this.foldersPerm.find(x => x.id == dir.id).cant_write = dir.cant_write
                      }
                      if (dir.cant_read == true && this.foldersPerm.find(x => x.id == dir.id).cant_read == false) {
                        this.foldersPerm.find(x => x.id == dir.id).cant_read = dir.cant_read
                      }
                    });
                  }
                }
              }
            }
          });
        }
            
  
          console.log("JOB foldersPermissions parsedData", parsedData)
          console.log("JOB foldersPermissions this.folders", this.folders)
          console.log("JOB foldersPermissions this.foldersPerm", this.foldersPerm)
          // }
      } else {

        // FOR CARD
        let parsedData;
        if (this.company.employees[0].employeeSetting && this.company.employees[0].employeeSetting.file_manager) {
          parsedData = JSON.parse(this.company.employees[0].employeeSetting.file_manager);
        } else if (this.company.employees[0].employeeToDepartmentPositions && this.company.employees[0].employeeToDepartmentPositions.length) {
          let arr = [];
          this.company.employees[0].employeeToDepartmentPositions.forEach(depPos => {
            let x;
            if (depPos.departmentPosition.employee_setting) {
              x = JSON.parse(depPos.departmentPosition.employee_setting)
            } else if (depPos.department.employee_setting) {
              x = JSON.parse(depPos.department.employee_setting)
            }
            console.log("depPos", x, depPos)
            if (!!x) {
              arr.push(...x)
            }
          });
          parsedData = arr
        }
        console.log("parsedData #tag", parsedData)
  
          // check job types and card types 
          // if (parsedData.filter(x => x.task_statuses_ids.includes(this.task.status_id) || x.task_statuses_ids.includes(-2)).length > 0 && (parsedData.filter(x => x.task_operation_operations_ids.includes(-2)).length > 0 || this.task.operations.filter(x => parsedData.filter(y => y.task_operation_operations_ids.includes(x.operation_id)).length).length > 0) && parsedData.filter(x => x.directories.length).length > 0) {
  
          if (parsedData) {
            // filter card types
            parsedData.filter(x => x.task_statuses_ids.includes(this.task.status_id) || x.task_statuses_ids.includes(-2)).forEach(pData => {
              // filter job types
              if (pData.task_operation_operations_ids.length == 0 || pData.task_operation_operations_ids.includes(-2) || this.task.operations.filter(x => pData.task_operation_operations_ids.includes(x.operation_id)).length > 0) {
                // filter job statuses
                if (pData.task_operation_statuses_ids.length == 0 || pData.task_operation_statuses_ids.includes(-2) || this.task.operations.filter(x => pData.task_operation_statuses_ids.includes(x.status_id)).length > 0) {
                  if (pData.is_manager == 2 || this.task.operations.filter(x => x.employees.filter(u => u.employee_id == this.company.employees[0].id && u.is_manager == pData.is_manager).length > 0).length > 0) {
                    if (this.foldersPerm.length == 0) { 
                      this.foldersPerm = pData.folders
                    } else {
                      pData.folders.forEach(dir => {
                        if (dir.cant_write == true && this.foldersPerm.find(x => x.id == dir.id).cant_write == false) {
                          this.foldersPerm.find(x => x.id == dir.id).cant_write = dir.cant_write
                        }
                        if (dir.cant_read == true && this.foldersPerm.find(x => x.id == dir.id).cant_read == false) {
                          this.foldersPerm.find(x => x.id == dir.id).cant_read = dir.cant_read
                        }
                      });
                    }
                  }
                }
              }
            });
          }
            
          console.log("CARD foldersPermissions parsedData", parsedData)
          console.log("CARD foldersPermissions this.folders", this.folders)
          console.log("CARD foldersPermissions this.foldersPerm", this.foldersPerm)
          // }
      }
    } else {
      this.foldersPerm = [];
    }
  }

  channelClick(e, profile) {
    e.preventDefault();
    e.stopPropagation()
    this.page = 1;
    this.files = [];
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];
    this.crumbs.push({
      name: profile.channel.name,
      location: profile.channel.name
    })
    this.fileRoute.patchValue({
      task_id: this.task.id,
      profile_id: profile.channel.id
    })
  }

  taskClick(e,t) {
    e.preventDefault();
    e.stopPropagation()
    this.page = 1;
    this.files = [];
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];
    this.fileRoute.patchValue({
      task_id: t.id,
      task_operation_id: 0,
      profile_id: 0
    })
    // this.router.navigate([`/files`], { queryParams: {company_id: this.company_id, task_id: t.id, work_id: 0}});
  }

  goToPrev() {
    this.page = 1;
    this.fileRoute.patchValue(this.prevRoute)
    this.cardSearch.patchValue(false);
    this.task_id = this.prevRoute.task_id
    this.task_operation_id = this.prevRoute.task_operation_id
    this.prevRoute = undefined;
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];
    this.getRootDirs();
  }

  cardClick(e,t) {
    e.preventDefault();
    e.stopPropagation()
    this.page = 1;
    this.files = [];
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];
    
    this.prevRoute = !!this.prevRoute ? this.prevRoute : {...this.fileRoute.value}
    
    this.fileRoute.patchValue({
      task_id: t.id,
      task_operation_id: 0,
      profile_id: 0
    })

    this.task_id = t.id
    this.task_operation_id = 0
    
    this.cardSearch.patchValue(false);
    
    this.getRootDirs();
    // this.router.navigate([`/files`], { queryParams: {company_id: this.company_id, task_id: t.id, work_id: 0}});
  }

  cardJobClick(e,j) {
    e.preventDefault();
    this.page = 1;
    this.files = [];
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];

    this.prevRoute = !!this.prevRoute ? this.prevRoute : {...this.fileRoute.value}
    
    this.fileRoute.patchValue({
      task_id: j.task_id,
      task_operation_id: j.id
    })
    this.cardSearch.patchValue(false);

    this.task_id = j.task_id,
    this.task_operation_id = j.id
    
    this.getRootDirs();
    // this.router.navigate([`/files`], { queryParams: {company_id: this.company_id, task_id: this.task_id, work_id: j.id}});
  }

  jobClick(e,j) {
    e.preventDefault();
    this.page = 1;
    this.files = [];
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];
    this.fileRoute.patchValue({
      task_operation_id: j.id
    })
    this.fileService.changeJob$.next(j)
    // this.router.navigate([`/files`], { queryParams: {company_id: this.company_id, task_id: this.task_id, work_id: j.id}});
  }

  selectAllBox() {
    this.done_all = true;
    this.boxFiles.selected.map(file => {
      if (!this.systemDirs.includes(file.filename)) {
        this.selectedFiles.select(file.id);
      }
    })
  }

  deSelectAllBox() {
    this.done_all = false;
    this.selectedFiles.clear();
  }

  selectAll() {
    this.done_all = true;
    this.files.map(file => {
      if (!this.systemDirs.includes(file.filename)) {
        this.selectedFiles.select(file.id);
      }
    })
  }

  deSelectAll() {
    this.done_all = false;
    this.selectedFiles.clear();
  }

  fileClick(e, file, i) {
    if(e.ctrlKey || e.metaKey) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      if (!this.systemDirs.includes(file.filename)) {
        if (this.selectedDocuments.find(x => x.id == file.id) && this.selectedFiles.isSelected(file.id)) {
          this.selectedFiles.select(file.id);
        } else {
          this.selectedFiles.deselect(file.id);
        }
      }
      return
    }

    if (e.shiftKey) {
      let lastItem:any = this.selectedFiles.hasValue() ? this.files.indexOf(this.files.find(x => x.id == this.selectedFiles.selected[this.selectedFiles.selected.length - 1])) : 0;
      let start:number = lastItem <= this.files.indexOf(file) ? lastItem : this.files.indexOf(file);
      let end:number = lastItem <= this.files.indexOf(file) ? this.files.indexOf(file) : lastItem;

      this.files.slice(start, end + 1).forEach(n=> {
        if (!this.selectedFiles.isSelected(n.id) && !this.systemDirs.includes(n.filename)) {
          this.selectedFiles.select(n.id);
        }
      });
      return;
    }

    if (file.is_dir) {
      this.page = 1;
      this.files = [];

      if (this.is_global) {
        this.form.patchValue({
          location: (file.location == '/' ? file.location : file.location + "/") + file.filename
        })
        let params = {...this.activatedRoute.queryParamMap.source['_value']}

        if (this.indicator()) {
          if (this.crumbs[this.crumbs.length - 1].is_nav) {
            this.crumbs[this.crumbs.length - 1].params = {...this.activatedRoute.queryParamMap.source['_value']}
          } else {
            this.crumbs.push({
              name: 'Search',
              params: {...this.activatedRoute.queryParamMap.source['_value']},
              is_nav: true
            })
          }
        }

        let newParams:any = {
          company_id: params.company_id
        }

        newParams.location_all = this.form.value.location;
        newParams.count = params.count ? +params.count + 1 : 1;
        if (file.task_id) {
          newParams.task_id = file.task_id;
        }

        if (file.task_operation_id) {
          newParams.task_operation_id = file.task_operation_id;
        }

        console.log("is_global newParams", newParams);
        this.router.navigate(['/files'], { queryParams: newParams })
      } else {
        this.form.patchValue({
          location: (this.form.value.location == '/' ? this.form.value.location : this.form.value.location + "/") + file.filename
        })
      }



      this.crumbs.push({
        name: file.filename,
        location: this.form.value.location,
        target: file
      })
    } else {
      if (!this.selectedMode) {
        if (!file.is_uploaded && !file.is_document) {
          this.layoutService.showSnackBar({name: ''}, marker("This file is corrupted."), SnackBarItem)
          this.deSelectAll();
          return
        }

        
        if (file.content_type.indexOf("image") != -1) {
          this.previewVideo(file);
        } else if (file.content_type.indexOf("video") != -1) {
          this.previewVideo(file);
        } else if (file.content_type.indexOf("audio") != -1) {
          this.previewVideo(file);
        }  else if (file.content_type == 'application/pdf') {
          this.previewVideo(file);
        } else if (!!file.thumbnail) {
          this.previewVideo(file);
        } else if (!!file.is_document) {
          this.previewVideo(file);
        } else {
          this.download(file);
        }
      } else {
        // this.selectedFileId = file.id
        if (file.id != this.disableId) {
          this.onSelectFile.emit(file)
        } else {
          this.layoutService.showSnackBar({name: ''}, marker("This file is already selected."), SnackBarItem)
        }
      }
      // window.open(environment.host + file.original, '_blank');
    }
    this.deSelectAll();
  }

  crumbClick(crumb,i) {

    if (this.is_global) {
      if (crumb.name == 'Root') {
        this.crumbs = this.crumbs.slice(0,i+1);
        this.router.navigate(['/files'], {queryParams: {
          company_id: this.company_id,
          location_all: '/',
          count: Math.round(Math.random()*100)
        }})
        return
      }
  
      if (crumb.is_nav) {
        console.log(this.crumbs);
        this.crumbs = this.crumbs.slice(0,i+1);
        this.router.navigate(['/files'], {queryParams: crumb.params})
        return
      }
    }

    this.page = 1;
    this.files = [];
    this.fileRoute.value.profile_id = 0;
    this.form.patchValue({
      location: crumb.location
    })

    console.log('crumb', crumb);
    if (this.is_global) {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      params.location_all = crumb.location
      params.count = params.count ? +params.count + 1 : 1;
      if (crumb.target && crumb.target.task_id) {
        params.task_id = crumb.target.task_id;
      } else {
        delete params.task_id
      }
      if (crumb.target && crumb.target.task_operation_id) {
        params.task_operation_id = crumb.target.task_operation_id;
      } else {
        delete params.task_operation_id
      }
      console.log("is_global params", params);
      this.router.navigate(['/files'], { queryParams: params })
    }

    this.crumbs = this.crumbs.slice(0,i+1);

    console.log(this.crumbs);
  }

  folderClick(folder) {
    this.page = 1;
    this.files = [];
    this.form.patchValue({
      location: folder.location
    })

    this.fileRoute.value.profile_id = 0;

    this.crumbs = [{
      name: "Root",
      location: "/"
    }];

    if (folder.id != -1) {
      this.crumbs.push({
        name: folder.name,
        location: folder.location,
        target: folder
      })
    }

    if (this.is_global) {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      params.location_all = folder.location
      params.count = params.count ? +params.count + 1 : 1;
      if (folder.task_id) {
        params.task_id = folder.task_id;
      } else {
        delete params.task_id
      }
      if (folder.task_operation_id) {
        params.task_operation_id = folder.task_operation_id;
      } else {
        delete params.task_operation_id
      }
      console.log("is_global params", params);
      this.router.navigate(['/files'], { queryParams: params })
    }

  }

  back() {
    this.page = 1;
    this.files = [];
    this.crumbs.pop();
    this.form.patchValue({
      location: this.crumbs[this.crumbs.length -1].location.toString()
    })
    if (this.is_global) {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      params.location_all = this.crumbs[this.crumbs.length -1].location.toString()
      params.count = params.count ? +params.count + 1 : 1;
      console.log("is_global params", params);
      this.router.navigate(['/files'], { queryParams: params })
    }
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  neededData(item, sub?) {
    let locationData = item.location;

    let neededFilter = {task_id: this.is_global ? this.globalFilterFmFiles.value.task_id : this.task_id, is_dir: '1', task_operation_id: this.is_global ? this.globalFilterFmFiles.value.task_operation_id : this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: locationData}
    let arr = [];
    
    if (item.name != 'Root') {
       arr = [
        this.fileService.getFileData("1", this.company_id, neededFilter, '0').pipe(
          tap(el => item.foldersCount = +el.headers.get('x-pagination-total-count')),
        )
      ];
    }
    return forkJoin(arr)
  }

  getRootDirs() {
    this.folders = [
      {
        name: "Root",
        id: -1,
        location: "/",
        over: false,
        is_sys: true,
        rootDir: true
      },
      {
        name: "Sources",
        id: 0,
        location: "/sources",
        over: false,
        is_sys: true,
        rootDir: true
      },
      {
        name: "To Approve",
        id: 1,
        location: "/to_approve",
        over: false,
        is_sys: true,
        rootDir: true
      },
      {
        name: "Ready Files",
        id: 2,
        location: "/ready_files",
        over: false,
        is_sys: true,
        rootDir: true
      },
      {
        name: "Approved",
        id: 3,
        location: "/approved",
        over: false,
        is_sys: true,
        rootDir: true
      },
      {
        name: "Project",
        id: 4,
        location: "/project",
        over: false,
        is_sys: true,
        rootDir: true
      },
      {
        name: "Trash",
        id: 5,
        location: "/trash",
        over: false,
        is_sys: true,
        rootDir: true
      }
    ]

    if (this.foldersExpandSub) {
      this.foldersExpandSub.unsubscribe()
    }
    this.foldersExpandSub = this.fileService.getMobFoldersStream().pipe(
      concatMap(topsInPage => {
        return concat(...topsInPage.map(topInPage => this.neededData(topInPage))).pipe(last(),map(x => topsInPage))
      }),
    ).subscribe(resp => console.log("-----getDeskFoldersStream-----",resp));

    this.attachSubscriptions(
      this.fileService.getFileData(1, this.company_id, {task_id: this.task_id, task_operation_id: this.task_operation_id, location: '/', is_dir: "1"}).pipe(
        map(el => el.body),
        tap(fdrs => {
          fdrs.forEach(element => {
            if (!this.folders.find(x => x.location.indexOf(element.filename) != -1)) {
              element.name = element.filename;
              element.location = "/" + element.filename;
              element.rootDir = true;
              delete element.is_dir;
              this.folders.push(element)
            }
          });
        }),
        tap(fdrs => {this.fileService.mobFolders$.next(this.folders)}),
      ).subscribe(resp => {
        console.log("getRootDirs", this.folders)
      })
    )
  }

  getSubFolders(e, folder) {
    e.preventDefault();
    e.stopPropagation();
    folder.open = !folder.open;

    
    if (folder.open) {
      if (!folder.hasOwnProperty('subFolders')) {
        folder.subFolders = [];
      }
      this.attachSubscriptions(
        this.fileService.getFileData(1, this.company_id, {task_id: this.task_id, task_operation_id: this.task_operation_id, location: folder.location, is_dir: "1"}).pipe(
          map(el => el.body),
          tap(fdrs => {
            fdrs.forEach(element => {
              if (!folder.subFolders.find(x => x.location.indexOf(element.filename) != -1)) {
                element.name = element.filename;
                element.location = element.location + "/" + element.filename;
                element.rootDir = true;
                delete element.is_dir;
                folder.subFolders.push(element)
              }
            });
          }),
          tap(fdrs => {this.fileService.mobFolders$.next(folder.subFolders)}),
        ).subscribe(resp => {
          console.log("folder.subFolders", folder.subFolders)
        })
      )
    }
  }

  fileLocation(file) {
    return file.location.indexOf("/to_approve") != -1
  }

  readyFile(file) {
    this.attachSubscriptions(
      this.fileService.editFile(file.id, {is_ready_if_approved: !!file.is_ready_if_approved ? 0 : 1}, this.company.id).subscribe(resp => {
        file.is_ready_if_approved = resp.is_ready_if_approved;

        if (!!file.is_ready_if_approved && !file.operation_reminder_id && file?.checklist && file?.checklist?.length > 0 && !this.company.permissions.includes('owner') && !this.checkIsManager(this.task, this.company, this.user)) {
          this.selectCheckList(file);
        }
      })
    )
  }

  getGlobalFoldersCount() {
    if (this.subFoldersCount) {
      this.subFoldersCount.unsubscribe()
    }

    let params = {...this.globalFilterFmFiles}
    // if (!!params.operation_reminder_id) {
    //   if (params.operation_reminder_id.length > 0) {
    //     params.operation_reminder_id = params.operation_reminder_id.join(",")
    //   } else {
    //     delete params.operation_reminder_id
    //   }
    // }
    delete params.operation_status_id
    if (!this.indicator()) {
      params.location = params.location_all;

      delete params.location_all;
    }
    console.log("params", params)
    this.subFoldersCount = this.fileService.getGlobalFileData("1", this.company_id, Object.assign(params, {is_dir: '1'}), '0').pipe(
      tap(el => this.foldersCount = el.headers.get('x-pagination-total-count'))
    ).subscribe(resp => {
      console.log(this.foldersCount)
    })
  }

  getGlobalFilesCount() {
    if (this.subFilesCount) {
      this.subFilesCount.unsubscribe()
    }

    let params = {...this.globalFilterFmFiles}
    // if (!!params.operation_reminder_id) {
    //   if (params.operation_reminder_id.length > 0) {
    //     params.operation_reminder_id = params.operation_reminder_id.join(",")
    //   } else {
    //     delete params.operation_reminder_id
    //   }
    // }
    delete params.operation_status_id

    console.log("params", params)
    if (!this.indicator()) {
      params.location = params.location_all;

      delete params.location_all;
    }
    this.subFilesCount = this.fileService.getGlobalFileData("1", this.company_id, Object.assign(params, {is_dir: '0'}), '0').pipe(
      tap(el => this.filesCount = el.headers.get('x-pagination-total-count'))
    ).subscribe(resp => {
      console.log(this.filesCount)
    })
  }

  getFoldersCount(location?) {
    this.subFoldersCount = this.fileService.getFileData("1", this.company ? this.company.id : this.company_id, {task_id: this.task_id, is_dir: '1', task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: location}, '0').pipe(
      tap(el => this.foldersCount = el.headers.get('x-pagination-total-count'))
    ).subscribe(resp => {
      console.log(this.foldersCount)
    })
  }
  getFilesCount(location?) {
    this.subFilesCount = this.fileService.getFileData("1", this.company ? this.company.id : this.company_id, {task_id: this.task_id, is_dir: '0', task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: location}, '0').pipe(
      tap(el => this.filesCount = el.headers.get('x-pagination-total-count'))
    ).subscribe(resp => {
      console.log(this.filesCount)
    })
  }

  getLastCreatedArchive(folder) {
    if (folder.archives && folder.archives.filter(x => x.is_created == 1).length > 0) {
      return folder.archives.filter(x => x.is_created == 1)[folder.archives.filter(x => x.is_created == 1).length - 1]
    } else {
      return false
    }
  }

  removeAllFromBox() {
    this.boxFiles.clear();
    this.boxMode = false;
  }
  
  removeFromBox(file) {
    if (this.selectedFiles.selected.length > 1 && this.selectedFiles.isSelected(file.id)) {
      this.selectedFiles.selected.forEach(id => {
        this.boxFiles.deselect(this.files.find(b => b.id == id))
      })
    } else {
      this.boxFiles.deselect(file)
    }
    this.deSelectAll();

    if (this.boxFiles.isEmpty()) {
      this.boxMode = false;
    }
  }
  
  addToBox(file) {
    if (this.selectedFiles.selected.length > 1 && this.selectedFiles.isSelected(file.id)) {
      this.selectedFiles.selected.forEach(id => {
        this.boxFiles.select(this.files.find(b => b.id == id))
      })
      this.deSelectAll();
    } else {
      this.boxFiles.select(file)
    }
    this.deSelectAll();
  }

  toggleBox() {
    console.log("selectedDocuments", this.selectedDocuments)
    if (!!this.boxMode) {
      this.boxMode = false
    } else {
      if (!this.boxFiles.isEmpty()) {
        this.deSelectAll();
        this.boxMode = true;
        this.filesContainer['hostElementRef'].nativeElement.scrollTop = 0;
        // this.scrollToTop()
      }
    }
  }

  getFilePlatforms(chans) {
    let arr = [];
    chans.forEach(item => {
      if (item.taskChannel && item.taskChannel.channel) {
        let x = {
          id: item.taskChannel.channel.platform_id,
          published: [6,7,9].includes(item.taskChannel.content_status_id) ? 0 : 1,
          non_published: [6,7,9].includes(item.taskChannel.content_status_id) ? 1 : 0
        }
        
        if (!!arr.filter(u => u.id == x.id).length) {
          arr.find(u => u.id == x.id).published += x.published;
          arr.find(u => u.id == x.id).non_published += x.non_published;
        } else {
          arr.push(x)
        }
      }
    });

    return arr;
  }

  getFiles(page?, location?) {
    // console.log("this.task", this.task)
    // console.log("this.work", this.work)
    // console.log("this.initData", this.initData);
    // console.log("this.company_id", this.company_id);
    console.log("getFilesQUERYES", page, this.company ? this.company.id : this.company_id, {summary: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, location: location})
    this.isLoad = true;
    this.subFiles = this.fileService.getFileDataWithTags(page, this.company ? this.company.id : this.company_id, {summary: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: location, order_by: this.sortValue}).pipe(
      tap(el => {
        if (this.page == 1) {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }

        console.log("el.headers", el.headers)

        if (this.pagination['currentPage'] == 1) {
          this.filesFilesize = el.headers.get('x-summary-filesize')
        }
      }),
      map(el => el.body),
      map(arr => {
        if (this.only) {
          return arr.filter(u => u.is_dir == 1 || this.understandFileType(u.content_type) == this.only || (this.only == 'is_document' && u.is_document == 1))
        } else {
          return arr
        }
      }),
      tap(arr => {
        arr.forEach(file => {
          if (file.parameterValuesToTask) {
            file.primaryTags = file.parameterValuesToTask.filter(x => !!x.is_primary)
          } else {
            file.primaryTags = []
          }
        })
      })
    ).subscribe(resp => {
      console.log("getFiles" + this.page, resp)
      this.folderFilesize = this.folderFilesize + resp.map(n => n.filesize).reduce((acc, number) => acc + number, 0)
      if (page == 1) {
        this.files = [...resp]
      } else {
        this.files.push(...resp);
      }
      
      console.log("foldersPermissions this.files", this.files)
      // if (window.location.pathname == '/file/YGUeBwuRsyYQPuiXW' && !this.only) {
      if (window.location.pathname == '/file/km_pF0W23oAeSPUvm' && !this.only) {
        this.videoEditorV3(this.files[this.files.length-1])
      }
      this.page = this.page + 1;
      this.isLoad = false;
    })
    
  }

  hideMenu() {
    this.HideMenu.emit()
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
        this.company.filesize = this.company.filesize + event.target.files[index].size
      }
    }

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }

    if (event.target.files.length > 0) {
      if (this.work && this.work.checklists && this.work.checklists.length && (this.crumbs[this.crumbs.length - 1].location.indexOf('to_approve') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('approved') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('ready_files') != -1)) {
        const dialogRef = this.dialog.open(FilePropertiesComponent, {
          panelClass: 'file_properties',
          autoFocus: false,
          disableClose: true,
          data: {
            is_set_propperties: true,
            filesNeedToSetProperties: event.target.files,
            company: this.company,
            user: this.user,
            work: this.work,
            files: this.files,
          }
        });
        // this.attachSubscriptions(
        //   dialogRef.afterClosed().subscribe(resp => {
        //     // console.log(resp)
        //   })
        // )
      }
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        if (this.sm.localStorageGetItem('md5hash') == '1') {
          this.layoutService.showSnackBar({name: element.name}, marker("MD5hash calculation started"), SnackBarItem)
          this.md5Service.calculateMd5(element).subscribe((resp:any) => {
            console.log("md5Service", resp);
            // this.putFile(element);
            this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID});
            this.layoutService.showSnackBar({name: resp.md5Hash}, new TimeFormatPipe().transform(Math.ceil(resp.duration / 1000)), SnackBarItem, 10000)
          })
        } else {
          this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID});
          // this.putFile(element);
        }
      }
    } 

    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  uploadFolder(event) {
    if (event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
        this.company.filesize = this.company.filesize + event.target.files[index].size
      }
    }
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }

    if (event.target.files.length > 0) {
      console.log("event.target.files", event.target.files)
      if (this.work && this.work.checklists && this.work.checklists.length && (this.crumbs[this.crumbs.length - 1].location.indexOf('to_approve') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('approved') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('ready_files') != -1)) {
        const dialogRef = this.dialog.open(FilePropertiesComponent, {
          panelClass: 'file_properties',
          autoFocus: false,
          disableClose: true,
          data: {
            is_set_propperties: true,
            filesNeedToSetProperties: event.target.files,
            company: this.company,
            user: this.user,
            work: this.work,
            files: this.files,
          }
        });
        // this.attachSubscriptions(
        //   dialogRef.afterClosed().subscribe(resp => {
        //     // console.log(resp)
        //   })
        // )
      }
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        // this.putFile(element, true);
        this.fileService.reserveFiles$.next({file: element, is_folder: true, initPath: false, id: this.putFilesID});
      }
    }
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  putFile(file, is_folder:boolean = false, initPath = false) {
    let path = this.crumbs[this.crumbs.length - 1].location;
    if (initPath) {
      path = initPath;
    }

    if (is_folder) {
      path = (path[path.length - 1] == '/' ? path : path + "/") + (!!file.filepath ? file.filepath : file.webkitRelativePath.replace("/" + file.name, ''));
    }

    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }
    
    console.log("_file", file)
    console.log("_task", _task)
    console.log("_work", _work)

    if (!!_work) {
      if ((_work.status_id == 98 || _work.status_id == 4) && path.indexOf("to_approve") != -1) {
        this.layoutService.showSnackBar({name: ''}, marker("Work in completed state, you can't upload files to 'To Approve' folder. Upload to any other folder."), SnackBarItem)
        return
      }
    }
    let needDelete = [];
    let test_1 = this.files.filter(x => x.filename == file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1);
    let test_2 = this.files.filter(x => x.filename == file.name);
    let test_3 = this.files.filter(x => x.is_uploaded == 0);
    let test_4 = this.files.filter(x => x.location.indexOf('/trash') == -1);
    console.log("test_1", test_1);
    console.log("test_2", test_2);
    console.log("test_3", test_3);
    console.log("test_4", test_4);
    if (this.files.filter(x => x.filename == file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1).length > 0) {
      needDelete = this.files.filter(x => x.filename == file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1);
    }
    console.log("needDelete", needDelete)
    if (needDelete.length > 0) {
      forkJoin(needDelete.map(x => this.fileService.editFile(x.id, {"location": '/trash'}, this.company.id))).pipe(
        last(),
        switchMap(() => {
          needDelete.forEach(x => {
            if (this.files.find(u => u.id == x.id)) {
              this.files.splice(this.files.indexOf(this.files.find(u => u.id == x.id)), 1)
            }
          })
          return this.fileService.uploadFile({
            company_id: this.target_company_id,
            task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
            task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
            filesize: file.size,
            filename: file.name,
            content_type: file.type ? file.type : mimeTypes.getType(file.name),
            location: path,
            is_dir: 0
          }, this.company.id)
        })
      ).subscribe(
        resp => {
          file.reportsFile = resp
          let x = {
            place: "file_manager",
            url: window.location.href,
            data: resp,
            files: this.files,
            folder: {
              is_folder: is_folder,
              path: path
            },
            task: _task,
            work: _work,
            activeLang: this.activeLang,
            operationsValues: this.operationsValues,
            location: this.crumbs[this.crumbs.length - 1].location,
            target: file,
            user: this.user,
            task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
            task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
            company: this.company,
            company_id: this.company.id,
            target_company_id: this.target_company_id
          }
          this.fileService.files$.next(x)
          let beforeUploadFile = x.target.reportsFile;
          beforeUploadFile.allData = x;
          if (path == x.location && this.files.filter(u => u.id == beforeUploadFile.id).length == 0) {
            this.files.push(beforeUploadFile)
          }
        },
        error => {
          if (error == "Company limit exceeded") {
            this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
          } else {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
        }
      )
    } else {
      this.fileService.uploadFile({
        company_id: this.target_company_id,
        task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
        task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
        filesize: file.size,
        filename: file.name,
        content_type: file.type ? file.type : mimeTypes.getType(file.name),
        location: path,
        is_dir: 0
      }, this.company.id).subscribe(
        resp => {
          file.reportsFile = resp
          let x = {
            place: "file_manager",
            url: window.location.href,
            data: resp,
            files: this.files,
            folder: {
              is_folder: is_folder,
              path: path
            },
            task: _task,
            work: _work,
            activeLang: this.activeLang,
            operationsValues: this.operationsValues,
            location: this.crumbs[this.crumbs.length - 1].location,
            target: file,
            user: this.user,
            task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
            task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
            company: this.company,
            company_id: this.company.id,
            target_company_id: this.target_company_id
          }
          this.fileService.files$.next(x)
          let beforeUploadFile = x.target.reportsFile;
          beforeUploadFile.allData = x;
          if (path == x.location && this.files.filter(u => u.id == beforeUploadFile.id).length == 0) {
            this.files.push(beforeUploadFile)
          }
        },
        error => {
          if (error == "Company limit exceeded") {
            this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
          } else {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
        }
      )
    }
  }
  
  logFiles() {
    console.log(this.files)
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }
  
  deleteTag() {
    this.close()
    console.log("this.selectedFiles", this.selectedFiles);

    let initData:any = {
      company: this.company
    }

    if (this.selectedFiles.selected.length > 1) {
      let arr = [];
      this.selectedFiles.selected.forEach(id => {
        if (this.files.find(x => x.id == id)) {
          arr.push(this.files.find(x => x.id == id))
        }
      });
      
      initData.selectedFiles = arr
    }

    const dialogRef = this.dialog.open(DeleteTagFromSelectedComponent, {
      backdropClass: [],
      panelClass: [],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == 'deleted') {
          this.refreshService.refreshFileTags$.next(initData.selectedFiles);
        }
        this.selectedFiles.clear();
      })
    )
  }
  setPrimaryTag() {
    this.close()
    console.log("this.selectedFiles", this.selectedFiles);

    let initData:any = {
      company: this.company
    }

    if (this.selectedFiles.selected.length > 1) {
      let arr = [];
      this.selectedFiles.selected.forEach(id => {
        if (this.files.find(x => x.id == id)) {
          arr.push(this.files.find(x => x.id == id))
        }
      });
      
      initData.selectedFiles = arr
    }

    const dialogRef = this.dialog.open(PrimaryTagForFilesComponent, {
      backdropClass: [],
      panelClass: [],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == 'deleted') {
          this.refreshService.refreshFileTags$.next(initData.selectedFiles);
        }
        this.selectedFiles.clear();
      })
    )
  }

  openTargetValues(info, element) {
    this.close()
    console.log("this.selectedFiles", this.selectedFiles);

    let initData:any = {
      company: this.company,
      inFile: element,
    }

    if (this.selectedFiles.selected.length > 1) {
      let arr = [];
      this.selectedFiles.selected.forEach(id => {
        if (this.files.find(x => x.id == id)) {
          arr.push(this.files.find(x => x.id == id))
        }
      });
      

      initData.selectedFiles = arr
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2],
      file_id: info[3]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == 'deleted') {
          if (initData.selectedFiles) {
            this.refreshService.refreshFileTags$.next(initData.selectedFiles);
          } else {
            this.refreshService.refreshFileTags$.next([element]);
          }
        }
        this.selectedFiles.clear();
      })
    )
  }

  videoEditorV2(file) {
    this.close()

    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }
    const dialogRef = this.dialog.open(MainVideoEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      data: {
        file: {...file},
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        user: this.user,
        activeLang: this.activeLang,
        imgRoute: this.imgRoute,
        host: this.host,
        task: _task,
        work: _work
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  videoEditorV4(file) {
    this.close()

    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    let addVideos = [];
    let addAudios = [];
    let allFiles = [];
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
      this.selectedFiles.selected.forEach(id => {
        if (id != file.id) {
          let exFile;
          if (this.files.find(b => b.id == id)) {
            exFile = this.files.find(b => b.id == id);
          } else if (this.boxFiles.selected.find(b => b.id == id)) {
            exFile = this.boxFiles.selected.find(b => b.id == id)
          }
          if (exFile) {
            allFiles.push(exFile)
          }
        }
      })
    }
    allFiles.forEach(el => {
      if (this.understandFileType(el.content_type) == 'video') {
        if (!!el.meta_height && !!el.meta_width) {
          addVideos.push(el)
        }
      } else if (this.understandFileType(el.content_type) == 'audio') {
        addAudios.push(el)
      }
    })
    const dialogRef = this.dialog.open(AlphaVideoEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      data: {
        file: {...file},
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        task: _task,
        work: _work,
        addAudios: addAudios,
        addVideos: addVideos
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  
  videoEditorV6(prevFile) {
    this.close()

    let file = JSON.parse(JSON.stringify(prevFile, this.getCircularReplacer()));
    let _task = this.task || file.task;
    if (file.task) {
      delete file.task;
    }
    if (_task.chatsInfo) {
      delete _task.chatsInfo;
    }
    if (file.taskChannelFiles) {
      delete file.taskChannelFiles;
    }
    if (file.createdEmployee) {
      delete file.createdEmployee;
    }
    if (file.meta) {
      delete file.meta;
    }
    if (file.taskOperation) {
      delete file.taskOperation;
    }
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    let addVideos = [];
    let addAudios = [];
    let allFiles = [];
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
      this.selectedFiles.selected.forEach(id => {
        let exFile;
        if (this.files.find(b => b.id == id)) {
          exFile = JSON.parse(JSON.stringify(this.files.find(b => b.id == id), this.getCircularReplacer()));
        } else if (this.boxFiles.selected.find(b => b.id == id)) {
          exFile = JSON.parse(JSON.stringify(this.boxFiles.selected.find(b => b.id == id), this.getCircularReplacer()));
        }
        if (exFile) {
          if (exFile.taskChannelFiles) {
            delete exFile.taskChannelFiles;
          }
          if (exFile.createdEmployee) {
            delete exFile.createdEmployee;
          }
          if (exFile.meta) {
            delete exFile.meta;
          }
          if (exFile.taskOperation) {
            delete exFile.taskOperation;
          }
          allFiles.push(exFile)
        }
      })
    }

    allFiles.forEach(el => {
      if (this.understandFileType(el.content_type) == 'video') {
        if (!!el.meta_height && !!el.meta_width) {
          addVideos.push(el)
        }
      } else if (this.understandFileType(el.content_type) == 'audio') {
        addAudios.push(el)
      }
    })
    let cstFile;
    if (addVideos && addVideos.length) {
      cstFile = {...addVideos[0]}
      addVideos.splice(0,1)
    } else {
      cstFile = {...file};
    }

    let modalData = {
      previusUrl: this.router.url,
      file: cstFile,
      company_id: this.company_id,
      target_company_id: this.target_company_id,
      company: this.company,
      user: this.user,
      imgRoute: this.imgRoute,
      activeLang: this.activeLang,
      host: this.host,
      task: _task,
      work: _work,
      addAudios: addAudios,
      addVideos: addVideos
    }

    console.log('modalData', modalData)
    this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
    // window.open(this.host,)
    // console.log('sm modalData', JSON.parse(this.sm.localStorageGetItem('ve_data')))
    
    window.open(this.origin + `/video-editor?company_id=${this.company_id}`, '_blank');
    // this.router.navigate(['/video-editor'], { queryParams: {company_id: this.company_id}, replaceUrl: true, state: { newTab: true }});
    // return
    // const dialogRef = this.dialog.open(VideoEditorTestComponent, {
    //   panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
    //   autoFocus: false,
    //   disableClose: true,
    //   data: modalData
    // });


    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log(result)
    //   })
    // )
  }

  closeAllDialogs(e?) {
    if (e) {
      e.stopPropagation();
      e.preventDefault()
    }
    this.dialog.closeAll()
  }

  videoEditorV5(file) {
    this.close()

    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    let addVideos = [];
    let addAudios = [];
    let allFiles = [];
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
      this.selectedFiles.selected.forEach(id => {
        let exFile;
        if (this.files.find(b => b.id == id)) {
          exFile = this.files.find(b => b.id == id);
        } else if (this.boxFiles.selected.find(b => b.id == id)) {
          exFile = this.boxFiles.selected.find(b => b.id == id)
        }
        if (exFile) {
          allFiles.push(exFile)
        }
      })
    }

    allFiles.forEach(el => {
      if (this.understandFileType(el.content_type) == 'video') {
        if (!!el.meta_height && !!el.meta_width) {
          addVideos.push(el)
        }
      } else if (this.understandFileType(el.content_type) == 'audio') {
        addAudios.push(el)
      }
    })
    let cstFile;
    if (addVideos && addVideos.length) {
      cstFile = {...addVideos[0]}
      addVideos.splice(0,1)
    } else {
      cstFile = {...file};
    }

    const dialogRef = this.dialog.open(VideoEditorTestComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        file: cstFile,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        host: this.host,
        task: _task,
        work: _work,
        addAudios: addAudios,
        addVideos: addVideos
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  videoEditorVA(prevFile) {
    this.close()
    let file = JSON.parse(JSON.stringify(prevFile, this.getCircularReplacer()));
    let _task = this.task || file.task;
    if (file.task) {
      delete file.task;
    }
    if (_task.chatsInfo) {
      delete _task.chatsInfo;
    }
    if (file.taskChannelFiles) {
      delete file.taskChannelFiles;
    }
    if (file.createdEmployee) {
      delete file.createdEmployee;
    }
    if (file.meta) {
      delete file.meta;
    }
    if (file.taskOperation) {
      delete file.taskOperation;
    }

    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    let addVideos = [];
    let addAudios = [];
    let allFiles = [];
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
      this.selectedFiles.selected.forEach(id => {
        let exFile;
        if (this.files.find(b => b.id == id)) {
          exFile = JSON.parse(JSON.stringify(this.files.find(b => b.id == id), this.getCircularReplacer()));
        } else if (this.boxFiles.selected.find(b => b.id == id)) {
          exFile = JSON.parse(JSON.stringify(this.boxFiles.selected.find(b => b.id == id), this.getCircularReplacer()));
        }
        if (exFile) {
          if (exFile.taskChannelFiles) {
            delete exFile.taskChannelFiles;
          }
          if (exFile.createdEmployee) {
            delete exFile.createdEmployee;
          }
          if (exFile.meta) {
            delete exFile.meta;
          }
          if (exFile.taskOperation) {
            delete exFile.taskOperation;
          }
          allFiles.push(exFile)
        }
      })
    }

    allFiles.forEach(el => {
      if (this.understandFileType(el.content_type) == 'video') {
        if (!!el.meta_height && !!el.meta_width) {
          addVideos.push(el)
        }
      } else if (this.understandFileType(el.content_type) == 'audio') {
        addAudios.push(el)
      }
    })
    let cstFile;
    if (addVideos && addVideos.length) {
      cstFile = {...addVideos[0]}
      addVideos.splice(0,1)
    } else {
      cstFile = {...file};
    }

    const dialogRef = this.dialog.open(VideoFfmpegEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        file: cstFile,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        host: this.host,
        task: _task,
        work: _work,
        addAudios: addAudios,
        addVideos: addVideos
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  
  V5VideoEditor(prevFile) {
    this.close()
    let file = JSON.parse(JSON.stringify(prevFile, this.getCircularReplacer()));
    let _task = this.task || file.task;
    if (file.task) {
      delete file.task;
    }
    if (_task.chatsInfo) {
      delete _task.chatsInfo;
    }
    if (file.taskChannelFiles) {
      delete file.taskChannelFiles;
    }
    if (file.createdEmployee) {
      delete file.createdEmployee;
    }
    if (file.meta) {
      delete file.meta;
    }
    if (file.taskOperation) {
      delete file.taskOperation;
    }

    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    let addVideos = [];
    let addAudios = [];
    let allFiles = [];
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
      this.selectedFiles.selected.forEach(id => {
        let exFile;
        if (this.files.find(b => b.id == id)) {
          exFile = JSON.parse(JSON.stringify(this.files.find(b => b.id == id), this.getCircularReplacer()));
        } else if (this.boxFiles.selected.find(b => b.id == id)) {
          exFile = JSON.parse(JSON.stringify(this.boxFiles.selected.find(b => b.id == id), this.getCircularReplacer()));
        }
        if (exFile) {
          if (exFile.taskChannelFiles) {
            delete exFile.taskChannelFiles;
          }
          if (exFile.createdEmployee) {
            delete exFile.createdEmployee;
          }
          if (exFile.meta) {
            delete exFile.meta;
          }
          if (exFile.taskOperation) {
            delete exFile.taskOperation;
          }
          allFiles.push(exFile)
        }
      })
    }

    allFiles.forEach(el => {
      if (this.understandFileType(el.content_type) == 'video') {
        if (!!el.meta_height && !!el.meta_width) {
          addVideos.push(el)
        }
      } else if (this.understandFileType(el.content_type) == 'audio') {
        addAudios.push(el)
      }
    })
    let cstFile;
    if (addVideos && addVideos.length) {
      cstFile = {...addVideos[0]}
      addVideos.splice(0,1)
    } else {
      cstFile = {...file};
    }

    const dialogRef = this.dialog.open(VideoEditorV5Component, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        file: cstFile,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        host: this.host,
        task: _task,
        work: _work,
        addAudios: addAudios,
        addVideos: addVideos
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  videoEditorV3(file) {
    this.close()

    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    let addVideos = [];
    let addAudios = [];
    let allFiles = [];
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
      this.selectedFiles.selected.forEach(id => {
        let exFile;
        if (this.files.find(b => b.id == id)) {
          exFile = this.files.find(b => b.id == id);
        } else if (this.boxFiles.selected.find(b => b.id == id)) {
          exFile = this.boxFiles.selected.find(b => b.id == id)
        }
        if (exFile) {
          allFiles.push(exFile)
        }
      })
    }

    allFiles.forEach(el => {
      if (this.understandFileType(el.content_type) == 'video') {
        if (!!el.meta_height && !!el.meta_width) {
          addVideos.push(el)
        }
      } else if (this.understandFileType(el.content_type) == 'audio') {
        addAudios.push(el)
      }
    })
    let cstFile;
    if (addVideos && addVideos.length) {
      cstFile = {...addVideos[0]}
      addVideos.splice(0,1)
    } else {
      cstFile = {...file};
    }
    const dialogRef = this.dialog.open(BetaVideoEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        file: cstFile,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        host: this.host,
        task: _task,
        work: _work,
        addAudios: addAudios,
        addVideos: addVideos
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  videoEditor(file) {
    this.close()

    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }
    const dialogRef = this.dialog.open(VideoEdtiorComponent, {
      panelClass: ['full_screen_modal', 'video_editor'],
      autoFocus: false,
      data: {
        file: {...file},
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        task: _task,
        work: _work
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  toShortCut(file) {
    this.close()
    this.attachSubscriptions(
      this.taskBarService.addBarItem({company_id: this.company.id, task_id: !!file.task_id ? file.task_id : 0, task_operation_id: !!file.task_operation_id ? file.task_operation_id : 0, discussion_id: !!file.discussion_id ? file.discussion_id : 0, file_id: file.id}).subscribe(res => {
        console.log("toShortCut", res)
        this.minimizeService.minimizes$.next(res)
      }, error => {
        console.log("error toShortCut", error)
      })
    )
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getSelectedDuration() {
    if (this.selectedFiles.isEmpty()) {
      return 0
    } else {
      let duration = 0;
      if (this.boxMode) {
        
        this.selectedFiles.selected.forEach(id => {
          if (this.boxFiles.selected.find(x => x.id == id) && this.boxFiles.selected.find(x => x.id == id).meta_duration) {
            duration += +this.boxFiles.selected.find(x => x.id == id).meta_duration
          }
        })
      } else {
        this.selectedFiles.selected.forEach(id => {
          if (this.files.find(x => x.id == id) && this.files.find(x => x.id == id).meta_duration) {
            duration += +this.files.find(x => x.id == id).meta_duration
          }
        })
      }
      return duration
    }
  }

  handleDragStart(e, file) {
    // e.preventDefault();
    // e.stopPropagation();
    // e.stopImmediatePropagation();

    console.log("drag start")
    // return
    if (this.selectedFiles.isSelected(file.id)) {
      file.dragged = true;
      this.isDragged = true;

      if (this.detectBrowserName() != 'safari') {
        let canvas = document.createElement('canvas');
        canvas.style.zIndex = '9999999999999999';
        canvas.width = 40;
        canvas.height = 40;
        let ctx = canvas.getContext("2d");
        ctx.font = "14px Comic Sans MS";
        ctx.fillStyle = "black";
        ctx.textAlign = "left";
        ctx.fillText(this.selectedFiles.selected.length.toString(), canvas.width/2, canvas.height/2);
        e.dataTransfer.setDragImage(canvas, 0, 0);
      }
      e.dataTransfer.setData("application/my-app", this.selectedFiles.selected.join(","));
      this.files.map(x => {
        if (this.selectedFiles.selected.includes(x.id)) {
          x.dragged = true;
        }
      })
      console.log(e.dataTransfer.getData("application/my-app").split(',').map(Number))
    } else {
      e.dataTransfer.setData("application/my-app", file.id);
      this.isDragged = true;
      file.dragged = true;
      this.deSelectAll();
    }
  }

  handleDragOver(e, folder:Folder) {
    // e.preventDefault();
    // e.stopPropagation();
    // // e.stopImmediatePropagation();
    if (e.preventDefault) e.preventDefault(); // allows us to drop
    folder.over = true;
    e.dataTransfer.dropEffect = 'copy';
    return false;

    console.log("drag over")
    folder.over = true
  }

  handleDragEnter(e, folder:Folder) {
    console.log("drag enter")
    e.dataTransfer.dropEffect = 'copy';
    folder.over = true;
  }
  
  handleDragLeave(e, folder:Folder) {
    // e.preventDefault();
    // e.stopPropagation();
    // e.stopImmediatePropagation();

    console.log("drag leave")
    folder.over = false;
  }

  handleDrop(e, folder:any) {
    e.stopPropagation();
    // e.stopImmediatePropagation();
    // e.preventDefault();
    // this.isLoad = true;


    console.log("handle drop", e, folder)
    folder.over = false
    let file_ids = e.dataTransfer.getData("application/my-app").split(',').map(Number);
    console.log("file_ids", file_ids);

    if (!file_ids || file_ids.toString() === '0' || (folder.rootDir && this.files.find(x => x.id == file_ids[0]).location == folder.location)) {
      // this.isLoad = false;
      return
    }
    let locationData:any = "";
    if (folder.is_dir == 1) {
      locationData = folder.location == "/" ? folder.location + folder.filename : folder.location + "/" + folder.filename
    } else {
      locationData = folder.location;
    }

    locationData = locationData.replaceAll('//', '/')

    if (!this.isWriteFolder(locationData)) {

      let x = "You can't upload files here."
      let y = ''

      if (this.foldersPerm.filter(u => !u.cant_write).length) {
        y = y + "You are allowed upload in folders: ";

        this.foldersPerm.filter(u => !u.cant_write).forEach((b, k) => {
          let name = b.name;
          if (k == this.foldersPerm.filter(u => !u.cant_write).length - 1) {
            name = name + ' or other nonsystem folders.'
          } else {
            name = name + ', '
          }
          y += name
        })
      }

      this.layoutService.showSnackBar({name: x}, marker(y), SnackBarItem)
      return
    }

    this.attachSubscriptions(
      from(file_ids).pipe(
        concatMap(id => {
          console.log("concatMap", id)
          return this.handleDropFunc(id, locationData)
        }),
        finalize(() => {
          file_ids.forEach((b,i) => {
            this.files.splice(this.files.indexOf(this.files.find(x => x.id == b)), 1)
            if (i == file_ids.length - 1) {
              this.deSelectAll();
            }
          })
        })
      ).subscribe(resp => {
        if (['/approved', '/ready_files'].includes(locationData)) {
          this.detectFiles();
        }
        console.log("fork handleDropFunc", resp)
        console.log("end handle drop", this.files)
      })
    )

    // this.attachSubscriptions(
    //   concat(file_ids.map(x => this.handleDropFunc(x, locationData))).pipe(
    //     finalize(() => {
    //       file_ids.forEach((z,i) => {
    //         this.files.splice(this.files.indexOf(this.files.find(x => x.id == z)), 1)
    //         if (i == file_ids.length - 1) {
    //           this.deSelectAll();
    //         }
    //       })
    //     })
    //   ).subscribe(resp => {
    //     if (['/approved', '/ready_files'].includes(locationData)) {
    //       this.detectFiles();
    //     }
    //     console.log("fork handleDropFunc", resp)
    //     console.log("end handle drop", this.files)
    //   })
    // )

  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  handleDropFunc(id, locationData) {
    let data:any = {"location": (locationData == '/approved' && !!this.files.find(x => x.id == id).is_ready_if_approved) ? '/ready_files' : locationData}
    return this.fileService.editFile(id, data, this.company.id).pipe(
      catchError(error => {
        console.log("handleDropFunc ERROR", error)
        data.filename = error[1].message
        return throwError('Error!');
      }),
      retry(1),
      filter(() => !!this.task_operation_id),
      switchMap(() => {
        if (locationData.indexOf("to_approve") != -1) {
          return this.taskService.editWork(this.task_operation_id, {is_to_approve_files: 1}, this.company_id).pipe(map(() => of(id)))
        } else if (locationData.indexOf("ready_files") != -1) {
          return this.taskService.editWork(this.task_operation_id, {is_approved_files: 1}, this.company_id).pipe(map(() => of(id)))
        } else {
          return of(id)
        }
      }),
      switchMap(() => {
        if (this.files.find(x => x.id == id).location.indexOf("to_approve") != -1) {
          return this.fileService.detectFiles("/to_approve", 0, this.company_id, this.task_operation_id).pipe(
            switchMap(val => {
              if (val.length == 0) {
                return this.taskService.editWork(this.task_operation_id, {is_to_approve_files: 0}, this.company_id).pipe(map(() => of(id)))
              } else {
                return of(id)
              }
            })
          )
        } else if (this.files.find(x => x.id == id).location.indexOf("ready_files") != -1) {
          return this.fileService.detectFiles("/ready_files", 0, this.company_id, this.task_operation_id).pipe(
            switchMap(val => {
              if (val.length == 0) {
                return this.taskService.editWork(this.task_operation_id, {is_approved_files: 0}, this.company_id).pipe(map(() => of(id)))
              } else {
                return of(id)
              }
            })
          )
        } else {
          return of(id)
        }
      })
    )    
  }

  onFileDropped(e, folder:any = false, file:any = false) {
    console.log("onFileDropped", e, folder)
    if (this.isDragged) {
      return
    }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      console.log("this.company", this.company)
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          this.company.filesize = this.company.filesize + files[index].size
        }
      }
    })

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }
    

    let locationVal:any = false;
    if (file) {
      locationVal = file.location + "/" + file.filename
    }

    if (folder) {
      locationVal = folder.location
    }


    let checkRoute = locationVal;

    if (typeof checkRoute == "boolean") {
      checkRoute = this.form.value.location;
    } else {
      locationVal = locationVal.replaceAll('//', '/');
      checkRoute = locationVal;
    }

    console.log("onFileDropped", checkRoute, this.isWriteFolder(checkRoute))
    if (!this.isWriteFolder(checkRoute)) {

      let x = "You can't upload files here."
      let y = ''

      if (this.foldersPerm.filter(u => !u.cant_write).length) {
        y = y + "You are allowed upload in folders: ";

        this.foldersPerm.filter(u => !u.cant_write).forEach((b, k) => {
          let name = b.name;
          if (k == this.foldersPerm.filter(u => !u.cant_write).length - 1) {
            name = name + ' or other nonsystem folders.'
          } else {
            name = name + ', '
          }
          y += name
        })
      }

      this.layoutService.showSnackBar({name: x}, marker(y), SnackBarItem)
      return
    }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {

        console.log("DropFilesLimitComponent", files);
        console.log("DropFilesLimitComponent", files.length);

        if (files.length >= 100) {
          const dialogRef = this.dialog.open(DropFilesLimitComponent, {
            panelClass: 'file_properties',
            autoFocus: false,
            data: {
              company: this.company,
              user: this.user,
            }
          });
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              if (!!result) {
                if (result.event == 'upload') {
                  console.log(this.uploader);
                  let el: HTMLElement = this.uploader.nativeElement;
                  el.click();
                } else if (result.event == 'upload_folder') {
                  console.log(this.uploaderFolder);
                  let el: HTMLElement = this.uploaderFolder.nativeElement;
                  el.click();
                }
                // this.files.push(result.data)
              }
            })
          )
          return
        }
        console.log("getFilesDataTransferItems",files);
        if (this.work && this.work.checklists && this.work.checklists.length && (this.crumbs[this.crumbs.length - 1].location.indexOf('to_approve') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('approved') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('ready_files') != -1)) {
          const dialogRef = this.dialog.open(FilePropertiesComponent, {
            panelClass: 'file_properties',
            autoFocus: false,
            disableClose: true,
            data: {
              is_set_propperties: true,
              filesNeedToSetProperties: files,
              company: this.company,
              user: this.user,
              work: this.work,
              files: this.files,
            }
          });
          // this.attachSubscriptions(
          //   dialogRef.afterClosed().subscribe(resp => {
          //     // console.log(resp)
          //   })
          // )
        }
        for (let index = 0; index < files.length; index++) {
          // this.putFile(files[index], !!files[index].filepath || !!files[index].webkitRelativePath, locationVal);
          this.fileService.reserveFiles$.next({file: files[index], is_folder: !!files[index].filepath || !!files[index].webkitRelativePath, initPath: locationVal, id: this.putFilesID});
        }
      }
    });
    
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  copy(file) {
    this.close();
    let copyData = [];
    if (this.selectedFiles.selected.length && this.selectedFiles.selected.includes(file.id)) {
      this.selectedFiles.selected.forEach(x => {
        copyData.push(
          {
            company_id: this.files.find(b => b.id == x).company_id,
            id: x,
            taskChannelFiles: this.files.find(b => b.id == x).taskChannelFiles
          }
        )
      })
    } else {
      copyData.push({
        company_id: file.company_id,
        id: file.id,
        taskChannelFiles: file.taskChannelFiles
      })
    }

    this.sm.localStorageSetItem('copiedFiles', copyData);
    this.copiedFiles = copyData
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  privacy(file) {
    this.close();
    console.log(file);

    const dialogRef = this.dialog.open(FilePrivacyComponent, {
      data: {
        file: file,
        company: this.company
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(resp => {
        console.log(resp)
      })
    )
  }

  paste(folder?, clear?) {
    this.close();
    let location = ''
    if (folder) {
      location = (folder.location != '/' ? (folder.location + "/") : folder.location) + folder.filename
    } else {
      location = this.form.get("location").value
    }

    this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
    
    this.attachSubscriptions(
      forkJoin(this.copiedFiles.map(x => this.fileService.copyFile({
        location: location,
        task_id: !this.is_global ? this.task_id : this.globalFilterFmFiles.task_id,
        task_operation_id: !this.is_global ? this.task_operation_id : this.globalFilterFmFiles.work_id,
        id: x.id, 
        company_id: this.target_company_id || this.company.id || this.company_id || x.company_id
      }, this.company.id)
      .pipe(
        switchMap((res) => {
          if (!folder) {
            return this.fileService.getTargetFile(res.id, this.company.id || x.company_id).pipe(
              tap(b => {
                this.user.name = this.user.display_name
                b.user = this.user
                b.createdEmployee = this.user
                b.taskChannelFiles = x.taskChannelFiles
                console.log("paste FILE",b)
                this.files.push(b)
              }),
              map(() => res)
            )
          } else {
            return of(res)
          }
        }),
        catchError(err => {
          this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
          return of(err)
        }),
      ))).subscribe(resp => {
        if (!!clear) {
          localStorage.removeItem('copiedFiles');
          this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
        }
        this.layoutService.showSnackBar({name: ''}, marker("File copied successfully!"), SnackBarItem)
      })
    )


    console.log("this.copiedFiles", this.copiedFiles)
  }
  
  taskDrop(e, task:any) {
    task.over = false
    let file_ids = e.dataTransfer.getData("application/my-app").split(',');

    forkJoin(file_ids
      .map(x => this.copyFunc({
        id: x,
        company_id: this.target_company_id || this.company.id || this.company_id || x.company_id,
        task_id: task.id,
        task_operation_id: 0,
        location: '/'
      }))
    ).subscribe(resp => {
      this.deSelectAll()
    })
  }

  profileDrop(e, channel:any) {
    e.preventDefault();
    channel.over = false
    if (!channel.channel.channel_account_id) {
      return
    }
    let file_ids = e.dataTransfer.getData("application/my-app").split(',');

    let big_img_file_ids = file_ids.filter(val => this.files.find(x => x.id == val).filesize >= 2000000 && (this.files.find(x => x.id == val).content_type.indexOf("image") != -1 || mimeTypes.getType(this.files.find(x => x.id == val).filename).indexOf("image") != -1))
    let img_file_ids = file_ids.filter(val => this.files.find(x => x.id == val).filesize < 2000000 && (this.files.find(x => x.id == val).content_type.indexOf("image") != -1 || mimeTypes.getType(this.files.find(x => x.id == val).filename).indexOf("image") != -1))
    file_ids = file_ids.filter(val => this.files.find(x => x.id == val).content_type.indexOf("video") != -1 || mimeTypes.getType(this.files.find(x => x.id == val).filename).indexOf("video") != -1)
    console.log("file_ids", file_ids)
    
    if (file_ids.length) {
      forkJoin(file_ids
        .map(x => this.addChannelContent({
          company_id: this.company_id,
          task_id: this.task.id,
          channel_id: channel.channel.id,
          content_file_id: x,
        }))
      ).subscribe(resp => {
        console.log("postChannelContent", file_ids, resp)
        this.deSelectAll();
        if (big_img_file_ids.length) {
          this.layoutService.showSnackBar({name: big_img_file_ids.length + " cover(s)"}, marker("Larger than 2 mb. For the cover, you can choose a photo smaller than 2mb."), SnackBarItem)
        }
        if (img_file_ids.length) {
          console.log(img_file_ids, channel)
          this.attachSubscriptions(
            this.connectedAppsService.getChannelContent(this.company_id, this.task_id, channel.channel.id).pipe(
              map(res => res.filter(x => !x.is_ready_to_upload && !x.is_uploaded && !x.is_uploading))
            ).subscribe(resp => {
              if (resp.length == 1) {
  
                this.connectedAppsService.editChannelContent(resp[0].id, {thumbnail_file_id: img_file_ids[0]}).subscribe(result => {
                  console.log(result)
                },
                error => {
                  this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
                })
  
              } else if (resp.length > 1) {
                const dialogRef = this.dialog.open(SelectContentComponent, {
                  data: {
                    videos: resp,
                    imgRoute: this.imgRoute,
                    host: this.host,
                    thumb_id: img_file_ids[0],
                    company: this.company
                  }
                });
            
              } else {
                return
              }
            })
          )
        }
      }, error => {
        console.log("RERRWEQERQW", error)
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    } else {
      if (big_img_file_ids.length) {
        this.layoutService.showSnackBar({name: big_img_file_ids.length + " cover(s)"}, marker("Larger than 2 mb. For the cover, you can choose a photo smaller than 2mb."), SnackBarItem)
      }
      if (img_file_ids.length) {
        console.log(img_file_ids, channel)
        this.attachSubscriptions(
          this.connectedAppsService.getChannelContent(this.company_id, this.task_id, channel.channel.id).pipe(
            map(res => res.filter(x => !x.is_ready_to_upload && !x.is_uploaded && !x.is_uploading))
          ).subscribe(resp => {
            if (resp.length == 1) {

              this.connectedAppsService.editChannelContent(resp[0].id, {thumbnail_file_id: img_file_ids[0]}).subscribe(result => {
                console.log(result)
              },
              error => {
                this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
              })

            } else if (resp.length > 1) {
              const dialogRef = this.dialog.open(SelectContentComponent, {
                data: {
                  videos: resp,
                  imgRoute: this.imgRoute,
                  host: this.host,
                  thumb_id: img_file_ids[0],
                  company: this.company
                }
              });
          
            } else {
              return
            }
          }, error => {
            console.log("RERRWEQERQW2", error)
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      }
    }
   
  }

  protected filterTimezoneGroups() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    const timezonesCopy = this.copyTimezoneGroups(this.timezones);
    if (!search) {
      this.filteredTimezonesGroups.next(timezonesCopy);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezonesGroups.next(
      timezonesCopy.filter(timezoneGroup => {
        const showBankGroup = timezoneGroup.value.toLowerCase().indexOf(search) > -1;
        if (!showBankGroup) {
          timezoneGroup.names = timezoneGroup.names.filter(name => name.toLowerCase().indexOf(search) > -1);
        }
        return timezoneGroup.names.length > 0;
      })
    );
  }

  protected copyTimezoneGroups(timezoneGroups: any) {
    const timezonesCopy = [];
    timezoneGroups.forEach(bankGroup => {
      timezonesCopy.push({
        value: bankGroup.value,
        names: bankGroup.names.slice()
      });
    });
    return timezonesCopy;
  }

  getTimeZone(val) {
    let o = Math.abs(val);
    return (val >= 0 ? "+" : "-") + ("00" + Math.floor(o / 3600)).slice(-2) + ":00";
  }
  
  getOffsetData(name) {
    if (!this.timezones) {
      return "0"
    }
    return this.timezones.find(x => x.names.filter(y => y == name).length > 0).value
  }

  getTimeZones() {
    this.attachSubscriptions(
      this.companyService.getTimeZones().subscribe(resp => {
        let val = [];
        Object.keys(resp).forEach(key => {
          val.push({
            value: key,
            names: resp[key]
          })
        })
        this.timezones = val;
        this.filteredTimezonesGroups.next(this.copyTimezoneGroups(this.timezones));
        console.log("getTimeZones",this.timezones);
      })
    )
  }

  saveStatus(item) {
    this.attachSubscriptions(
      this.connectedAppsService.editYouTubeContentData(item.id, {privacy_status: this.statusForm.value.privacy_status}).subscribe(resp => {
        if (resp) {
          item.youTubeData.privacy_status = this.statusForm.value.privacy_status
          item.status_opened = false
        }
        console.log("saveStatus", resp)
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  saveScheduleStatus(item) {
    let arr:Number[] = [];
    [0,15,30,45].forEach(x => {
      arr.push(Math.abs(x - this.statusForm.value.publish_at.minutes()))
    })
    
    this.statusForm.patchValue({
      publish_at: this.statusForm.value.publish_at.minute([0,15,30,45][arr.indexOf(Math.min.apply(Math, arr))])
    }) 

    // console.log("minutes", cloneDate);
    // console.log("minutes", cloneDate.minutes());

    let date = JSON.parse(JSON.stringify(moment(this.statusForm.value.publish_at).format("X")))
    let val = moment((+date + (-(+this.timeZone*60) - +this.getOffsetData(this.statusForm.value.timezone)))*1000).toISOString()
    // console.log(+date + (-(+this.timeZone*60) - +this.getOffsetData(this.statusForm.value.timezone)))
    // console.log(moment((+date + (-(+this.timeZone*60) - +this.getOffsetData(this.statusForm.value.timezone)))*1000).toISOString())

    this.attachSubscriptions(
      this.connectedAppsService.editYouTubeContentData(item.id, {privacy_status: "private", publish_at: val}).subscribe(resp => {
        console.log(resp)
        if (resp) {
          item.youTubeData.publish_at = val;
          item.status_opened = false
        }
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  openStatus(item) {
    this.channelFiles.map(x => {
      x.status_opened = false
    })

    if (item.is_uploaded == 1) {

      this.statusForm.get('status_block').valueChanges.subscribe(resp => {
        if (resp == 'first') {
          if (this.statusForm.contains('publish_at')) {
            this.statusForm.removeControl('publish_at')
            this.statusForm.removeControl('timezone')
          }
        } else {
          this.statusForm.patchValue({
            privacy_status: 'schedule'
          })
          this.statusForm.addControl('publish_at', this.fb.control(item.youTubeData.privacy_status.publish_at ? moment(item.youTubeData.privacy_status.publish_at) : moment().minute(0).second(0).hour(0)))
          this.statusForm.addControl('timezone', this.fb.control(this.company.timezone))
        }
        this.statusForm.updateValueAndValidity();

        console.log(this.statusForm.value)
      })


      this.statusForm.patchValue({
        status_block: item.youTubeData.privacy_status == 'private' && item.youTubeData.publish_at != '' ? 'second' : 'first',
        privacy_status: item.youTubeData.privacy_status
      })

      console.log(this.statusForm.value)
    }

    item.status_opened = true
  }

  closeStatus(item) {
    item.status_opened = false
  }

  tryToUpload(item) {
    this.attachSubscriptions(
      this.connectedAppsService.editChannelContent(item.id, {name: item.name ? item.name : item.contentFile.filename, is_ready_to_upload: 1, is_uploading: 0}).subscribe(
        resp => {
          console.log(resp)
          item.is_ready_to_upload = resp.is_ready_to_upload;
          item.is_uploaded = resp.is_uploaded;
          item.uploading_error = resp.uploading_error;
          item.uploading_progress = resp.uploading_progress;
          item.is_uploading = resp.is_uploading;
          if (this.uploadProgress) {
            this.uploadProgress.unsubscribe()
          }
          this.checkUploadProgress()
        }
      )
    )
  }

  uploadProfileVideo(item) {
    this.attachSubscriptions(
      this.connectedAppsService.editChannelContent(item.id, {name: item.name ? item.name : item.contentFile.filename, is_ready_to_upload: 1}).subscribe(
        resp => {
          console.log(resp)
          item.is_ready_to_upload = resp.is_ready_to_upload;
          item.is_uploaded = resp.is_uploaded;
          item.uploading_error = resp.uploading_error;
          item.uploading_progress = resp.uploading_progress;
          item.is_uploading = resp.is_uploading;
          // if (resp.is_uploading == 0) {
          //   this.layoutService.showSnackBar({name: ''}, marker('Not accessible'), SnackBarItem)
          // } else {            
          // }
          if (this.uploadProgress) {
            this.uploadProgress.unsubscribe()
          }
          this.checkUploadProgress()
        },
        error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      )
    )
  }

  editName(item) {
    this.channelFiles.map(x => {
      x.name_edited = false
    })
    item.name_edited = true

   
    setTimeout(() => {
      document.getElementById(`name_${item.id}`).focus()
      if (document.getElementById(`name_${item.id}`)) {
      }
    }, 0)
  }

  changeName(item) {
    let saveName = item.name;
    item.name = (document.getElementById(`name_${item.id}`) as HTMLInputElement).value
    if (item.is_uploaded == 1) {
      item.youTubeData.title = item.name;
    }
    this.attachSubscriptions(
      this.connectedAppsService.editChannelContent(item.id, {name: item.name}).pipe(
        switchMap(res => {
          if (item.is_uploaded == 1) {
            return this.connectedAppsService.editYouTubeContentData(item.id, {title: item.name}).pipe(tap(n => console.log("editYouTubeContentData", n)))
          } else {
            return of(res)
          }
        })
      ).subscribe(
        resp => {
          console.log(resp)
        },
        error => {
          item.name = saveName
          if (item.is_uploaded == 1) {
            item.youTubeData.title = saveName;
          };
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      )
    )

  }

  focusOutName(item) {
    item.name_edited = false
  }

  deleteProfileVideo(content, index) {
    this.attachSubscriptions(
      this.connectedAppsService.deleteChannelContent(content.id).subscribe(resp => {
        this.channelFiles.splice(index, 1)
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getFile(file) {
    this.attachSubscriptions(
      this.fileService.getFilesPreview(this.company.id, [file.id]).pipe(
        map(arr => arr[0])
      ).subscribe(resp => {
        this.files.splice(this.files.indexOf(file),1,resp)
        console.log("RESP", resp)
        console.log("FILE", resp)
      })
    )
  }

  transcribeFile(file) {
    this.close();
    const dialogRef = this.dialog.open(FileSpeachToTextComponent, {
      backdropClass: ['speech_file_backdrop'],
      panelClass: ['speech_file_pane'],
      data: {
        company: this.company,
        file: file,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      this.getFile(file)
    })
    // this.attachSubscriptions(
    //   this.fileService.editFile(file.id, {is_rev_transcribe: 1}, this.company.id).subscribe(resp => {
    //     file.is_rev_transcribe = resp.is_rev_transcribe
    //     this.layoutService.showSnackBar({name: ''}, marker('File in the process of receiving text'), SnackBarItem)
    //     const dialogRef = this.dialog.open(FileSettingsComponent, {
    //       data: {
    //         company: this.company,
    //         file: file,
    //       }
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //       console.log(result)
    //     })

    //   },
    //   error => {
    //     this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
    //   })
    // )
  }
  
  focusOutDesc(item) {
    item.desc_edited = false
  }
  editDesc(item) {
    this.channelFiles.map(x => {
      x.desc_edited = false
    })
    item.desc_edited = true

   
    setTimeout(() => {
      if (document.getElementById(`desc_${item.id}`)) {
        document.getElementById(`desc_${item.id}`).focus()
      }
    }, 0)
  }

  changeDesc(item) {
    console.log(item);
    let saveDescription = item.youTubeData.description
    item.youTubeData.description = (document.getElementById(`desc_${item.id}`) as HTMLInputElement).value

    this.attachSubscriptions(
      this.connectedAppsService.editYouTubeContentData(item.id, {description: item.youTubeData.description}).subscribe(
        resp => {
          console.log(resp)
          if (!resp) {
            item.youTubeData.description = saveDescription
          }
        },
        error => {
          item.youTubeData.description = saveDescription
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      )
    )

  }



  addChannelContent(data) {
    return this.connectedAppsService.addChannelContent(data)
  }

  copyFunc(data) {
    return this.fileService.copyFile(data, this.company.id)
  }

  approveFile(file) {
    this.close();

    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.filter(x => !x.is_dir).length > 1) { 
      let lenSelection = this.selectedFiles.selected.filter(x => !x.is_dir).length;
      let count = 0;

      this.ls.requests$.next({
        value: 0,
        target: `Approving ${lenSelection} files`,
      })

      from(this.selectedFiles.selected.filter(x => !x.is_dir)).pipe(
        concatMap((fileID:any) => {
  
          let exFile;
          if (this.files.find(b => b.id == fileID)) {
            exFile = this.files.find(b => b.id == fileID);
          } else if (this.boxFiles.selected.find(b => b.id == fileID)) {
            exFile = this.boxFiles.selected.find(b => b.id == fileID)
          }
          return this.fileService.editFile(file.id, {
            location: !!file.is_ready_if_approved ? '/ready_files' : '/approved'
          }, this.company.id).pipe(
            tap(res => {
              this.files.splice(this.files.indexOf(this.files.find(t => t.id == file.id)), 1)
              console.log(res)
            })
          )
        })
      ).subscribe({
        next: (next) => {
          console.log("next approveFile", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Approving ${lenSelection} files`,
          })
          count++;
        },
        complete: () => {
          console.log("complete approveFile");
          this.fileService.checkRejectFolder$.next(true);
          this.detectRejectFiles();
          this.selectedFiles.clear();
        },
        error: (error) => {
          console.log("error approveFile", error)
        }
      })
    } else {
      this.attachSubscriptions(
        this.fileService.editFile(file.id, {
          location: !!file.is_ready_if_approved ? '/ready_files' : '/approved'
        }, this.company.id).subscribe(res => {
          this.detectFiles();
          this.files.splice(this.files.indexOf(this.files.find(t => t.id == file.id)), 1)
          console.log(res)
        })
      )
    }
  }

  rejectFile(file) {
    this.close();

    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.filter(x => !x.is_dir).length > 1) { 
      let lenSelection = this.selectedFiles.selected.filter(x => !x.is_dir).length;
      let count = 0;

      this.ls.requests$.next({
        value: 0,
        target: `Rejecting ${lenSelection} files`,
      })

      from(this.selectedFiles.selected.filter(x => !x.is_dir)).pipe(
        concatMap((fileID:any) => {
  
          let exFile;
          if (this.files.find(b => b.id == fileID)) {
            exFile = this.files.find(b => b.id == fileID);
          } else if (this.boxFiles.selected.find(b => b.id == fileID)) {
            exFile = this.boxFiles.selected.find(b => b.id == fileID)
          }
          return this.fileService.editFile(file.id, {
            location: '/reject'
          }, this.company.id).pipe(
            tap(res => {
              this.files.splice(this.files.indexOf(this.files.find(t => t.id == file.id)), 1)
              console.log(res)
            })
          )
        })
      ).subscribe({
        next: (next) => {
          console.log("next rejectFile", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Rejecting ${lenSelection} files`,
          })
          count++;
        },
        complete: () => {
          console.log("complete rejectFile");
          this.fileService.checkRejectFolder$.next(true);
          this.detectRejectFiles();
          this.selectedFiles.clear();
        },
        error: (error) => {
          console.log("error rejectFile", error)
        }
      })
    } else {
      this.attachSubscriptions(
        this.fileService.editFile(file.id, {
          location: '/reject'
        }, this.company.id).subscribe(res => {
          this.fileService.checkRejectFolder$.next(true);
          this.detectRejectFiles();
          this.files.splice(this.files.indexOf(this.files.find(t => t.id == file.id)), 1)
          console.log(res)
        })
      )
    }
  }

  clFile(file) {
    console.log(file)
  }

  selectCheckList(file) {
    console.log(file)
    this.close();
    let work = this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company_id, {task_operation_id: work.id}).subscribe(resp => {
        console.log("getOperationReminder", resp);
        const dialogRef = this.dialog.open(SelectChecklistComponent, {
          data: {
            company: this.company,
            task: this.task,
            work: work,
            user: this.user,
            file: file,
            checklist: resp.body,
            operationsValues: this.operationsValues
          }
        });

        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            if (!!result.must_delete && this.files.find(x => x.id == file.id)) {
              this.files.splice(this.files.indexOf(file), 1)
            }
            this.refreshService.refreshTask$.next({company_id: this.company.id, task_id: this.task.id, fromFileManager: true})
          })
        )
      })
    )
  }

  detectRejectFiles() {
    console.log("START1 detectRejectFiles")
    let work = this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work

    work.mobEmployees = [];
    // let allEmployees = [...work.employees.filter(x => x.task_operation_id == work.id), ...work.partnerCompanies.filter(x => x.task_operation_id == work.id).map(b => b.is_partner = true), ...work.partnerEmployees.filter(x => x.task_operation_id == work.id).map(b => b.is_partner_employee = true)];
    let allEmployees = [...work.employees.filter(x => x.task_operation_id == work.id), ...work.partnerCompanies.filter(x => x.task_operation_id == work.id).map(b => b.is_partner = true), ...work.partnerEmployees.filter(x => x.task_operation_id == work.id).map(b => b.is_partner_employee = true)];
    allEmployees.forEach(empl => {
      if (!work.mobEmployees.find(x => x.employee_id == empl.employee_id)) {
        work.mobEmployees.push(empl)
      }
    });

    console.log("detectRejectFiles work", work)

    // console.log("!work", !work)
    // console.log("work.status_id != 3", work.status_id != 3)
    // console.log("!this.company.permissions.includes('owner')", !this.company.permissions.includes("owner"))
    // console.log("!this.company.permissions.includes('owner') || !this.checkIsManager(this.task, this.company, this.user)", !(this.company.permissions.includes("owner") || this.checkIsManager(this.task, this.company, this.user)))
    // console.log("!this.checkIsManager(this.task, this.company, this.user)", !this.checkIsManager(this.task, this.company, this.user))
    
    if (!work || work.status_id != 3 || !(this.company.permissions.includes("owner") || this.checkIsManager(this.task, this.company, this.user))) {
      return
    }

    console.log("START2 detectRejectFiles")
    
    this.fileService.detectFiles("/to_approve", "0", this.company.id, this.task_operation_id).pipe(
      map(x => !!x.length),
      filter(x => !x),
      switchMap(() => forkJoin([
        this.fileService.detectFiles("/approved", "0", this.company.id, this.task_operation_id).pipe(map(b => !!b.length)),
        this.fileService.detectFiles("/ready_files", "0", this.company.id, this.task_operation_id).pipe(map(b => !!b.length)),
      ])),
      tap(vals => console.log("vals", vals)),
      map(vals => vals.filter(x => x == false).length == 2 ? true : false),
      switchMap(b => {
        if (!!b) {
          return this.taskService.getOperationsStatus().pipe(
            tap(res => {
              this.statuses = res.slice();
            }),
            switchMap(m => {
              return this.chatService.getChatsExpand('1', this.company.id, {task_operation_id: work.id}, '200').pipe(
                tap(n => {
                  work.chatsInfo = n.body
                })
              )
            }),
            map(() => b)
          )
        } else {
          return of(b)
        }

      })
    ).subscribe(resp => {
      console.log("detectRejectFiles sub", resp);
      if (resp) {
        const statAndEmplRef = this.dialog.open(SelectStatusAndEmployeeComponent, {
          panelClass: 'open_task_dialog',
          data: {
            company: this.company,
            imgRoute: this.imgRoute,
            is_mobile: this.is_mobile,
            host: this.host,
            statuses: this.statuses,
            employees: work.mobEmployees,
          }
        });
        
        
        this.attachSubscriptions(
          statAndEmplRef.afterClosed().subscribe(result => {
            if (result.event && result.event == "select") {
              const dialogRef = this.dialog.open(StatusReasonComponent, {
                panelClass: 'open_task_dialog',
                data: {
                  task: this.task,
                  job: work,
                  user: this.user,
                  activeLang: this.activeLang,
                  company: this.company,
                  imgRoute: this.imgRoute,
                  is_mobile: this.is_mobile,
                  host: this.host,
                  empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
                  statuses: this.statuses,
                  status: result.data.status,
                  selected_empls: result.data.employees,
                  operationsValues: this.operationsValues,
                  is_rejected: true
                }
              });
              
              this.attachSubscriptions(
                dialogRef.afterClosed().subscribe(afterResult => {
                  if (afterResult.change) {
                    forkJoin(result.data.employees.map(empl => {
                      if (!!empl.is_partner) {
                        if (!!empl.partnerCompanyStatus?.id) {
                          return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: result.data.status.id}, this.company_id).pipe(
                            catchError(error => {
                              this.showStatusError(error, empl?.partnerCompany?.name)
                              return of(false)
                            })
                          )
                        } else {
                          return this.membersService.addPartnerStatus({
                            status_id: result.data.status.id,
                            company_id: this.company_id,
                            task_id: work.task_id,
                            task_operation_id: work.id,
                            partner_company_id: empl.partner_company_id,
                          }, this.company_id).pipe(
                            catchError(error => {
                              this.showStatusError(error, empl?.partnerCompany?.name)
                              return of(false)
                            })
                          )
                        }
                      } else if (!!empl.is_partner_employee) {
                        if (!!empl.employeeStatus?.id) {
                          return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: result.data.status.id}, this.company_id).pipe(
                            catchError(error => {
                              this.showStatusError(error, empl?.employee?.display_name)
                              return of(false)
                            })
                          )
                        } else {
                          return this.membersService.addPartnerEmployeeStatus({
                            status_id: result.data.status.id,
                            company_id: this.company_id,
                            task_id: work.task_id,
                            task_operation_id: work.id,
                            partner_company_id: empl.partner_company_id,
                            partner_employee_id: empl.partner_employee_id
                          }, this.company_id).pipe(
                            catchError(error => {
                              this.showStatusError(error, empl?.employee?.display_name)
                              return of(false)
                            })
                          )
                        }
                      } else {
                        if (!!empl.employeeStatus?.id) {
                          return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: result.data.status.id}, this.company_id).pipe(
                            catchError(error => {
                              this.showStatusError(error, empl?.employee?.display_name)
                              return of(false)
                            })
                          )
                        } else {
                          return this.membersService.addTaskEmployeeStatus({
                            status_id: result.data.status.id,
                            company_id: this.company_id,
                            task_id: work.task_id,
                            task_operation_id: work.id,
                            employee_id: empl.employee_id,
                          }, this.company_id).pipe(
                            catchError(error => {
                              this.showStatusError(error, empl?.employee?.display_name)
                              return of(false)
                            })
                          )
                        }
                      }
                    })).subscribe(resp => {
                      this.refreshService.refreshTask$.next({company_id: this.company.id, task_id: this.task.id, fromFileManager: true})
                    })
                  }
                })
              )
            }
          })
        )
      }
    })
  }

  showStatusError(error, user?) {
    this.layoutService.showSnackBar({name: user}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  openNotes(item, type?) {
    this.close();
    console.log(item);
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        type: type || 'file',
        item: item,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        
        this.deSelectAll()
      })
    )
  }

  detectFiles() {
    console.log("START1")
    let work = this.task && this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work
    console.log("detectFiles", work)

    // console.log("!work", !work)
    // console.log("work.status_id != 3", work.status_id != 3)
    // console.log("!this.company.permissions.includes('owner')", !this.company.permissions.includes("owner"))
    // console.log("!this.company.permissions.includes('owner') || !this.checkIsManager(this.task, this.company, this.user)", !(this.company.permissions.includes("owner") || this.checkIsManager(this.task, this.company, this.user)))
    // console.log("!this.checkIsManager(this.task, this.company, this.user)", !this.checkIsManager(this.task, this.company, this.user))
    
    if (!work || work.status_id != 3 || !(this.company.permissions.includes("owner") || this.checkIsManager(this.task, this.company, this.user))) {
      return
    }

    console.log("START2")
    
    this.fileService.detectFiles("/to_approve", "0", this.company.id, this.task_operation_id).pipe(
      map(x => !!x.length),
      filter(x => !x),
      switchMap(() => forkJoin([
        this.fileService.detectFiles("/approved", "0", this.company.id, this.task_operation_id).pipe(map(b => !!b.length)),
        this.fileService.detectFiles("/ready_files", "0", this.company.id, this.task_operation_id).pipe(map(b => !!b.length)),
      ])),
      tap(vals => console.log("vals", vals)),
      map(vals => vals.filter(x => x == true).length > 0 ? true : false)
    ).subscribe(resp => {
      console.log(resp);
      if (resp) {
        const dialogRef = this.dialog.open(ApproveJobComponent, {
          data: {
            task: this.task,
            work: work,
            company: this.company
          }
        });
        
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            if (result.event && result.event == "yes") {
              this.refreshService.refreshTask$.next({company_id: this.company.id, task_id: this.task.id, fromFileManager: true})
            }
          })
        )
      }
    })
  }

  jobDrop(e, job:any) {
    job.over = false
    let file_ids = e.dataTransfer.getData("application/my-app").split(',');

    forkJoin(file_ids.map(x => this.copyFunc({
      id: x,
      company_id: this.target_company_id || this.company.id || this.company_id || x.company_id,
      task_id: job.task_id,
      task_operation_id: job.id,
      location: '/'
    }))).subscribe(resp => {
      console.log(resp)
      this.deSelectAll()
    })
  }

  handleDragEnd(e, file) {
    this.folders.map(val => val.over = false)
    this.files.map(val => val.dragged = false)
    this.isDragged = false;
  }

  // FILE LOGIC

  downloadJD(file) {
    this.close();
    window.open(this.host + `/file/download/?company_id=${this.company_id}&id=${file.id}&type=crawljob`, '_blank');
    
    // this.attachSubscriptions(
    //   this.fileService.downloadFileJD(this.company_id, file.id).subscribe((resp:any) => {
    //     // saveAs(resp.download_url, file.filename);

    //     // new method
    //     console.log("downloadJD resp", resp)

    //     // var a = document.createElement('a');
    //     // if (resp.download_url) {
    //     //   a.setAttribute('href', resp.download_url + `&filename=${file.filename}`);
    //     // } else {
    //     //   a.setAttribute('href', this.host + file.original + '?company_id=' + this.company_id + `&filename=${file.filename}`);
    //     // }
    //     // a.setAttribute('download','download');
    //     // // a.target = "_blank"
    //     // a.click();
    //     // a.remove();
    //   })
    // )
  }

  moveToFolder(file, toFolder) {
    let allFiles = [];
    if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
      this.selectedFiles.selected.forEach(id => {
        let exFile;
        if (this.files.find(b => b.id == id)) {
          exFile = this.files.find(b => b.id == id);
        } else if (this.boxFiles.selected.find(b => b.id == id)) {
          exFile = this.boxFiles.selected.find(b => b.id == id)
        }
        if (exFile) {
          allFiles.push(exFile)
        }
      })
    } else {
      allFiles.push(file)
    }

    let lenSelection = allFiles.length;
    let count = 0;

    this.close()
    if (!allFiles.length) {
      return
    }
    this.ls.requests$.next({
      value: 0,
      target: `Moving ${lenSelection} file(s) to folder ${toFolder.name}`,
    })

    from(allFiles).pipe(
      concatMap((exFile:any) => {
        return this.fileService.editFile(exFile.id, {location: toFolder.location}, this.company_id).pipe(
          tap(editedFile => {
            if (this.files.find(t => t.id == exFile.id) && exFile.location != toFolder.location) {
              this.files.splice(this.files.findIndex(t => t.id == exFile.id), 1)
            }
          }),
          catchError(error => {
            return of(error)
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next moveToFolder", next);
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: `Moving ${lenSelection} file(s) to folder ${toFolder.name}`,
        })
        count++;
      },
      complete: () => {
        console.log("complete moveToFolder");
        this.selectedFiles.clear();
      },
      error: (error) => {
        console.log("error moveToFolder", error)
      }
    })
  }

  download(file) {
    this.close();
    window.open(this.host + file.original + '?company_id=' + this.company_id + `&filename=${file.filename}`, '_blank');
    
    // this.attachSubscriptions(
    //   this.fileService.getPdfSrc(file, this.company_id).subscribe((resp:any) => {
    //     // saveAs(resp.download_url, file.filename);

    //     // new method
    //     console.log("resp", resp)

    //     var a = document.createElement('a');
    //     if (resp.download_url) {
    //       a.setAttribute('href', resp.download_url + `&filename=${file.filename}`);
    //     } else {
    //       a.setAttribute('href', this.host + file.original + '?company_id=' + this.company_id + `&filename=${file.filename}`);
    //     }
    //     a.setAttribute('download','download');
    //     // a.target = "_blank"
    //     a.click();
    //     // a.remove();
    //   })
    // )
  }

  archive(close?, file?) {
    if (close) {
      this.close()
    }

    if (file) {
      console.log("archive", file)
      this.createArchive(file)
    } else {
      console.log("archive", "no file")
      if (this.crumbs[this.crumbs.length - 1].target && this.crumbs[this.crumbs.length - 1].target.acm) {
        console.log("archive", "no file", this.crumbs[this.crumbs.length - 1].target);
      } else {
        this.attachSubscriptions(
          this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {is_dir: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: this.crumbs.length > 1 ? this.crumbs[this.crumbs.length - 2].location : '/'}).pipe(
            map(x => x.body)
          ).subscribe(res => {
            if (!!res.length && res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)) {
              let targetFolder = res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)
              if (targetFolder) {
                this.createArchive(targetFolder)
              }
              console.log("archive targetFolder", "no file", targetFolder)
            }
          })
        )
      }
    }
  }

  createArchive(folder) {
    const dialogRef = this.dialog.open(CreateArchiveComponent, {
      data: {
        company: this.company,
        target_company_id: this.target_company_id,
        company_id: this.company_id,
        folder: folder
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result && result.event == 'create') {
          this.page = 1;
          this.getFiles(this.page, this.form.value.location);
        }
      })
    )
  }
  
  downloadFolder(close?, folder?) {
    if (close) {
      this.close()
    }

    if (folder) {
      console.log("archive", folder)
      this.layoutService.showSnackBar({}, "Folder is getting ready for download. Don't close this browser tab. Download will start automatically when folder archive will be completed.", SnackBarItem)
      let postData:any = {
        company_id: folder.company_id,
        is_deferred: 1,
        file_id: [folder.id],
        delete_at: moment().add(1, 'd').format("X")
      }
      this.createdArchForDownSub = this.fileService.createArchive(postData, this.company_id).pipe(
        take(1)
      ).subscribe(resp => {
        console.log(resp)
        if (resp && resp.is_deferred == 1) {
          let download_archives = JSON.parse(this.sm.localStorageGetItem('download_archives')) || []
          download_archives.push({
            c_id: folder.company_id,
            a_id: resp.id,
            u_id: this.user.id
          })
          this.sm.localStorageSetItem('download_archives', JSON.stringify(download_archives))
        }
        // if (resp.download_url.length) {
        //   async function loop() {
        //     for (let i = 0; i < resp.download_url.length; i++) {
        //       await new Promise((res) => setTimeout(res, i == 0 ? 0 : 4000)); // пауза перед очередным
        //       console.log(resp.download_url[i]); // вывод блока
        //       if (resp.download_url[i]) {
        //         let a = document.createElement('a');
        //         a.setAttribute('href', this.host + resp.download_url[i]);
        //         a.setAttribute('download','download');
        //         // a.target = "_blank"
        //         a.click();
        //       }
        //     }
        //   }
        //   loop();
        // }
      }, error => {
        this.layoutService.showSnackBar({}, "Something went wrong.", SnackBarItem)
      })
      
    } else {
      console.log("archive", "no file")
      if (this.crumbs[this.crumbs.length - 1].target && this.crumbs[this.crumbs.length - 1].target.acm) {
        console.log("archive", "no file", this.crumbs[this.crumbs.length - 1].target);
        this.layoutService.showSnackBar({}, "Something went wrong.", SnackBarItem)
      } else {
        this.layoutService.showSnackBar({}, "Folder is getting ready for download. Don't close this browser tab. Download will start automatically when folder archive will be completed.", SnackBarItem)
        this.createdArchForDownSub = this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {is_dir: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: this.crumbs.length > 1 ? this.crumbs[this.crumbs.length - 2].location : '/'}).pipe(
          map(x => x.body),
          map(res => {
            if (!!res.length && res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)) {
              let targetFolder = res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)
              return of(targetFolder)
            } else {
              return of(false)
            }
          }),
          switchMap((res:any) => {
            if (res) {
              let postData:any = {
                company_id: this.company_id,
                is_deferred: 1,
                file_id: [res.id],
                delete_at: moment().add(1, 'd').format("X")
              }
              return this.fileService.createArchive(postData, this.company_id)
            } else {
              return of(res)
            }
          }),
          take(1)
        ).subscribe(response => {
          if (response && response.is_deferred == 1) {
            let download_archives = JSON.parse(this.sm.localStorageGetItem('download_archives')) || []
            download_archives.push({
              c_id: folder.company_id,
              a_id: response.id,
              u_id: this.user.id
            })
            this.sm.localStorageSetItem('download_archives', JSON.stringify(download_archives))
          }
        }, error => {
        this.layoutService.showSnackBar({}, "Something went wrong.", SnackBarItem)
      })
      }
    }    
  }

  downloadArchive(folder) {
    let archive = folder.archives.filter(x => x.is_created == 1)[0]
    this.close();
    this.attachSubscriptions(
      this.fileService.downloadCreatedArchive(archive.id, this.company_id).subscribe(resp => {
        // console.log(resp)
        // return;
        if (resp.download_url.length) {
          async function loop() {
            for (let i = 0; i < resp.download_url.length; i++) {
              await new Promise((res) => setTimeout(res, i == 0 ? 0 : 4000)); // пауза перед очередным
              console.log(resp.download_url[i]); // вывод блока
              if (resp.download_url[i]) {
                let a = document.createElement('a');
                a.setAttribute('href', resp.download_url[i]);
                a.setAttribute('download','download');
                // a.target = "_blank"
                a.click();
              }
            }
          }
          loop();
        }
      })
    )
  }

  openFolderArchives(folder) {
    this.close()
    console.log(folder)
    const dialogRef = this.dialog.open(FolderArchivesComponent, {
      data: {
        company: this.company,
        folder: folder,
        target_company_id: this.target_company_id,
        company_id: this.company_id,
        task_id: this.task_id, 
        task_operation_id: this.task_operation_id,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {

      })
    )
  }
  
  showFileLocation(file) {
    let fileLocationString = file.location
    if (!!fileLocationString) {
      if (file.location == '/') {
        fileLocationString = "Root"
      } else {
        this.folders.filter(x => x.rootDir && x.location != '/').forEach(el => {
          fileLocationString = (fileLocationString as any).replaceAll(el.location.replace('/',''), el.name)
        })
      }
    }

    return fileLocationString.split("/")
  }

  clearFolder(is_delete, is_delete_copies:boolean = false) {

    if (!!this.isCleared) {
      this.layoutService.showSnackBar({name: ''}, marker("The logic is already running, wait until it finishes."), SnackBarItem)
      return
    }

    console.log('this.globalFilterFmFiles', this.globalFilterFmFiles);
    this.isCleared = true;
    let alertData = {}
    if (is_delete) {
      alertData = {
        targetVal: {},
        msg: marker(`Are you sure you want to delete ${this.filesCount} files ${is_delete_copies ? 'and their copies' : ''} its can't be restored anymore.`),
        msg_1: marker("Proceed with files delete?")
      }
    } else {
      alertData = {
        targetVal: {},
        msg: marker(`Are you sure you want to move ${this.filesCount} files ${is_delete_copies ? 'and their copies' : ''} to the trash?`),
        msg_1: marker("Proceed with files moving?")
      }
    }

    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
    hasBackdrop: true,
    backdropClass: 'bottom-sheed-backdrop',
      data: alertData
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        this.isCleared = false;
        return
      } else if (data && data.message == 'yes') {
        let bufferFiles = is_delete_copies ? [] : JSON.parse(JSON.stringify(this.files, this.getCircularReplacer()));
        let bufferPage = is_delete_copies ? 1 : this.page;
        let bufferPagination = this.pagination;
        if (is_delete_copies) {
          bufferPagination.currentPage = 0
        }
        let length = bufferPagination['pageCount']-bufferPagination['currentPage'];
        let count = 0;
        this.ls.requests$.next({
          value: is_delete_copies ? 0 : Math.round((100 / length) * (count+1)),
          // target: is_delete ? `Deleting ${this.filesCount} files` : `Moving ${this.filesCount} files`
          target: "Getting all files"
        })
    
        this.getBufferFiles(bufferPage, bufferFiles, bufferPagination, length, count, is_delete, is_delete_copies);
      } else {
        this.isCleared = false;
      }
    });


  }

  getBufferFiles(n, array, pagination, length, count, is_delete, is_delete_copies?) {
    console.log("getBufferFiles(n, array, pagination, length, count, is_delete, is_delete_copies)", n, array, pagination, length, count, is_delete, is_delete_copies)
    
    let params = {...this.globalFilterFmFiles}
    delete params.operation_status_id;

    if (!this.indicator()) {
      params.location = params.location_all;

      delete params.location_all;
    }

    console.log("params", params);

    this.isBufferLoad = true;

    this.filesBufferSub = this.fileService.getGlobalFileData(n, this.company_id, Object.assign(params , {summary: 1, order_by: this.sortValue})).pipe(
      tap(el => {
        pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
      }),
      map(el => el.body),
      switchMap(bFiles => {
        if (is_delete_copies) {
          return from(bFiles.filter(k => k.copies_count > 1)).pipe(
            concatMap((bFile:any) => {
              console.log("bFile", bFile)
              return this.fileService.getFileCopiesData("1", this.company_id, {'location_all': "/", task_id: 0, original_file_id : bFile.original_file_id || bFile.id}).pipe(
                tap(el => {
                  bFile.countSameFiles = el.headers.get('x-pagination-total-count');
                  bFile.sameFiles = el.body;
        
                  if (bFile.original_file_id) {            
                    if (bFile.sameFiles.find(x => x.id == bFile.id)) {
                      bFile.countSameFiles = bFile.countSameFiles - 1;
                      bFile.sameFiles.splice(bFile.sameFiles.findIndex(x => x.id == bFile.id), 1);
                    }
                  }
                })
              )
            }),
            last(),
            map(() => bFiles)
          )
        } else {
          return of(bFiles)
        }
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      console.log("getBufferFiles", resp)
      
      if (n == 1) {
        array = resp;
      } else {
        array.push(...resp);
      }
      n++;
      count++;
      this.ls.requests$.next({
        value: Math.round((100 / length) * (count+1)),
        target: "Getting all files"
        // target: is_delete ? `Deleting ${this.filesCount} files` : `Moving ${this.filesCount} files`
      })

      if (+pagination['currentPage'] < +pagination['pageCount'] && +pagination['pageCount'] > 0) {
        this.getBufferFiles(n, array, pagination, length, count, is_delete)
      } else {
        this.ls.requests$.next({
          value: 100,
          target: "Getting all files"
          // target: is_delete ? `Deleting ${this.filesCount} files` : `Moving ${this.filesCount} files`
        })

        let sbmtData = [];
        let partsSbmtData = [];

        console.log("100% is_delete", is_delete);
        console.log("100% array", array);

        array.forEach(file => {
          if (is_delete) {
            sbmtData.push({
              "path": `/api/file/${file.id}/`,
              "query": {company_id: this.company_id},
              "method": "DELETE",
            });

            if (is_delete_copies && file.sameFiles && file.sameFiles.length > 0) {
              file.sameFiles.forEach(sameFile => {
                sbmtData.push({
                  "path": `/api/file/${sameFile.id}/`,
                  "query": {company_id: this.company_id},
                  "method": "DELETE",
                });
              })
            }
      
          } else {
            if (file.location.indexOf('/trash') == -1) {
              sbmtData.push({
                "path": `/api/file/${file.id}/`,
                "query": {company_id: this.company_id},
                "method": "POST",
                "body": Object.assign({
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                }, {location: '/trash'})
              });
            }

            if (is_delete_copies && file.sameFiles && file.sameFiles.length > 0) {
              file.sameFiles.forEach(sameFile => {
                if (sameFile.location.indexOf('/trash') == -1) {
                  sbmtData.push({
                    "path": `/api/file/${sameFile.id}/`,
                    "query": {company_id: this.company_id},
                    "method": "POST",
                    "body": Object.assign({
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    }, {location: '/trash'})
                  });
                }
              })
            }
          }
        })

        
      
        for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
          partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
        }
      
        let delCount = 1;
        this.ls.requests$.next({
          value: 0,
          target: is_delete ? `Deleting ${this.filesCount} files forever` : `Moving ${this.filesCount} files to trash`
        })

        concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
          tap(el => {
            this.ls.requests$.next({
              value: Math.round((100 / partsSbmtData.length) * delCount),
              target: is_delete ? `Deleting ${this.filesCount} files forever` : `Moving ${this.filesCount} files to trash`
            })
            delCount++;
          })
        ).subscribe({
          next: (next) => {
            // console.log("next Fin", next);
            console.log("concat next", next)
          },
          complete: () => {
            this.isCleared = false;
            console.log("concat complete")
            let params = {...this.activatedRoute.queryParamMap.source['_value']}
            params.count = !!params.count ? +params.count + 1 : 1; 
        
            this.router.navigate(['/files'], {queryParams: params})
          },
          error: (error) => {
            this.isCleared = false;
            console.log("concat error", error)
          }
        })
      }
      this.isBufferLoad = false;
    },
    error => {
      this.isCleared = false;
      this.isBufferLoad = false;
    })
    
  }

  delete(file) {
    this.close();
    if (!this.selectedFiles.isSelected(file.id)) {
      this.deSelectAll()
    }
    this.selectedFiles.select(file.id)


    if (this.crumbs.length == 1 || this.crumbs[1].location != "/trash") {
      console.log("this is trash")

      from(this.selectedFiles.selected).pipe(
        concatMap(id => {
          console.log("concatMap", id)
          return this.deleteFunc(this.files.find(u => u.id == id))
        }),
        finalize(() => {
          console.log('SUB finalize')
          this.selectedFiles.selected.forEach((b,i) => {
            this.files.splice(this.files.indexOf(this.files.find(x => x.id == b)), 1)
            if (i == this.selectedFiles.selected.length - 1) {
              this.deSelectAll();
              this.detectFiles();
            }
          })
        })
      ).subscribe(resp => {
        console.log('SUB')
      })

      // forkJoin(this.selectedFiles.selected.map(x => this.deleteFunc(this.files.find(z => z.id == x)))).subscribe(resp => {
      //   resp.forEach(z => {
      //     this.files.splice(this.files.indexOf(this.files.find(x => x.id == z)), 1)
      //   })
      //   this.detectFiles();
      //   this.deSelectAll()
      // })
    } else {
      if (!this.company.permissions.includes('owner') && !this.company.permissions.includes('admin')) {
        this.layoutService.showSnackBar({name: ''}, marker("Your rights are limited to delete files from task file manager."), SnackBarItem)
        return
      }
      let alertData = {}
      if (this.selectedFiles.selected.length > 1) {
        alertData = {
          targetVal: {},
          msg: marker("After you delete the files it can't be restored anymore."),
          msg_1: marker("Proceed with file delete?")
        }
      } else {
        alertData = {
          targetVal: {},
          msg: marker("After you delete the file it can't be restored anymore."),
          msg_1: marker("Proceed with file delete?")
        }
      }

      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
        data: alertData
      });
  
      deleteAlert.afterDismissed().subscribe( data => {
        if (data && data.message == 'no') {
          console.log("no");
          return
        } else if (data && data.message == 'yes') {
          forkJoin(this.selectedFiles.selected.map(x => this.fileService.deleteFile(x))).subscribe({
            next: (next) => {
              console.log("next", next)
            },
            complete: () => {
              console.log("complete")
              this.selectedFiles.selected.forEach((y,i) => {
                this.files.splice(this.files.indexOf(this.files.find(t => t.id == y)), 1)
                if (i == this.selectedFiles.selected.length - 1) {
                  this.deSelectAll()
                }
              })
              this.detectFiles();
            },
            error: (error) => {
              console.log(error)
              this.deSelectAll()
            }
          })
        }
      });
    }
  }

  emptyTrash(file?) {
    this.close();

    let alertData = {
      targetVal: {},
      msg: `Are you sure you want empty Trash?`,
      btn_yes: "Yes",
      btn_no: "No"
    }
 

    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: alertData
    });

    this.attachSubscriptions(
      deleteAlert.afterDismissed().subscribe(data => {
        if (data && data.message == 'no') {
          console.log("no");
        } else if (data && data.message == 'yes') {
          console.log("yes");
          this.deleteTrash(file, true)
        }
      })
    )

  }

  deleteTrash(file, withDel:boolean = true) {
    if (!!file) {
      this.attachSubscriptions(
        this.fileService.deleteFile(file.id).subscribe(resp => {
          if (withDel) {
            if (this.files.find(t => t.id == file.id)) {
              this.files.splice(this.files.indexOf(this.files.find(t => t.id == file.id)), 1)
            }
          } else {
            this.crumbClick(this.crumbs[0], 0);
          }
        }, error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {is_dir: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, location: '/'}).pipe(
          map(x => x.body)
        ).subscribe(res => {
          if (!!res.length && res.find(x => x.filename == 'trash' && x.location == '/')) {
            let targetFolder = res.find(x => x.filename == 'trash' && x.location == '/')
            this.deleteTrash(targetFolder, false)
          }
        })
      )
    }
  }

  deleteFunc(file) {
    let data:any = {"location": '/trash'}
    return this.fileService.editFile(file.id, data, this.company.id).pipe(
      catchError(error => {
        console.log("handleDropFunc ERROR", error)
        data.filename = error[1].message
        return throwError('Error!');
      }),
      retry(1),
      switchMap(val => {
        if (file.location.indexOf("to_approve") != -1) {
          return this.fileService.detectFiles("/to_approve", 0, this.company_id, this.task_operation_id).pipe(
            switchMap(val => {
              if (val.length == 0) {
                return this.taskService.editWork(this.task_operation_id, {is_to_approve_files: 0}, this.company_id).pipe(map(() => of(file.id)))
              } else {
                return of(file.id)
              }
            })
          )
        } else if (file.location.indexOf("ready_files") != -1) {
          return this.fileService.detectFiles("/ready_files", 0, this.company_id, this.task_operation_id).pipe(
            switchMap(val => {
              if (val.length == 0) {
                return this.taskService.editWork(this.task_operation_id, {is_approved_files: 0}, this.company_id).pipe(map(() => of(file.id)))
              } else {
                return of(file.id)
              }
            })
          )
        } else {
          return of(file.id)
        }
      })
    )
  }
  
  settings(file?) {
    this.close();
    if (file) {
      let _task = this.task || file.task;
      let _work;
      if (_task && _task.operations) {
        _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
      }
      const dialogRef = this.dialog.open(FileSettingsComponent, {
        data: {
          company_id: this.company_id,
          target_company_id: this.target_company_id,
          company: this.company,
          file: file,
          user: this.user,
          imgRoute: this.imgRoute,
          activeLang: this.activeLang,
          host: this.host,
          location: this.form.value.location,
          task: _task,
          work: _work,
          operationsValues: this.operationsValues
        }
      });
    } else {
      console.log(this.crumbs)
    }


    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == 'create') {
    //       this.files.push(result.data)
    //     }
    //   })
    // )
  }

  sort(val) {
    console.log(val);

    if (this.is_global) {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      params.order_by = val
  
      this.router.navigate(['/files'], {queryParams: params})
    } else {
      this.sortValue = val;
      this.filterFmFiles()
    }

  }

  createDocument() {
    this.close();

    if (this.is_global && !this.globalFilterFmFiles.task_id) {
      this.layoutService.showSnackBar({name: ''}, marker("You can only create a folder within a task or job. To add a folder, go to 1 of the existing folders (it already belongs to a task or work)."), SnackBarItem)
      return;
    }

    const dialogRef = this.dialog.open(AddEditNoteComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        task_id: !this.is_global ? this.task_id : this.globalFilterFmFiles.task_id,
        work_id: !this.is_global ? this.task_operation_id : this.globalFilterFmFiles.work_id,
        files: this.files,
        location: this.form.value.location,
        user: this.user,
        is_file: true
      }
    });
  }

  createPostDocument() {
    this.close();

    if (this.is_global && !this.globalFilterFmFiles.task_id) {
      this.layoutService.showSnackBar({name: ''}, marker("You can only create a folder within a task or job. To add a folder, go to 1 of the existing folders (it already belongs to a task or work)."), SnackBarItem)
      return;
    }

    const dialogRef = this.dialog.open(AddEditPostDocumentComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        task_id: !this.is_global ? this.task_id : this.globalFilterFmFiles.task_id,
        work_id: !this.is_global ? this.task_operation_id : this.globalFilterFmFiles.work_id,
        files: this.files,
        location: this.form.value.location,
        user: this.user,
        is_file: true
      }
    });
  }
  
  createFolder(file?) {
    this.close();

    let file_ids = [];

    if (file) {
      if (this.selectedFiles.isSelected(file.id)) {
        file_ids = this.selectedFiles.selected.slice()
      } else {
        file_ids.push(file.id)
      }
    }
    console.log('file_ids', file_ids)

    if (this.is_global && !this.globalFilterFmFiles.task_id) {
      this.layoutService.showSnackBar({name: ''}, marker("You can only create a folder within a task or job. To add a folder, go to 1 of the existing folders (it already belongs to a task or work)."), SnackBarItem)
      return;
    }

    const dialogRef = this.dialog.open(CreateFolderComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        globalFilterFmFiles: this.globalFilterFmFiles,
        task_id: !this.is_global ? this.task_id : this.globalFilterFmFiles.task_id,
        work_id: !this.is_global ? this.task_operation_id : this.globalFilterFmFiles.work_id,
        files: this.files,
        location: this.form.value.location,
        is_global: this.is_global,
        file_ids: file_ids
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'create') {
            file_ids.forEach((y,i) => {
              if (this.files.indexOf(this.files.find(t => t.id == y))) {
                this.files.splice(this.files.indexOf(this.files.find(t => t.id == y)), 1)
                if (i == file_ids.length - 1) {
                  this.deSelectAll()
                }
              }
            })
          }
          // if (result.event == 'upload') {
          //   console.log(this.uploader);
          //   let el: HTMLElement = this.uploader.nativeElement;
          //   el.click();
          // } else if (result.event == 'upload_folder') {
          //   console.log(this.uploaderFolder);
          //   let el: HTMLElement = this.uploaderFolder.nativeElement;
          //   el.click();
          // }
          // this.files.push(result.data)
        }
      })
    )
  }

  edit(file) {
    console.log(file)
    this.close();
    const dialogRef = this.dialog.open(RenameComponent, {
      data: {
        file: file,
        company: this.company
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == 'Edit') {
    //       task.custom_id = result.data.custom_id;
    //     }
    //   })
    // )
  }

  setTaskCover(file) {
    let _task = this.task || file.task
    this.attachSubscriptions(
      this.taskService.editTask(_task.id, {avatar_file_id: file.id}, this.company.id).subscribe(resp => {
        _task.avatar_file_id = resp.avatar_file_id;
        _task.avatarFile = file;
        this.close()
      })
    )
  }

  getProfile(id) {
    if (!this.profiles || !this.profiles.find(el => el.id == id)) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getChecklist(id) {
    if (!this.checklists || !this.checklists.find(el => el.id == id)) {
      return false;
    }
    return this.checklists.find(el => el.id == id)
  }  

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  closeFileChannels() {
    this.fileChannelsSub && this.fileChannelsSub.unsubscribe();
    if (this.overlayFileChannels) {
      this.overlayFileChannels.dispose();
      this.overlayFileChannels = null;
    }
  }  

  openFileChannels({ x, y }: MouseEvent, val) {
    this.closeFileChannels();

    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      }
    ]);


    this.overlayFileChannels = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayFileChannels.attach(new TemplatePortal(this.channelsMenu, this.viewContainerRef, {
      $implicit: val
    }));
  

    this.fileChannelsSub = fromEvent<MouseEvent>(document, 'click')
    .pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
      
        let check1 = !!this.overlayFileChannels && !this.overlayFileChannels.overlayElement.contains(clickTarget)
        return check1
      }),
      take(1)
    ).subscribe(() => this.closeFileChannels())
  }

  openChannelContent(url) {
    if (url) {
      window.open(url, "_blank")
    }
  }

  closeRelations() {
    this.relationsSub && this.relationsSub.unsubscribe();
    if (this.overlayRelations) {
      this.overlayRelations.dispose();
      this.overlayRelations = null;
    }
  }  

  openRelations({ x, y }: MouseEvent, val, key) {
    this.closeRelations();
    val.openRelations = key;
    let filterRel;
    if (key == 'part_of') {
      filterRel = {consist_of_file_id: val.original_file_id};
    } else {
      filterRel = {file_id: val.original_file_id};
    }
    this.attachSubscriptions(
      this.fileService.getFilePartition("1", this.company ? this.company.id : this.company_id, filterRel).subscribe(resp => {
        val[key] = resp;
        console.log("get " + key, val);
      
        const positionStrategy = this.overlay.position()
        .flexibleConnectedTo({ x, y })
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          }
        ]);
    
    
        this.overlayRelations = this.overlay.create({
          backdropClass: 'transparent_bg',
          hasBackdrop: true,
          positionStrategy,
          scrollStrategy: this.overlay.scrollStrategies.close()
        });
    
        this.overlayRelations.attach(new TemplatePortal(this.relationsMenu, this.viewContainerRef, {
          $implicit: val
        }));
      
      })
    )

    this.relationsSub = fromEvent<MouseEvent>(document, 'click')
    .pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
      
        let check1 = !!this.overlayRelations && !this.overlayRelations.overlayElement.contains(clickTarget)
        return check1
      }),
      take(1)
    ).subscribe(() => this.closeRelations())
  }

  closeParameterFilter() {
    this.parameterFilterSub && this.parameterFilterSub.unsubscribe();
    if (this.overlayParameterRefFilter) {
      this.overlayParameterRefFilter.dispose();
      this.overlayParameterRefFilter = null;
    }
  }

  openParameterFilter(el, val) {
    this.closeParameterFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayParameterRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayParameterRefFilter.attach(new TemplatePortal(this.filesFilterParameterMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.parameterFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
       
          let check1 = !!this.overlayParameterRefFilter && !this.overlayParameterRefFilter.overlayElement.contains(clickTarget)
          // let check2 = (document.getElementsByClassName('filter_autocomlete').length > 0 && !document.getElementsByClassName('filter_autocomlete')[0].contains(clickTarget))
          // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
          // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
       
//(check1 && check2) || (check1 && check3 && !check4)
          return check1
        }),
        take(1)
      ).subscribe(() => this.closeParameterFilter())

  }

  previewVideo(file) {
    console.log(file)
    console.log(this.fileRoute)
    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }
    this.close();
    const dialogRef = this.dialog.open(VideoViewerComponent, {
      panelClass: 'video_viewer',
      autoFocus: false,
      data: {
        file: file,
        files: this.files,
        playlist: this.files.filter(x => (x.is_uploaded || !!x.is_document) && (!!x.is_document || (x.content_type.indexOf("image") != -1 || x.content_type.indexOf("video") != -1 || x.content_type.indexOf("audio") != -1) || x.content_type == 'application/pdf' || !!x.thumbnail)),
        task: _task,
        work: _work || file.work || file.taskOperation,
        operationsValues: this.operationsValues,
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        boxFiles: this.boxFiles,
        initCompanyId: this.company_id
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(res => {
        if (this.sm.localStorageGetItem("copiedFiles")) {
          this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
        } else {
          this.copiedFiles = undefined;
        }
        if (this.boxFiles.isEmpty()) {
          this.boxMode = false;
        }
      })
    )
  }


  previewImage(e, file) {
    this.close()
    // console.log();
    // // console.log("previewImage", this._ngxViewer.viewerShow)
    // this._ngxViewer.viewerShow
    // this.fileClick(file)
  }

  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
    const isVisible = $event.target.visibilityState === 'visible';
    // console.log("&&&&", isVisible)
    if (isVisible) {
      if (this.sm.localStorageGetItem("copiedFiles")) {
        this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
      } else {
        this.copiedFiles = undefined;
      }
        // tab is visible
    } else {
        // tab is not-visible
    }
  }

  pasteHandler(e) {
    // console.log("FM component e", e)
    // console.log("FM component e.target.tagName", e.target.tagName)
    // console.log("FM component e.clip", e.clipboardData);
    // console.log("this.dialog.openDialogs[this.dialog.openDialogs.length - 1]", this.dialog.openDialogs[this.dialog.openDialogs.length - 1])
    // console.log("this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.dialogHasFm", this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.dialogHasFm)

    if (["INPUT", "TEXTAREA"].includes(e.target.tagName) || !this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.dialogHasFm) {
      return
    }

    if (!this.isWriteFolder()) {

      let x = "You can't upload files here."
      let y = ''

      if (this.foldersPerm.filter(u => !u.cant_write).length) {
        y = y + "You are allowed upload in folders: ";

        this.foldersPerm.filter(u => !u.cant_write).forEach((b, k) => {
          let name = b.name;
          if (k == this.foldersPerm.filter(u => !u.cant_write).length - 1) {
            name = name + ' or other nonsystem folders.'
          } else {
            name = name + ', '
          }
          y += name
        })
      }

      this.layoutService.showSnackBar({name: x}, marker(y), SnackBarItem)
      return
    }

    // added && e.clipboardData.files && e.clipboardData.files.length
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));

    if (!e.clipboardData) {
      return
    };

    let items = e.clipboardData.items;
    let files = []

    if (items) {
      for (var i = 0; i < items.length; i++) {
        var blob = items[i].getAsFile();
        if (!!blob) {
          files.push(blob);
        }
      }
    }
    console.log(files)

    if (files.length) {
      if (this.copiedFiles && this.copiedFiles.length) {
        const askDialogRef = this.dialog.open(AskPasteFilesComponent, {
          data: {
            company: this.company
          }
        });
  
        askDialogRef.afterClosed().subscribe(result => {
          if (!!result) {
            if (result.action == "reports") {
              this.paste(false, result.clear)
            } else {
              for (let index = 0; index < files.length; index++) {
                const element = files[index];
                // this.putFile(element);
                this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID});
              }
            }
          }
        })
      } else {
        for (let index = 0; index < files.length; index++) {
          const element = files[index];
          // this.putFile(element);
          this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID});
        }
      }
    } else {
      if (this.copiedFiles && this.copiedFiles.length) {
        this.paste()
      }
    }
  }


  pasteFile(event: ClipboardEvent) {
    // WORK FOR CHROME
    const clipboard: any = navigator.clipboard;
    if (clipboard && clipboard.read) {
      clipboard.read().then((clipboardItems) => {

        let files = []
        for (let ind = 0; ind < clipboardItems.length; ind++) {
          const clipboardItem = clipboardItems[ind];
          for (let index = 0; index < clipboardItem.types.length; index++) {
            const type = clipboardItem.types[index];
            clipboardItem.getType(type).then((blob) => {
              console.log("type, blob", type, blob)
              blob.name = `File copied at ${moment().format("MMM DD Y, hh:mm a")}.${mimeTypes.getExtension(type)}`;
              if (type.indexOf('text') == -1) {
                files.push(blob)
              }
              if (index == clipboardItem.types.length - 1 && clipboardItems.length - 1 == ind) {
                if (files.length) {
                  const askDialogRef = this.dialog.open(AskPasteFilesComponent, {
                    data: {
                      company: this.company
                    }
                  });
          
                  askDialogRef.afterClosed().subscribe(result => {
                    if (!!result) {
                      if (result.action == "reports") {
                        this.paste(false, result.clear)
                      } else {
                        for (let index = 0; index < files.length; index++) {
                          const element = files[index];
                          // this.putFile(element);
                          this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID});
                        }
                      }
                    }
                  })
                } else {
                  this.paste()
                }
              }
            });
          }
        }

        if (!clipboardItems || !clipboardItems.length) {
          this.paste()
        }

        console.log("this.copiedFiles", this.copiedFiles)
      }).catch(error => {
        console.log("ERROR")
        this.paste()
      });
    } else {
      this.paste()
    }
  }

  @HostListener('window:paste',['$event'])
  onKeyPress($event) {
    this.pasteHandler($event);
  }

  ngOnDestroy(): void {
    if (this.uploadProgress) {
      this.uploadProgress.unsubscribe()
    }
    if (this.specialParamDataSub) {
      this.specialParamDataSub.unsubscribe();
    }
    if (this.foldersExpandSub) {
      this.foldersExpandSub.unsubscribe()
    }
    if (this.subOne) {
      this.subOne.unsubscribe()
    }
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    if (this.subTwo) {
      this.subOne.unsubscribe()
    }
    if (this.subThird) {
      this.subThird.unsubscribe()
    }
    if (this.subFour) {
      this.subFour.unsubscribe()
    }
    if (this.subFiles) {
      this.subFiles.unsubscribe()
    }
    if (this.tasksSub) {
      this.tasksSub.unsubscribe()
    }
    if (this.subFilesCount) {
      this.subFilesCount.unsubscribe()
    }
    if (this.filesBufferSub) {
      this.filesBufferSub.unsubscribe()
    }
    if (this.subFoldersCount) {
      this.subFoldersCount.unsubscribe()
    }
    if (this.refreshSub) {
      this.refreshSub.unsubscribe()
    }
    if (this.refreshFileTagsSub) {
      this.refreshFileTagsSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
