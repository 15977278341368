<div class="mob_fm">
    <div class="mob_fm_header">
        <div class="d-f-c">
            <div class="location_btn" (click)="openFoldersNav()">
                <span class="text_one_line">
                    {{activeFolder?.name | translate}}
                </span>
                <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.91699 0.333008L0.0836592 0.333008L3.00033 4.91634L5.91699 0.333008Z" fill="#686868"/>
                </svg>                    
            </div>
        </div>
        <div class="d-f-c">
            <button mat-icon-button (click)="toggleView()">
                <mat-icon>{{mob_fm_list_view ? 'view_list' : 'apps' }}</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="filesizeMenu" #tooltip="matTooltip" matTooltip="{{ 'Sort' | translate }}" [matTooltipPosition]="'above'">
                <mat-icon>sort</mat-icon>
            </button>
            <mat-menu #filesizeMenu="matMenu" xPosition="before" class="header_menu sort_tablet" overlayPanelClass='sortTAB'>
                <div class="mat-menu-title">{{"Sort By" | translate}}:</div>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="sort(sortValue == 'filename_desc' ? 'filename' : 'filename_desc')" [ngClass]="{'active_status': sortValue == 'filename_desc' || sortValue == 'filename'}">
                    {{  "Filename" | translate }}
                    <div class="d-f-c ml-1">
                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filename_desc')" [ngClass]="{'active': sortValue == 'filename_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filename')" [ngClass]="{'active': sortValue == 'filename'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                    </div>
                </button>
                <button mat-menu-item  (click)="sort(sortValue == 'meta_duration_desc' ? 'meta_duration' : 'meta_duration_desc')" [ngClass]="{'active_status': sortValue == 'meta_duration_desc' || sortValue == 'meta_duration'}">
                    {{  "Duration" | translate }}
                    <div class="d-f-c ml-1">
                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('meta_duration_desc')" [ngClass]="{'active': sortValue == 'meta_duration_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('meta_duration')" [ngClass]="{'active': sortValue == 'meta_duration'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                    </div>
                </button>
                <button mat-menu-item  (click)="sort(sortValue == 'filesize_desc' ? 'filesize' : 'filesize_desc')" [ngClass]="{'active_status': sortValue == 'filesize_desc' || sortValue == 'filesize'}">
                    {{ "Filesize" | translate }}
                    <div class="d-f-c ml-1">
                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filesize_desc')" [ngClass]="{'active': sortValue == 'filesize_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filesize')" [ngClass]="{'active': sortValue == 'filesize'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                    </div>
                </button>
            </mat-menu>
        </div>
    
    </div>
    <!-- <div>
        <span *ngFor="let item of crumbs">+{{item.name}}+</span>
    </div>
    <div>
        <span>+{{form.get('location').value}}+</span>
    </div>
    <div>
        <span>+{{activeFolder?.location}}+{{activeFolder?.name}}+</span>
    </div> -->
    <div class="mob_fm_info">
        <div class="selected_wrp mob_info_block">
            <span>{{ "Selected" | translate }}: </span>
            <span>{{ selectedFiles.selected.length }}</span>
        </div>
        <div class="d-f-c">
            <div class="files_count_wrp mob_info_block">
                <span>{{ "Files" | translate }}: </span>
                <span>{{ filesCount }}</span>
            </div>
            <div class="folders_count_wrp mob_info_block">
                <span>{{ "Folders" | translate }}: </span>
                <span>{{ foldersCount }}</span>
            </div>
            <div class="filesize_wrp mob_info_block">
                <span>{{ "Filesize" | translate }}: </span>
                <span>{{ filesFilesize | filesize }}</span>
            </div>
        </div>
    </div>
    <div class="mob_fm_files" 
    [ngClass]="{'mob_fm_list_view': mob_fm_list_view}"
    #filesContainer
    infinite-scroll
    [infiniteScrollDisabled]="isLoad"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDown($event)"
    >
        <div class="mob_fm_file" *ngFor="let file of files; let i = index;"
        [ngClass]="{mode: mode, 'selected_mode_file': selectedMode, 'has_dmg': !file.is_uploaded && file.is_dir == 0, 'downloaded': file?.download != null && file?.download?.content == null, 'selected_v2': selectedFiles.isSelected(file.id), 'selected_file': selectedFileId == file.id || (selectedFileIds && selectedFileIds.includes(file.id))}"
        (click)="fileClick(file)"
        (contextmenu)="open($event, file); $event.preventDefault(); $event.stopPropagation();">

            <div class="indicators" *ngIf="(file.archives && file.archives.length > 0) || file.is_ready_if_approved || (file?.documents_count && file?.documents_count > 0)">
                <div class="notes_count card_notes_mob" *ngIf="file?.documents_count && file?.documents_count > 0">
                    <mat-icon>text_snippet</mat-icon>
                    <span>{{ file?.documents_count }}</span>
                </div>
                <mat-icon class="zipped" *ngIf="file.archives && file.archives.length > 0" [ngClass]="{'purple': !!getLastCreatedArchive(file)}">
                    folder_zip
                </mat-icon>

                <mat-icon class="recomend" *ngIf="file.is_ready_if_approved">
                    recommend
                </mat-icon>

                <div class="platforms_file" *ngIf="file?.taskChannelFiles && file?.taskChannelFiles.length">
                    <ng-container *ngFor="let item of file?.taskChannelFiles">
                        <a *ngIf="item?.taskChannel?.content_status_id != 8" [ngClass]="{'b_w': [6,7,9].includes(item?.taskChannel?.content_status_id)}" [href]="item?.taskChannel?.content_url" target="_blank" (click)="$event.stopPropagation();" #tooltip="matTooltip" matTooltip="{{ (!!item?.taskChannel?.content_views_count ? ('(' + (item?.taskChannel?.content_views_count | number:'':'fr-FR') + ') - ') : '') + item?.taskChannel?.channel?.name + ': ' + item?.taskChannel?.content_name }}" [matTooltipPosition]="'left'">
                            <app-platform [platform_id]="item?.taskChannel?.channel?.platform_id"></app-platform>
                        </a>
                    </ng-container>
                </div>
            </div>
            <div class="file_data">

                <ng-container *ngIf="file.is_dir; else elseTemplateFolder">
                    <div class="file_img">
                        <ng-container [ngSwitch]="file.filename">
                            <ng-container *ngSwitchCase="'sources'">
                                <mat-icon class="file_img_icon sources_folder">
                                    source
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'to_approve'">
                                <mat-icon class="file_img_icon to_approve_folder">
                                    rule_folder
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'ready_files'">
                                <mat-icon class="file_img_icon to_ready_folder">
                                    cloud_done
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'project'">
                                <mat-icon class="file_img_icon project_folder">
                                    stars
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'trash'">
                                <mat-icon class="file_img_icon trash_folder">
                                    delete
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'approved'">
                                <mat-icon class="file_img_icon approved_folder">
                                    folder_special
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mat-icon class="file_img_icon default_folder">
                                    folder
                                </mat-icon>                                     
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="file_name_wrp">
                        <span class="file_name text_one_line">
                            {{ file.filename | translate }}
                        </span>  
                    </div>
                </ng-container>
                <ng-template #elseTemplateFolder>      
                    <div class="file_img">
                        <ng-container [ngSwitch]="understandFileType(file.content_type)">
                            <ng-container *ngSwitchCase="'video'">
                                <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnail">
                                    <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                </ng-container>
                                <ng-template #elseTemplateThumbnail>
                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                        movie
                                    </mat-icon>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'audio'">
                                <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnailAudio">
                                    <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                </ng-container>
                                <ng-template #elseTemplateThumbnailAudio>
                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                        music_note
                                    </mat-icon>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="'pdf'">
                                <div class="pdf">
                                    <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        viewBox="0 0 303.188 303.188" xml:space="preserve">
                                        <g>
                                            <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                            <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                            <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                            <g>
                                                <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                    v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                    M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                    h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                    h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                    c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                    C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                    L196.579,273.871L196.579,273.871z"/>
                                            </g>
                                            <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                        </g>
                                    </svg>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'image'">
                                <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file.thumbnail ? file.thumbnail : file.original) + '?company_id=' + company?.id">
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                    <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                </ng-container>
                                <ng-template #elseTemplateHasThumb>
                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                        insert_drive_file
                                    </mat-icon>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="file_name_wrp">
                        <div class="d-f-c">
                            <span class="text_one_line file_name">{{file.filename}}</span>
                            <span class="file_size" *ngIf="file?.filesize && mob_fm_list_view">{{ file?.filesize | filesize }}</span>
                        </div>
                        <div class="d-f-c" *ngIf="mob_fm_list_view" style="padding-right: 3px;">
                            <div class="file_reminder_name text_one_line" style="color: #3f51b5;" *ngIf="file.primaryTags && file.primaryTags.length">
                                <ng-container *ngFor="let prim of file.primaryTags; let last = last">
                                  <span>{{prim?.parameterValue?.value}}</span>
                                  <ng-container *ngIf="!last">, </ng-container>
                                </ng-container>
                            </div>
                            <div *ngIf="!(file.primaryTags && file.primaryTags.length)"></div>
                            <div class="file_reminder_name text_one_line" style="color: #3f51b5;" *ngIf="['image', 'video'].includes(understandFileType(file?.content_type)) || ['image', 'video'].includes(_mimeTypes.getType(file.name))">
                                {{file?.meta_width + " &#10005; " + file?.meta_height}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <!-- <div class="file_video_time">
                <ng-container *ngIf="understandFileType(file?.content_type) == 'video' || _mimeTypes.getType(file.name) == 'video'">
                    {{file?.meta_duration | timeFormat}}
                </ng-container>
            </div>
            <ng-container *ngIf="is_list">
                <div class="file_size">
                    <ng-container *ngIf="file.is_dir == 0">
                        {{ file.filesize | filesize }}
                    </ng-container>
                </div>
                <div class="file_type">
                    {{ file.extension }}
                </div>
                <div class="file_date_added" #tooltip="matTooltip" matTooltip="{{ file.created_at*1000 | date:'short':'timeZone':activeLang | yearPipe }}" [matTooltipPosition]="'above'">
                    {{ file.created_at*1000 | date:'MMM d':'':activeLang }}
                </div>
            </ng-container>
            <div class="file_res">
                <ng-container *ngIf="['image', 'video'].includes(understandFileType(file?.content_type)) || ['image', 'video'].includes(_mimeTypes.getType(file.name))">
                    {{file?.meta_width + "px &#10005; " + file?.meta_height + "px"}}
                </ng-container>
            </div>                           
            <ng-container *ngIf="is_list">
                <div class="file_size">
                    <ng-container *ngIf="file.is_dir == 0">
                        {{ file.filesize | filesize }}
                    </ng-container>
                </div>
                <div class="file_type">
                    {{ file.extension }}
                </div>
                <div class="file_date_added" #tooltip="matTooltip" matTooltip="{{ file.created_at*1000 | date:'short':'timeZone':activeLang | yearPipe }}" [matTooltipPosition]="'above'">
                    {{ file.created_at*1000 | date:'MMM d':'':activeLang }}
                </div>
            </ng-container> -->
        </div>

    </div>
</div>


<div class="mob_fm_footer">
    <div class="d-f-c">
        <div (click)="closeParentDialog()" class="mob_close_icon_wrp">
            <svg class="mob_close_icon" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.88 14.1067L3.77333 8L9.88 1.88L8 0L0 8L8 16L9.88 14.1067Z" fill="#FF0000"/>
            </svg>
        </div>
    </div>

    <div class="d-f-c margins_mob_btns">
        <div *ngIf="!!copiedFiles" (click)="paste()">
            <mat-icon>content_paste</mat-icon>
        </div>

        <ng-container *ngIf="form.get('location').value != '/'">
            <div (click)="archive(false, crumbs[crumbs.length - 1]?.target)">
                <mat-icon>folder_zip</mat-icon>
            </div>
        </ng-container>

        <div *ngIf="form.get('location').value != '/'" (click)="archive(false, crumbs[crumbs.length - 1]?.target, true)">
            <mat-icon class="mr-1" style="color: #686868;">download</mat-icon>
        </div>

        <div class="" (click)="createFolder()">
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10 2H18C18.5304 2 19.0391 2.21071 19.4142 2.58579C19.7893 2.96086 20 3.46957 20 4V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16H2C0.89 16 0 15.1 0 14V2C0 0.89 0.89 0 2 0H8ZM13 5V8H10V10H13V13H15V10H18V8H15V5H13Z" fill="#686868"/>
            </svg>
        </div>

        <div class="" (click)="createDocument()">
            <mat-icon style="color: #686868;">note_add</mat-icon>
        </div>

        <div class="" (click)="mobUploader.click()">
            <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.70588 15.2941V8.23529H0L8.23529 0L16.4706 8.23529H11.7647V15.2941H4.70588ZM0 20V17.6471H16.4706V20H0Z" fill="#686868"/>
            </svg>
        </div>

        <ng-container *ngIf="form.get('location').value != '/'; else elseTemplateISNoRoot">
            <ng-container *ngIf="!!crumbs[crumbs.length - 1].target && crumbs[crumbs.length - 1].target?.acm; else elseTemplateCrumbHasTarget">
                <!-- has target -->
                <ng-container *ngIf="!fileRoute.get('task_operation_id').value; else elseTemplateIsTaskDirLink">
                    <div class="" ngxClipboard [cbContent]="origin + '/file/' + crumbs[crumbs.length - 1].target?.acm + '?path=' + getPathForLink(form.get('location').value)" (cbOnSuccess)="copyLink('folder');">
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#686868"/>
                        </svg>
                    </div>
                </ng-container>
                <ng-template #elseTemplateIsTaskDirLink>
                    <div class="" ngxClipboard [cbContent]="origin + '/file/' + crumbs[crumbs.length - 1].target?.acm + '?path=' + getPathForLink(form.get('location').value)" (cbOnSuccess)="copyLink('folder');">
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#686868"/>
                        </svg>
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #elseTemplateCrumbHasTarget>
                <!-- no target -->
                <ng-container *ngIf="!fileRoute.get('task_operation_id').value; else elseTemplateIsTaskDirLink">
                    <div class="" (click)="copyLinkToFolder()" (cbOnSuccess)="copyLink('folder');">
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#686868"/>
                        </svg>
                    </div>
                </ng-container>
                <ng-template #elseTemplateIsTaskDirLink>
                    <div class="" (click)="copyLinkToFolder()" (cbOnSuccess)="copyLink('folder');">
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#686868"/>
                        </svg>
                    </div>
                </ng-template>
            </ng-template>
        </ng-container>
        <ng-template #elseTemplateISNoRoot>     
            <ng-container *ngIf="!fileRoute.get('task_operation_id').value; else elseTemplateIsTaskDirLink">
                <div class="" ngxClipboard [cbContent]="origin + '/task/' + task?.acm + '?path=' + getPathForLink(form.get('location').value)" (cbOnSuccess)="copyLink('folder');">
                    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#686868"/>
                    </svg>
                </div>
            </ng-container>
            <ng-template #elseTemplateIsTaskDirLink>
                <div class="" ngxClipboard [cbContent]="origin + '/job/' + work?.acm + '?path=' + getPathForLink(form.get('location').value)" (cbOnSuccess)="copyLink('folder');">
                    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#686868"/>
                    </svg>
                </div>
            </ng-template>
        </ng-template>


    </div>
</div>

<input 
hidden 
type="file" 
#mobUploader
multiple
(change)="uploadFile($event)"
/>
<!-- <input 
hidden 
type="file" 
webkitdirectory 
directory 
multiple
#mobUploaderFolder
(change)="uploadFolder($event)"
/> -->