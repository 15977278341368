<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a task" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography"  dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <div class="custom_radio">
                <label>{{ "Choose a status" | translate }}: </label>
                <mat-radio-group formControlName="status_id">
                  <mat-radio-button color="primary" *ngFor="let status of statuses" [value]="status.id">{{ status.name | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>   
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
