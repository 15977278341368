import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subscription, concat, forkJoin, from, fromEvent, of } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { GetFilesSettingsComponent } from '../get-files-settings/get-files-settings.component';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { FormControl } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { MobUrlAnalyticsComponent } from '../mob-url-analytics/mob-url-analytics.component';
import { UrlAnalyticsComponent } from '../url-analytics/url-analytics.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { AddTaskProfileComponent } from '../add-task-profile/add-task-profile.component';
import { AddEditLiveStreamComponent } from '../add-edit-live-stream/add-edit-live-stream.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { SelectionModel } from '@angular/cdk/collections';
import { AskTextComponent } from 'src/app/shared/global_components/ask-text/ask-text.component';
import { DuplicateSettingsComponent } from './dialogs/duplicate-settings/duplicate-settings.component';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const colors: Record<string, any> = {
  1: {
    type: "Waiting",
    primary: '#2F80ED',
    secondary: '#BCD7FD',
  },
  2: {
    type: "Draft",
    primary: '#DB0000',
    secondary: '#FFAEAE',
  },
  3: {
    type: "Scheduled",
    primary: '#BEC11B',
    secondary: '#FEFFB9',
  },
  4: {
    type: "Published",
    primary: '#219653',
    secondary: '#98F1BD',
  },
  5: {
    type: "Canceled",
    primary: '#686868',
    secondary: '#E0E0E0',
  },
  6: {
    type: "content",
    primary: '#DD5E89',
    secondary: '#F7BB97',
  }
};



@Component({
  selector: 'app-servers-table',
  templateUrl: './servers-table.component.html',
  styleUrls: ['./servers-table.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class ServersTableComponent extends BaseClass implements OnInit, OnDestroy {
  public page = this.data.page;
  public viewVal = this.data.viewVal;
  public pagination = this.data.pagination;
  public liveCollection: any = new SelectionModel(
    true,
    []
  );
  public urlsCount:number = 0;
  public isLoad: boolean = false;
  public is_search_active: boolean = false;
  public is_multi_stop: boolean = false;
  public countServers: number = 0;
  public is_multi_backup: boolean = false;
  public is_multi_duplicate: boolean = false;
  public urlsTableSub: Subscription;
  public urlsByServers: any[] = [];
  public urlsByServers$: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('postContext') postContext: TemplateRef<any>;
  @ViewChild('postBckpContext') postBckpContext: TemplateRef<any>;
  overlayRefPost: OverlayRef | null;
  public postContextSub: Subscription;
  overlayRefPostBckp: OverlayRef | null;
  public postBckpContextSub: Subscription;
  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();

  public profileStatuses: any;
  public urls: any = this.data.urls;
  public is_multi_remove_backups: boolean = false;
  public isRealGettingFiles: boolean = false;
  public isGettingFiles: boolean = false;
  public isSubmit: boolean = false;
  public getFilesSettings:any = {
    job_id: 0,
    is_location_all: true,
    folder: "/",
  };
  public searchCtrl: FormControl = new FormControl();
  public status_id: FormControl = new FormControl('');
  public is_backup: FormControl = new FormControl('');
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private ls: LoadingService,
    private globalDataService: GlobalDataService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private bottomSheet: MatBottomSheet,
    private refreshService: RefreshService,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private fileService: FileService,
    public dialogRef: MatDialogRef<ServersTableComponent>,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("ServersTableComponent this.data", this.data)
    // this.urls.forEach(url => {
    //   if (this.urlsByServers.filter(x => x.server_id == url.server_id).length == 0) {
    //     this.urlsByServers.push({
    //       server_id: url.server_id,
    //       content_views_count: !!url.content_views_count ? +url.content_views_count : 0,
    //       urls: [url]
    //     })
    //   } else {
    //     this.urlsByServers.find(x => x.server_id == url.server_id).content_views_count += (!!url.content_views_count ? +url.content_views_count : 0);
    //     this.urlsByServers.find(x => x.server_id == url.server_id).urls.push(url)
    //   }
    // });
    this.pagination = undefined;
    this.page = 1;
    this.getUrls(this.page);
    this.getProfilesStatus();

    // this.attachSubscriptions(
    //   this.status_id.valueChanges.subscribe(res => {
    //     this.urls.map(x => x.content_status_id = res)
    //   })
    // )

    this.attachSubscriptions(
      this.searchCtrl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchUrls(resp))
    )

    this.attachSubscriptions(
      this.is_backup.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchUrls(this.searchCtrl.value, resp))
    )
  }

  setFilBackup(val) {
    this.is_backup.patchValue(val);
  }

  onSearchBackup(backup) {
    this.urlsCount = 0;
    this.attachSubscriptions(
      this.urlsByServers$.asObservable().pipe(take(1)).subscribe(resp => {
        let newServerUrls = [];
        console.log("onSearchBackup", resp)
        resp.forEach(k => {
          k.newUrls = [];
          k.urls.forEach(url => {
            if (backup === 1) {
              if (url.publishing_params && !!url.publishing_params.is_backup) {
                k.newUrls.push(url)
              }
            } else if (backup === 0) {
              if (!url.publishing_params || !url.publishing_params.is_backup) {
                k.newUrls.push(url)
              }
            } else {
              k.newUrls.push(url)
            }
          });
  
          if (k.newUrls.length) {
            // k.urls = k.newUrls;
            // delete k.newUrls;
            this.urlsCount = +this.urlsCount + +k.newUrls.length;
            newServerUrls.push(k)
          }
        })
        this.urlsByServers$.next(newServerUrls.slice())
        this.liveCollection.clear();
      })
    )
    
    // if (!this.urls || !this.urls.length) {
    //   return
    // }

    // let newUrls = [];
    
    // this.urls.forEach(url => {
    //   if (backup === 1) {
    //     if (url.content_concurrent_views_count < val) {
    //       return
    //     }
    //   } else if (backup === 0) {
    //     if (!!url.publishing_params.is_backup) {
    //       return
    //     }
    //   }
 
    //   if (newUrls.filter(x => x.channel_id == url.channel_id).length == 0) {
    //     newUrls.push({
    //       channel_id: url.channel_id,
    //       channel: url.channel,
    //       content_views_count: !!url.content_views_count ? +url.content_views_count : 0,
    //       content_concurrent_views_count: !!url.content_concurrent_views_count ? +url.content_concurrent_views_count : 0,
    //       content_duration: !!url.content_duration ? +url.content_duration : 0,
    //       urls: [url]
    //     })
    //   } else {
    //     newUrls.find(x => x.channel_id == url.channel_id).content_views_count += (!!url.content_views_count ? +url.content_views_count : 0);
    //     newUrls.find(x => x.channel_id == url.channel_id).content_concurrent_views_count += (!!url.content_concurrent_views_count ? +url.content_concurrent_views_count : 0);
    //     newUrls.find(x => x.channel_id == url.channel_id).content_duration += (!!url.content_duration ? +url.content_duration : 0);
    //     newUrls.find(x => x.channel_id == url.channel_id).urls.push(url)
    //   }
    // });

    // // filter the banks
    // this.urlsByChannel$.next(newUrls.slice());
  }

  onSearchUrls(resp, backup?) {
    console.log("onSearchUrls", resp)
    if (!this.urlsByServers) {
      return;
    }

    if (!resp) {
      this.urlsByServers$.next(this.urlsByServers.slice());
      this.onSearchBackup(backup || this.is_backup.value);
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.urlsByServers$.next(
      this.urlsByServers.filter(b => b.server_id.toLowerCase().indexOf(resp) > -1)
    );
    this.onSearchBackup(backup || this.is_backup.value)
  }

  refresh() {
    this.pagination = undefined;
    this.page = 1;
    this.urlsByServers = [];
    this.urls = [];
    this.getUrls(this.page)
  }

  
  openPostContext({ x, y }: MouseEvent, el, i, j, server) {
    this.closePostContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefPost = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefPost.attach(new TemplatePortal(this.postContext, this.viewContainerRef, {
      $implicit: {
        el: el,
        i: i,
        j: j,
        server: server
      }
    }));
    
    this.postContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefPost && !this.overlayRefPost.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closePostContext())

  }

  closePostContext() {
    this.postContextSub && this.postContextSub.unsubscribe();
    if (this.overlayRefPost) {
      this.overlayRefPost.dispose();
      this.overlayRefPost = null;
    }
  }
  
  openPostBckp({ x, y }: MouseEvent, el, i, j, server, type) {
    this.closePostBckp();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefPostBckp = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefPostBckp.attach(new TemplatePortal(this.postBckpContext, this.viewContainerRef, {
      $implicit: {
        el: type == 'backup' ? el.backupOriginal : el,
        element: el,
        i: i,
        j: j,
        server: server,
        type: type
      }
    }));
    
    this.postBckpContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefPostBckp && !this.overlayRefPostBckp.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closePostBckp())

  }

  closePostBckp() {
    this.postBckpContextSub && this.postBckpContextSub.unsubscribe();
    if (this.overlayRefPostBckp) {
      this.overlayRefPostBckp.dispose();
      this.overlayRefPostBckp = null;
    }
  }

  goToPost(e, profile) {
    console.log("e", e);
    console.log("profile", profile);
    
    if (!e.target.closest('.item_top')) {
      if (e.ctrlKey || e.metaKey) { // для Mac OS
        e.preventDefault();
        e.stopPropagation();
        this.liveCollection.toggle(profile)
        return
      }
    }
    if (!!profile.content_url) {
      window.open(profile.content_url, '_blank')
    }
  }

  checkLengthWithExId() {
    return this.liveCollection.selected.filter(u => !!u.external_content_id).length
  }

  multiRemoveBackup() {
    if (!!this.is_multi_remove_backups) {
      this.layoutService.showSnackBar({name: "Already running"}, marker("Wait for the finish"), SnackBarItem)
      return
    }

    this.is_multi_remove_backups = true;
    let lenSelection = this.urls.filter(p => !!p.publishing_params && !!p.publishing_params.is_backup).length;
    let count: number = 0;
    this.attachSubscriptions(
      from(this.urls.filter(p => !!p.publishing_params && !!p.publishing_params.is_backup)).pipe(
        concatMap((post:any) => {
          let publParams = JSON.parse(JSON.stringify(post.publishing_params));
          publParams.is_backup = 0;
          return this.taskService.editProfile(post.id, {
            publishing_params: publParams,
          }, this.data.company.id).pipe(
            tap(resp => {
              post.publishing_params = resp.publishing_params;
            })
          )
        })
      ).subscribe({
        next: (next) => {
          console.log("next multiBackup", next);
          // this.refreshService.refreshUrls$.next({url_id: next.newPost.id, company_id: this.data.company.id, new: true})
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: "Remove backup from posts",
          })
          count++;
        },
        complete: () => {
          console.log("complete multiBackup");
          this.is_multi_remove_backups = false;
        },
        error: (error) => {
          this.is_multi_remove_backups = false;
          console.log("error multiBackup", error)
        }
      })
    )
  }

  multiDuplicatePosts() {
    if (!!this.is_multi_duplicate) {
      this.layoutService.showSnackBar({name: "Already running"}, marker("Wait for the live backup to finish"), SnackBarItem)
      return
    }

    this.is_multi_duplicate = true;
    let lenSelection = this.liveCollection.selected.filter(u => !!u.external_content_id).length;
    let count = 0;
    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'All of selected posts has no external content id, you cannot duplicate them.'}, '', SnackBarItem)
      return
    }
    

    this.ls.requests$.next({
      value: 0,
      target: "Duplicate posts",
    })

    const dialogRef = this.dialog.open(DuplicateSettingsComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        title: "Fill new url",
        field: 'Url',
      }
    });
    // https://www.youtube.com/watch?v=36YnV9STBqc
    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!!result && !!result.data) {
            return from(this.liveCollection.selected.filter(u => !!u.external_content_id)).pipe(
              concatMap((post:any) => {
                // let filterData = {
                //   channel_id: profile.channel_id,
                //   content_type_id: profile.content_type_id,
                //   publish_at_from: moment().format("X")
                // }
                let sbmtData = {
                  task_id: post.task_id,
                  channel_id: post.channel_id,
                  content_url: post.content_url,
                  content_name: post.content_name,
                  is_content_views_count_update: post.is_content_views_count_update,
                  is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
                  content_image: post.content_image,
                  content_filename: post.content_filename,
                  external_content_id: post.external_content_id,
                  content_type_id: post.content_type_id,
                  content_published_at: post.content_published_at,
                  content_status_id: 6,
                  content_views_count: post.content_views_count,
                  publishing_params: post.publishing_params,
                }
                if (result.data.post_backup_after) {
                  sbmtData.publishing_params.live_stream_url = "rtmp://b.rtmp.youtube.com/live2";
                  sbmtData.publishing_params.live_stream_to = ((sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key) + "?backup=1";
                }
        
                return this.taskService.getTaskChannelFile(this.data.company.id, {task_channel_id: post.id}).pipe(
                  switchMap(files => {
                    if (result.data.post_link_after) {
                      return this.taskService.getSmartLinkData(result.data.new_link, post.channel_id, this.data.company.id, (post.company_id != this.data.company.id ? post.company_id : null)).pipe(
                        switchMap(newLinkData => {
                          sbmtData.content_url = result.data.new_link;
                          if (!!newLinkData) {
                            sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                            sbmtData.external_content_id = newLinkData.external_content_id;
                            sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                            sbmtData.content_image = newLinkData.image;
                            if (newLinkData.published_at) {
                              sbmtData.content_published_at = newLinkData.published_at;
                            }
                            sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
                          }

                          return this.taskService.addProfile(sbmtData, this.data.company.id).pipe(
                            map((res) => {
                              return {
                                newPost: res,
                                files: files
                              }
                            }),
                          )
                        }) 
                      )
                    } else {
                      return this.taskService.addProfile(sbmtData, this.data.company.id).pipe(
                        map((res) => {
                          return {
                            newPost: res,
                            files: files
                          }
                        }),
                      )
                    }
                  }),
                  switchMap(val => {
                    if (val.files.length) {
                        return forkJoin([...val.files.map(fileData => this.taskService.setTaskChannelFile({
                        company_id: fileData.company_id,
                        task_channel_id: val.newPost.id,
                        file_id: fileData.file_id,
                        is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                        is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                      }, this.data.company.id).pipe(
                        catchError((error) => {
                          val.newPost.hasError = true;
                          return of(null)
                        })
                      ))]).pipe(
                        map((newFiles) => {
                          return {
                            newPost: val.newPost,
                            files: val.files,
                            newFiles: newFiles
                          }
                        }),
                      )
                    } else {
                      return of({
                        newPost: val.newPost,
                        files: val.files
                      })
                    }
                  }),
                  switchMap(rslt => {
                    if (result.data.post_delete_after) {
                      return this.taskService.deleteProfile(post.id).pipe(
                        map(() => rslt)
                      )
                    } else {
                      return of(rslt)
                    }
                  })
                )
              })
            )
          } else {
            return of(result)
          }
        })
      ).subscribe({
        next: (next) => {
          console.log("next multiBackup", next);
          // this.refreshService.refreshUrls$.next({url_id: next.newPost.id, company_id: this.data.company.id, new: true})
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: "Duplicate posts",
          })
          count++;
        },
        complete: () => {
          console.log("complete multiBackup");
          this.liveCollection.clear();
          this.is_multi_duplicate = false;
        },
        error: (error) => {
          this.is_multi_duplicate = false;
          console.log("error multiBackup", error)
        }
      })
    )
  }

  multiStop() {
    if (!!this.is_multi_stop) {
      this.layoutService.showSnackBar({name: "Already running"}, marker("Wait for the logic is finish"), SnackBarItem)
      return
    }
    
    this.is_multi_stop = true;
    let lenSelection = this.liveCollection.selected.length;
    let count = 0;

    from(this.liveCollection.selected).pipe(
      concatMap((post:any) => {
        // let filterData = {
        //   channel_id: profile.channel_id,
        //   content_type_id: profile.content_type_id,
        //   publish_at_from: moment().format("X")
        // }

        return this.taskService.editProfile(post.id, {
          task_id: post.task_id,
          content_status_id: 4,
        }, this.data.company.id)
      })
    ).subscribe({
      next: (next) => {
        console.log("next multiBackup", next);
        // this.refreshService.refreshUrls$.next({url_id: next.newPost.id, company_id: this.data.company.id, new: true})
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: "End Lives",
        })
        count++;
      },
      complete: () => {
        console.log("complete multiBackup");
        this.liveCollection.clear();
        this.is_multi_stop = false;
      },
      error: (error) => {
        this.is_multi_stop = false;
        console.log("error multiBackup", error)
      }
    })
  }

  multiBackup() {
    if (!!this.is_multi_backup) {
      this.layoutService.showSnackBar({name: "Already running"}, marker("Wait for the live backup to finish"), SnackBarItem)
      return
    }
    
    this.is_multi_backup = true;
    let lenSelection = this.liveCollection.selected.filter(u => !!u.external_content_id).length;
    let count = 0;
    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'All of selected posts has no external content id, you cannot duplicate them.'}, '', SnackBarItem)
      return
    }
    from(this.liveCollection.selected.filter(u => !!u.external_content_id)).pipe(
      concatMap((post:any) => {
        // let filterData = {
        //   channel_id: profile.channel_id,
        //   content_type_id: profile.content_type_id,
        //   publish_at_from: moment().format("X")
        // }

        return this.taskService.getTaskChannelFile(this.data.company.id, {task_channel_id: post.id}).pipe(
          switchMap(files => {
            let sbmtData = {
              task_id: post.task_id,
              channel_id: post.channel_id,
              content_url: post.content_url,
              content_name: post.content_name,
              is_content_views_count_update: post.is_content_views_count_update,
              is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
              content_image: post.content_image,
              content_filename: post.content_filename,
              external_content_id: post.external_content_id,
              content_type_id: post.content_type_id,
              content_published_at: post.content_published_at,
              content_status_id: 6,
              content_views_count: post.content_views_count,
              publishing_params: post.publishing_params,
            }
            sbmtData.publishing_params.live_stream_url = "rtmp://b.rtmp.youtube.com/live2";
            sbmtData.publishing_params.live_stream_to = ((sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key) + "?backup=1";
            return this.taskService.addProfile(sbmtData, this.data.company.id).pipe(
              switchMap(res => {
                if (files.length) {
                   return forkJoin([...files.map(fileData => this.taskService.setTaskChannelFile({
                    company_id: fileData.company_id,
                    task_channel_id: res.id,
                    file_id: fileData.file_id,
                    is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                    is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                  }, this.data.company.id).pipe(
                    catchError((error) => {
                      res.hasError = true;
                      return of(null)
                    })
                  ))]).pipe(
                    map((newFiles) => {
                      return {
                        newPost: res,
                        files: files,
                        newFiles: newFiles
                      }
                    }),
                  )
                } else {
                  return of({
                    newPost: res,
                    files: files
                  })
                }
              })
            )
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next multiBackup", next);
        // this.refreshService.refreshUrls$.next({url_id: next.newPost.id, company_id: this.data.company.id, new: true})
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: "Backup",
        })
        count++;
      },
      complete: () => {
        console.log("complete multiBackup");
        this.liveCollection.clear();
        this.is_multi_backup = false;
      },
      error: (error) => {
        this.is_multi_backup = false;
        console.log("error multiBackup", error)
      }
    })
  }

  setIsBackup(post, val) {
    let publParams = JSON.parse(JSON.stringify(post.publishing_params));
    if (!!val) {
      publParams.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      publParams.live_stream_to_backup =  publParams.live_stream_url_backup.split('?')[0] + '/' + publParams.live_stream_key + "?backup=1";
    }
    publParams.is_backup = val;
    this.attachSubscriptions(
      this.taskService.editProfile(post.id, {
        publishing_params: publParams,
      }, this.data.company.id).subscribe(resp => {
        post.publishing_params = resp.publishing_params;
        // this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
      })
    )
  }

  addLive(element, profile?) {
    let x:any = {
      company: this.data.company,
      company_id: this.data.company.id,
      task: element
    }
    if (profile) {
      x.profile = profile;
      x.is_edit = true
    }
    const dialogRef = this.dialog.open(AddEditLiveStreamComponent, {
      disableClose: true,
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && (result.event == "Add" || result.event == 'edit')) {
          this.refresh();
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.data.company.id});
        }
      })
    )
  }

  stopLive(element, profile) {
    this.attachSubscriptions(
      this.taskService.editProfile(profile.id, {
        task_id: element.id,
        content_status_id: 4,
      }, this.data.company.id).subscribe(resp => {
        profile.content_status_id = resp.content_status_id;
        this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.data.company.id})
      })
    )
  }

  startLive(element, profile) {
    this.attachSubscriptions(
      this.taskService.editProfile(profile.id, {
        task_id: element.id,
        content_status_id: 6,
      }, this.data.company.id).subscribe(resp => {
        profile.content_status_id = resp.content_status_id;
        this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.data.company.id})
      })
    )
  }


  editProfile(element, profile) {
    if (profile.content_type_id == '106') {
      this.addLive(element, profile);
      return
    }
    const dialogRef = this.dialog.open(AddTaskProfileComponent, {
      disableClose: true,
      data: {
        company: this.data.company,
        company_id: this.data.company.id,
        task: element,
        profile: profile,
        is_edit: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.getTask(true);
          this.refresh()
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.data.company.id})
        }
      })
    )
  }

  deleteProfile(task, profile, k, server) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: task,
        target: marker("profile")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteProfile(profile.id).subscribe(resp => {
            server.urls.splice(k, 1)
            if (!!profile.content_views_count) {
              server.content_views_count = server.content_views_count - profile.content_views_count;
            }
            this.onSearchUrls(this.searchCtrl.value);
          })
        )
      }
    });

  }



  showStats(profile) {
    if (!this.data.is_mobile) {
      const dialogRef = this.dialog.open(UrlAnalyticsComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          company: this.data.company,
          company_id: this.data.company.id,
          task: profile.task,
          profile: profile,
          is_edit: true,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.data.company.id})
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobUrlAnalyticsComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company: this.data.company,
          company_id: this.data.company.id,
          task: profile.task,
          profile: profile,
          is_edit: true,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.data.company.id})
        })
      )
    }
  }
  

  getUrls(n) {
    this.isLoad = true;
    this.urlsTableSub = this.taskService.getExpandUrls(n, this.data.company.id, {...this.data.filterVal}, this.viewVal == 'list' ? (!this.data.is_mobile ? '20' : '10') : '200').pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.pagination TEST", this.pagination)
      }),
      map(el => el.body),
      tap(urls => {
        urls.map((url, i) => {
          // url.start = moment(url.content_published_at * 1000);
          // url.start = startOfDay(new Date());
          url.start = new Date(url.content_published_at * 1000);
          url.title = url.content_name || marker("Without name");
          url.color = colors[url.content_status_id];
          // url.server_id = (this.urls.length + i + 1).toString();
          // url.allDay = true;
          url.cssClass = 'publish_url';
          let by = ''
          if ((this.data.filterVal.group_id.length) && (this.data.filterVal.channel_id.length || this.data.filterVal.channel_platform_id || this.data.filterVal.channel_platform_id.toString() == '0')) {
            by = 'combine'
          } else {
            if ((this.data.filterVal.group_id.length) && !(this.data.filterVal.channel_id.length || this.data.filterVal.channel_platform_id || this.data.filterVal.channel_platform_id.toString() == '0')) {
              by = 'group'
            } else {
              by = 'platform'
            }
          }
      
          url.meta = {
            type:'publication',
            filterBy: by
          }
        });
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getUrls", resp)
      
      if (this.page == 1) {
        this.urls = resp;
      } else {
        this.urls.push(...resp);
      }
      // resp.forEach(url => {
      //   if (this.urlsByServers.filter(x => x.server_id == url.server_id).length == 0) {
      //     this.urlsByServers.push({
      //       server_id: url.server_id,
      //       content_views_count: !!url.content_views_count ? +url.content_views_count : 0,
      //       urls: [url]
      //     })
      //   } else {
      //     this.urlsByServers.find(x => x.server_id == url.server_id).content_views_count += (!!url.content_views_count ? +url.content_views_count : 0);
      //     this.urlsByServers.find(x => x.server_id == url.server_id).urls.push(url)
      //   }
      // });

      // this.urlsByServers$.next(this.urlsByServers.slice());
      this.page = this.page + 1;
      this.isLoad = false;

      
      if (+this.pagination['currentPage'] < +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
        this.getUrls(this.page)
      } else {
        this.urls.forEach(post => {
          if (post.content_type_id == 106) {
            if (post.publishing_params && post.publishing_params.live_stream_to && post.publishing_params.live_stream_to.indexOf('backup') != -1) {
              if (!!this.urls.filter(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).length) {
                if (this.urls.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).hasOwnProperty('backups')) {
                  this.urls.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).backups.push(post)
                } else {
                  this.urls.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).backups = [post]
                }
                post.backup_id = this.urls.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).id;
                let copiedOrig = {...this.urls.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1)}
                delete copiedOrig.backups;
                post.backupOriginal = copiedOrig;
              } else {
                post.backup_id = -1;
              }
            } else {
              post.backup_id = 0;
            }
          } else {
            post.backup_id = 0;
          }
        });

        this.getServers();
      }
    },
    error => {
      this.isLoad = false;
    })
  }

  getServers() {
    this.countServers = 0;
    this.attachSubscriptions(
      concat(...this.urls.map(live => {
        return this.taskService.getPostExtra(1, this.data.company.id, {task_channel_id: live.id}).pipe(
          map(x => x.body),
          tap(extras => {
            live.extras = extras;
          })
        )
      })).subscribe({
        next: (next) => {
          console.log("getServers next", next);
          this.countServers++;
          this.ls.requests$.next({
            value: Math.round((100 / (this.urls.length)) * (this.countServers)),
            target: "Getting servers and grouping lives by server id"
          })
        },
        complete: () => {
          console.log("getServers complete")
          this.urls.forEach(url => {
            if (this.urlsByServers.filter(x => x.server_id == url.server_id).length == 0) {
              this.urlsByServers.push({
                server_id: url.server_id,
                content_views_count: !!url.content_views_count ? +url.content_views_count : 0,
                urls: [url]
              })
            } else {
              if (this.urlsByServers.find(x => x.server_id == url.server_id).urls.filter(p => url.id == p.id).length == 0) {
                this.urlsByServers.find(x => x.server_id == url.server_id).content_views_count += (!!url.content_views_count ? +url.content_views_count : 0);
                this.urlsByServers.find(x => x.server_id == url.server_id).urls.push(url)
              }
            }

            if (url.extras && url.extras.length) {
              url.extras.forEach(el => {
                if (this.urlsByServers.filter(x => x.server_id == el.server_id).length == 0) {
                  this.urlsByServers.push({
                    server_id: el.server_id,
                    content_views_count: !!url.content_views_count ? +url.content_views_count : 0,
                    urls: [url]
                  })
                } else {
                  if (this.urlsByServers.find(x => x.server_id == el.server_id).urls.filter(p => url.id == p.id).length == 0) {
                    this.urlsByServers.find(x => x.server_id == el.server_id).content_views_count += (!!url.content_views_count ? +url.content_views_count : 0);
                    this.urlsByServers.find(x => x.server_id == el.server_id).urls.push(url)
                  }
                }
              });
            }
          });
          this.onSearchUrls(this.searchCtrl.value)
        },
        error: (error) => {
          console.log("getServers error", error)
        }
      })
    )
  }

  toggleSearch() {
    this.is_search_active = !this.is_search_active;
    if (!this.is_search_active) {
      this.searchCtrl.patchValue('')
    }
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
  }

  getProfileStatusById(id) {
    if (this.profileStatuses) {
      return this.profileStatuses.find(x => x.id == id)
    } else {
      return undefined
    }
  }

  log() {
    console.log("ServersTableComponent this.searchCtrl.searchCtrl", this.searchCtrl.value)
    console.log("ServersTableComponent this.urls", this.urls)
    console.log("ServersTableComponent this.urlsByServers", this.urlsByServers)
  }

  openFilesSettings() {
    const dialogRef = this.dialog.open(GetFilesSettingsComponent, {
      data: {
        getFilesSettings: this.getFilesSettings,
        task: this.data.task,
        company: this.data.company
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("result", result)
        console.log("this.getFilesSettings", this.getFilesSettings)
        if (result) {
          if (result.event == 'find') {
            this.getFilesBySettings()
          }
        }
      })
    )
  }

  copyLiveData(type) {
    this.layoutService.showSnackBar({name: "Live " + type}, marker("Copied"), SnackBarItem)
  }

  getFilesBySettings() {
    this.isGettingFiles = true;
    this.attachSubscriptions(
      (!this.getFilesSettings.job_id ? this.fileService.getFileDataWithTags('1', this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.task.id, location_all: '/'}, '1') : this.fileService.getFileDataWithTags('1', this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.task.id, task_operation_id: this.getFilesSettings.job_id, [this.getFilesSettings.is_location_all ? 'location_all' : 'location']: this.getFilesSettings.folder}, '1')).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 100)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          if (pages == 0) {
            return of([]);
          } else {
            return forkJoin(arr.map(x => (!this.getFilesSettings.job_id ? this.fileService.getFileDataWithTags(x, this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.task.id, location_all: '/'}, '100') : this.fileService.getFileDataWithTags(x, this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.task.id, task_operation_id: this.getFilesSettings.job_id, [this.getFilesSettings.is_location_all ? 'location_all' : 'location']: this.getFilesSettings.folder}, '100')).pipe(map(u => u.body)))).pipe(
              last(),
              map(values => {
                return [].concat(...values);
              })
            )
          }
        }),
      ).subscribe(resp => {
        console.log("getFilesBySettings", resp);
        console.log("getFilesBySettings videos", resp.filter(x => this.understandFileType(x.content_type) == 'video'));
        if (resp.length && resp.filter(x => this.understandFileType(x.content_type) == 'video').length) {
          this.urls.filter(x => !!x.checked).forEach(live => {
            let live_tag_ids = live.channel && live.channel.parameterValuesToChannel ? live.channel.parameterValuesToChannel.map(u => u.parameter_value_id) : [];
            if (live_tag_ids.length == 0) {
              return
            }
  
            resp.filter(x => this.understandFileType(x.content_type) == 'video').forEach(file => {
              if (file.parameterValuesToTask && file.parameterValuesToTask.length) {
                let file_tag_ids = file.parameterValuesToTask.map(u => u.parameter_value_id);
                if (file_tag_ids.some(item => live_tag_ids.includes(item))) {
                  if (live.files.length == 0 || live.files.filter(x => x.file.id == file.id).length == 0) {
                    live.files.push({
                      company_id: file.company_id,
                      task_channel_id: live.id,
                      file_id: file.id,
                      is_to_upload: 1,
                      is_live_stream_content: 1,
                      file: file,
                      is_new: true
                    })
                  }
                }
              }
            })
          })
        }
        
        this.isGettingFiles = false;
      })
    )
  }
  
  deleteEl(live,chFile, i) {
    if (chFile.is_new) {
      live.files.splice(i,1)
    } else {
      chFile.deleted = true;
    }
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  toggleCol() {
    if (this.isAllChecked()) {
      this.urls.map(x => x.checked = false)
    } else {
      this.urls.map(x => x.checked = true)
    }
  }

  isAllChecked() {
    return this.urls.filter(x => !!x.checked).length == this.urls.length
  }

  hasChecked() {
    return !!this.urls.filter(x => !!x.checked).length
  }

  getFilesForLives() {
    this.isRealGettingFiles = true;
    this.attachSubscriptions(
      forkJoin([...this.urls.map(live => this.taskService.getTaskChannelFileWithTags(this.data.company.id, {task_channel_id: live.id}).pipe(
        tap(chFiles => {
          live.files = chFiles
        })
      ))]).subscribe(resp => {
        this.isRealGettingFiles = false;
        console.log("getFilesForLives", this.urls)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteProfiles() {
    console.log(this.liveCollection.selected);

    let confirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        msg: marker(`Are you sure you want to delete ${this.liveCollection.selected.length} profile(s):`),
        profileCollection: this.liveCollection.selected,
        btn_no: "Cancel",
        btn_yes: "Delete"
      }
    });

    confirmAlert.afterDismissed().subscribe(data => {
      if (data && data.message == 'no') {
        console.log("no");
        this.liveCollection.clear()
      } else if (data && data.message == 'yes') {

        if (this.liveCollection.selected.filter(x => !!x.content_url).length > 0) {
          let newConfirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
            hasBackdrop: true,
            backdropClass: 'bottom-sheed-backdrop',
            data: {
              msg: marker(`Are you sure you want to delete ${this.liveCollection.length} profile(s):`),
              profileCollection: this.liveCollection.selected,
              hasInfoProfileCollection: this.liveCollection.selected.filter(x => !!x.content_url),
              btn_no: "Yes!",
              btn_yes: "Leave non-empty profiles."
            }
          });
      
          newConfirmAlert.afterDismissed().subscribe(data => {
            if (data && data.message == 'no') {
              forkJoin(this.liveCollection.selected.map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
                this.liveCollection.clear()
                this.refresh();
              })
            } else if (data && data.message == 'yes') {
              forkJoin(this.liveCollection.selected.filter(x => !x.content_url).map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
                this.liveCollection.clear()
                this.refresh();
              })
            }
          })
        } else {
          forkJoin(this.liveCollection.selected.map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
            this.liveCollection.clear()
            this.refresh();
          })
        }
      }
    })
  }

  inputContentUrl(live, e) {
   // stepper: MatStepper
    if (live.isParseWork) {
      this.layoutService.showSnackBar({name: ""}, marker("Data is being received. Wait a moment, please."), SnackBarItem)
      return false;
    }

    live.isParseWork = true;

    this.attachSubscriptions(
      this.taskService.getSmartLinkData(live.content_url, live.channel_id, this.data.company.id, (this.data.task.company_id != this.data.company.id ? this.data.task.company_id : null)).subscribe(resp => {
        console.log("getLinkData ------ ############### ------", resp)
        live.linkData = resp
        live.content_name = resp.name ? resp.name : 'Post has no text';
        live.external_content_id = resp.external_content_id;
        live.content_filename = !!resp.filename ? resp.filename : '';
        live.content_image = resp.image;
        live.content_views_count = !!resp.views_count ? resp.views_count : '0';
        live.content_published_at = resp.published_at;

        // this.form.get('date').patchValue({
        //   day: resp.published_at ? moment(this.showUserDate(false, resp.published_at) * 1000).hour(0).minute(0) : '',
        //   time: resp.published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).minutes())) : '',
        // })

        live.isParseWork = false;
      })
    )
  }

  submitForm2() {
    if (this.isSubmit) {
      return
    }
    this.isSubmit = true;
    // let livesFiles = this.urls.map(x => x.files).flat(Infinity)

    let count = 0;
    this.attachSubscriptions(
      concat(...this.urls.map(live => {
        let sbmtData:any = {
          task_id: live.task_id,
          channel_id: live.channel_id,
          content_url: live.content_url,
          content_name: live.content_name,
          is_content_views_count_update : 1,
          is_bravourl_content_views_count_update : 1,
          content_image: live.content_image,
          content_filename: live.content_filename,
          external_content_id: live.external_content_id,
          content_type_id: live.content_type_id,
          content_published_at: live.content_published_at,
          content_status_id: live.content_status_id,
          content_views_count: live.content_views_count,
          publishing_params: {
            live_stream_url: live.publishing_params.live_stream_url,
            live_stream_key: live.publishing_params.live_stream_key,
            live_stream_to: "",
            is_loop: live.publishing_params.is_loop,
            is_random: live.publishing_params.is_random,
            random_start_count: !!live.publishing_params.is_random ? live.publishing_params.random_start_count : 0,
          }
        }
        sbmtData.publishing_params.is_loop = !!sbmtData.publishing_params.is_loop ? 1 : 0;
        sbmtData.publishing_params.is_random = !!sbmtData.publishing_params.is_random ? 1 : 0;
        sbmtData.publishing_params.live_stream_to = (sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key;
        // sbmtData.publishing_params.live_stream_to = sbmtData.publishing_params.live_stream_url+'/'+sbmtData.publishing_params.live_stream_key;
        // sbmtData.publishing_params.live_stream_to = sbmtData.publishing_params.live_stream_to.replaceAll('//', '/')
        return this.taskService.editProfile(live.id, sbmtData, this.data.company.id).pipe(
          catchError((error) => {
            return of(error)
          }),
          switchMap(res => {
            let newFiles = [...live.files.filter(u => !!u.is_new)];
            let deletedFiles = [...live.files.filter(u => !!u.deleted)];
            let oldFiles = [...live.files.filter(u => !u.is_new && u.is_changed)]

            let filesData = [];
            
            newFiles.forEach(fileData => {
              filesData.push({
                "path": `/api/task-channel-file/`,
                "query": {company_id: this.data.company.id},
                "method": "POST",
                "body": {
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  company_id: fileData.company_id,
                  task_channel_id: fileData.task_channel_id,
                  file_id: fileData.file_id,
                  is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                  is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                }
              })
            })

            oldFiles.forEach(fileData => {
              filesData.push({
                "path": `/api/task-channel-file/${fileData.id}/`,
                "query": {company_id: this.data.company.id},
                "method": "POST",
                "body": {
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  company_id: fileData.company_id,
                  task_channel_id: fileData.task_channel_id,
                  file_id: fileData.file_id,
                  is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                  is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                }
              })
            })

            deletedFiles.forEach(fileData => {
              filesData.push({
                "path": `/api/task-channel-file/${fileData.id}/`,
                "query": {company_id: this.data.company.id},
                "method": "DELETE"
              })
            })
            
            if (filesData.length) {
              return this.taskService.multiRequest(filesData).pipe(map(() => res))
            } else {
              return of(res)
            }
          })
        )
      }))
      .subscribe({
        next: (next) => {
          // console.log("next Fin", next);
          console.log("submitForm2 next", next)
          count++;
          this.ls.requests$.next({
            value: Math.round((100 / (this.urls.length)) * (count)),
            target: "Lives settings"
          })
        },
        complete: () => {
          console.log("submitForm2 complete")
          this.isSubmit = false;
          this.refreshAll();
        },
        error: (error) => {
          console.log("submitForm2 error", error)
        }
      })
    )
  }

  refreshAll() {
    this.attachSubscriptions(
      this.taskService.getExpandUrlsWithTags('1', this.data.company.id, {task_id: this.data.task.id, content_type_id: '106'}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.taskService.getExpandUrlsWithTags(x, this.data.company.id, {task_id: this.data.task.id, content_type_id: '106'}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            map(values => {
              return [].concat(...values)
            }),
          )
        }),
      ).subscribe(resp => {
        this.urls = resp;
        console.log("refreshAll", this.urls);
        this.getFilesForLives();
      })
    )
  }

  submitForm() {
    console.log('submitForm')
    if (this.isSubmit) {
      return
    }
    this.isSubmit = true;
    let livesFiles = this.urls.map(x => x.files).flat(Infinity)
    console.log("livesFiles", livesFiles);

    let count = 0;
    let newFiles = [...livesFiles.filter(u => !!u.is_new)];
    let deletedFiles = [...livesFiles.filter(u => !!u.deleted)];
    let oldFiles = [...livesFiles.filter(u => !u.is_new && u.is_changed)]
    if (newFiles.length || deletedFiles.length || oldFiles.length) {
      this.attachSubscriptions(
        concat(...newFiles.map(fileData => this.taskService.setTaskChannelFile({
         company_id: fileData.company_id,
         task_channel_id: fileData.task_channel_id,
         file_id: fileData.file_id,
         is_to_upload: !!fileData.is_to_upload ? 1 : 0,
         is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
       }, this.data.company.id).pipe(
         catchError((error) => {
           return of(error)
         })
       )),...oldFiles.map(fileData => this.taskService.editTaskChannelFile(fileData.id, {
         company_id: fileData.company_id,
         task_channel_id: fileData.task_channel_id,
         file_id: fileData.file_id,
         is_to_upload: !!fileData.is_to_upload ? 1 : 0,
         is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
       }, this.data.company.id).pipe(
         catchError((error) => {
           return of(error)
         })
       )),...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.data.company.id).pipe(
         catchError((error) => {
           return of(error)
         })
       ))).subscribe({
        next: (next) => {
          // console.log("next Fin", next);
          console.log("submitForm next", next)
          count++
          this.ls.requests$.next({
            value: Math.round((100 / (newFiles.length + deletedFiles.length + oldFiles.length)) * (count)),
            target: "Lives settings"
          })
        },
        complete: () => {
          console.log("submitForm complete")
          this.isSubmit = false;
          this.getFilesForLives();
        },
        error: (error) => {
          console.log("submitForm error", error)
        }
      })
      )
    } else {
      this.isSubmit = false;
    }
  }

  ngOnDestroy(): void {
    if (Math.round((100 / (this.urls.length)) * (this.countServers)) < 100) {
      this.ls.requests$.next({
        value: 100,
        target: "Getting servers and grouping lives by server id"
      })
    }
    this.clearSubscriptions()
  }

}
