import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';

@Component({
  selector: 'app-add-card-scenario',
  templateUrl: './add-card-scenario.component.html',
  styleUrls: ['./add-card-scenario.component.scss']
})
export class AddCardScenarioComponent extends BaseClass implements OnInit, OnDestroy {
  public scenarioForm: FormGroup;
  public templateForm: FormGroup;
  public host: any = '';
  public imgRoute: any = '';
  public templates: any;
  public activeIndex = new FormControl(0);
  public isSubmit: boolean = false;
  public submited: boolean = false;

  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddCardScenarioComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private scenariosService: ScenariosService,
    private layoutService: LayoutService
    ) { super() }

  ngOnInit(): void {
    console.log('AddCardScenarioComponent', this.data)
    this.templateForm = this.fb.group({
      company_id: this.data.company.id,
      task_id: this.data.task.id,
      template_id: ''
    })

    this.scenarioForm = this.fb.group({
      company_id: this.data.company.id,
      task_id: this.data.task.id,
      scenario_name: ['', Validators.required],
      scenario_data: this.fb.group({
        status_id: ['', Validators.required],
        operation_id: ['', Validators.required],
        employees_ids: [],
        is_copy_approved_files: 0,
        is_copy_ready_files: 0,
        is_create_discussions: 0,
      }),
      is_active: 1
    })

    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task: 1}, '200').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getAutomationTemplates", resp);
        this.templates = resp;
        this.templates$.next(this.templates.slice());
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  toggleChip = (chip: any) => {
    this.templateForm.patchValue({
      template_id: !!chip.id ? chip.id : chip.value
    })
  }

  changeTab(val) {
    this.activeIndex.patchValue(val);
  }

  addScenario() {

  }

  addTemplate() {
    // .pipe(
    //   switchMap(res => {
    //     return this.scenariosService.tryScenario(res.id).pipe(tap(result => res.tryData = result), map(() => res))
    //   }),
    // )
    this.attachSubscriptions(
      this.scenariosService.addAutomationScenario(this.templateForm.value, this.data.company.id).subscribe(resp => {
        console.log("addAutomationScenario", resp);
        this.close();
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
