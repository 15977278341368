<mat-dialog-title class="dialog_title profile_dialog_title">   
    <div class="d-f-c"> 
        <span class="info" (click)="log()">
            {{ "Live info" | translate }}
        </span>
        <div class="for_space users_select" [ngClass]="{'req_at': !form.get('channel_id').value && submited}">
            <span>{{ "Outlet for live:" | translate }}</span>
            <ng-container *ngIf="data?.profile || form.get('channel_id').value; else elseTemplateHasChannel">
                <mat-chip-list [multiple]="false" [selected]="false">
                    <mat-chip class="chip_user" style="padding-left: 0 !important;">
                        <div class="user_div">
                            <app-platform [platform_id]="data?.profile ? data?.profile?.channel?.platform_id : getProfileById(form.get('channel_id').value)?.platform_id"></app-platform>
                            <span>{{ data.profile ? data.profile?.channel?.name : getProfileById(form.get('channel_id').value)?.name }}</span>
                        </div>
                    </mat-chip>
                </mat-chip-list>
                <span style="color: #1877F2; cursor: pointer;" (click)="profilesContainer.open()">
                    {{ "Change" | translate }}
                </span>
            </ng-container>
            <ng-template #elseTemplateHasChannel>
                <span style="color: #1877F2; cursor: pointer;" (click)="profilesContainer.open()">
                    {{ "Select" | translate }}
                </span>
            </ng-template>
    
            <form [formGroup]="form">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a profile" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir #profilesContainer formControlName="channel_id" required>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching outlet found' | translate }}"
                            [formControl]="profilesMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let profile of profiles$ | async" [value]="profile.id">
                            <div class="platform_wrp">
                                <app-platform [platform_id]="profile?.platform_id"></app-platform>
                                <span class="ml-1">
                                    {{ profile.name }}
                                </span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
    </div>
    
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</mat-dialog-title>
  
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">

        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="white_block">
                <div class="white_block_title">
                    {{ "Status of live" | translate }}
                </div>
                <div class="custom_radio" style="position: relative;">
                    <ng-container *ngIf="required.content_status_id && !form.get('content_status_id').value && submited">
                        <div class="req_field"></div>
                        <div class="req_label">
                            <div class="angle"></div>
                            <span>
                                {{ "You need to choose a value" | translate }}
                            </span>
                        </div>
                    </ng-container>
                    <mat-radio-group style="position: relative;" formControlName="content_status_id">
                        <ng-container *ngFor="let status of profileStatuses">
                            <ng-container *ngIf="!data.is_edit || !withoutToUpload() || status.id != 6">
                                <mat-radio-button (click)="userSelectedStatus(status)" color="primary" [disabled]="status.is_user == 0" [value]="status.id">{{ status.name+'_profile' | translate }}</mat-radio-button>
                            </ng-container>
                        </ng-container>
                    </mat-radio-group>
                </div>
                <div class="error_label" *ngIf="data.profile && data.profile.publishing_error">
                    {{data.profile.publishing_error}}
                </div>
            </div>
            
            <!-- <div class="white_block">
                <div class="white_block_title">
                    {{ "Live details" | translate }}
                </div>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Content Name' | translate }}</mat-label>
                    <input formControlName="content_name" matInput placeholder="{{ 'Content Name' | translate }}">
                </mat-form-field>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Content URL' | translate }}</mat-label>
                    <input formControlName="content_url" matInput placeholder="{{ 'Content URL' | translate }}">
                </mat-form-field>

                <div class="date_and_time_c" formGroupName="date">
                    <mat-form-field appearance="standard" class="date_picker_cst">
                        <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                        <input formControlName="day" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                        <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDay></mat-datepicker>
                    </mat-form-field>
                    
                    <div class="ngx-timepicker-field-example">
                        <div class="prefix">{{ "Time" | translate }}</div>
                        <ngx-timepicker-field [format]="24" [defaultTime]="form.get('date').get('time').value" (timeChanged)="changedTime($event)" formControlName="time"></ngx-timepicker-field>
                    </div>
                    <div class="user_timezone" *ngIf="isValidNotYetDate()">
                        <span>{{"Your time" | translate}}</span>
                        <span>{{showUserDate(form.get('content_published_at').value) * 1000 | date:'MMM dd, hh:mm a':'':activeLang }}</span>
                    </div>
                </div>
                <div class="local_time">{{ "Workspace Time Zone" | translate }} <span>({{data.company.timezone}})</span></div>

            </div> -->
            <div class="white_block">
                <div class="white_block_title">
                    {{ "Live details" | translate }}
                </div>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Content URL' | translate }}</mat-label>
                    <mat-spinner class="spin_24 mr-1" matPrefix *ngIf="isParseWork"></mat-spinner>
                    <input (input)="onInputUrl($event.target.value)" formControlName="content_url" matInput placeholder="{{ 'Content URL' | translate }}">
                </mat-form-field>

                <div class="parse_data" [ngClass]="{'parcing': isParseWork}">
                    <div *ngIf="isParseWork" class="parcing_text">
                        {{ "Data is bieng received" | translate }}
                    </div>
                    <div class="parse_form" [ngClass]="{'white': photoEdited}">
                        <ng-container *ngIf="!photoEdited; else elseTemplateIMGEdited">
                            <div [ngStyle]="{'width': urlNotYet.value ? '100%' : 'calc(100% - 150px)'}">
               
                                <mat-form-field *ngIf="!urlNotYet.value" appearance="standard" class="full_width_form">
                                    <ng-container *ngIf="required.content_name && !form.get('content_url').value && submited">
                                        <div class="req_field"></div>
                                        <div class="req_label">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to fill this field" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <mat-label>{{ 'Content Name' | translate }}</mat-label>
                                    <input formControlName="content_name" matInput placeholder="{{ 'Content Name' | translate }}">
                                </mat-form-field>

     
                                <div class="date_and_time_c" formGroupName="date">
                                    <ng-container *ngIf="required.content_published_at && !isValidNotYetDate() && submited">
                                        <div class="req_field"></div>
                                        <div class="req_label">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to fill this field" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <mat-form-field appearance="standard" class="date_picker_cst">
                                        <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                                        <input formControlName="day" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                                        <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerDay></mat-datepicker>
                                    </mat-form-field>
                                    
                                    <div class="ngx-timepicker-field-example">
                                        <div class="prefix">{{ "Time" | translate }}</div>
                                        <ngx-timepicker-field [format]="24" [defaultTime]="form.get('date').get('time').value" (timeChanged)="changedTime($event)" formControlName="time"></ngx-timepicker-field>
                                    </div>
                                    <div class="user_timezone" *ngIf="isValidNotYetDate()">
                                        <span>{{"Your time" | translate}}</span>
                                        <span>{{showUserDate(form.get('content_published_at').value) * 1000 | date:'MMM dd, hh:mm a':'':activeLang }}</span>
                                    </div>
                                </div>
                                <div class="local_time">{{ "Workspace Time Zone" | translate }} <span>({{data.company.timezone}})</span></div>
                            </div>
                            <div *ngIf="!urlNotYet.value">
            
                                <div class="photo_btns">
                                    <mat-icon style="color: #1877F2;" (click)="editPhoto()">edit</mat-icon>
                                    <mat-icon style="color: #FF6A6A;" (click)="deletePhoto()">delete</mat-icon>
                                </div>
                                <ng-container *ngIf="data.profile && contentImage.value == data.profile.content_image && data.profile.file; else elseTemplatePhoto">
                                    <img (error)="onPreviewImgError($event)" [src]="host + data.profile.file.original + '?company_id=' + data?.company?.id" alt="">
                                </ng-container>
                                <ng-template #elseTemplatePhoto>
                                    <img (error)="onPreviewImgError($event)" [src]="form.get('content_image').value" alt="">
                                </ng-template>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateIMGEdited>
                            <div>
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Content preview' | translate }}</mat-label>
                                    <input [formControl]="contentImage" matInput placeholder="{{ 'Content preview' | translate }}">
                                </mat-form-field>

                                <div class="content_image_btns">
                                    <button mat-button (click)="closePhoto()">
                                        {{ "Close" | translate }}
                                    </button>
                                    <button class="ml-1" (click)="savePhoto()" mat-raised-button cdkFocusInitial color="primary">
                                        {{ "Save" | translate }}
                                    </button>
                                </div>
                            </div>
                            <div>
                                <img (error)="onPreviewImgError($event)" [src]="contentImage.value" alt="">
                            </div>
                        </ng-template>
                    </div>
                    <mat-progress-bar mode="indeterminate" *ngIf="isParseWork" class="mat_proggress_w8"></mat-progress-bar>
                </div>
            </div>

            <div class="white_block" formGroupName="publishing_params">
                <div class="white_block_title">
                    {{ "Live settings" | translate }}
                </div>
                <div class="d-f-c">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Live stream url' | translate }}</mat-label>
                        <input formControlName="live_stream_url" matInput placeholder="{{ 'Live stream url' | translate }}">
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="ml-1 full_width_form">
                        <mat-label>{{ 'Live stream key' | translate }}</mat-label>
                        <input formControlName="live_stream_key" matInput placeholder="{{ 'Live stream key' | translate }}">
                    </mat-form-field>
                </div>
                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_loop">{{ "Loop" | translate }}</mat-checkbox>
                </div>
                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_random">{{ "Random" | translate }}</mat-checkbox>
                </div>
                <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!form.get('publishing_params').get('is_random').value">
                    <mat-label>{{ 'Start streaming when files are ready (pcs)' | translate }}</mat-label>
                    <input formControlName="random_start_count" type="number" matInput placeholder="{{ 'Start streaming when files are ready (pcs)' | translate }}">
                </mat-form-field>

                <mat-divider style="margin: 15px 0;"></mat-divider>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_backup">{{ "Additional stream from backup server" | translate }}</mat-checkbox>
                </div>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Live stream backup url' | translate }}</mat-label>
                    <input formControlName="live_stream_url_backup" matInput placeholder="{{ 'Live stream backup url' | translate }}">
                </mat-form-field>

            </div>
            
        </form>
        <div class="form_with_pads form_with_valids">
            <div class="white_block white_expanded">
                <div class="d-f-c" (click)="showFiles = !showFiles">
                    <div class="white_block_title">
                        {{ "Live files" | translate }}
                    </div>

                    <button mat-icon-button>
                        <mat-icon *ngIf="showFiles">expand_less</mat-icon>
                        <mat-icon *ngIf="!showFiles">expand_more</mat-icon>
                    </button>
                </div>
                <div class="white_inside" [ngClass]="{'open': showFiles}">
                    <div class="live_files_head">
                        <div class="live_files_count" (click)="logFiles()">
                            {{channelFiles?.length}} {{'files'}}
                        </div>
                        <div class="d-f-c">
                            <div class="add_media_item ml-5 silver" *ngIf="channelFiles?.length" (click)="reprocessMissingFiles()">
                                {{ "Reprocess missing file(s)" | translate }}
                            </div>
                            <div class="add_media_item ml-5 red" *ngIf="channelFiles?.length" (click)="removeAllFiles()">
                                {{ "Remove all file(s)" | translate }}
                            </div>
                            <div class="add_media_item ml-5" (click)="selectFileBtn('video')">
                                {{ "Add Video file(s)" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="live_files">
                        <ng-container *ngFor="let item of channelFiles; let i = index;">
                            <div class="live_file" *ngIf="!!item" [ngClass]="{'deleted': !!item?.deleted}">
                                <mat-icon class="done_icon no_done" *ngIf="!item?.liveStreamOriginalFile">cloud_upload</mat-icon>
                                <mat-icon class="done_icon" *ngIf="!!item?.liveStreamOriginalFile">cloud_done</mat-icon>
                                <div class="item_photo" (contextmenu)="openLiveFileContext($event, item); $event.preventDefault(); $event.stopPropagation();">
                                    <!-- <mat-icon>movie</mat-icon> -->
                                    <img [src]="host + item?.file?.thumbnail + '?company_id=' + data?.company?.id">
                                </div>
                                <div class="item_info" (contextmenu)="openLiveFileContext($event, item); $event.preventDefault(); $event.stopPropagation();">
                                    <div class="name text_one_line">{{item?.file?.filename}}</div>
                                    <div class="info">
                                        <span>{{item?.file?.meta_duration | timeFormat}}</span>
                                        <span>{{item?.file?.meta_width + " &#10005; " + item?.file?.meta_height}}</span>
                                    </div>
                                </div>
                                <div class="item_checks">
                            
                                    <div class="box_wrp">
                                        <mat-checkbox color="primary" [(ngModel)]="item.is_live_stream_content">{{ "Live stream content" | translate }}</mat-checkbox>
                                    </div>
                                    <div class="box_wrp">
                                        <mat-checkbox color="primary" [(ngModel)]="item.is_to_upload">{{ "To upload" | translate }}</mat-checkbox>
                                    </div>
                                </div>
                                <div class="delete_el" (click)="deleteChFile(item, i)" *ngIf="!item?.deleted">
                                    <mat-icon style="color: red;">close</mat-icon>
                                </div>
                                <div class="delete_el" (click)="item.deleted = false;" *ngIf="!!item?.deleted">
                                    <mat-icon style="color: green;">refresh</mat-icon>
                                </div>
                                <div class="id_el" (click)="settings(item?.file, item)" #tooltip="matTooltip" matTooltip="{{ 'File properties' | translate }}" [matTooltipPosition]="'above'">
                                    {{item?.file?.id}}
                                </div>
                                <div class="upd_el">
                                    <ng-container *ngIf="item?.updated_at > today && item?.updated_at < todayEnd; else updatedAtTempl">
                                        <span #tooltip="matTooltip"
                                          matTooltip="{{ item?.updated_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                                          [matTooltipPosition]="'above'">
                                          {{ item?.updated_at*1000 | date:'hh:mm a':'':activeLang }}
                                        </span>
                                      </ng-container>
                                      <ng-template #updatedAtTempl>
                                        <span *ngIf="item?.updated_at && item?.updated_at > 30000" #tooltip="matTooltip"
                                          matTooltip="{{ item?.updated_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                                          [matTooltipPosition]="'above'">
                                          {{ item?.updated_at*1000 | date:'MM.dd.yy':'': activeLang }}
                                        </span>
                                      </ng-template>
                                </div>
                                <div class="error_el" *ngIf="item.is_error" #tooltip="matTooltip" matTooltip="{{ !!user?.is_root ? item.error_message : '' }}" [matTooltipPosition]="'above'">
                                    <mat-icon style="color: #c0392b;">warning</mat-icon>
                                </div>
                            </div>
                        </ng-container>
                        
                    </div>
                    <!-- <app-file-manager
                    [useOut]="true"
                    [selectedFileIds]="getChannelFilesId()"
                    [unSelectedFileIds]="getUnSelectedFilesId()"
                    [selectedMode]="true"
                    [multiSelectedMode]="true"
                    (onSelectFile)="selectFile($event)"
                    [initLocation]="!!channelFiles && !!channelFiles[0] && !!channelFiles[0] ? channelFiles[0]?.location :  '/'"
                    [initData]="{
                        'task': data?.task,
                        'work': channelFiles && !!channelFiles[0] && !!channelFiles[0] ? getJob(channelFiles[0]?.task_operation_id) : undefined,
                        'company': data?.company
                    }"></app-file-manager> -->
                </div>
            </div>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="white_block" *ngIf="form.get('content_views_count').value">
                <div class="white_block_title d-f-c-j" style="padding: 0;">
                    <div class="d-f-c analitic_title">
                        <button *ngIf="data.profile && data.profile.id" mat-raised-button (click)="showStats()">{{ "Analytics" | translate }}</button>
                    </div>
                    <div class="d-f-c">
                        <div class="box_wrp">
                            <mat-checkbox color="primary" formControlName="is_content_views_count_update">{{ "Auto-update" | translate }}</mat-checkbox>
                        </div>
                    </div>
                    <div class="d-f-c">
                        <div class="box_wrp">
                            <mat-checkbox color="primary" formControlName="is_bravourl_content_views_count_update">{{ "Auto-update(bravo)" | translate }}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <div class="d-f-c">
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </div>

        <div class="d-f-c">
            <div class="content_filename" *ngIf="form && form.value && form.value.content_filename">
                {{form.value.content_filename}}
            </div>

            <div class="post_id ml-1 cp" *ngIf="data.profile" ngxClipboard [cbContent]="data.profile.id" (cbOnSuccess)="copyLink('Post ID')">
                {{data.profile.id}} 
            </div>
        </div>
    </div>
  </mat-dialog-actions>
  


  <ng-template #liveFileMenu let-post>
    <section class="card-menu">
      <div (click)="$event.preventDefault(); settings(post?.file, post)">
        <mat-icon class="mr-1">info</mat-icon>
        {{ 'Properties' | translate }}
    </div>
    </section>
  </ng-template>