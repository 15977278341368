import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concat, forkJoin, fromEvent, ReplaySubject, Subscription } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { environment } from 'src/environments/environment';
import { TargetParametersComponent } from '../../../atTasksDialog/target-parameters/target-parameters.component';
import { ChannelToEmployeeComponent } from '../../members/employees/dialogs/channel-to-employee/channel-to-employee.component';
import { ProfileCreateComponent } from '../profile-create/profile-create.component';
import { ConnectWithYoutubeComponent } from '../profile-edit/dialogs/connect-with-youtube/connect-with-youtube.component';
import { ProfileEditComponent } from '../profile-edit/profile-edit.component';
import { ProfilePlaylistComponent } from '../profile-playlist/profile-playlist.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseClass implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public company_id: any;
  public group_id: any;
  public open_id: any;
  public profiles: any[] = [];
  public group: any;
  public allValues: any;
  public company: any;
  public pagination: any;
  public filter: FormGroup = this.fb.group({
    q: '',
    platform_id: '',
    parameter_value_id: [[]],
    group_id: '',
    partner_company_id: ''
  });

  public profilesDataSub: Subscription;
  public page: number = 1;
  public groups: any;
  public platforms: any;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();
  
  public partners: any;
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public partnersControl: FormControl = new FormControl();

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private parametersService: ParametersService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.group_id = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.open_id = this.activatedRoute.snapshot.queryParamMap.get('open_id');

    this.getCompany();
    this.getPlatforms();
    this.getGroupsCompany();
   
    this.getImgRoute();
    this.getCsrf();
    this.getAllApiParameterValues();
    this.getUser();

    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(params => {
        if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.getGroupsCompany();
          this.getAllApiParameterValues();
        }
        this.group_id = this.activatedRoute.snapshot.queryParamMap.get('id');
        this.open_id = this.activatedRoute.snapshot.queryParamMap.get('open_id');
        console.log("route checklists", params);
        this.profiles = [];
        this.page = 1;

        this.filter.patchValue({
          q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
          platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : '',
          group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number)[0] : '',
          partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') : '',
          parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
        })

        this.getProfiles(this.company_id, this.page);
      })
    )
    
    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )

          
    this.profilesDataSub = this.companyService.getProfilesDataStream().pipe(
      concatMap(itemsInPage => {
        return concat(...itemsInPage.map(itemInPage => this.neededData(itemInPage))).pipe(last(),map(x => itemsInPage))
      }),
    ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
      })
    )
  }
  
  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getChanTags(channel) {
    this.attachSubscriptions(
      this.parametersService.getTargetChannelParameters(this.company_id, {channel_id : channel.id}).subscribe(res => {
        channel.tags = res
      })
    )
  }

  neededData(channel) {
    let arr = [
      this.parametersService.getTargetChannelParameters(this.company_id, {channel_id : channel.id}).pipe(
        tap(res => {
          channel.tags = res
        })
      )
    ]
    // if (task.company_id == this.company.id) {
    //   arr.push(
    //     this.taskService.getTaskClients(task.id, this.company_id).pipe(
    //       tap(res => {
    //         task.clients = res
    //       }),
    //       catchError(err => {
    //         return err
    //       })
    //     )
    //   )
    // }
    return forkJoin(arr)
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        // this.partners.unshift({
        //   id: '',
        //   partner_company_id: '',
        //   partnerCompany: Object.assign({...this.company}, {name: "Active Workspace"})
        // })
        console.log("getPartners", this.partners)
        this.partners$.next(this.partners.slice())
      })
    )
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  openChannelToEmployee(channel) {
    const dialogRef = this.dialog.open(ChannelToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        channel: channel,
        fromEmployees: false,
        forPartner: !!this.filter.value.partner_company_id,
        partner_company_id: this.filter.value.partner_company_id
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }
  
  selectChange(e) {
    this.filterData();
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  filterData() {
    let params = {
      company_id: this.company_id,
      page: 'profiles'
    };

    Object.keys(this.filter.value).forEach(element => {
      if (!!this.filter.value[element]) {
        if (element == 'partner_company_id') {
          params['operation_partner_company_id'] = this.filter.value[element]
        } else {
          params[element] = this.filter.value[element]
        }
      }
    });

    this.router.navigate(['/settings'], { queryParams: params});
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page')
      }
      this.router.navigate(['/settings'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/settings'], {queryParams: params})
    }
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterData()
  }

  filterReset() {
    this.filter.patchValue({
      q: '',
      platform_id: '',
      group_id: '',
      parameter_value_id: [],
      partner_company_id: ''
    })
    this.router.navigate(['/settings'], { queryParams: {page: 'profiles', company_id: this.company_id}});
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getProfiles(this.company_id, this.page);
          }
        }
      )
    )
  }

  openTargetValues(profile) {
    let initData:any = {
      company: this.company,
      channel: profile
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getChanTags(profile)
      })
    )
  }

  openPlaylist(profile) {
    const dialogRef = this.dialog.open(ProfilePlaylistComponent, {
      data: {
        company: this.company,
        profile: profile,
        user: this.user
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.page = 1;
        this.getProfiles(this.company_id, this.page);
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
        this.getPartners();
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getProfiles(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getFilteredProfiles(company_id, page, this.filter.value).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
          if (page == 1) {
            this.profiles = []
          }
        }),
        map(el => el.body),
        tap(res => {
          if (res.length) {
            this.companyService.profilesData$.next(res)
          }
        })
      ).subscribe(resp => {
        if (page == 1) {
          this.profiles = resp
        } else {
          this.profiles.push(...resp);
        }
        console.log("getProfiles", this.profiles)

        this.page = this.page + 1;
        if (!!this.open_id && this.profiles.find(x => x.id == this.open_id)) {
          this.connectWithYouTube(this.profiles.find(x => x.id == this.open_id))
        }
      })
    )
  }

  connectWithYouTube(profile) {
    const dialogRef = this.dialog.open(ConnectWithYoutubeComponent, {
      disableClose: true,
      data: {
        company_id: this.company_id,
        profile: profile,
        user: this.user,
        company: this.company
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'edit') {
          if (this.open_id) {
            this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'}});
          } else {
            this.page = 1;
            this.pagination = undefined;
            this.getProfiles(this.company_id, this.page)
          }
        } else {
          if (this.open_id) {
            this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'}});
          }
        }
      })
    )
  }

  deleteProfileCompany(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("profile")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteProfileCompany(id).subscribe(resp => {
            this.profiles.splice(i, 1);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      }
    });
  }

  addProfile(e) {
    e.preventDefault();
    const dialogRef = this.dialog.open(ProfileCreateComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getProfiles(this.company_id, this.page);
        }
      })
    )
  }
  
  editProfile(e, element, group) {
    e.preventDefault();
    const dialogRef = this.dialog.open(ProfileEditComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        profile: element,
        group: group
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getProfiles(this.company_id, this.page);
        }
      })
    )
  }

  goBack(e) {
    e.preventDefault();
    history.back();
  }

  getPartnerById(id) {
    if (!this.partners || this.partners.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.partners.find(el => el.id == id)
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }


  goToCompany(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/tasks'], { queryParams: {company_id: company_id, order_by: "updated_desc"} });
  }

  ngOnDestroy(): void {
    if (this.profilesDataSub) {
      this.profilesDataSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}

