import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, HostListener, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2,
  ROTATE = 3
}

@Component({
  selector: 'app-resizable-draggable',
  templateUrl: './resizable-draggable.component.html',
  styleUrls: ['./resizable-draggable.component.scss']
})
export class ResizableDraggableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input('combine') public combine: any;
  @Input('part') public part: any;
  @Input('host') public host: any;
  @Input('forAll') public forAll: boolean = true;
  @Input('width') public width: any;
  @Input('height') public height: any;
  @Input('project') public project: any;
  @Input('verticalLine') public verticalLine: any;
  @Input('isProjectPlaying') public isProjectPlaying: any;
  @Input('hasCropOn') public hasCropOn: any;
  @Input('currentTimeProject') public currentTimeProject: any;
  @Input('currentCutTimeProject') public currentCutTimeProject: any;
  @Input('company_id') public company_id: any;
  @Input('ratio') public ratio: number;
  @Input('holst') public holst: any;
  @Output() Loadedmetadata = new EventEmitter<any>();
  @ViewChild("black_sq") public black_sq: ElementRef;
  @ViewChild("video") public videoEl: ElementRef;
  // @ViewChild("blurBg") public blurBg: ElementRef;
  // @ViewChild("pointTopLeft") public pointTopLeft: ElementRef;
  // @ViewChild("pointTopRight") public pointTopRight: ElementRef;
  // @ViewChild("pointBottomLeft") public pointBottomLeft: ElementRef;
  // @ViewChild("pointBottomRight") public pointBottomRight: ElementRef;
  @ViewChild("box") public box: ElementRef;
  @ViewChild("rotate") public rotateEl: ElementRef;
  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: {x: number, y: number};
  public status: Status = Status.OFF;
  private mouseClick: {x: number, y: number, left: number, top: number};
  public center: {x: number, y: number};
  public R2D = 180 / Math.PI;
  public startAngle:number = 0;
  public max_side:number = 0;
  public blackHolst: any;
  public resizeTimeout: any;

  ngOnInit() {
    this.center = {
      x: +(this.part.rd.width/2).toFixed(2),
      y: +(this.part.rd.height/2).toFixed(2)
    }

    if (this.part.rd.ratio >= 1) {
      this.max_side = this.part.rd.width*2;
    } else {
      this.max_side = this.part.rd.height*2;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // console.log("ngOnChanges", changes, (!!changes.project && !!changes.project.currentValue) || (changes.width && !!changes.width.currentValue && changes.width.previousValue != changes.width.currentValue) || (changes.height && !!changes.height.currentValue && changes.height.previousValue != changes.height.currentValue));
    if ((!!changes.project && !!changes.project.currentValue) || (changes.width && !!changes.width.currentValue && changes.width.previousValue != changes.width.currentValue) || (changes.height && !!changes.height.currentValue && changes.height.previousValue != changes.height.currentValue)) {
      this.loadBox();
      this.loadContainer();
      this.center = {
        x: +(this.part.rd.width/2).toFixed(2),
        y: +(this.part.rd.height/2).toFixed(2)
      }
      if (this.part.rd.ratio >= 1) {
        this.max_side = this.part.rd.width*2;
      } else {
        this.max_side = this.part.rd.height*2;
      }
      console.log("ngOnChanges0", this.center);
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      console.log("ngOnChanges0.5");

      if (!this.part.saveRD && this.part.rd.params && this.part.rd.params.hasOwnProperty('part_crop_height')) {
        this.part.saveRD = JSON.parse(JSON.stringify(this.part.rd));
      }
      this.resizeTimeout = setTimeout(() => {
        console.log("ngOnChanges1");
        if (this.project.height && this.part && this.part.rd && this.part.rd.height) {
          this.calculateSizes(this.part, this.project);
        }
      }, 300)
    }
  }

  ngAfterViewInit() {
    this.loadBox();
    this.loadContainer();
    // this.calculateSizes(this.part, this.project);
    // if (this.part && this.part.has_blur_bg) {
    //   this.loadBlurBg()
    // }
  }

  loadBlurBg(e) {
    if (this.part && !!this.part.has_blur_bg) {
      const video = (e.target as HTMLVideoElement);
      const canvas = document.getElementById(`bg_${this.part.pId}`) as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
    
      // Настройка размеров холста
      function resizeCanvas() {
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
      }
  
      function drawVideoOnCanvas() {
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
      
        // Масштабирование по высоте
        const scale = canvas.height / videoHeight;
      
        // Новая ширина с учётом пропорций
        const scaledVideoWidth = videoWidth * scale;
      
        // Позиция для центрирования по ширине
        const offsetX = (canvas.width - scaledVideoWidth) / 2;
      
        // Очищаем холст
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      
        // Рисуем видео с учётом масштабирования и позиционирования
        ctx.drawImage(video, offsetX, 0, scaledVideoWidth, canvas.height);
      }
  
      // Устанавливаем правильные размеры холста при изменении размера окна
      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();
    
      // Рисуем текущий кадр
      drawVideoOnCanvas();
    }
  }

  private loadBox(){
    if (this.box) {
      const {left, top} = this.box.nativeElement.getBoundingClientRect();
      this.boxPosition = {left, top};
    }

    if (this.holst) {
      this.blackHolst = JSON.parse(JSON.stringify(this.holst.getBoundingClientRect()))
      console.log("blackHolst", this.blackHolst)
    }

    // console.log("this.boxPosition", this.boxPosition)
    // const {l, t} = this.rotateEl.nativeElement.getBoundingClientRect();
    // let evRotate = {left: l + 6, top: t + 6};
    // let center = {x: this.boxPosition.left + this.center.x, y: this.boxPosition.top + this.center.y};
    // let x = evRotate.left - center.x;
    // let y = evRotate.top - center.y;
    // this.startAngle = Math.round(this.R2D * Math.atan2(y, x));
  }

  private loadContainer(){
    if (this.boxPosition) {
      const left = this.boxPosition.left - this.part.rd.left;
      const top = this.boxPosition.top - this.part.rd.top;
      const right = left + 600;
      const bottom = top + 450;
      this.containerPos = { left, top, right, bottom };
    }
  }

  loadedVideo(e) {
    if (this.part.from) {
      // console.log("loadedVideo", this.part)
      // console.log("loadedVideo", (e.target as HTMLVideoElement).currentTime);
      (e.target as HTMLVideoElement).currentTime = Number(this.part.from.toFixed(6))
    }
    
    this.Loadedmetadata.emit(e)
  }

  setStatus(event: MouseEvent, status: number){
    if ([1,2,3].includes(status)) {
      document.body.classList.add('rd')
    } else {
      setTimeout(() => {
        document.body.classList.remove('rd')
      }, 0)
    }
    if (status === 1) {
      event.stopPropagation();
    } else if (status === 2) {
      this.mouseClick = { x: event.clientX, y: event.clientY, left: this.part.rd.left, top: this.part.rd.top };
    }
    else if (status === 3) {
      event.stopPropagation(); 
      this.mouseClick = { x: event.clientX, y: event.clientY, left: this.part.rd.left, top: this.part.rd.top };
      let center = {x: this.boxPosition.left + this.center.x, y: this.boxPosition.top + this.center.y};
      let x = event.clientX - center.x;
      let y = event.clientY - center.y;
      this.startAngle = Math.round(this.R2D * Math.atan2(y, x));
      // console.log("SET STATUS", this.startAngle)
    } else {
      this.loadBox();
    }
    this.status = status;
    console.log("setStatus", this.status)
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    this.mouse = { x: event.clientX, y: event.clientY };

    if(this.status === Status.RESIZE) this.resize();
    else if(this.status === Status.MOVE) this.move();
    else if(this.status === Status.ROTATE) this.rotate();
  }

  private resize(){
    if(this.resizeCondMeet()){
      this.part.rd.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
      this.part.rd.width = this.part.rd.ratio * this.part.rd.height;
    }

    this.center = {
      x: +(this.part.rd.width/2).toFixed(2),
      y: +(this.part.rd.height/2).toFixed(2)
    }
    this.part.rd.percentX =  this.part.rd.width / this.part.rd.mWidth;
    this.part.rd.percentY = this.part.rd.height / this.part.rd.mHeight;
    // console.log(this.part.rd) 
    this.calculateSizes(this.part, this.project)
  }

  checkDefault(part) {
    if (part.saveRD && Object.keys(part.saveRD.params).some(key => part.saveRD.params[key] != part.rd.params[key])) {
      part.is_changed = true;
    } else {
      part.is_changed = false;
    }
  }

  private resizeCondMeet(){
    return true || (this.mouse.x < this.containerPos.right && this.mouse.y < this.containerPos.bottom);
  }

  private rotate(){
    let center = {x: this.boxPosition.left + this.center.x, y: this.boxPosition.top + this.center.y};
    let x = this.mouse.x - center.x;
    let y = this.mouse.y - center.y;
    let d = this.R2D * Math.atan2(y, x);
    console.log(" rotate center", center)
    let newAngle = Number((d - this.startAngle).toFixed(2));
    let rotation = Math.abs(newAngle - Math.round(newAngle)) <= 0.05 ? Math.round(newAngle) : newAngle;

    this.part.rd.deg = rotation;
    // console.log("startAngle", this.startAngle)
    // console.log("d", d)
    // console.log("rotation", rotation)
    // console.log("deg", this.part.rd.deg)
    this.part.rd.params.part_rotate_deg = this.part.rd.deg;
    this.calculateSizes(this.part, this.project)
  }

  private move(){
    if(this.moveCondMeet()){
      this.part.rd.left = Number(this.mouseClick.left + (this.mouse.x - this.mouseClick.x));
      this.part.rd.top = Number(this.mouseClick.top + (this.mouse.y - this.mouseClick.y));
    }
    this.loadBox();
    // console.log(this.part.rd)

    this.calculateSizes(this.part, this.project)

    // this.part.rd.params = {
    //   part_crop_width: this.part.mWidth,
    //   part_crop_height: this.part.mHeight,
    //   part_crop_x: this.part.rd.left < 0 ? this.part.rd.left/this.project.percentX : 0,
    //   part_crop_y: this.part.rd.top < 0 ? this.part.rd.top/this.project.percentY : 0,
    //   part_scale_width: this.project.mWidth,
    //   part_scale_height: this.project.mHeight,
    //   part_pad_x: 0,
    //   part_pad_y: 0
    // }
  }

  calculateSizes(part, project) {
    if (part.rd.ratio >= 1) {
      this.max_side = part.rd.width*2;
    } else {
      this.max_side = part.rd.height*2;
    }
    // console.log("this.boxPosition", this.boxPosition)
    // console.log("this.project.rect", this.project.rect)
    part.rd.params.part_rotate_deg = part.rd.deg%360;

    if (part.rd.params.part_rotate_deg != 0) {
      const {x, y, height, width} = this.black_sq.nativeElement.getBoundingClientRect();
      let black_sq = {x, y, height, width};
      let real_max_side = this.max_side*2;
      let blackHolst = JSON.parse(JSON.stringify(this.holst.getBoundingClientRect()))
      console.log("NGON #TEST calculateSizes", blackHolst.x, black_sq.x)
      part.rd.params.part_crop_x = blackHolst.x - black_sq.x > 0 ? Math.abs(Math.round((blackHolst.x - black_sq.x)/part.rd.percentX)) : 0;
      part.rd.params.part_crop_y = blackHolst.y - black_sq.y > 0 ? Math.abs(Math.round((blackHolst.y - black_sq.y)/part.rd.percentY)) : 0;
      part.rd.params.part_pad_x = blackHolst.x - black_sq.x < 0 ? Math.abs(Math.round((blackHolst.x - black_sq.x)/project.percentX)) : 0;
      part.rd.params.part_pad_y = blackHolst.y - black_sq.y < 0 ? Math.abs(Math.round((blackHolst.y - black_sq.y)/project.percentY)) : 0

      if ((black_sq.y - blackHolst.y) < 0) {
        if (black_sq.height - Math.abs((black_sq.y - blackHolst.y)) >= project.height) {
          part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((black_sq.height - Math.abs((black_sq.y - blackHolst.y))) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((black_sq.height - Math.abs((black_sq.y - blackHolst.y))) / project.percentY);
        }
      } else if ((black_sq.y - blackHolst.y) == 0) {
        if (black_sq.height >= project.height) {
          part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((black_sq.height) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((black_sq.height) / project.percentY);
        }
      } else {
        if (black_sq.height + (black_sq.y - blackHolst.y) <= project.height) {
          part.rd.params.part_crop_height = Math.round(black_sq.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(black_sq.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((project.height - (black_sq.y - blackHolst.y)) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((project.height - (black_sq.y - blackHolst.y)) / project.percentY);
        }
      }
      if ((black_sq.x - blackHolst.x) < 0) {
        if (black_sq.width - Math.abs((black_sq.x - blackHolst.x)) >= project.width) {
          part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((black_sq.width - Math.abs((black_sq.x - blackHolst.x))) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((black_sq.width - Math.abs((black_sq.x - blackHolst.x))) / project.percentX);
        }
      } else if ((black_sq.x - blackHolst.x) == 0) {
        if (black_sq.width >= project.width) {
          part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((black_sq.width) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((black_sq.width) / project.percentX);
        }
      } else {
        if (black_sq.width + (black_sq.x - blackHolst.x) <= project.width) {
          part.rd.params.part_crop_width = Math.round(black_sq.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(black_sq.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((project.width - (black_sq.x - blackHolst.x)) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((project.width - (black_sq.x - blackHolst.x)) / project.percentX);
        }
      }

    } else {
    
      part.rd.params.part_crop_x = part.rd.left < 0 ? Math.round(Math.abs(part.rd.left)/part.rd.percentX) : 0;
      part.rd.params.part_crop_y = part.rd.top < 0 ? Math.round(Math.abs(part.rd.top)/part.rd.percentY) : 0;
      part.rd.params.part_pad_x = part.rd.left > 0 ? Math.round(part.rd.left/project.percentX) : 0;
      part.rd.params.part_pad_y = part.rd.top > 0 ? Math.round(part.rd.top/project.percentY) : 0;
  
      if (part.rd.top < 0) {
        if (part.rd.height - Math.abs(part.rd.top) >= project.height) {
          part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((part.rd.height - Math.abs(part.rd.top)) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((part.rd.height - Math.abs(part.rd.top)) / project.percentY);
        }
      } else if (part.rd.top == 0) {
        if (part.rd.height >= project.height) {
          part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((part.rd.height) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((part.rd.height) / project.percentY);
        }
      } else {
        if (part.rd.height + part.rd.top <= project.height) {
          part.rd.params.part_crop_height = Math.round(part.rd.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(part.rd.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((project.height - part.rd.top) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((project.height - part.rd.top) / project.percentY);
        }
      }
  
      if (part.rd.left < 0) {
        if (part.rd.width - Math.abs(part.rd.left) >= project.width) {
          part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((part.rd.width - Math.abs(part.rd.left)) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((part.rd.width - Math.abs(part.rd.left)) / project.percentX);
        }
      } else if (part.rd.left == 0) {
        if (part.rd.width >= project.width) {
          part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((part.rd.width) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((part.rd.width) / project.percentX);
        }
      } else {
        if (part.rd.width + part.rd.left <= project.width) {
          part.rd.params.part_crop_width = Math.round(part.rd.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(part.rd.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((project.width - part.rd.left) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((project.width - part.rd.left) / project.percentX);
        }
      }
    }
    // console.log("PARAMS ### --- ", part.rd.params)
    this.checkDefault(part);

    if (!!this.combine && !!this.forAll) {
      this.combine.blends.forEach(blend => {
        if (blend.pId != part.pId && blend.meta_width == part.meta_width && blend.meta_height == part.meta_height) {
          blend.rd = part.rd;
          blend.is_changed = part.is_changed;
        }
      })
    }
  }

  private moveCondMeet(){
    const offsetLeft = this.mouseClick.x - this.boxPosition.left; 
    const offsetRight = this.part.rd.width - offsetLeft; 
    const offsetTop = this.mouseClick.y - this.boxPosition.top;
    const offsetBottom = this.part.rd.height - offsetTop;
    return true || (
      this.mouse.x > this.containerPos.left + offsetLeft && 
      this.mouse.x < this.containerPos.right - offsetRight &&
      this.mouse.y > this.containerPos.top + offsetTop &&
      this.mouse.y < this.containerPos.bottom - offsetBottom
      );
  }
}