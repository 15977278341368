import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime,filter,last,map,switchMap,take,tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { OpenTaskComponent } from '../../workspace-pages/cases/dialogs/open-task/open-task.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { CdkDragEnter, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})

export class CreateTaskComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('consistPlaceholder') consistPlaceholder: any;
  @ViewChild('partPlaceholder') partPlaceholder: any;

  @ViewChild('consistDragPlaceholder') consistDragPlaceholder: ElementRef;
  @ViewChild('partDragPlaceholder') partDragPlaceholder: ElementRef;

  @ViewChild('relatedPlaceholder') relatedPlaceholder: any;
  @ViewChild('relatedDragPlaceholder') relatedDragPlaceholder: ElementRef;

  @ViewChild('consistPlaceholderTemplate') consistPlaceholderTemplate: any;
  @ViewChild('partPlaceholderTemplate') partPlaceholderTemplate: any;

  @ViewChild('consistDragPlaceholderTemplate') consistDragPlaceholderTemplate: ElementRef;
  @ViewChild('partDragPlaceholderTemplate') partDragPlaceholderTemplate: ElementRef;

  @ViewChild('relatedPlaceholderTemplate') relatedPlaceholderTemplate: any;
  @ViewChild('relatedDragPlaceholderTemplate') relatedDragPlaceholderTemplate: ElementRef;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;

  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public backContextSub: Subscription;
  public origin = window.location.origin;

  public values: any;
  public parameters: any;
  public allValues: any;

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public form: FormGroup;
  public creating: FormGroup;
  public templateForm: FormGroup;
  public prioritys: any = prioritys;
  public groups: any;
  public page: number = 1;
  public pagination: any;
  public taskTemplates: any;
  public customIdValue: number;
  public isGetId: boolean = false;
  public isFormChange: boolean = false;
  public relations: boolean = false;
  public submited: boolean = false;
  public submitedTmpl: boolean = false;
  public templatesIsActive: boolean = false;
  public thumbIsOpen: boolean = false;
  public jobsIsOpen: boolean = false;
  public is_mobile: boolean = false;
  public tasks: any[] = [];
  public savedTasks: any[] = [];
  public isSubmit: boolean = false;
  public isSubmitTmpl: boolean = false;

  public typeOfSearchControl: FormControl = new FormControl('Everywhere');
  public consistOfControl: FormControl = new FormControl();
  public partOfControl: FormControl = new FormControl();
  public relativeControl: FormControl = new FormControl();
  public consistOfControlTemplate: FormControl = new FormControl();
  public partOfControlTemplate: FormControl = new FormControl();
  public relativeControlTemplate: FormControl = new FormControl();

  public groupMoreControl: FormControl = new FormControl();
  public groupOfSearchControl: FormControl = new FormControl('');

  public groupMoreControlTemplate: FormControl = new FormControl();
  public groupOfSearchControlTemplate: FormControl = new FormControl('');

  public tasks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public operationStatuses: any;

  public statuses: any;
  public statuses$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public statusesMoreControl: FormControl = new FormControl();

  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public operations: any;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public jobs:any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateTaskComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private taskService: TaskService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private companyService: CompanyService,
    private scenariosService: ScenariosService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")

    console.log("CreateTaskComponent", this.data)
    this.creating = this.fb.group({
      channel_id: this.data.channel ? this.data.channel.id : '',
      company_id: this.data.company_id,
      name: ['', Validators.required],
      is_active: 1
    })

    this.form = this.fb.group({
      company_id: this.data.company_id,
      status_id: ['', Validators.required],
      custom_id: [0, Validators.required],
      name: [!!this.data.is_not_assigned ? '{video_name}' : '', Validators.required],
      comment: '',
      priority: 0,
      private_comment: '',
      group_id: '',
      is_random_avatar: 1,
      consist_of_task_id: this.fb.group({
        add: [[]]
      }),
      part_of_task_id: this.fb.group({
        add: [[]]
      }),
      related_task_id: this.fb.group({
        add: [[]]
      })
    })

    this.templateForm = this.fb.group({
      consist_of_task_id: this.fb.group({
        add: [[]]
      }),
      part_of_task_id: this.fb.group({
        add: [[]]
      }),
      related_task_id: this.fb.group({
        add: [[]]
      })
    })

    if (this.data.related) {
      if (!!this.data.related[0]) {
        this.form.get('consist_of_task_id').get('add').patchValue([this.data.related[0]])
        this.templateForm.get('consist_of_task_id').get('add').patchValue([this.data.related[0]])
      }
      if (!!this.data.related[1]) {
        this.form.get('part_of_task_id').get('add').patchValue([this.data.related[1]])
        this.templateForm.get('part_of_task_id').get('add').patchValue([this.data.related[1]])
      }
      if (!!this.data.related[2]) {
        this.form.get('related_task_id').get('add').patchValue([this.data.related[2]])
        this.templateForm.get('related_task_id').get('add').patchValue([this.data.related[2]])
      }

      let arr = this.data.related.filter(x => !!x);

      this.attachSubscriptions(
        forkJoin(arr.map(id => this.taskService.getTask(id, this.data.company.id))).subscribe(resp => {
          this.savedTasks.push(...resp)
        })
      )
    }

    if (this.data.related && this.data.related.filter(x => !!x).length == 0 && this.data.relatedArrs) {
      if (this.data.relatedArrs[0].length) {
        this.form.get('consist_of_task_id').get('add').patchValue([...this.data.relatedArrs[0]])
        this.templateForm.get('consist_of_task_id').get('add').patchValue([...this.data.relatedArrs[0]])
      }
      if (this.data.relatedArrs[1].length) {
        this.form.get('part_of_task_id').get('add').patchValue([...this.data.relatedArrs[1]])
        this.templateForm.get('part_of_task_id').get('add').patchValue([...this.data.relatedArrs[1]])
      }
      if (this.data.relatedArrs[2].length) {
        this.form.get('related_task_id').get('add').patchValue([...this.data.relatedArrs[2]])
        this.templateForm.get('related_task_id').get('add').patchValue([...this.data.relatedArrs[2]])
      }

      let arr = this.data.relatedArrs.flat();

      this.attachSubscriptions(
        forkJoin(arr.map(id => this.taskService.getTask(id, this.data.company.id))).subscribe(resp => {
          this.savedTasks.push(...resp)
        })
      )
    }
    
    this.checkIsMobile();
    this.getTaskTemplates();
    this.getPartners();

    this.attachSubscriptions(
      this.consistOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.partOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.relativeControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.consistOfControlTemplate.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.partOfControlTemplate.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.relativeControlTemplate.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.typeOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = []
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(resp) ? this.groupOfSearchControl.value : 0})
      })
    )

    this.attachSubscriptions(
      this.groupOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = []
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? resp : 0})
      })
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.statusesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchStatuses(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getTaskStatus();

    this.getGroupsCompany();

    this.getTasks(this.page);

    if (this.data.basedVideos) {
      if (this.data.isMulti) {
        // this.form.patchValue({
        //   name: `Task based on ${this.data.videos.length} selected videos`
        // })
        if (!this.data.is_not_assigned) {
          this.form.removeControl('name')
        } else {
          this.form.addControl('custom_id_getter', this.fb.control(''))
        }
        this.form.removeControl('custom_id')
        this.form.updateValueAndValidity()
      } else {
        if (this.data.videos.length > 1) {
          this.form.patchValue({
            name: `Task based on ${this.data.videos.length} selected videos`
          })
        } else {
          this.form.patchValue({
            name: this.data.videos[0].name
          })
        }
      }
    }

    this.getOperationsStatus();
    this.getEmployees();
    this.getOperations();
  
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  log() {
    console.log(this.form.value);
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  deleteExecutor(job, ind) {
    job.create_task_employees.splice(ind,1);
  }

  deletePartner(job, ind) {
    job.create_task_partners.splice(ind,1);
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  checkIfDisabled(job, execId) {
    if (job.create_task_employees.filter(x => x.employee_id == execId).length > 0) {
      return true
    } else {
      return false
    }
  }

  checkIfDisabledPartner(job, partnerId) {
    if (job.create_task_partners.filter(x => x.partner_company_id == partnerId).length > 0) {
      return true
    } else {
      return false
    }
  }

  deleteJob(i) {
    this.jobs.splice(i,1);
  }

  addExecutor(job) {
    if (!job.newExecutor.employee_id) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose an executor"), SnackBarItem)
      return;
    }
    job.create_task_employees.push(job.newExecutor);
    job.newExecutor = {
      employee_id: '',
      is_manager: 1
    }
  }

  addPartner(job) {
    if (!job.newPartner.partner_company_id) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose an executor"), SnackBarItem)
      return;
    }
    job.create_task_partners.push(job.newPartner);
    job.newPartner = {
      partner_company_id: '',
      is_manager: 1
    }
  }

  openThumbnail() {
    this.thumbIsOpen = !this.thumbIsOpen
  }

  openJobs() {
    this.jobsIsOpen = !this.jobsIsOpen
  }
  
  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
  //       console.log('getEmployees', resp)
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice())
  //     })
  //   )
  // }
  
  
  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        console.log("getPartners", this.partners)
        this.partners$.next(this.partners.slice())
      }, error => {
        console.log(error)
      })
    )
  }

  addJob() {
    let jobData:any = {
      operation_id: 1,
      status_id: 1,
      priority: 0,
      name: '',
      comment: '',
      create_task_employees: [],
      create_task_partners: [],
    }

    if (this.employees.length > 0) {
      jobData.newExecutor = {
        employee_id: '',
        is_manager: 1
      }
    }

    if (this.partners.length > 0) {
      jobData.newPartner = {
        partner_company_id: '',
        is_manager: 1
      }
    }

    this.jobs.push(jobData)
  }

  selected(e, input: HTMLInputElement, key) {
    console.log("selected", e)
    let addArr = this.form.get(key).get('add').value.slice();

    if (!addArr.includes(e.option.value)) {
      addArr.push(e.option.value)
      if (this.tasks.find(x => x.id == e.option.value) && this.savedTasks.filter(u => u.id == e.option.value).length == 0) {
        this.savedTasks.push(this.tasks.find(x => x.id == e.option.value))
      }
    }
    this.form.get(key).patchValue({
      add: addArr
    })

    input.value = ''

    this.tasks$.next(this.tasks.slice());
  }

  selectedTemplate(e, input: HTMLInputElement, key) {
    console.log("selected", e)
    let addArr = this.templateForm.get(key).get('add').value.slice();

    if (!addArr.includes(e.option.value)) {
      addArr.push(e.option.value)
      if (this.tasks.find(x => x.id == e.option.value) && this.savedTasks.filter(u => u.id == e.option.value).length == 0) {
        this.savedTasks.push(this.tasks.find(x => x.id == e.option.value))
      }
    }
    this.templateForm.get(key).patchValue({
      add: addArr
    })

    input.value = ''

    this.tasks$.next(this.tasks.slice());
  }

  // drop(e) {
  //   moveItemInArray(this.form.get('consist_of_task_id').get('add').value, e.previousIndex, e.currentIndex);
  //   console.log(this.form.get('consist_of_task_id').get('add').value)
  // }

  
  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.data.company.id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.data.company.id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.taskTemplates = [].concat(...values)
              console.log("taskTemplates", this.taskTemplates)
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  toggleTemplates() {
    this.templatesIsActive = !this.templatesIsActive

    
    
    // console.log(conPhElementT)
    
    setTimeout(() => {
      if (this.templatesIsActive) {
        let conPhElementT = this.consistPlaceholderTemplate.nativeElement;
  
        conPhElementT.style.display = 'none';
        conPhElementT.parentNode.removeChild(conPhElementT);
  
        let partPhElementT = this.partPlaceholderTemplate.nativeElement;
    
        partPhElementT.style.display = 'none';
        partPhElementT.parentNode.removeChild(partPhElementT);
    
        let relatedPhElementT = this.relatedPlaceholderTemplate.nativeElement;
    
        relatedPhElementT.style.display = 'none';
        relatedPhElementT.parentNode.removeChild(relatedPhElementT);

      } else {
        let conPhElement = this.consistPlaceholder.nativeElement;

        conPhElement.style.display = 'none';
        conPhElement.parentNode.removeChild(conPhElement);
    
        let partPhElement = this.partPlaceholder.nativeElement;
    
        partPhElement.style.display = 'none';
        partPhElement.parentNode.removeChild(partPhElement);
    
        let relatedPhElement = this.relatedPlaceholder.nativeElement;
    
        relatedPhElement.style.display = 'none';
        relatedPhElement.parentNode.removeChild(relatedPhElement);
    
      }
    }, 0)

  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  openRelations() {
    this.relations = !this.relations;
  }

  onSearchStatuses(resp) {
    if (!this.statuses) {
      return;
    }

    if (!resp) {
      this.statuses$.next(this.statuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.statuses$.next(
      this.statuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onScroll(type) {
    console.log("onScroll", type, this[type].value || '');
    this.onSearchTasks(this[type].value || '', this.typeOfSearchControl.value, true)
  }

  onSearchTasks(resp, query, notDel?) {
    
    resp = resp && typeof resp == 'string' ? resp.toLowerCase() : (resp || '');
    console.log('onSearchTasks', resp, query, notDel)

    let filter:any = {
      group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? this.groupOfSearchControl.value : 0
    }
    if (!notDel) {
      this.tasks = []
      this.tasks$.next(this.tasks.slice())
      this.page = 1;
      this.pagination = undefined
    }
    // filter the banks
    switch (query) {
      case 'Everywhere':
        filter.q = resp;
        break;
      case 'Task Name':
        filter.q_task_name = resp;
        break;
      case 'Custom ID':
        filter.custom_id = resp;
        break;
      case 'Internal ID':
        filter.internal_id = resp;
        break;
      case 'Global ID':
        filter.id = resp;
        break;
    }

    this.getTasks(this.page, filter)

  }

  getTasks(page, filter:any = null) {
    console.log("getTasks filter", filter)
    this.attachSubscriptions(
      this.taskService.getTasksSelect(page, this.data.company_id, filter).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.tasks.push(...resp.body)
        console.log("getTasks res" + this.page, this.tasks)
        this.page = this.page + 1; 
        this.tasks$.next(this.tasks.slice())
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.statuses = resp;
        this.statuses$.next(this.statuses.slice());
      })
    )
  }

  pasteIdVal() {
    this.form.patchValue({
      custom_id: this.customIdValue
    })
    this.isGetId = false;
  }
  
  getCustomId(value) {
    this.attachSubscriptions(
      this.taskService.getCustomId(this.data.company_id, value).subscribe(resp => {
        this.customIdValue = +resp;
        this.isGetId = true;
      })
    )
  }

  selectGroup(group) {
    if (!this.data.isMulti) {
      this.getCustomId(!!group.id ? group.id : group.value);
    }
    this.form.patchValue({
      group_id: !!group.id ? group.id : group.value
    })

    this.groupOfSearchControl.patchValue(!!group.id ? group.id : group.value)
  }

  selectType(type) {
    this.form.patchValue({
      status_id: !!type.id ? type.id : type.value
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }

    if (this.data.is_not_assigned && !this.creating.valid) {
      this.submited = true;
      console.log(this.creating);
      return
    }

    this.submited = false;
    this.isSubmit = true;
    if (!!!this.form.value.group_id) {
      delete this.form.value.group_id
    }
    
    let sbmtVal = {...this.form.value}
    if (!!sbmtVal.is_random_avatar) {
      sbmtVal.is_random_avatar = 1
    } else {
      sbmtVal.is_random_avatar = 0
    }

    // Есил создаём задачи по всем не добавленным постам
    if (this.data.is_not_assigned) {
      delete sbmtVal.company_id
      sbmtVal.is_not_assigned = 1;
      if (!!this.data.is_alt) {
        sbmtVal.type = 'channel'
      }
      this.attachSubscriptions(
        this.taskService.createTasks(Object.assign(this.creating.value, {data: sbmtVal})).subscribe(resp => {
          console.log("Tasks for all unassigned posts", resp)
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: `Tasks for all unassigned posts`}, marker("created!"), SnackBarItem);
          this.dialogRef.close({event: "Add", data: resp});
        })
      )
    } else {
      // Если создаём задачу на основе видео
      if (this.data.basedVideos) {
        sbmtVal.create_task_channels = []
  
        // Если создаём задачи (несколько) кол-во == кол-ву выбранных видео
        if (this.data.isMulti) {
  
          let multiSbmtData = []
          this.data.videos.forEach(video => {
            let x = JSON.parse(JSON.stringify(sbmtVal))
            x.name = video.name;
            x.create_task_channels.push({
              channel_id: this.data.channel.id,
              external_content_id: video.video_id,
              content_url: 'https://youtu.be/' + video?.video_id,
              content_filename: video.filename,
              content_name: video.name,
              is_content_views_count_update : 1,
              is_bravourl_content_views_count_update : 1,
              content_image: video.thumbnail,
              content_type_id: 101,
              content_published_at: video.published_at,
              content_status_id: video.status == 'private' ? 1 : ((video.status == 'public') ? 4 : 1),
              content_views_count: video.views_count,
            })
  
            multiSbmtData.push(x)
          });
  
          console.log("multiSbmtData", multiSbmtData)
  
          forkJoin(multiSbmtData.map(x => {
            return this.taskService.createTask(this.data.company_id, x).pipe(
              switchMap(res => {
                if ( this.form.get('consist_of_task_id').get('add').value.length > 0 || this.form.get('part_of_task_id').get('add').value.length > 0 || this.form.get('related_task_id').get('add').value.length > 0 ) {
                  let sortData = [];
      
                  this.form.get('consist_of_task_id').get('add').value.forEach((el, i) => {
                    sortData.push(
                      {
                        "path": '/api/task-partition/register/',
                        "query": {'company_id': this.data.company_id},
                        "method": "POST",
                        "body": {
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          company_id: this.data.company_id,
                          consist_of_task_id: res.id,
                          part_of_task_id: el,
                          consist_of_order: i,
                          part_of_order: null
                        }
                      }
                    )
                  });
      
                  this.form.get('part_of_task_id').get('add').value.forEach((el, i) => {
                    sortData.push(
                      {
                        "path": '/api/task-partition/register/',
                        "query": {'company_id': this.data.company_id},
                        "method": "POST",
                        "body": {
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          company_id: this.data.company_id,
                          part_of_task_id: res.id,
                          consist_of_task_id: el,
                          part_of_order: i,
                          consist_of_order: null
                        }
                      }
                    )
                  });
      
                  this.form.get('related_task_id').get('add').value.forEach((el, i) => {
                    sortData.push(
                      {
                        "path": '/api/task-related/register/',
                        "query": {company_id: this.data.company_id},
                        "method": "POST",
                        "body": {
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          company_id: this.data.company_id,
                          task_id: res.id,
                          related_task_id: el,
                          order: i
                        }
                      }
                    )
                  });
      
                  return this.taskService.multiRequest(sortData).pipe(map(() => res))
                } else {
                  return of(res)
                }
              }),
              tap(res => {
                this.taskService.newCard$.next({company_id: res.company_id, task_id: res.id})
              })
            )
          })).subscribe(resp => {
            this.isSubmit = false;
            this.layoutService.showSnackBar({name: `${this.data.videos.length} task(s) based on selected videos`}, marker("created!"), SnackBarItem)
            // this.openTask(resp);
            this.dialogRef.close({event: "Add", data: resp})
          })
        } else {
          // Если создаём 1 задачу в которой будут публикации выбранных видео
          this.data.videos.forEach(video => {
            sbmtVal.create_task_channels.push({
              channel_id: this.data.channel.id,
              content_url: 'https://youtu.be/' + video?.video_id,
              external_content_id: video.video_id,
              content_filename: video.filename,
              content_name: video.name,
              is_content_views_count_update : 1,
              is_bravourl_content_views_count_update : 1,
              content_image: video.thumbnail,
              content_type_id: 101,
              content_published_at: video.published_at,
              content_status_id: video.status == 'private' ? 1 : ((video.status == 'public') ? 4 : 1),
              content_views_count: video.views_count,
            })
          });
  
          this.attachSubscriptions(
            this.taskService.createTask(this.data.company_id, sbmtVal).pipe(
              switchMap(res => {
                if ( this.form.get('consist_of_task_id').get('add').value.length > 0 || this.form.get('part_of_task_id').get('add').value.length > 0 || this.form.get('related_task_id').get('add').value.length > 0 ) {
                  let sortData = [];
      
                  this.form.get('consist_of_task_id').get('add').value.forEach((el, i) => {
                    sortData.push(
                      {
                        "path": '/api/task-partition/register/',
                        "query": {'company_id': this.data.company_id},
                        "method": "POST",
                        "body": {
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          company_id: this.data.company_id,
                          consist_of_task_id: res.id,
                          part_of_task_id: el,
                          consist_of_order: i,
                          part_of_order: null
                        }
                      }
                    )
                  });
      
                  this.form.get('part_of_task_id').get('add').value.forEach((el, i) => {
                    sortData.push(
                      {
                        "path": '/api/task-partition/register/',
                        "query": {'company_id': this.data.company_id},
                        "method": "POST",
                        "body": {
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          company_id: this.data.company_id,
                          part_of_task_id: res.id,
                          consist_of_task_id: el,
                          part_of_order: i,
                          consist_of_order: null
                        }
                      }
                    )
                  });
      
                  this.form.get('related_task_id').get('add').value.forEach((el, i) => {
                    sortData.push(
                      {
                        "path": '/api/task-related/register/',
                        "query": {company_id: this.data.company_id},
                        "method": "POST",
                        "body": {
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          company_id: this.data.company_id,
                          task_id: res.id,
                          related_task_id: el,
                          order: i
                        }
                      }
                    )
                  });
      
                  return this.taskService.multiRequest(sortData).pipe(map(() => res))
                } else {
                  return of(res)
                }
              }),
              switchMap(task => {
                if (!this.jobs.length) {
                  return of(task)
                } else {
                  return forkJoin(this.jobs.map(_job => {
                    return this.taskService.addWork(this.data.company.id, {
                      task_id: task.id,
                      operation_id: _job.operation_id,
                      status_id: _job.status_id,
                      name: _job.name,
                      comment: _job.comment,
                      priority: _job.priority
                    }).pipe(
                      switchMap(job => {
                        let arr = []
                        if (_job.create_task_employees.length > 0) {
                          arr.push(..._job.create_task_employees.map(empl => this.membersService.addMember({
                            company_id: this.data.company.id,
                            task_id: task.id,
                            task_operation_id: job.id,
                            discussion_id: 0,
                            status_id: job.status_id,
                            is_manager: empl.is_manager,
                            is_price_manager: 0,
                            employee_id: empl.employee_id,
                          }, this.data.company.id)))
                        } 
                        if (_job.create_task_partners.length > 0) {
                          arr.push(..._job.create_task_partners.map(empl => this.membersService.addTaskPartner({
                            company_id: this.data.company.id,
                            task_id: task.id,
                            task_operation_id: job.id,
                            discussion_id: 0,
                            status_id: job.status_id,
                            is_manager: empl.is_manager,
                            is_price_manager: 0,
                            partner_company_id: empl.partner_company_id,
                          }, this.data.company.id)))
                        } 

                        if (arr.length) {
                          return forkJoin(arr)
                        } else {
                          return of(job)
                        }
                      })
                    )
                  })).pipe(
                    map(() => task)
                  )
                }
              })
            ).subscribe(resp => {
              this.isSubmit = false;
              this.layoutService.showSnackBar({name: sbmtVal.name}, marker("created!"), SnackBarItem)
              this.openTask(resp);
              this.taskService.newCard$.next({company_id: resp.company_id, task_id: resp.id})
              this.dialogRef.close({event: "Add", data: resp})
            })
          )
        }
      } else {
        // Обычное создание задачи (по дефолту)
        this.attachSubscriptions(
          this.taskService.createTask(this.data.company_id, sbmtVal).pipe(
            switchMap(task => {
              if ( this.form.get('consist_of_task_id').get('add').value.length > 0 || this.form.get('part_of_task_id').get('add').value.length > 0 || this.form.get('related_task_id').get('add').value.length > 0 ) {
                let sortData = [];
    
                this.form.get('consist_of_task_id').get('add').value.forEach((el, i) => {
                  sortData.push(
                    {
                      "path": '/api/task-partition/register/',
                      "query": {'company_id': this.data.company_id},
                      "method": "POST",
                      "body": {
                        [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                        company_id: this.data.company_id,
                        consist_of_task_id: task.id,
                        part_of_task_id: el,
                        consist_of_order: i,
                        part_of_order: null
                      }
                    }
                  )
                });
    
                this.form.get('part_of_task_id').get('add').value.forEach((el, i) => {
                  sortData.push(
                    {
                      "path": '/api/task-partition/register/',
                      "query": {'company_id': this.data.company_id},
                      "method": "POST",
                      "body": {
                        [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                        company_id: this.data.company_id,
                        part_of_task_id: task.id,
                        consist_of_task_id: el,
                        part_of_order: i,
                        consist_of_order: null
                      }
                    }
                  )
                });
    
                this.form.get('related_task_id').get('add').value.forEach((el, i) => {
                  sortData.push(
                    {
                      "path": '/api/task-related/register/',
                      "query": {company_id: this.data.company_id},
                      "method": "POST",
                      "body": {
                        [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                        company_id: this.data.company_id,
                        task_id: task.id,
                        related_task_id: el,
                        order: i
                      }
                    }
                  )
                });
    
                return this.taskService.multiRequest(sortData).pipe(map(() => task))
              } else {
                return of(task)
              }
            }),
            switchMap(task => {
              if (!this.jobs.length) {
                return of(task)
              } else {
                return forkJoin(this.jobs.map(_job => {
                  return this.taskService.addWork(this.data.company.id, {
                    task_id: task.id,
                    operation_id: _job.operation_id,
                    status_id: _job.status_id,
                    name: _job.name,
                    comment: _job.comment,
                    priority: _job.priority
                  }).pipe(
                    switchMap(job => {
                      let arr = []
                      if (_job.create_task_employees.length > 0) {
                        arr.push(..._job.create_task_employees.map(empl => this.membersService.addMember({
                          company_id: this.data.company.id,
                          task_id: task.id,
                          task_operation_id: job.id,
                          discussion_id: 0,
                          status_id: job.status_id,
                          is_manager: empl.is_manager,
                          is_price_manager: 0,
                          employee_id: empl.employee_id,
                        }, this.data.company.id)))
                      } 
                      if (_job.create_task_partners.length > 0) {
                        arr.push(..._job.create_task_partners.map(empl => this.membersService.addTaskPartner({
                          company_id: this.data.company.id,
                          task_id: task.id,
                          task_operation_id: job.id,
                          discussion_id: 0,
                          status_id: job.status_id,
                          is_manager: empl.is_manager,
                          is_price_manager: 0,
                          partner_company_id: empl.partner_company_id,
                        }, this.data.company.id)))
                      } 

                      console.log("ARR", arr)

                      if (arr.length) {
                        return forkJoin(arr)
                      } else {
                        return of(job)
                      }
                    })
                  )
                })).pipe(
                  map(() => task)
                )
              }
            }),
            switchMap(task => {
              if (!this.data.file_ids) {
                return of(task)
              } else {
                return this.taskService.getWorksIds(this.data.company.id, task.id).pipe(
                  map(jobs => jobs.map(x => x.id)),
                  switchMap(job_ids => {
      
                    if (!!job_ids.length) {
                      let postData = [];
                      
                      job_ids.forEach(job_id => {
                        this.data.file_ids.forEach(file_id => {     
                          postData.push({
                            "path": `/api/file/copy/`,
                            "query": {company_id: this.data.company.id},
                            "method": "POST",
                            "body": {
                              location: '/',
                              task_id: task.id,
                              task_operation_id: job_id,
                              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                              id: file_id, 
                              company_id: this.data.company.id
                            }
                          })
                        });
                      });
      
                      return this.taskService.multiRequest(postData)
                    } else {
                      let postData = [];
                      
                      this.data.file_ids.forEach(file_id => {
                        postData.push({
                          "path": `/api/file/copy/`,
                          "query": {company_id: this.data.company.id},
                          "method": "POST",
                          "body": {
                            location: '/',
                            task_id: task.id,
                            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                            id: file_id, 
                            company_id: this.data.company.id
                          }
                        })
                      });
                      return this.taskService.multiRequest(postData)
                    }
                  }),
                  map(() => task)
                )
              }
            })
          ).subscribe(resp => {
            this.isSubmit = false;
            this.layoutService.showSnackBar({name: sbmtVal.name}, marker("created!"), SnackBarItem)
            this.openTask(resp);
            this.taskService.newCard$.next({company_id: resp.company_id, task_id: resp.id})
            this.dialogRef.close({event: "Add", data: resp})
          })
        )
      }
    }  
  }

  findTask(id) {
    if (this.savedTasks.find(x => x.id == id)) {
      return this.savedTasks.find(x => x.id == id)
    }
  }
  
  onRemoved(value: string, key) {
    const values = this.form.get(key).get('add').value as string[];
    this.removeFirst(values, value);
    this.form.get(key).get('add').setValue(values); // To trigger change detection

    console.log(this.form.value)
  }

  onRemovedTemplate(value: string, key) {
    const values = this.templateForm.get(key).get('add').value as string[];
    this.removeFirst(values, value);
    this.templateForm.get(key).get('add').setValue(values); // To trigger change detection

    console.log(this.form.value)
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      initCompanyId: this.data.company.id
    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/partner.png'
  }

  selectTmpl(tmpl) {
    this.taskTemplates.map(x => x.active = false)

    tmpl.active = true;
  }

  createTaskByTemplate(tmpl) {
    if (tmpl.template_data.name == '') {
      this.submitedTmpl = true;
      return
    }

    this.submitedTmpl = false;
    this.isSubmitTmpl = true;

    console.log(tmpl);
    this.attachSubscriptions(
      this.taskService.createTask(this.data.company_id, Object.assign(this.templateForm.value, tmpl.template_data, {created_task_template_id: tmpl.id}, !this.data.file_ids ? {} : {created_file_id: this.data.file_ids[0]})).pipe(
        switchMap(task => {
          if (!this.data.file_ids) {
            return of(task)
          } else {
            return this.taskService.getWorksIds(this.data.company.id, task.id).pipe(
              map(jobs => jobs.map(x => x.id)),
              switchMap(job_ids => {
  
                if (!!job_ids.length) {
                  let postData = [];
                  
                  job_ids.forEach(job_id => {
                    this.data.file_ids.forEach(file_id => {     
                      postData.push({
                        "path": `/api/file/copy/`,
                        "query": {company_id: this.data.company.id},
                        "method": "POST",
                        "body": {
                          location: '/',
                          task_id: task.id,
                          task_operation_id: job_id,
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          id: file_id, 
                          company_id: this.data.company.id
                        }
                      })
                    });
                  });
  
                  return this.taskService.multiRequest(postData)
                } else {
                  let postData = [];
                  
                  this.data.file_ids.forEach(file_id => {
                    postData.push({
                      "path": `/api/file/copy/`,
                      "query": {company_id: this.data.company.id},
                      "method": "POST",
                      "body": {
                        location: '/',
                        task_id: task.id,
                        [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                        id: file_id, 
                        company_id: this.data.company.id
                      }
                    })
                  });
                  return this.taskService.multiRequest(postData)
                }
              }),
              map(() => task)
            )
          }
        }),
        switchMap(task => {
          if ( this.templateForm.get('consist_of_task_id').get('add').value.length > 0 || this.templateForm.get('part_of_task_id').get('add').value.length > 0 || this.templateForm.get('related_task_id').get('add').value.length > 0 ) {
            let sortData = [];

            this.templateForm.get('consist_of_task_id').get('add').value.forEach((el, i) => {
              sortData.push(
                {
                  "path": '/api/task-partition/register/',
                  "query": {'company_id': this.data.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    company_id: this.data.company_id,
                    consist_of_task_id: task.id,
                    part_of_task_id: el,
                    consist_of_order: i,
                    part_of_order: null
                  }
                }
              )
            });

            this.templateForm.get('part_of_task_id').get('add').value.forEach((el, i) => {
              sortData.push(
                {
                  "path": '/api/task-partition/register/',
                  "query": {'company_id': this.data.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    company_id: this.data.company_id,
                    part_of_task_id: task.id,
                    consist_of_task_id: el,
                    part_of_order: i,
                    consist_of_order: null
                  }
                }
              )
            });

            this.templateForm.get('related_task_id').get('add').value.forEach((el, i) => {
              sortData.push(
                {
                  "path": '/api/task-related/register/',
                  "query": {company_id: this.data.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    company_id: this.data.company_id,
                    task_id: task.id,
                    related_task_id: el,
                    order: i
                  }
                }
              )
            });

            return this.taskService.multiRequest(sortData).pipe(map(() => task))
          } else {
            return of(task)
          }
        })
      ).subscribe(resp => {
        this.isSubmitTmpl = false;
        this.layoutService.showSnackBar({name: tmpl.template_data.name}, marker("created!"), SnackBarItem)
        this.openTask(resp);
        this.taskService.newCard$.next({company_id: resp.company_id, task_id: resp.id})
        this.dialogRef.close({event: "Add", data: resp})
      })
    )
  }

  change() {
    this.companyService.addTask$.next(true);
    this.close();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
  
  ngAfterViewInit() {
    let conPhElement = this.consistPlaceholder.nativeElement;

    conPhElement.style.display = 'none';
    conPhElement.parentNode.removeChild(conPhElement);

    let partPhElement = this.partPlaceholder.nativeElement;

    partPhElement.style.display = 'none';
    partPhElement.parentNode.removeChild(partPhElement);

    let relatedPhElement = this.relatedPlaceholder.nativeElement;

    relatedPhElement.style.display = 'none';
    relatedPhElement.parentNode.removeChild(relatedPhElement);
  }

  dropped(papa, ph, arr) {
    if (!this.target) return;

    const parent: HTMLElement = papa;
    const phElement: HTMLElement = ph;
    const phElementIndex = __indexOf(parent.children, phElement);

    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);

    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    console.log(this.sourceIndex, ' => ', phElementIndex);

    if (this.sourceIndex != phElementIndex) {
      moveItemInArray(arr, this.sourceIndex, phElementIndex);
    }

    this.target = null;
    this.targetIndex = undefined;
    this.source = null;
    this.sourceIndex = undefined;
  }

  entered({ item, container }: CdkDragEnter, papa, ph) {
    const phElement: HTMLElement = ph;
    const dropElement: HTMLElement = container.element.nativeElement;
    const prevTarget: CdkDropList = this.target;
    const prevTargetIndex: number = this.targetIndex;
    this.target = container;

    const dropElementIsTheSource: boolean = !dropElement.parentNode;
    const prevAndCurrentTargetAreTheSame: boolean = this.target === prevTarget;
    if (dropElementIsTheSource || prevAndCurrentTargetAreTheSame) {
      return;
    }

    this.targetIndex = __indexOf(dropElement.parentNode.children, dropElement);

    if (!this.source) {
      this.source = item.dropContainer;
      this.sourceIndex = __indexOf(
        dropElement.parentNode.children,
        item.dropContainer.element.nativeElement
      );
      const sourceElement: HTMLElement = this.source.element.nativeElement;

      this.fixPhElementStyling(phElement, sourceElement);

      sourceElement.parentNode.removeChild(sourceElement);
    }

    const index: number = prevTargetIndex ?? this.sourceIndex;
    const insertAfter: boolean = index < this.targetIndex;

    papa.insertBefore(
      phElement,
      insertAfter ? dropElement.nextSibling : dropElement
    );
  }

  dragReleased(ph, dragPh) {
    const phElementPositionWasChanged: boolean = !!this.source;
    if (phElementPositionWasChanged) {
      // console.log("dragReleased dragPh", ph, dragPh)
      dragPh.nativeElement.style.transform = 'none';
      dragPh.nativeElement.parentNode.removeChild(
        dragPh.nativeElement
      );
      ph.appendChild(
        dragPh.nativeElement
      );
    }
  }

  private fixPhElementStyling(
    phElement: HTMLElement,
    sourceElement: HTMLElement
  ) {
    phElement.style.width = sourceElement.clientWidth - 6 + 'px';
    phElement.style.height = sourceElement.clientHeight - 6 + 'px';

    const size = Array.from(sourceElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );

    phElement.style.display = '';
    const oldSize = Array.from(phElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );
    if (oldSize) {
      phElement.classList.remove(oldSize);
    }
    if (size) {
      phElement.classList.add(size);
    }
  }

  openContext({ x, y }: MouseEvent, contextData) {
    this.closeContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: contextData
    }));
    
    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  closeContext() {
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  moveTo(contextData, targetControl) {
    console.log(contextData, targetControl)

    const fromValues = contextData.form.get(contextData.control).get('add').value as string[];
    this.removeFirst(fromValues, contextData.id);
    contextData.form.get(contextData.control).get('add').setValue(fromValues); // To trigger change detection

    let toValues = contextData.form.get(targetControl).get('add').value as string[];
    toValues.push(String(contextData.id))
    contextData.form.get(targetControl).get('add').setValue(toValues); // To trigger change detection

    console.log(contextData.form.value)
    this.closeContext()
  }

  remove(contextData) {
    const fromValues = contextData.form.get(contextData.control).get('add').value as string[];
    this.removeFirst(fromValues, contextData.id);
    contextData.form.get(contextData.control).get('add').setValue(fromValues);
    this.closeContext()
  }

  openCard(contextData) {
    this.closeContext()
    let task = this.findTask(contextData.id);
    console.log("task", task)
    if (!task) {
      return
    }
    window.open(`${this.origin}/task/${task.acm}`, '_blank')
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}
