import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectedAppsService {

  constructor(
    private http: HttpRestService
  ) { }

  getChannelAccountsScroll(page, type, q?, ids?, company_id?) {
    let filterParams = new HttpParams().set('expand', "user,channels.avatarFile,channels.platform").set("per-page", "200").set('type', type).set('page', page)
    if (ids) {
      filterParams = filterParams.set('id', ids.join(","))
    }
    if (company_id) {
      filterParams = filterParams.set('company_id', company_id)
    }
    if (!!q) {
      filterParams = filterParams.set('q', q)
    }
    return this.http.executeGet<any>(`/api/channel-account/`, true, {
      observe: 'response',
      params: filterParams
    })
  }

  getOneChanExpand(id, type, company_id?) {
    let filter_params = new HttpParams().set('expand', "user,channels.avatarFile,channels.platform").set('id', id).set('type', type)

    if (company_id) {
      filter_params = filter_params.set('company_id', company_id)
    }

    return this.http.executeGet<any>('/api/channel-account/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getChannelAccountsScrollFilter(page, type, filter, ids?) {
    let filterParams = new HttpParams().set('expand', "user,channels.avatarFile,channels.platform").set("per-page", "50").set('type', type).set('page', page)
    if (ids) {
      filterParams = filterParams.set('id', ids.join(","))
    }
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key] || key == 'is_active') {
          filterParams = filterParams.set(key, filter[key]);
        }
      })
    }
    return this.http.executeGet<any>(`/api/channel-account/`, true, {
      observe: 'response',
      params: filterParams
    })
  }

  getChannelAccounts(type, ids?) {
    let filterParams = new HttpParams().set('expand', "user,channels.avatarFile,channels.platform").set("per-page", "200").set('type', type)
    if (ids) {
      filterParams = filterParams.set('id', ids.join(","))
    }
    return this.http.executeGet<any>(`/api/channel-account/`, true, {
      params: filterParams
    })
  }

  getChannelAccountsChannels(id, channel_account_id, type, is_active, company_id?) {
    let filterParams = new HttpParams().set("per-page", "200").set("expand", "imageFile,computedUrl")
    if (id) {
      filterParams = filterParams.set('id', id)
    }
    if (channel_account_id) {
      filterParams = filterParams.set('channel_account_id', channel_account_id)
    }
    if (company_id) {
      filterParams = filterParams.set('company_id', company_id)
    }
    if (type) {
      filterParams = filterParams.set('type', type)
    }
    if (is_active) {
      filterParams = filterParams.set('is_active', is_active)
    }
    return this.http.executeGet<any>(`/api/channel-account-channel/`, true, {
      params: filterParams
    })
  }

  getChannelAccountsChannelsDyn(page, id, channel_account_id, type, is_active, per_page = '200', company_id?) {
    let filterParams = new HttpParams().set('page', page).set("per-page", per_page).set("expand", "imageFile,computedUrl,channels.avatarFile,channels.platform")
    if (id) {
      filterParams = filterParams.set('id', id)
    }
    if (channel_account_id) {
      filterParams = filterParams.set('channel_account_id', channel_account_id)
    }
    if (company_id) {
      filterParams = filterParams.set('company_id', company_id)
    }
    if (type) {
      filterParams = filterParams.set('type', type)
    }
    if (is_active) {
      filterParams = filterParams.set('is_active', is_active)
    }
    return this.http.executeGet<any>(`/api/channel-account-channel/`, true, {
      observe: 'response',
      params: filterParams
    })
  }

  getChannelAccount(id) {
    return this.http.executeGet<any>(`/api/channel-account/${id}/`)
  }

  getChannelAccountAuthUrl(type, return_url, channel_account_id:any = 0, company_id?) {
    let filterParams = new HttpParams().set("type", type).set("channel_account_id", channel_account_id)
    
    if (company_id) {
      filterParams = filterParams.set('company_id', company_id)
    }

    return this.http.executeGet<any>(`/api/channel-account/get-auth-url/?return_url=${encodeURIComponent(return_url)}`, true, {
      params: filterParams
    })
  }

  editChannelAccount(id, data) {
    return this.http.executePost<any>(`/api/channel-account/${id}/`, data)
  }

  editYTChannelAccount(id, data) {
    return this.http.executePost<any>(`/api/channel-account-channel/update-youtube-data/${id}/`, data)
  }
  
  deleteChannelAccount(id) {
    return this.http.executeDelete<any>(`/api/channel-account/${id}/`)
  }

  editChannelAccountComp(company_id, id, data) {
    let filterParams = new HttpParams().set("company_id", company_id)
    
    return this.http.executePost<any>(`/api/channel-account/${id}/`, data, true, {
      params: filterParams
    })
  }

  getChannelContent(company_id, task_id, channel_id) {
    let filterParams = new HttpParams().set('expand', "task,channel,channelAccount,channelAccount.computedUrl,contentFile,thumbnailFile").set("company_id", company_id).set("task_id", task_id).set("channel_id", channel_id).set("per-page", "200")
    
    return this.http.executeGet<any>(`/api/channel-content/`, true, {
      params: filterParams
    })
  }

  addChannelContent(data) {
    return this.http.executePost<any>(`/api/channel-content/`, data)
  }

  editChannelContent(id, data) {
    return this.http.executePost<any>(`/api/channel-content/${id}/`, data)
  }

  getChannelContentItem(id) {
    return this.http.executeGet<any>(`/api/channel-content/${id}/`)
  }

  deleteChannelContent(id) {
    return this.http.executeDelete<any>(`/api/channel-content/${id}/`)
  }

  getYouTubeContentData(id) {
    return this.http.executeGet<any>(`/api/channel-content/get-service-data/`, true, {
      params: new HttpParams().set('id', id)
    })
  }

  editYouTubeContentData(id, data) {
    return this.http.executePost<any>(`/api/channel-content/set-service-data/`, data, true, {
      params: new HttpParams().set('id', id)
    })
  }
}
