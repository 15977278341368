import { CdkDragDrop, CdkDragEnd, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { concat, forkJoin, fromEvent, interval, of, ReplaySubject, Subscription, timer } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { BoardService } from 'src/app/shared/services/rest/board.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { SelectionModel } from '@angular/cdk/collections';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { ChooseStatusComponent } from '../dialogs/choose-status/choose-status.component';
import { MultiEditStartAtComponent } from '../dialogs/multi-edit-start-at/multi-edit-start-at.component';
import { OpenJobComponent } from '../../cases/dialogs/open-job/open-job.component';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { environment } from 'src/environments/environment';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MobPriorityComponent } from 'src/app/shared/global_components/chat/dialogs/mob-priority/mob-priority.component';
import { ChangePriorityComponent } from 'src/app/shared/global_components/change-priority/change-priority.component';

@Component({
  selector: 'app-board-list-page',
  templateUrl: './board-list-page.component.html',
  styleUrls: ['./board-list-page.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class BoardListPageComponent extends BaseClass implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild("todoContainer") todoContainer: ElementRef;
  @ViewChild("doneContainer") doneContainer: ElementRef;
  @ViewChild("mobile_filter_wrp") mobile_filter_wrp: ElementRef;
  public _todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];
  public _done: any[] = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog'];


  public filterCount: number = 1;

  public boardCollection: any = new SelectionModel(
    true,
    []
  );
  public showFilter: boolean = !!this.sm.localStorageGetItem('showFilter')
  public origin = window.location.origin;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public company: any;
  public company_id: any;
  public groups: any;
  public activeLang: any;
  public currMonthName: any;
  public timeZone = new Date().getTimezoneOffset();

  public profiles: any[] = [];
  public profilesPagination: any;
  public profilesPage: number = 1;

  public groups_partner_id: any;
  public partnerGroups: any;

  public bookmarks: any;
  public bookmarksControl: FormControl = new FormControl();
  public bookmarks$: ReplaySubject<any> = new ReplaySubject<any>(1);

  
  public cardContextSub: Subscription;
  overlayRefCard: OverlayRef | null;
  @ViewChild('cardContext') cardContext: TemplateRef<any>;
  

  public now = new Date();
  public today = moment().set({hour:0,minute:0,second:0}).unix();

  public todayStartVal = moment().set({hour:0,minute:0,second:0}).format("X");
  public todayEndVal = moment().set({hour:23,minute:59,second:59}).format("X");
  public tomorrowStartVal = moment().add(1,'days').set({hour:0,minute:0,second:0}).format("X");
  public tomorrowEndVal = moment().add(1,'days').set({hour:23,minute:59,second:59}).format("X");
  public todayStart = moment().set({hour:0,minute:0,second:0});
  public todayEnd = moment().set({hour:23,minute:59,second:59});
  public tomorrowStart = moment().add(1,'days').set({hour:0,minute:0,second:0});
  public tomorrowEnd = moment().add(1,'days').set({hour:23,minute:59,second:59});

  public page: number = 1;
  public donePage: number = 1;
  public pagination: object = {};
  public donePagination: object = {};
  
  public todo: any[] = [];
  public done: any[] = [];
  public groupedDone: any[] = [];
  public todoDataSub: Subscription;
  public doneDataSub: Subscription;
  public todoSub: Subscription;
  public doneSub: Subscription;
  
  public isLoad: boolean = false;
  public boardLoad: boolean = false;
  public dateFromChanged: boolean = false;
  public isDragged: boolean = false;
  public dateToChanged: boolean = false;
  public allValues: any;

  public me: any;
  public operationsValues: any;
  public statuses: any;
  public employees: any;
  public taskStatuses: any;
  public prioritys = prioritys
  public activeStatus: number[] = [1,2,5];
  public doneStatus: number[] = [3,4,6,97,98,99];
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]
  public filter: FormGroup = this.fb.group({
    q: '',
    status_id: [[1,2,5]],
    doneStatus: [[3,4,6,97,98,99]],
    operation_employee_id: '',
    client_employee_id: '',
    no_operation_id: '',
    origin_company_id: '',
    channel_platform_id: '',
    is_content_url: '',
    group_id: [[]],
    channel_id: [[]],
    no_channel_id: [[]],
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id : [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    parameter_value_id: [[]],
    priority: '',
    content_type_id: '',
    preset_id: '',
    operation_partner_company_id: '',
    task_operation_operation_id: '',
    task_custom_id: '',
    discussion_id: '',
    start_at_from : '',
    start_at_to : '',
    order_by: 'priority_order',
    task_created_at_from: "",
    task_created_at_to: "",
    task_operation_created_at_from: "",
    task_operation_created_at_to: "",
    task_operation_completed_at_from: "",
    task_operation_completed_at_to: "",
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    task_channel_published_at_from: "",
    task_channel_published_at_to: "",
  });

  public is_mobile: boolean = false;

  public partners: any;
  public platforms: any;
  @Input() useOut:boolean = false;
  @Input() task_id:any = false;
  @Input() initCompanyId: any;
  @Input() task_operation_id:any = false;
  
  public selectedIndex: FormControl = new FormControl(0);
  
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  
  constructor(
    private fb: FormBuilder,
    private taskService: TaskService,
    private sm: StorageManagerService,
    private auth: AuthService,
    public activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private membersService: MembersService,
    private refreshService: RefreshService,
    private _adapter: DateAdapter<any>,    
    private parametersService: ParametersService,
    private dialog: MatDialog,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private _snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private boardService: BoardService,
    private companyService: CompanyService,
    private chatService: ChatService,
    private router: Router,

  ) {
    super()
  }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId;
    this.boardService.boardTab$.next(this.selectedIndex.value);
    // this.ifAllStatuses()
    this.checkIsMobile();

    console.log("moment", this.today)

    console.log("todayStartVal", this.todayStartVal)
    console.log("todayEndVal", this.todayEndVal)
    console.log("tomorrowStartVal", this.tomorrowStartVal)
    console.log("tomorrowEndVal", this.tomorrowEndVal)
    this.groups_partner_id = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
    if (!!this.groups_partner_id) {
      this.getPartnerGroups()
    }
    this.attachSubscriptions(
      interval(300000).subscribe(res => {
        if (!this.isDragged) {
          this.initRefreshBoard();
        } else {
          timer(10000).subscribe(x => {
            if (!this.isDragged) {
              this.initRefreshBoard();
            }
          })
        }
      })
    )
    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Board by Order") {
          this.getBookmarks()
        }
      })
    )
    this.getLangData(this.company_id);
    this.getImgRoute();
    this.getUser();
    this.getOperationsStatus();
    this.getTaskStatus();
    this.getEmployees();
    if (!this.useOut) {
      this.onRouteChange();
    } else {
      this.filterValuesChange();
      this.filterCount++
      this.filter.patchValue({
        count: this.filterCount
      })
    }
    this.refresh();

    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.bookmarksControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBookmarks(resp))
    )
  }

  onSearchBookmarks(resp) {
    if (!this.bookmarks) {
      return;
    }

    if (!resp) {
      this.bookmarks$.next(this.bookmarks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.bookmarks$.next(
      this.bookmarks.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Board by Order"}).subscribe(res => {
        this.bookmarks = res
        this.bookmarks$.next(this.bookmarks.slice())
        console.log("bookmarks", this.bookmarks)
      })
    )
  }

  bookmarksChange(e) {
    this.router.navigate(['/board-list'], { queryParams: Object.assign({company_id: this.company_id, preset_id: e.value}, this.getBookmarkById(e.value).settings)});
  }
  
  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }

  copyLink(type) {
    let snackData = {};
    snackData['name'] = marker(type + " link");
    this.layoutService.showSnackBar(snackData, marker("Copied"), SnackBarItem)
  }

  copyName(type) {
    let snackData = {};
    snackData['name'] = marker(type);
    this.layoutService.showSnackBar(snackData, marker("Copied"), SnackBarItem)
  }

  changeTab(e) {
    this.selectedIndex.patchValue(e)
    this.boardService.boardTab$.next(this.selectedIndex.value);
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
      })
    )
  }

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }

  openMobFilter() {
    this.layoutService.overflowHidden();
    this.mobile_filter_wrp.nativeElement.classList.add("active");
  }
  

  closeMobFilter() {
    this.layoutService.overflowAuto();
    this.mobile_filter_wrp.nativeElement.classList.remove("active");
  }

  
  searchInMobile(element: ElementRef) {
    this.filter.patchValue({
      q: element['value']
    })
    this.filterData();
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.useOut) {
      console.log("ChatsPageComponent changes", changes)
      if (changes.task_operation_id && changes.task_operation_id.previousValue != changes.task_operation_id.currentValue) {
        this.filterCount++
        this.filter.patchValue({
          count: this.filterCount
        })
      }
    }
  }
  
  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshBoard().subscribe(resp => {
        console.log("getRefreshBoard, getRefreshBoard, getRefreshBoard")
        console.log("getRefreshBoard", resp)
        if (resp != 'refresh') {
          return
        }
        
        this.sm.localStorageSetItem('todoScroll', this.todoContainer.nativeElement.scrollTop)
        this.sm.localStorageSetItem('doneScroll', this.doneContainer.nativeElement.scrollTop)
 
        this.page = 1;
        this.pagination = undefined;
        this.donePage = 1;
        this.donePagination = undefined;
        this.getTodo(this.page)
        this.getDone(this.donePage)
      })
    )
  }

  initRefreshBoard() {
    this.refreshService.refreshBoard$.next('refresh')
  }

  startDrag(e:CdkDragStart) {
    this.isDragged = true;
  }

  endDrag(e:CdkDragEnd) {
    this.isDragged = false;
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }

  goToPlaningBoard(e) {
    e.preventDefault();
    this.router.navigate(['/board'], { queryParams: {company_id: this.company_id}});
  }

  multiMove(dir, type, arr) {
    this.boardCollection?.selected.sort((a,b) => arr.indexOf(a) - arr.indexOf(b));
    console.log(this.boardCollection?.selected)
    if (dir == 'top') {
      if (type == "all") {
        console.log("top, all")
        this.boardCollection?.selected.forEach((el, i) => {
          let target = arr.find(x => x.id == el.id)
          let currentIndex = arr.indexOf(target)
          moveItemInArray(arr, currentIndex, i);
        })
      } else {
        console.log("top, 1")
        this.boardCollection?.selected.forEach((x, i) => {
          let target = arr.find(y => y.id == x.id)
          let currentIndex = arr.indexOf(target)
          if (arr[currentIndex - 1]) {

            if (i > 0) {
              let previousSelectedBoardItem = this.boardCollection.selected[i - 1]
              let previousCurrentIndex = arr.indexOf(previousSelectedBoardItem)
              if (Math.abs(currentIndex - previousCurrentIndex) == 1 && previousSelectedBoardItem.cant_move) {
                x.cant_move = true
                return
              }
            }

          } else {
            x.cant_move = true
          }
        })

        this.boardCollection?.selected.forEach((el, i) => {
          if (el.cant_move) {
            return
          }

          let target = arr.find(x => x.id == el.id)
          let currentIndex = arr.indexOf(target)
          moveItemInArray(arr, currentIndex, currentIndex - 1);
        })
      }
    } else {
      if (type == "all") {
        console.log("bottom, all")
        this.boardCollection?.selected.forEach((el, i) => {
          let target = arr.find(x => x.id == el.id)
          let currentIndex = arr.indexOf(target)
          moveItemInArray(arr, currentIndex, arr.length - 1);
        })
      } else {
        console.log("bottom, 1")
        for (var i = this.boardCollection?.selected.length - 1; i >= 0; i--) {
          let x = this.boardCollection?.selected[i]
          console.log(x);

          let target = arr.find(y => y.id == x.id)
          let currentIndex = arr.indexOf(target)
          if (arr[currentIndex + 1]) {

            if (i != this.boardCollection?.selected.length - 1) {
              let nextSelectedBoardItem = this.boardCollection.selected[i + 1]
              let nextCurrentIndex = arr.indexOf(nextSelectedBoardItem)
              if (Math.abs(currentIndex - nextCurrentIndex) == 1 && nextSelectedBoardItem.cant_move) {
                x.cant_move = true
              } 
            }

          } else {
            x.cant_move = true
          }
        }

        for (var i = this.boardCollection?.selected.length - 1; i >= 0; i--) {
          let x = this.boardCollection?.selected[i]

          if (!x.cant_move) {
            let target = arr.find(y => y.id == x.id)
            let currentIndex = arr.indexOf(target)
            moveItemInArray(arr, currentIndex, currentIndex + 1);
          }
        }
      }
    }

    console.log(this.boardCollection.selected)
    this.sortBoard(arr)
  }

  sortBoard(array) {
    console.log("sortBoard", array)
    if (array.length == 1) {
      return
    }

    console.log("out1", this.boardCollection.selected.length)
    console.log("out2", this.boardCollection.selected.filter(x => !!x.cant_move).length)
    if (this.boardCollection.selected.length == this.boardCollection.selected.filter(x => !!x.cant_move).length) {
      this.boardCollection.selected.map(b => b.cant_move = false);
      console.log("inside", this.boardCollection.selected)
      return
    }


    let sbmtData = [];
    this.boardCollection.selected.forEach(boardItem => {
      let prevSibling:any = array[array.indexOf(boardItem) - 1];
      let nextSibling:any = array[array.indexOf(boardItem) + 1];
      boardItem.cant_move = false

      if (boardItem.is_partner) {
        sbmtData.push({
          "path": `/api/task-partner-status/${boardItem.id}/`,
          "query": {company_id: this.company_id},
          "method": "POST",
          "body": Object.assign({
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
        });
      } else if (boardItem.is_partner_employee) {
        sbmtData.push({
          "path": `/api/task-partner-employee-status/${boardItem.id}/`,
          "query": {company_id: this.company_id},
          "method": "POST",
          "body": Object.assign({
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
        })
      } else {
        sbmtData.push({
          "path": `/api/task-employee-status/${boardItem.id}/`,
          "query": {company_id: this.company_id},
          "method": "POST",
          "body": Object.assign({
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
        })
      }
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(sbmtData).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  sortDropBoard(event: CdkDragDrop<string[]>) {
    console.log(event);


    let sbmtData = [];

    let prevSibling:any = event.container.data[event.currentIndex - 1];
    let nextSibling:any = event.container.data[event.currentIndex + 1];

    if (event.item.data.is_partner) {
      sbmtData.push({
        "path": `/api/task-partner-status/${event.item.data.id}/`,
        "query": {company_id: this.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
      });
    } else if (event.item.data.is_partner_employee) {
      sbmtData.push({
        "path": `/api/task-partner-employee-status/${event.item.data.id}/`,
        "query": {company_id: this.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
      })
    } else {
      sbmtData.push({
        "path": `/api/task-employee-status/${event.item.data.id}/`,
        "query": {company_id: this.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
      })
    }

    this.attachSubscriptions(
      this.taskService.multiRequest(sbmtData).subscribe(resp => {
        console.log(resp)
        this.groupedDone = []
        this.groupDone()
      },
      error => {
        moveItemInArray(event.container.data, event.currentIndex, event.previousIndex);
        this.groupedDone = []
        this.groupDone()
      })
    )
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(event)
    if (event.previousContainer === event.container) {
      if (event.container.id == 'todo') {   
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.sortDropBoard(event)
      }
    } else {

      if (event.container.id == 'todo') {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        this.sortDropBoard(event)
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          0,
        );
        
        this.groupedDone = []
        this.groupDone()
      }
      const dialogRef = this.dialog.open(ChooseStatusComponent, {
        disableClose: true,
        data: {
          company: this.company,
          element: event.item.data,
          dirLeft: event.container.id == 'todo' ? true : false,
          showError: () => { this.showError() }
        }
      });
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result && result.event == "update") {
            if (event.container.id == 'todo') {
              this.todo.find(x => x.id == event.item.data.id).status_id = result.data.status_id
            } else {
              this.done.find(x => x.id == event.item.data.id).status_id = result.data.status_id
            }
            this.groupedDone = []
            this.groupDone()
          } else {
            transferArrayItem(
              event.container.data,
              event.previousContainer.data,
              event.currentIndex,
              event.previousIndex,
            );
            this.groupedDone = []
            this.groupDone()
          }
        })
      )
    }
  }

  compareDates(val) {
    if (this.today > val) {
      return false
    } else {
      return true
    }
  }

  // neededData(elem) {
  //   let arr = [
  //   ]
  //   if (elem.company_id == this.company.id) {
  //     arr.push(
  //       this.taskService.getTaskClients(elem.task_id).pipe(
  //         tap(res => {
  //           elem.clients = res
  //         }),
  //         catchError(err => {
  //           return err
  //         })
  //       )
  //     )
  //   }
  //   return forkJoin(arr)
  // }
  
  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  filterValuesChange() {
    this.filter.valueChanges.pipe(
      tap((val) => {
        this.profilesPage = 1;
        if (this.todoDataSub) {
          this.todoDataSub.unsubscribe();
        }
        if (this.doneDataSub) {
          this.doneDataSub.unsubscribe();
        }
        if (!this.initCompanyId) {
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getLangData(this.activatedRoute.snapshot.queryParamMap.get('company_id'));
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
        } else {
          this.getLangData(this.initCompanyId);
        }
      }),
      switchMap((val) => {
        console.log("filterValuesChange", this.company)
        if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
          return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.company_id).pipe(
            map(x => x[0]),
            tap(x => {
              this.company = x;
              this.me = this.company.employees[0];
              this.companyService.company$.next(x);
            }),
            map(x => val)
          )
        } else {
          return of(val)
        }
      }),
    ).subscribe(params => {
      console.log("route STREAM", params);
      this.boardCollection = new SelectionModel(
        true,
        []
      )

      this.todo = [];
      this.done = [];
      this.groupedDone = [];
      this.page = 1;  
      this.donePage = 1;  
      this.getProfiles(this.company_id, this.profilesPage);
      
      
      if (this.filter.value.status_id.length) {
        this.activeStatus = this.filter.value.status_id
      } else {
        this.activeStatus = [1,2,5];
      }

      if (this.filter.value.doneStatus.length) {
        this.doneStatus = this.filter.value.doneStatus
      } else {
        this.doneStatus = [3,4,6,97,98,99];
      }
    
      // this.todoDataSub = this.boardService.getTodoDataStream().pipe(
      //   concatMap(todosInPage => {
      //     return concat(...todosInPage.map(todoInPage => this.neededData(todoInPage))).pipe(last(),map(x => todosInPage))
      //   }),
      // ).subscribe(resp => console.log("-----getTodoDataStream-----",resp));

      // this.doneDataSub = this.boardService.getDoneDataStream().pipe(
      //   concatMap(donesInPage => {
      //     return concat(...donesInPage.map(doneInPage => this.neededData(doneInPage))).pipe(last(),map(x => donesInPage))
      //   }),
      // ).subscribe(resp => console.log("-----getDoneDataStream-----",resp));
      this.getTodo(this.page);
      this.getDone(this.donePage);
    })
  }

  mobEditTaskPriority(item) {
    const dialogRef = this.dialog.open(ChangePriorityComponent, {
      data: {
        company: this.company,
        work: item.taskOperation,
        chat: item.discussion,
        user: this.user,
        autoFocus: false,
        target: !!item.discussion_id ? 'chat' : 'work'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.initRefreshBoard();
        }
      })
    )
  }

  openCardContext({ x, y }: MouseEvent, val) {
    this.closeCardContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefCard = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefCard.attach(new TemplatePortal(this.cardContext, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.cardContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefCard && !this.overlayRefCard.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeCardContext())

  }

  closeCardContext() {
    this.cardContextSub && this.cardContextSub.unsubscribe();
    if (this.overlayRefCard) {
      this.overlayRefCard.dispose();
      this.overlayRefCard = null;
    }
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          this.profilesPage = 1;
          if (this.todoDataSub) {
            this.todoDataSub.unsubscribe();
          }
          if (this.doneDataSub) {
            this.doneDataSub.unsubscribe();
          }
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getLangData(this.activatedRoute.snapshot.queryParamMap.get('company_id'));
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.filter.patchValue({
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            status_id: !!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [1,2,5],
            doneStatus: !!this.activatedRoute.snapshot.queryParamMap.get('doneStatus') ? this.activatedRoute.snapshot.queryParamMap.get('doneStatus').split(',').map(Number) : [3,4,6,97,98,99],
            task_operation_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') : "",
            task_custom_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') : "",
            discussion_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : "",
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') : "",
            client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') : "",
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : "",
            origin_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') : "",
            channel_platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : "",
            is_content_url: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_url') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_url') : "",
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : "",
            preset_id: !!this.activatedRoute.snapshot.queryParamMap.get('preset_id') ? +this.activatedRoute.snapshot.queryParamMap.get('preset_id') : "",
            task_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id').split(',').map(Number) : [],
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : [],
            task_operation_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id').split(',').map(Number) : [],
            discussion_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id').split(',').map(Number) : [],
            file_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            priority: !!this.activatedRoute.snapshot.queryParamMap.get('priority') ? +this.activatedRoute.snapshot.queryParamMap.get('priority') : "",
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') : "",
            start_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('start_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('start_at_from')*1000).set({hour:0,minute:0,second:0}) : "",
            start_at_to: this.activatedRoute.snapshot.queryParamMap.get('start_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('start_at_to')*1000).set({hour:23,minute:59,second:59}) : "",
            task_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from')*1000) : ""),
            task_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to')*1000) : ""),
            task_operation_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from')*1000) : ""),
            task_operation_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to')*1000) : ""),
            task_operation_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from')*1000) : ""),
            task_operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to')*1000) : ""),
            discussion_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from')*1000) : ""),
            discussion_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to')*1000) : ""),
            file_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from')*1000) : ""),
            file_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to')*1000) : ""),
            file_updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from')*1000) : ""),
            file_updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to')*1000) : ""),
            file_meta_creation_time_from: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from')*1000) : ""),
            file_meta_creation_time_to: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to')*1000) : ""),
            task_channel_published_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from')*1000) : ""),
            task_channel_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to')*1000) : ""),
          })
          console.log("this.filter.value", this.filter.value);
        }),
        switchMap((val) => {
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
                if (!!x && !!x.employees && !!x.employees[0]) {
                  this.company['rate_target'] = x.employees[0].rate_target
                }
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("route STREAM", params);
        this.boardCollection = new SelectionModel(
          true,
          []
        )


        this.todo = [];
        this.done = [];
        this.groupedDone = [];
        this.getAllApiParameterValues();
        this.getPlatforms();
        this.getPartners();
        this.getGroupsCompany();
        this.getProfiles(this.company_id, this.profilesPage);
        this.page = 1;  
        this.donePage = 1;  
        
        
        if (!!this.activatedRoute.snapshot.queryParamMap.get('status_id')) {
          this.activeStatus = this.filter.value.status_id
        } else {
          this.activeStatus = [1,2,5];
        }

        if (!!this.activatedRoute.snapshot.queryParamMap.get('doneStatus')) {
          this.doneStatus = this.filter.value.doneStatus
        } else {
          this.doneStatus = [3,4,6,97,98,99];
        }
        if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
          this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
          this.getPartnerGroups()
        }
        // this.todoDataSub = this.boardService.getTodoDataStream().pipe(
        //   concatMap(todosInPage => {
        //     return concat(...todosInPage.map(todoInPage => this.neededData(todoInPage))).pipe(last(),map(x => todosInPage))
        //   }),
        // ).subscribe(resp => console.log("-----getTodoDataStream-----",resp));

        // this.doneDataSub = this.boardService.getDoneDataStream().pipe(
        //   concatMap(donesInPage => {
        //     return concat(...donesInPage.map(doneInPage => this.neededData(doneInPage))).pipe(last(),map(x => donesInPage))
        //   }),
        // ).subscribe(resp => console.log("-----getDoneDataStream-----",resp));
        this.getBookmarks();
        this.getTodo(this.page);
        this.getDone(this.donePage);
      })
    )
  }

  getProfiles(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getProfiles(company_id, page).pipe(
        tap(el => {
          this.profilesPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)

        if (page == 1) {
          this.profiles = resp
        } else {
          resp.forEach(profile => {
            if (this.profiles.filter(x => x.id == profile.id).length == 0) {
              this.profiles.push(...resp);
            }
          })
        }
      })
    )
  }

  getPartnerGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, x, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.partnerGroups = [].concat(...res);
        console.log("getPartnerGroups", this.partnerGroups);
      })
    )
  }
  getPartnerGroup(id) {
    if (!this.partnerGroups || !this.partnerGroups.find(el => el.id == id)) {
      return false;
    }
    return this.partnerGroups.find(el => el.id == id)
  } 

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        resp.unshift({
          id:	0,
          name: "Without platform",
          url: '',
          created_at:	0,
          updated_at:	0,
        })
        this.platforms = resp;
      })
    )
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  hasTodoValue() {
    return this.boardCollection.selected.filter(x => x.isTodo).length > 0 ? true : false;
  }

  hasDoneValue() {
    return this.boardCollection.selected.filter(x => !x.isTodo).length > 0 ? true : false;
  }

  checkboxTodoLabel(row?): string {
    if (!row) {
      return `${this.isAllTodoSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.boardCollection.isSelected(row) ? 'deselect' : 'select'} task ${row.name}`;
  }

  isAllTodoSelected() {
    const numSelected = this.boardCollection.selected.filter(x => x.isTodo).length;
    const numRows = this.todo.length;
    return numSelected === numRows;
  }

  masterTodoToggle() {
    this.isAllTodoSelected() ?
        this.todo.forEach(row => this.boardCollection.deselect(row)) :
        this.todo.forEach(row => this.boardCollection.select(row));
  }
  checkboxDoneLabel(row?): string {
    if (!row) {
      return `${this.isAllDoneSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.boardCollection.isSelected(row) ? 'deselect' : 'select'} task ${row.name}`;
  }

  isAllDoneSelected() {
    const numSelected = this.boardCollection.selected.filter(x => !x.isTodo).length;
    const numRows = this.done.length;
    return numSelected === numRows;
  }

  masterDoneToggle() {
    console.log(this.boardCollection.hasValue());
    this.isAllDoneSelected() ?
        this.done.forEach(row => this.boardCollection.deselect(row)) :
        this.done.forEach(row => this.boardCollection.select(row));
  }

  multiEditStartAt() {
    const dialogRef = this.dialog.open(MultiEditStartAtComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        boardCollection: this.boardCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.boardCollection.clear();
        }
      })
    )
  }

  toggleItem(item): void {
    this.boardCollection.toggle(item);
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getTodo(n) {
    this.isLoad = true;
    let getFilter = JSON.parse(JSON.stringify(this.filter.value));
    delete getFilter.doneStatus;

    if (!!this.filter.value.start_at_from) {
      getFilter.start_at_from = moment(this.filter.value.start_at_from._d).format("X");
    }
    if (!!this.filter.value.start_at_to) {
      getFilter.start_at_to = moment(this.filter.value.start_at_to._d).format("X");
    }
    Object.keys(getFilter).forEach(key => {
      if (key.indexOf("_at_",) != -1 && !["start_at_from", "start_at_to"].includes(key) && !!getFilter[key]) {
        getFilter[key] = moment(this.filter.value[key]._d).format("X")
      }
    })
    this.activeStatus = this.filter.value.status_id
    this.todoSub = this.boardService.getBoard(n, this.company_id, getFilter, !this.is_mobile ? '20' : '10', this.task_operation_id).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (this.pagination['currentPage'] == '1') {
          this.todo = [];
        }
      }),
      map(el => el.body),
      tap(elements => elements.map(x => {
        if (x.partner_employee_id) {
          x.is_partner_employee = true
          x.employee = x.partnerEmployee
        } else if (x.partner_company_id) {
          x.is_partner = true;
        }
        x.isTodo = true
      })),
      tap(elements => {
        this.boardService.todoData$.next(elements)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      this.todo.push(...resp)


      console.log("this.todo "+ this.page, this.todo)

      this.page = this.page + 1;
      this.isLoad = false;
      // if (+this.pagination['currentPage'] == 1) {
      //   this.todoContainer.nativeElement.scrollTop = 0;
      // }

      // this.todoContainer.nativeElement.scrollTo(0, this.sm.localStorageGetItem('todoScroll'));
      
      // if (+this.pagination['currentPage'] <= 2 && +this.pagination['currentPage'] != +this.pagination['pageCount']) {
      //   this.getTodo(this.page);
      // }

    },
    error => {
      this.isLoad = false;
    })
    
  }

  getDone(n) {
    this.isLoad = true;
    let getFilter = JSON.parse(JSON.stringify(this.filter.value));
    getFilter.order_by = 'wpc_updated_at_desc'
    delete getFilter.doneStatus;
    delete getFilter.status_id;

    if (!!this.filter.value.start_at_from) {
      getFilter.start_at_from = moment(this.filter.value.start_at_from._d).format("X");
    }
    if (!!this.filter.value.start_at_to) {
      getFilter.start_at_to = moment(this.filter.value.start_at_to._d).format("X");
    }
    getFilter.status_id = this.filter.value.doneStatus;
    Object.keys(getFilter).forEach(key => {
      if (key.indexOf("_at_",) != -1 && !["start_at_from", "start_at_to"].includes(key) && !!getFilter[key]) {
        getFilter[key] = moment(this.filter.value[key]._d).format("X")
      }
    })
    this.doneStatus = this.filter.value.doneStatus;
    this.doneSub = this.boardService.getBoard(n, this.company_id, getFilter, !this.is_mobile ? '30' : '10', this.task_operation_id).pipe(
      tap(el => {
        this.donePagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (this.donePagination['currentPage'] == '1') {
          this.done = [];
          this.groupedDone = [];
        }
      }),
      map(el => el.body),
      tap(elements => elements.map(x => {
        if (x.partner_employee_id) {
          x.is_partner_employee = true
          x.employee = x.partnerEmployee
        } else if (x.partner_company_id) {
          x.is_partner = true;
        }
        x.isTodo = false
      })),
      tap(elements => {
        this.boardService.doneData$.next(elements)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      this.done.push(...resp)
      this.groupDone()

      console.log("this.done "+ this.page, this.done)
      this.donePage = this.donePage + 1;
      this.isLoad = false;
      // if (+this.donePagination['currentPage'] == 1) {
      //   this.todoContainer.nativeElement.scrollTop = 0;
      // }
      // this.doneContainer.nativeElement.scrollTo(0, this.sm.localStorageGetItem('doneScroll'));
      // if (+this.donePagination['currentPage'] <= 2 && +this.donePagination['currentPage'] != +this.donePagination['pageCount']) {
      //   this.getDone(this.page);
      // }
    },
    error => {
      this.isLoad = false;
    })
    
  }


  groupDone() {
    console.log("groupDone", this.done);
    this.done.forEach(item => {
      if (this.groupedDone.filter(x => moment(item.wpc_updated_at*1000).isSameOrAfter(x.start) && moment(item.wpc_updated_at*1000).isSameOrBefore(x.end)).length == 0) {
        this.groupedDone.push({
          start: moment(item.wpc_updated_at*1000).clone().startOf('day'),
          end: moment(item.wpc_updated_at*1000).clone().endOf('day'),
          timestamp: moment(item.wpc_updated_at*1000).clone().startOf('day').format("X"),
          items: [item]
        })
      } else {
        if (this.groupedDone.find(x => moment(item.wpc_updated_at*1000).isSameOrAfter(x.start) && moment(item.wpc_updated_at*1000).isSameOrBefore(x.end)).items.filter(x => x.id == item.id).length == 0) {
          this.groupedDone.find(x => moment(item.wpc_updated_at*1000).isSameOrAfter(x.start) && moment(item.wpc_updated_at*1000).isSameOrBefore(x.end)).items.push(item)
        }
      }
    })
    console.log("groupedDone", this.groupedDone);
  }


  neededTaskData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    return forkJoin(arr)
  }

  openJob(board) {
    console.log(board)
    console.log(this.boardLoad)
    if (this.boardLoad) {
      return
    }
    this.boardLoad = true;
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, board.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededTaskData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        if (this.useOut) {
          this.dialog.closeAll()
        }
        this.boardLoad = false;
        console.log(resp, board)
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          autoFocus: false,
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          data: {
            task_id: board.task_id,
            task_operation_id: board.task_operation_id,
            task: resp,
          }
        });
      })
    )

  }

  openChat(board) {
    if (this.boardLoad) {
      return
    }
    this.boardLoad = true;
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, board.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededTaskData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        console.log(resp);
        this.boardLoad = false;
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: {
            company: this.company,
            task: resp,
            dialogType: 'operation_chat',
            operationsValues: this.operationsValues,
            work: resp.operations.find(el => el.id == board.task_operation_id),
            user: this.user,
            empl_status: this.company.permissions.includes('owner') || this.company?.permissions.includes('admin') || this.company.permissions.includes('manager'),
            chat: board.discussion,
            chats: resp.operations.find(el => el.id == board.task_operation_id).chatsInfo
          }
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(board.discussion_id, 0, this.company.id).subscribe(resp => {
                console.log(resp);
              })
            }
          })
        )
      })
    )
  }

  // openChat(board) {
  //   this.attachSubscriptions(
  //     this.chatService.getChatsExpand(1, this.company_id, {id: board.discussion_id}).pipe(
  //       map(res => res.body[0])
  //     ).subscribe(chat => {
  //       const goToChat = this.dialog.open(ChatsComponent, {
  //         disableClose: true,
  //         panelClass: ['full_screen_dialog', 'global_chats_dialog'],
  //         data: {
  //           company: this.company,
  //           user: this.user,
  //           imgRoute: this.imgRoute,
  //           host: this.host,
  //           activeLang: this.activeLang, 
  //           chat: chat
  //         }
  //       });
    
  //       this.attachSubscriptions(
  //         goToChat.afterClosed().subscribe(result => {
  //           // if (!!result && result.event == 'close') {
  //           //   this.chatService.postChatRead(chat.id, 0, this.company.id).subscribe(resp => {
  //           //     chat.is_read = 1;
  //           //   })
  //           // }
  //         })
  //       )
  //     })
  //   )
  // }

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }
  
  // onStartDateChange(e: MatDatepickerInputEvent<Date, Date>) {
  //   if (e && e.value) {
  //     this.dateFromChanged = true;
  //   }
  //   this.checkRange();
  // }

  // onEndDateChange(e: MatDatepickerInputEvent<Date, Date>) {
  //   if (e && e.value) {
  //     this.dateToChanged = true;
  //   }
  //   this.checkRange();
  // }

  // checkRange(): void {
  //   if (this.dateFromChanged && this.dateToChanged) {
  //     this.dateFromChanged = false;
  //     this.dateToChanged = false;
  //     this.filterData()
  //   }
  // }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    this.filter.patchValue({
      [key]: arr
    })
    this.filterData()
  }

  removeValue(val, multi:boolean = false) {
    if (multi) {
      val.split(",").forEach(v => {
        this.filter.patchValue({
          [v]: ''
        })
      })
      this.filterData()
      return
    }

    
    if (val == "all") {
      this.activeStatus = [1,2,5];
      if (this.useOut) {
        this.doneStatus = [3,4,6,97,98,99];
        this.filter.patchValue({
          q: '',
          status_id: this.activeStatus,
          doneStatus: this.doneStatus,
          operation_employee_id: '',
          task_parameter_value_id: [],
          task_operation_parameter_value_id : [],
          discussion_parameter_value_id: [],
          file_parameter_value_id: [],
          parameter_value_id: [],
          channel_id: [],
          no_channel_id: [],
          client_employee_id: '',
          no_operation_id: '',
          origin_company_id: '',
          channel_platform_id: '',
          is_content_url: '',
          group_id: [],
          content_type_id: '',
          preset_id: '',
          priority: '',
          operation_partner_company_id: '',
          task_operation_operation_id: '',
          task_custom_id: '',
          discussion_id: '',
          start_at_from : '',
          start_at_to : '',
          task_created_at_from: "",
          task_created_at_to: "",
          task_operation_created_at_from: "",
          task_operation_created_at_to: "",
          task_operation_completed_at_from: "",
          task_operation_completed_at_to: "",
          discussion_created_at_from: "",
          discussion_created_at_to: "",
          file_created_at_from: "",
          file_created_at_to: "",
          file_updated_at_from: "",
          file_updated_at_to: "",
          file_meta_creation_time_from: "",
          file_meta_creation_time_to: "",
          task_channel_published_at_from: "",
          task_channel_published_at_to: "",
        })
      } else {
        this.filterReset()
      }
    } else {
      this.filter.patchValue({
        [val]: ''
      })
      this.filterData()
    }
  }

  selectChange(e) {
    // console.log(!e)
    this.filterData();
  }

  filterReset() {
    this.router.navigate(['/board-list'], { queryParams: {company_id: this.company_id, status_id: '1,2,5', count: "1"}});
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp.slice();
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
          moment.locale(String(resp.active).toLowerCase());
        }),
        switchMap(() => this.taskService.getOperations(id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
        console.log("statuses", this.statuses);
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  // ifAllStatuses() {
  //   this.attachSubscriptions(
  //     this.filter.get('status_id').valueChanges.subscribe(val => {
  //       if (val.sort().toString() == [1,2,5,4,5,6,98,99].toString() || val.sort().toString() == [0,1,2,5,4,5,6,98,99].toString()) {
  //         this.filter.patchValue({
  //           status_id: [1,2,5]
  //         })
  //       }
  //     })
  //   )
  // }

  ngAfterViewInit(): void {
    this.onScrollTodo();
    this.onScrollDone();
  }

  onScrollTodo() {
    this.attachSubscriptions(
      fromEvent(this.todoContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.page <= this.pagination['pageCount']) {
          this.getTodo(this.page);
        }
      })
    )
  }

  onScrollDone() {
    this.attachSubscriptions(
      fromEvent(this.doneContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.donePage <= this.donePagination['pageCount']) {
          this.getDone(this.donePage);
        }
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  changeDay(e: MatButtonToggleChange) {
    if (e.value == "today") {
      this.filter.patchValue({
        start_at_from: this.todayStart,
        start_at_to: this.todayEnd
      })
    } else {
      this.filter.patchValue({
        start_at_from: this.tomorrowStart,
        start_at_to: this.tomorrowEnd
      })
    }
    this.filterData()
  }
  
  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }

  changeActiveStatus(id, event = null) {

    if (id == 0) {
      this.activeStatus = [1,2,5];
    } else {
      this.activeStatus = [id];
    }

    if (this.useOut) {
      this.filter.patchValue({
        status_id: this.activeStatus
      })
    } else {
      this.filterData(this.activeStatus)
    }

  }

  changeDoneStatus(id, event = null) {

    console.log("changeDoneStatus", id)
    if (id == 0) {
      this.doneStatus = [3,4,6,97,98,99];
    } else {
      this.doneStatus = [id];
    }

    if (!this.useOut) {
      this.filterData(null, this.doneStatus)
    } else {
      this.filter.patchValue({
        doneStatus: this.doneStatus
      })
    }
  }
  
  refreshPage() {
    this.filterData();
  }

  filterData(status_id = null, done_id = null) {
    console.log("filterData", this.filter.value)
    if (this.useOut) {
      return
    }
    if (this.todoSub) {
      this.todoSub.unsubscribe()
    }
    if (this.doneSub) {
      this.doneSub.unsubscribe()
    }
    this.filterCount = this.filterCount + 1;
    this.filter.value.count = this.filterCount;
    
    let filterData = JSON.parse(JSON.stringify(this.filter.value));

    // if (this.filter.value.start_at_from.clone().format('X') == this.filter.value.start_at_to.clone().format('X')) {
    //   this.filter.patchValue({
    //     start_at_from:  moment(this.filter.value.start_at_from*1000).startOf('day'),
    //     start_at_to: moment(this.filter.value.start_at_to*1000).endOf('day')
    //   })
    //   filterData.start_at_from = moment(filterData.start_at_from*1000).startOf('day')
    //   filterData.start_at_to = moment(filterData.start_at_to*1000).endOf('day')
    // }
  
    if (!!this.filter.value.start_at_from) {
      filterData.start_at_from = moment(this.filter.value.start_at_from._d).startOf('day').format("X");
    }
    if (!!this.filter.value.start_at_to) {
      filterData.start_at_to = moment(this.filter.value.start_at_to._d).endOf('day').format("X");
    }


    // console.log("filter", this.filter);
    // console.log("filterData", filterData);

    // return
    if (!!status_id) {
      filterData.status_id = status_id
    }
    if (!!done_id) {
      filterData.doneStatus = done_id
    }

    let params = {
      company_id: this.company_id
    };
    
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if ((element == 'channel_id' || element == 'no_channel_id' || element == 'group_id' || element == 'status_id' || element == 'doneStatus') && filterData[element].length > 0) {
        params[element] = filterData[element].join(',')
      } else {
        if (!!filterData[element] && element != 'status_id' && element != 'doneStatus') {
          params[element] = filterData[element]
        }
      }
    })

    this.router.navigate(['/board-list'], { queryParams: params});

  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  ngOnDestroy(): void {
    this.layoutService.overflowAuto();
    if (this.todoSub) {
      this.todoSub.unsubscribe()
    }
    if (this.todoDataSub) {
      this.todoDataSub.unsubscribe();
    }
    if (this.doneSub) {
      this.doneSub.unsubscribe()
    }
    if (this.doneDataSub) {
      this.doneDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }
}
