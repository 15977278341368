import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseClass } from '../../models/base-class';
import { FileService } from '../../services/rest/file.service';
import { catchError, concatMap, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { TaskService } from '../../services/rest/task.service';
import { ChatService } from '../../services/rest/chat.service';
import { Subscription, forkJoin, fromEvent, of } from 'rxjs';
import { casesModel } from '../../functions/casesModel';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TemplatePortal } from '@angular/cdk/portal';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { VideoViewerComponent } from '../file-manager/dialog/video-viewer/video-viewer.component';
import { Router } from '@angular/router';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { VideoEditorV5Component } from '../file-manager/dialog/video-editor-v5/video-editor-v5.component';
import { PublishToOutletComponent } from '../file-manager/dialog/publish-to-outlet/publish-to-outlet.component';
import { CompanyService } from '../../services/rest/company.service';
import { FileSettingsComponent } from '../file-manager/dialog/file-settings/file-settings.component';
import { mimeTypes } from 'mime-wrapper';
import { RenameComponent } from '../file-manager/dialog/rename/rename.component';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { PrimaryTagForFilesComponent } from '../file-manager/dialog/primary-tag-for-files/primary-tag-for-files.component';
import { LayoutService } from '../../services/common/layout.service';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { CreateFolderComponent } from '../file-manager/dialog/create-folder/create-folder.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Md5Service } from '../../services/common/media/md5.service';
import { TimeFormatPipe } from '../../pipes/timeFormat';
import { UploadService } from '../../services/rest/upload.service';
import { RefreshService } from '../../services/rest/refresh.service';
import { DropFilesLimitComponent } from '../drop-files-limit/drop-files-limit.component';
import { ProjectsInterfaceComponent } from '../file-manager/dialog/main-video-editor/dialog/projects-interface/projects-interface.component';
import { AddEditNoteComponent } from '../add-edit-note/add-edit-note.component';
import { CreateArchiveComponent } from '../file-manager/dialog/create-archive/create-archive.component';
import { FolderArchivesComponent } from '../file-manager/dialog/folder-archives/folder-archives.component';
import { FilePropertiesComponent } from '../file-interface/dialogs/file-properties/file-properties.component';
import { ParametersService } from '../../services/rest/parameters.service';
import { AskPasteFilesComponent } from '../ask-paste-files/ask-paste-files.component';
import { VideoFfmpegEditorComponent } from '../file-manager/dialog/video-ffmpeg-editor/video-ffmpeg-editor.component';

@Component({
  selector: 'app-lite-file-manager',
  templateUrl: './lite-file-manager.component.html',
  styleUrls: ['./lite-file-manager.component.scss']
})
export class LiteFileManagerComponent extends BaseClass implements OnInit, OnChanges, OnDestroy {
  @ViewChild('outFileMenu') outFileMenu: TemplateRef<any>;
  @ViewChild('fileMenu') fileMenu: TemplateRef<any>;
  @ViewChild('liteUploader') liteUploader: ElementRef<HTMLElement>;
  @ViewChild('liteUploaderFolder') liteUploaderFolder: ElementRef<HTMLElement>;
  @Input() target: any;
  @Input() company: any;
  @Input() is_mobile: any;
  @Input() host: any;
  @Input() liteFilter: any;
  @Input() liteFilterForm: any;
  @Input() user: any;
  @Input() useOut: boolean = false;
  @Input() activeLang: any;
  @Input() imgRoute: any;
  public _mimeTypes = mimeTypes;
  public uploadLimit: boolean = false;
  public profiles: any;
  public copiedFiles: any;
  public uniqueChannels: any;
  public operationsValues: any;
  public origin = window.location.origin;
  public putFilesID = this.generateRandomId();
  throttle = 100;
  scrollUpDistance = 3.5;
  public selectedFiles = new SelectionModel(
    true,
    []
  );
  public crumbs = [];
  selectedDocuments: Array<any> = [];

  public folders: any[] = [
    {
      filename: "sources",
      id: 0,
      location: "/",
    },
    {
      filename: "to_approve",
      id: 1,
      location: "/",
    },
    {
      filename: "ready_files",
      id: 2,
      location: "/",
    },
    {
      filename: "approved",
      id: 3,
      location: "/",
    },
    {
      filename: "project",
      id: 4,
      location: "/",
    },
    {
      filename: "trash",
      id: 5,
      location: "/",
    }
  ]

  public overlayRefFileMenu: OverlayRef | null;
  public subFileMenu: Subscription;
  public subThird: Subscription;
  public refreshSub: Subscription;
  @Output() CloseContext = new EventEmitter<any>();
  @Output() OpenJob = new EventEmitter<any>();
  constructor(
    private dialog: MatDialog,
    private fileService: FileService,    
    public router: Router,
    public sm: StorageManagerService,
    public overlay: Overlay,
    private uploadService: UploadService,
    public companyService: CompanyService,
    private layoutService: LayoutService,
    private bottomSheet: MatBottomSheet,
    private parametersService: ParametersService,
    private md5Service: Md5Service,
    public viewContainerRef: ViewContainerRef,
    private refreshService: RefreshService,
    private taskService: TaskService,
    private chatService: ChatService,
  ) {
    super()
   }

  ngOnInit(): void {
    console.log("ngOnInit LiteFileManagerComponent", this.target)
    console.log("ngOnInit LiteFileManagerComponent liteFilter", this.liteFilter)
    console.log("ngOnInit LiteFileManagerComponent active_location", this.target.active_location)
    console.log("ngOnInit LiteFileManagerComponent output_location", this.target.output_location);
    
    this.target.outputFolder = {
      targetIds: this.target.targetIds,
      page: 1,
      pagination: undefined,
      files: [],
      is_load: true
    }
    if (!!this.target.hasOwnProperty('acm')) {
      this.getReminders();
    }
    this.getProjectFiles(this.target);
    this.getOperations();

    
    if (this.sm.localStorageGetItem("copiedFiles")) {
      this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
    }

    this.subThird = this.uploadService.getUploadLimit().subscribe(resp => {
      console.log("getUploadLimit", resp)
      if (resp || this.company.filesize >= this.company.filesize_limit) {
        this.uploadLimit = true;
      }
    })

    if (this.company) {
      if (this.company.filesize >= this.company.filesize_limit) {
        this.uploadService.uploadLimit$.next(true)
      }
    }
    if (this.target.outputFolder.targetIds.task_id) {
      let expPermFilter:any = {task_id: this.target.outputFolder.targetIds.task_id}
      if (this.company.id != this.target.company_id) {
        expPermFilter.partner_company_id = this.target.company_id
      }
      this.attachSubscriptions(
        this.taskService.getExpandPermUrls('1', this.company.id, expPermFilter, '100').pipe(
          tap(rslt => {
            this.uniqueChannels = [];
            rslt.sort((a, b) => {
              if (a.content_status_id === 13 && b.content_status_id !== 13) {
                return -1;
              } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                return -1;
              } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                return 1;
              } else {
                return 0;
              }
            });
            rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
              if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                if (this.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                  this.uniqueChannels.push(channel)
                } else {
                  if (!([1, 13].includes(this.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !this.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                    this.uniqueChannels.splice(this.uniqueChannels.indexOf(this.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                  }
                }
              }
            });
          })
        ).subscribe(res => {
          console.log("this.uniqueChannels", this.uniqueChannels)
        })
      )
    }

    // this.crumbs
    let loc = this.target.active_location || this.target.output_location || "/"
    console.log("loc.split('/')", loc.split('/'))
    if (loc == '/') {
      this.crumbs.push({
        name: "Root",
        location: "/"
      })
    } else {
      loc.split('/').forEach(val => {
        if (val === '') {
          this.crumbs.push({
            name: "Root",
            location: "/"
          })
        } else {
          this.crumbs.push({
            name: val,
            location: (this.crumbs[this.crumbs.length - 1].location == '/' ? this.crumbs[this.crumbs.length - 1].location : this.crumbs[this.crumbs.length - 1].location + '/') + val
          })
        }
      })
    }
    console.log("this.crumbs", this.crumbs);

    // this.fileService.reserveFiles$.next({file: files[index], is_folder: !!files[index].filepath || !!files[index].webkitRelativePath, initPath: locationVal, id: this.putFilesID, location: locationVal});
    this.attachSubscriptions(
      this.fileService.getReserveFiles().pipe(
        filter(x => !!x && x.id == this.putFilesID),
        concatMap(fileParams => {
          let path = fileParams.location;
          if (fileParams.initPath) {
            path = fileParams.initPath;
          }
      
          if (fileParams.is_folder) {
            path = (path[path.length - 1] == '/' ? path : path + "/") + (!!fileParams.file.filepath ? fileParams.file.filepath : fileParams.file.webkitRelativePath.replace("/" + fileParams.file.name, ''));
          }
      
          let _task;
          // let _task = this.task || fileParams.file.task;
          let _work;
          // if (_task && _task.operations) {
          //   _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
          // }

          if (!path) {
            path = this.crumbs[this.crumbs.length - 1].location;
          }
          
          console.log("_file", fileParams.file)
          // console.log("_task", _task)
          // console.log("_work", _work)
      
          // if (!!_work) {
          //   if ((_work.status_id == 98 || _work.status_id == 4) && path.indexOf("to_approve") != -1) {
          //     this.layoutService.showSnackBar({name: ''}, marker("Work in completed state, you can't upload files to 'To Approve' folder. Upload to any other folder."), SnackBarItem)
          //     return
          //   }
          // }
          let needDelete = [];
          // let test_1 = this.files.filter(x => x.filename == fileParams.file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1);
          // let test_2 = this.files.filter(x => x.filename == fileParams.file.name);
          // let test_3 = this.files.filter(x => x.is_uploaded == 0);
          // let test_4 = this.files.filter(x => x.location.indexOf('/trash') == -1);
          // console.log("test_1", test_1);
          // console.log("test_2", test_2);
          // console.log("test_3", test_3);
          // console.log("test_4", test_4);
          if (this.target.outputFolder.files.filter(x => x.filename == fileParams.file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1).length > 0) {
            needDelete = this.target.outputFolder.files.filter(x => x.filename == fileParams.file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1);
          }
          console.log("needDelete", needDelete)
          if (needDelete.length > 0) {
            return forkJoin(needDelete.map(x => this.fileService.editFile(x.id, {"location": '/trash'}, this.company.id))).pipe(
              last(),
              switchMap(() => {
                needDelete.forEach(x => {
                  if (this.target.outputFolder.files.find(u => u.id == x.id)) {
                    this.target.outputFolder.files.splice(this.target.outputFolder.files.indexOf(this.target.outputFolder.files.find(u => u.id == x.id)), 1)
                  }
                })
                return this.fileService.uploadFile({
                  company_id: this.target.company_id,
                  task_id: this.target.outputFolder.targetIds.task_id,
                  task_operation_id: this.target.outputFolder.targetIds.task_operation_id,
                  filesize: fileParams.file.size,
                  filename: fileParams.file.name,
                  content_type: fileParams.file.type ? fileParams.file.type : mimeTypes.getType(fileParams.file.name),
                  location: path,
                  is_dir: 0
                }, this.company.id)
              }),
              tap(res => {
                fileParams.file.reportsFile = res
                let x = {
                  place: "file_manager",
                  url: window.location.href,
                  data: res,
                  files: path == this.crumbs[this.crumbs.length - 1].location ? this.target.outputFolder.files : [],
                  folder: {
                    is_folder: fileParams.is_folder,
                    path: path
                  },
                  task: _task,
                  work: _work,
                  activeLang: this.activeLang,
                  operationsValues: this.operationsValues,
                  location: this.crumbs[this.crumbs.length - 1].location,
                  target: fileParams.file,
                  user: this.user,
                  task_id: this.target.outputFolder.targetIds.task_id,
                  task_operation_id: this.target.outputFolder.targetIds.task_operation_id,
                  company: this.company,
                  company_id: this.company.id,
                  target_company_id: this.target.company_id,
                }
                this.fileService.files$.next(x)
                let beforeUploadFile = x.target.reportsFile;
                beforeUploadFile.allData = x;
                if (path == x.location && this.target.outputFolder.files.filter(u => u.id == beforeUploadFile.id).length == 0) {
                  this.target.outputFolder.files.push(beforeUploadFile)
                }
              }),
              catchError((error) => {
                if (error == "Company limit exceeded") {
                  this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
                } else {
                  this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
                }
                return of(false)
              })
            )
          } else {
            return this.fileService.uploadFile({
              company_id: this.target.company_id,
              task_id: this.target.outputFolder.targetIds.task_id,
              task_operation_id: this.target.outputFolder.targetIds.task_operation_id,
              filesize: fileParams.file.size,
              filename: fileParams.file.name,
              content_type: fileParams.file.type ? fileParams.file.type : mimeTypes.getType(fileParams.file.name),
              location: path,
              is_dir: 0
            }, this.company.id).pipe(
              tap(res => {
                fileParams.file.reportsFile = res
                let x = {
                  place: "file_manager",
                  url: window.location.href,
                  data: res,
                  files: path == this.crumbs[this.crumbs.length - 1].location ? this.target.outputFolder.files : [],
                  folder: {
                    is_folder: fileParams.is_folder,
                    path: path
                  },
                  task: _task,
                  work: _work,
                  activeLang: this.activeLang,
                  operationsValues: this.operationsValues,
                  location: this.crumbs[this.crumbs.length - 1].location,
                  target: fileParams.file,
                  user: this.user,
                  task_id: this.target.outputFolder.targetIds.task_id,
                  task_operation_id: this.target.outputFolder.targetIds.task_operation_id,
                  company: this.company,
                  company_id: this.company.id,
                  target_company_id: this.target.company_id,
                }
                this.fileService.files$.next(x)
                let beforeUploadFile = x.target.reportsFile;
                beforeUploadFile.allData = x;
                if (path == x.location && this.target.outputFolder.files.filter(u => u.id == beforeUploadFile.id).length == 0) {
                  this.target.outputFolder.files.push(beforeUploadFile)
                }
              }),
              catchError((error) => {
                if (error == "Company limit exceeded") {
                  this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
                } else {
                  this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
                }
                return of(false)
              })
            )
          }
        })
      ).subscribe(res => {

      })
    )
    
    this.getProfiles();
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!!this.target.hasOwnProperty('acm') && !this.target.hasOwnProperty('checklists')) {
      this.getReminders();
    }
  }

  dtsMouseDown(e) {
    console.log("dtsMouseDown", e)
    console.log("dtsMouseDown", e.target.className)
    if (e.target && e.which == 1 && typeof e.target.className == "string" && e.target.className.indexOf("lite_fm_files") != -1) {
      console.log(e,"dtsMouseDown");
      this.selectedFiles.clear();
    }
  }

  getReminders() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company.id, {task_operation_id: this.target.id}).pipe(
        map(x => x.body),
        switchMap(val => {
          let tagsFilter:any = {
            task_id: this.target.outputFolder.targetIds.task_id,
            task_operation_id: this.target.outputFolder.targetIds.task_operation_id,
          }

          if (this.company.id != this.target.company_id) {
            tagsFilter.partner_company_id = this.target.company_id
          }

          return this.parametersService.getTargetParameters(this.company.id, tagsFilter).pipe(
            tap(b => {
              let _checklist = val;
              console.log("REminders _checklist", _checklist)
              let _jobParameters = b
              this.target.checklists = [];
              _checklist.forEach(element => {
                if (element.exceptParameterValues.length > 0) {
                  if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !_jobParameters.map(m => m.parameter_value_id).includes(n))) {
                    this.target.checklists.push(element)
                  }
                } else if (element.toParameterValues.length > 0) {
                  if (element.toParameterValues.map(b => b.parameter_value_id).some(n => _jobParameters.map(m => m.parameter_value_id).includes(n))) {
                    this.target.checklists.push(element)
                  }
                } else {
                  this.target.checklists.push(element)
                }
              });
            })
          )
        })
      ).subscribe(resp => {
        console.log("REminders", resp)
        console.log("REminders this.target", this.target)
        
      })
    )
  }

  resetFilter() {
    this.liteFilterForm.patchValue({
      count: '',
      q: '',
      is_dir: '',
      content_type_id: '',
      operation_reminder_id: '',
      meta_device: '',
      related_count: '',
      part_of_count: '',
      consist_of_count: '',
      is_ready_if_approved: '',
      task_parameter_value_id: [[]],
      task_operation_parameter_value_id : [[]],
      discussion_parameter_value_id: [[]],
      file_parameter_value_id : [[]],
      parameter_value_id: [[]],
      operation_employee_id: [[]],
      operation_partner_company_id: [[]],
      origin_company_id : '',
      channel_platform_id : '',
      group_by : 'original_file_id',
      channel_id: [[]],
      no_channel_id: [[]],
      task_custom_id: '',
      file_id: '',
      order_by: 'updated_desc',
      content_status_id: '',
      id: '',
      internal_id: "",
      file_created_at_from: "",
      file_created_at_to: "",
      file_updated_at_from: "",
      file_updated_at_to: "",
      file_meta_creation_time_from: "",
      file_meta_creation_time_to: "",
      location_type: "location",
    })
  }

  itemSelected(e) {
    console.log("itemSelected", e)
    if (e.is_dir == 1 || (e.filename != 'sources' && e.filename != 'to_approve' && e.filename != 'ready_files' && e.filename != 'project' && e.filename != 'trash')) {
      this.selectedFiles.select(e);
      console.log("this.selectedFiles itemSelected", this.selectedFiles.selected)
    }
  }
  
  itemDeselected(e) {
    // if (!e.dragged) {
    //   this.selectedFiles.deselect(e.id);
    // }
  }

  refresh() {
    this.refreshSub = this.refreshService.getRefreshFiles().pipe(
      switchMap(resp => {
        console.log("getRefreshFiles resp", resp);
        if (resp.task_id == this.target.outputFolder.targetIds.task_id && resp.task_operation_id == this.target.outputFolder.targetIds.task_operation_id) {
          console.log("getRefreshFiles file DATA -------------------------", resp)
          console.log("getRefreshFiles crumbs ----------------------------", this.crumbs)
  
          let folderNamePaths = resp.folder.path.split('/');
          console.log('folderNamePaths', folderNamePaths)
          let folderName = folderNamePaths[folderNamePaths.length - 1]
          console.log('folderName', folderName)
          
          if (this.crumbs[this.crumbs.length - 1].location != resp.folder.path && this.target.outputFolder.files.filter(x => x.filename == folderName).length == 0) {
            return this.fileService.getFileData(1, this.company.id, {task_id: this.target.outputFolder.task_id, task_operation_id: this.target.outputFolder.task_operation_id, location: this.crumbs[this.crumbs.length - 1].location, is_dir: "1"}, "200")
            .pipe(
              map(b => b.body),
              tap(res => {
                console.log("getRefreshFolder +++++++++++++++++", res)
                res.forEach(dir => {
                  if (this.target.outputFolder.files.filter(x => x.id == dir.id).length == 0) {
                    this.target.outputFolder.files.push(dir);
                  }
                })
              }),
              map(b => resp),
            )
          } else {
            if (this.target.outputFolder.files.filter(x => !x.is_uploaded && x.filename == resp.data.filename).length != 0) {
              return this.fileService.getTargetFile(resp.data.upload_url.id, resp.company_id).pipe(
                tap(xFile => {
                  resp.user.name = resp.user.display_name
                  xFile.user = resp.user;
                  xFile.createdEmployee = resp.user;
                  if (xFile.parameterValuesToTask) {
                    xFile.primaryTags = xFile.parameterValuesToTask.filter(x => !!x.is_primary)
                  } else {
                    xFile.primaryTags = []
                  }
                  this.target.outputFolder.files.splice(this.target.outputFolder.files.indexOf(this.target.outputFolder.files.find(y => y.id == resp.data.upload_url.id)), 1, xFile)
                }),
                map(b => resp)
              )
            } else {
              return of(resp)
            }
          }
        } else {
          return of(resp)
        }
      })
    ).subscribe(resp => {
      console.log("getRefreshFiles file RESULT =======================", resp)
    })
    
  }

  generateRandomId(): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = 10;
    let randomId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
  
    return randomId;
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: type + " link"}, "Copied", SnackBarItem)
  }
    
  setPrimaryTag() {
    this.closeFileMenu()
    console.log("this.selectedFiles", this.selectedFiles);

    let initData:any = {
      company: this.company
    }

    if (this.selectedFiles.selected.length > 1) {
      initData.selectedFiles = [...this.selectedFiles.selected]
    }

    const dialogRef = this.dialog.open(PrimaryTagForFilesComponent, {
      backdropClass: [],
      panelClass: [],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.target.outputFolder.page = 1;
        this.target.outputFolder.pagination = undefined;
        this.target.outputFolder.files = [];
        this.target.outputFolder.is_load = true;
        this.getProjectFiles(this.target);
      })
    )
  }

  openTargetValues(info, element) {
    this.closeFileMenu()
    console.log("this.selectedFiles", this.selectedFiles);

    let initData:any = {
      company: this.company,
      inFile: element,
    }

    if (this.selectedFiles.selected.length > 0) {   
      initData.selectedFiles = [...this.selectedFiles.selected]
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2],
      file_id: info[3]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.target.outputFolder.page = 1;
        this.target.outputFolder.pagination = undefined;
        this.target.outputFolder.files = [];
        this.target.outputFolder.is_load = true;
        this.getProjectFiles(this.target);
      })
    )
  }

  goBack() {
    this.crumbs.pop();
    this.target.active_location = this.crumbs[this.crumbs.length - 1].location;

    this.target.outputFolder.page = 1;
    this.target.outputFolder.pagination = undefined;
    this.target.outputFolder.files = [];
    this.target.outputFolder.is_load = true;
    this.getProjectFiles(this.target);
  }

  goToCrumb(i) {
    this.crumbs = this.crumbs.slice(0,i+1);
    this.target.active_location = this.crumbs[this.crumbs.length - 1].location;

    this.target.outputFolder.page = 1;
    this.target.outputFolder.pagination = undefined;
    this.target.outputFolder.files = [];
    this.target.outputFolder.is_load = true;
    this.getProjectFiles(this.target);
  }

  log() {
    console.log('target', this.target)
    console.log('crumbs', this.crumbs)
    console.log('selectedFiles', this.selectedFiles.selected)
    console.log('selectedDocuments', this.selectedDocuments)
    console.log('folders', this.folders)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company.id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  neededCardData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.company.id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
      tap(val => {
        x.filesCount = val.headers.get('x-pagination-total-count')
        x.files = val.body
      })
    )))

    return forkJoin(arr)
  }

  openJob(task_id, task_operation_id, loc?) {
    if (this.target.can_open) {
      this.OpenJob.emit(true);
    } else {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.company.id, task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededCardData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(OpenJobComponent, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            autoFocus: false,
            data: {
              task_id: task_id,
              task_operation_id: task_operation_id,
              task: resp,
              initCompanyId: this.company.id,
              loc: loc
            }
          });
          this.closeContext();
        })
      )
    }
  }

  closeContext() {
    this.CloseContext.emit(true)
    this.closeFileMenu();
  }

  // openProjects() {
  //   this.attachSubscriptions(
  //     this.taskService.getOneTaskExpand(this.company.id, this.target.outputFolder.targetIds.task_id).pipe(
  //       map(x => x.body[0]),
  //       switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
  //     ).subscribe(resp => {
  //       let _task = resp;
  //       let _work = !!this.target.outputFolder.targetIds.task_operation_id ? resp.operations.find(x => x.id == this.target.outputFolder.targetIds.task_operation_id) : undefined;
  //       const dialogRef = this.dialog.open(ProjectsInterfaceComponent, {
  //         panelClass: 'projects_dialog',
  //         disableClose: true,
  //         data: {
  //           host: this.host,
  //           imgRoute: this.imgRoute,
  //           activeLang: this.activeLang,
  //           is_mobile: this.is_mobile,
  //           user: this.user,
  //           company: this.company,
  //           company_id: this.company.id,
  //           target_company_id: this.target.company_id,
  //           task_id: this.target.outputFolder.targetIds.task_id,
  //           task_operation_id: this.target.outputFolder.targetIds.task_operation_id,
  //           task: _task,
  //           work: _work,
  //         }
  //       });
    
  //       this.attachSubscriptions(
  //         dialogRef.afterClosed().subscribe(result => {
  //           console.log(result)
  //         })
  //       )
  //     })
  //   )

  // }

  outletClick(outlet, file) {
    console.log("outletClick", outlet, file);

    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, this.target.outputFolder.targetIds.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        let _task = resp;
        let _work = !!this.target.outputFolder.targetIds.task_operation_id ? resp.operations.find(x => x.id == this.target.outputFolder.targetIds.task_operation_id) : undefined;
        if ((outlet.content_status_id == 1 || outlet.content_status_id == 13) && !outlet.content_url && !outlet.w8publish) {
          console.log('create in selected')

          const dialogRef = this.dialog.open(PublishToOutletComponent, {
            disableClose: true,
            data: {
              host: this.host,
              imgRoute: this.imgRoute,
              activeLang: this.activeLang,
              user: this.user,
              company: this.company,
              company_id: this.company.id,
              target_company_id: this.target.company_id,
              profile: outlet,
              file: file,
              task: _task,
              work: _work,
            }
          });
          this.closeContext();
      
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              outlet.w8publish = true;
            })
          )
          
        } else {
          console.log('create in new outlet same as selected')
          this.attachSubscriptions(
            this.taskService.addProfile({
              task_id: this.target.outputFolder.targetIds.task_id,
              channel_id: outlet.channel.id,
              content_type_id: outlet.content_type_id,
              content_status_id: 1
            }, this.company.id).pipe(
              switchMap(res => {
                return this.taskService.getTaskForManager(this.company.id, this.target.outputFolder.targetIds.task_id).pipe(
                  map(el => {
                    if (el[0]) {
                      if (this.target.outputFolder.targetIds.task_operation_id != "0") {
                        el[0].operations.find(x => x.id == this.target.outputFolder.targetIds.task_operation_id).is_active = true;
                        el[0].is_open = true;
                      } else {
                        el[0].is_open = false;
                      }
                      return casesModel(el, [], 'update').arr[0]
                    } else {
                      return false
                    }
                  }),
                  switchMap(resp => {
                    let expPermFilter:any = {task_id: this.target.outputFolder.targetIds.task_id}
                    if (this.company.id != this.target.company_id) {
                      expPermFilter.partner_company_id = this.target.company_id
                    }
                    return this.taskService.getExpandPermUrls('1', this.company.id, expPermFilter, '100').pipe(
                      tap(rslt => {
                        resp.uniqueChannels = [];
                        rslt.sort((a, b) => {
                          if (a.content_status_id === 13 && b.content_status_id !== 13) {
                            return -1;
                          } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                            return -1;
                          } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                            return 1;
                          } else {
                            return 0;
                          }
                        });
                        rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
                          if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                            if (resp.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                              resp.uniqueChannels.push(channel)
                            } else {
                              if (!([1, 13].includes(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                                resp.uniqueChannels.splice(resp.uniqueChannels.indexOf(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                              }
                            }
                          }
                        });
                        // getProfile
                        console.log("resp.uniqueChannels", resp.uniqueChannels)
                      }),
                      map(() => resp)
                    )
                  }),
                  tap(resp => {
                    _task = resp;
                    _work = this.target.outputFolder.targetIds.task_operation_id && _task.operations.filter(z => z.id == this.target.outputFolder.targetIds.task_operation_id).length > 0 ? _task.operations.find(z => z.id == this.target.outputFolder.targetIds.task_operation_id) : undefined;
                  }),
                  map((resp) => resp.channels.find(x => x.id == res.id))
                )
              })
            ).subscribe(resOutlet => {
              const dialogRefDialog = this.dialog.open(PublishToOutletComponent, {
                disableClose: true,
                data: {
                  host: this.host,
                  imgRoute: this.imgRoute,
                  activeLang: this.activeLang,
                  user: this.user,
                  company: this.company,
                  company_id: this.company.id,
                  target_company_id: this.target.company_id,
                  profile: resOutlet,
                  file: file,
                  task: _task,
                  work: _work,
                }
              });
              this.closeContext();
          
              // this.attachSubscriptions(
              //   dialogRefDialog.afterClosed().subscribe(result => {
              //     console.log(result)
              //   })
              // )
            })
          )
    
        }
      })
    )
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company.id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("profiles", this.profiles);
      })
    )
  }

  openFileMenu({ x, y }: MouseEvent, target, file) {
    this.closeFileMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefFileMenu = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    // console.log("open 1 this.task", this.task)

    if (this.uniqueChannels && this.uniqueChannels.length && this.profiles && file && file.is_dir == 0 && file.parameterValuesToTask && file.parameterValuesToTask.length) {
      this.uniqueChannels.sort((a,b) => {
        let aTags = this.getProfile(a.channel_id).parameterValuesToChannel;
        let bTags = this.getProfile(b.channel_id).parameterValuesToChannel;
        let fileTags = file.parameterValuesToTask;

        if (fileTags.filter(u => aTags.filter(x => x.parameter_value_id == u.parameter_value_id).length).length && !fileTags.filter(u => bTags.filter(x => x.parameter_value_id == u.parameter_value_id).length).length) {
          return -1;
        } 

        if (fileTags.filter(u => bTags.filter(x => x.parameter_value_id == u.parameter_value_id).length).length && !fileTags.filter(u => aTags.filter(x => x.parameter_value_id == u.parameter_value_id).length).length) {
          return 1;
        } 
        
        return 0;
      })
    }

    // console.log("open 2 this.task", this.task)

    if (!file.hasOwnProperty('reports_type')) {
      if (document.body.offsetWidth - x > 650) {
        file.open_to_left = false
      } else {
        file.open_to_left = true
      }
    }

    // if (file.task_operation_id) {
    //   this.attachSubscriptions(
    //     this.taskService.getOperationReminder(this.company_id, {task_operation_id: file.task_operation_id}).subscribe(resp => {
    //       file.checklist = resp.body;
    //     })
    //   )
    // }

    if (file.reports_type && file.reports_type == 'outFiles') {
      this.overlayRefFileMenu.attach(new TemplatePortal(this.outFileMenu, this.viewContainerRef, {
        $implicit: {
          target: target
        }
      }));
    } else {
      this.overlayRefFileMenu.attach(new TemplatePortal(this.fileMenu, this.viewContainerRef, {
        $implicit: {
          file: file,
          target: target
        }
      }));
    }
    
    this.subFileMenu = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefFileMenu && !this.overlayRefFileMenu.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeFileMenu())

  }

  paste(folder?, clear?) {
    this.closeFileMenu();
    let location = ''
    if (folder) {
      location = (folder.location != '/' ? (folder.location + "/") : folder.location) + folder.filename
    } else {
      location = this.crumbs[this.crumbs.length - 1].location
    }

    this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
    
    this.attachSubscriptions(
      forkJoin(this.copiedFiles.map(x => this.fileService.copyFile({
        location: location,
        task_id: this.target.outputFolder.targetIds.task_id,
        task_operation_id: this.target.outputFolder.targetIds.task_operation_id,
        id: x.id, 
        company_id: this.target.company_id || this.company.id || x.company_id
      }, this.company.id)
      .pipe(
        switchMap((res) => {
          if (!folder) {
            return this.fileService.getTargetFile(res.id, this.company.id || x.company_id).pipe(
              tap(b => {
                this.user.name = this.user.display_name
                b.user = this.user
                b.createdEmployee = this.user
                b.taskChannelFiles = x.taskChannelFiles
                console.log("paste FILE",b)
                this.target.outputFolder.files.push(b)
              }),
              map(() => res)
            )
          } else {
            return of(res)
          }
        }),
        catchError(err => {
          this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
          return of(err)
        }),
      ))).subscribe(resp => {
        if (!!clear) {
          localStorage.removeItem('copiedFiles');
          this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
        }
        this.layoutService.showSnackBar({name: ''}, marker("File copied successfully!"), SnackBarItem)
      })
    )


    console.log("this.copiedFiles", this.copiedFiles)
  }

  archive(close?, file?) {
    if (close) {
      this.closeFileMenu()
    }

    if (file) {
      console.log("archive", file)
      this.createArchive(file)
    } else {
      console.log("archive", "no file")
      if (this.crumbs[this.crumbs.length - 1].target && this.crumbs[this.crumbs.length - 1].target.acm) {
        console.log("archive", "no file", this.crumbs[this.crumbs.length - 1].target);
      } else {
        this.attachSubscriptions(
          this.fileService.getFileData(1, this.company.id, {is_dir: 1, task_id: this.target.outputFolder.targetIds.task_id, task_operation_id: this.target.outputFolder.targetIds.task_operation_id, location: this.crumbs.length > 1 ? this.crumbs[this.crumbs.length - 2].location : '/'}).pipe(
            map(x => x.body)
          ).subscribe(res => {
            if (!!res.length && res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)) {
              let targetFolder = res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)
              if (targetFolder) {
                this.createArchive(targetFolder)
              }
              console.log("archive targetFolder", "no file", targetFolder)
            }
          })
        )
      }
    }
  }

  ctxOpenFolderArch() {
    this.attachSubscriptions(
      this.fileService.getFileData(1, this.company.id, {is_dir: 1, task_id: this.target.outputFolder.targetIds.task_id, task_operation_id: this.target.outputFolder.targetIds.task_operation_id, location: this.crumbs.length > 1 ? this.crumbs[this.crumbs.length - 2].location : '/'}).pipe(
        map(x => x.body)
      ).subscribe(res => {
        if (!!res.length && res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)) {
          let targetFolder = res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)
          if (targetFolder) {
            this.openFolderArchives(targetFolder)
          }
          console.log("archive targetFolder", "no file", targetFolder)
        }
      })
    )
  }

  openFolderArchives(folder) {
    this.closeFileMenu()
    console.log("openFolderArchives", folder)
    const dialogRef = this.dialog.open(FolderArchivesComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        target_company_id: this.target.company_id,
        folder: folder,
        task_id: this.target.outputFolder.targetIds.task_id,
        task_operation_id: this.target.outputFolder.targetIds.task_operation_id,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {

      })
    )
  }

  createArchive(folder) {
    const dialogRef = this.dialog.open(CreateArchiveComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        target_company_id: this.target.company_id,
        folder: folder
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result && result.event == 'create') {
          this.target.outputFolder.page = 1;
          this.target.outputFolder.pagination = undefined;
          this.target.outputFolder.files = [];
          this.target.outputFolder.is_load = true;
          this.getProjectFiles(this.target);
        }
      })
    )
  }

  createDocument() {
    this.closeFileMenu();

    const dialogRef = this.dialog.open(AddEditNoteComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company.id,
        target_company_id: this.target.company_id,
        task_id: this.target.outputFolder.targetIds.task_id,
        work_id: this.target.outputFolder.targetIds.task_operation_id,
        files: this.target.outputFolder.files,
        location: this.crumbs[this.crumbs.length - 1].location,
        user: this.user,
        is_file: true
      }
    });
  }

  createFolder(file?) {
    this.closeFileMenu();

    let files = [];

    if (file) {
      if (this.selectedFiles.isSelected(file)) {
        files = this.selectedFiles.selected.slice()
      } else {
        files.push(file)
      }
    }
    console.log('files', files)

    const dialogRef = this.dialog.open(CreateFolderComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        target_company_id: this.target.company_id,
        task_id: this.target.outputFolder.targetIds.task_id,
        work_id: this.target.outputFolder.targetIds.task_operation_id,
        files: this.target.outputFolder.files,
        location: this.crumbs[this.crumbs.length - 1].location,
        is_global: false,
        file_ids: files.map(x => x.id)
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          // if (result.event == 'create') {
          //   files.forEach((y,i) => {
          //     if (this.files.indexOf(this.files.find(t => t.id == y))) {
          //       this.files.splice(this.files.indexOf(this.files.find(t => t.id == y)), 1)
          //       if (i == file_ids.length - 1) {
          //         this.deSelectAll()
          //       }
          //     }
          //   })
          // }
          // if (result.event == 'upload') {
          //   console.log(this.uploader);
          //   let el: HTMLElement = this.uploader.nativeElement;
          //   el.click();
          // } else if (result.event == 'upload_folder') {
          //   console.log(this.uploaderFolder);
          //   let el: HTMLElement = this.uploaderFolder.nativeElement;
          //   el.click();
          // }
          // this.files.push(result.data)
        }
      })
    )
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
        this.company.filesize = this.company.filesize + event.target.files[index].size
      }
    }

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }

    if (event.target.files.length > 0) {
      if (this.target && this.target.checklists && this.target.checklists.length && (this.crumbs[this.crumbs.length - 1].location.indexOf('to_approve') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('approved') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('ready_files') != -1)) {
        const dialogRef = this.dialog.open(FilePropertiesComponent, {
          panelClass: 'file_properties',
          autoFocus: false,
          disableClose: true,
          data: {
            is_set_propperties: true,
            filesNeedToSetProperties: event.target.files,
            company: this.company,
            user: this.user,
            work: this.target,
            files: this.target.outputFolder.files,
          }
        });
        // this.attachSubscriptions(
        //   dialogRef.afterClosed().subscribe(resp => {
        //     // console.log(resp)
        //   })
        // )
      }
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        if (this.sm.localStorageGetItem('md5hash') == '1') {
          this.layoutService.showSnackBar({name: element.name}, marker("MD5hash calculation started"), SnackBarItem)
          this.md5Service.calculateMd5(element).subscribe((resp:any) => {
            console.log("md5Service", resp);
            // this.putFile(element);
            this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID});
            this.layoutService.showSnackBar({name: resp.md5Hash}, new TimeFormatPipe().transform(Math.ceil(resp.duration / 1000)), SnackBarItem, 10000)
          })
        } else {
          this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID, location: this.crumbs[this.crumbs.length - 1].location});
          // this.putFile(element);
        }
      }
    } 

    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  uploadFolder(event) {
    if (event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
        this.company.filesize = this.company.filesize + event.target.files[index].size
      }
    }
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }

    if (event.target.files.length > 0) {
      console.log("event.target.files", event.target.files)
      if (this.target && this.target.checklists && this.target.checklists.length && (this.crumbs[this.crumbs.length - 1].location.indexOf('to_approve') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('approved') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('ready_files') != -1)) {
        const dialogRef = this.dialog.open(FilePropertiesComponent, {
          panelClass: 'file_properties',
          autoFocus: false,
          disableClose: true,
          data: {
            is_set_propperties: true,
            filesNeedToSetProperties: event.target.files,
            company: this.company,
            user: this.user,
            work: this.target,
            files: this.target.outputFolder.files,
          }
        });
        // this.attachSubscriptions(
        //   dialogRef.afterClosed().subscribe(resp => {
        //     // console.log(resp)
        //   })
        // )
      }
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        // this.putFile(element, true);
        this.fileService.reserveFiles$.next({file: element, is_folder: true, initPath: false, id: this.putFilesID, location: this.crumbs[this.crumbs.length - 1].location});
      }
    }
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }


  onFileDropped(e, crumb:any = false, file:any = false) {
    console.log("onFileDropped", e, crumb)
    // if (this.isDragged) {
    //   return
    // }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      console.log("this.company", this.company)
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          this.company.filesize = this.company.filesize + files[index].size
        }
      }
    })

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }
    

    let locationVal:any = false;

    if (file) {
      locationVal = file.location + "/" + file.filename
    }

    if (crumb) {
      locationVal = crumb.location
    }


    let checkRoute = locationVal;

    if (typeof checkRoute == "boolean") {
      checkRoute = this.crumbs[this.crumbs.length - 1].location
    } else {
      locationVal = locationVal.replaceAll('//', '/');
      checkRoute = locationVal;
    }

    // console.log("onFileDropped", checkRoute, this.isWriteFolder(checkRoute))
    // if (!this.isWriteFolder(checkRoute)) {

    //   let x = "You can't upload files here."
    //   let y = ''

    //   if (this.foldersPerm.filter(u => !u.cant_write).length) {
    //     y = y + "You are allowed upload in folders: ";

    //     this.foldersPerm.filter(u => !u.cant_write).forEach((b, k) => {
    //       let name = b.name;
    //       if (k == this.foldersPerm.filter(u => !u.cant_write).length - 1) {
    //         name = name + ' or other nonsystem folders.'
    //       } else {
    //         name = name + ', '
    //       }
    //       y += name
    //     })
    //   }

    //   this.layoutService.showSnackBar({name: x}, marker(y), SnackBarItem)
    //   return
    // }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {

        console.log("DropFilesLimitComponent", files);
        console.log("DropFilesLimitComponent", files.length);

        if (files.length >= 100) {
          const dialogRef = this.dialog.open(DropFilesLimitComponent, {
            panelClass: 'file_properties',
            autoFocus: false,
            data: {
              company: this.company,
              user: this.user,
            }
          });
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              if (!!result) {
                if (result.event == 'upload') {
                  console.log(this.liteUploader);
                  let el: HTMLElement = this.liteUploader.nativeElement;
                  el.click();
                } else if (result.event == 'upload_folder') {
                  console.log(this.liteUploaderFolder);
                  let el: HTMLElement = this.liteUploaderFolder.nativeElement;
                  el.click();
                }
                // this.files.push(result.data)
              }
            })
          )
          return
        }
        console.log("getFilesDataTransferItems",files);
        if (this.target && this.target.checklists && this.target.checklists.length && (this.crumbs[this.crumbs.length - 1].location.indexOf('to_approve') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('approved') != -1 || this.crumbs[this.crumbs.length - 1].location.indexOf('ready_files') != -1 || checkRoute.indexOf('ready_files') != -1)) {
          const dialogRef = this.dialog.open(FilePropertiesComponent, {
            panelClass: 'file_properties',
            autoFocus: false,
            disableClose: true,
            data: {
            is_set_propperties: true,
            filesNeedToSetProperties: files,
            company: this.company,
            user: this.user,
            work: this.target,
            files: this.target.outputFolder.files,
            }
          });
          // this.attachSubscriptions(
          //   dialogRef.afterClosed().subscribe(resp => {
          //     // console.log(resp)
          //   })
          // )
        }
        for (let index = 0; index < files.length; index++) {
          // this.putFile(files[index], !!files[index].filepath || !!files[index].webkitRelativePath, locationVal);
          this.fileService.reserveFiles$.next({file: files[index], is_folder: !!files[index].filepath || !!files[index].webkitRelativePath, initPath: checkRoute, id: this.putFilesID, location: checkRoute});
        }
      }
    });
    
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }


  getProfile(id) {
    if (!this.profiles || !this.profiles.find(el => el.id == id)) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  } 

  closeFileMenu() {
    this.subFileMenu && this.subFileMenu.unsubscribe();
    if (this.overlayRefFileMenu) {
      this.overlayRefFileMenu.dispose();
      this.overlayRefFileMenu = null;
    }
  }

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.company.id
      }
    });
  }

  
  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  
  fileClick(e, target, file, is_folder:boolean = false) {
    if (file.is_dir == 1 || is_folder) {
      e.preventDefault();
      e.stopPropagation();
      this.closeFileMenu();
      if (is_folder) {
        this.crumbs = [];
        this.crumbs.push({
          name: "Root",
          location: "/"
        });
        this.crumbs.push({
          name: file.filename,
          location: '/' + file.filename
        })
      } else if (this.liteFilter.location_type == 'location_all') {
        let loc = ((file.location == "/" ? file.location : file.location + "/") + file.filename).replaceAll('//', '/');
        this.crumbs = []
        if (loc == '/') {
          this.crumbs.push({
            name: "Root",
            location: "/"
          })
        } else {
          loc.split('/').forEach(val => {
            if (val === '') {
              this.crumbs.push({
                name: "Root",
                location: "/"
              })
            } else {
              this.crumbs.push({
                name: val,
                location: (this.crumbs[this.crumbs.length - 1].location == '/' ? this.crumbs[this.crumbs.length - 1].location : this.crumbs[this.crumbs.length - 1].location + '/') + val
              })
            }
          })
        }
      } else {
        this.crumbs.push({
          name: file.filename,
          location: (file.location == "/" ? file.location : file.location + "/") + file.filename
        })
      }

      
      this.target.active_location = this.crumbs[this.crumbs.length - 1].location;
  
      this.target.outputFolder.page = 1;
      this.target.outputFolder.pagination = undefined;
      this.target.outputFolder.files = [];
      this.target.outputFolder.is_load = true;

      this.getProjectFiles(this.target);
      return
    }

    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      this.selectedFiles.toggle(file)
      console.log("this.selectedFiles", this.selectedFiles.selected)
      return
    }

    target.is_clicked = false;

    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, file.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(VideoViewerComponent, {
          panelClass: 'video_viewer',
          autoFocus: false,
          data: {
            file: file,
            files: target.outputFolder.files,
            playlist: target.outputFolder.files.filter(x => (x.is_uploaded || !!x.is_document) && (!!x.is_document || (x.content_type.indexOf("image") != -1 || x.content_type.indexOf("video") != -1 || x.content_type.indexOf("audio") != -1) || x.content_type == 'application/pdf' || !!x.thumbnail)),
            task: resp,
            work: !!file.task_operation_id ? resp.operations.find(x => x.id == file.task_operation_id) : undefined,
            operationsValues: this.operationsValues,
            company: this.company,
            activeLang: this.activeLang,
            user: this.user,
            initCompanyId: this.company.id
          }
        });
        this.closeContext();
      })
    )
  }

  
  downloadJD(file) {
    this.closeFileMenu();
    window.open(this.host + `/file/download/?company_id=${this.company.id}&id=${file.id}&type=crawljob`, '_blank');
  }

  download(file) {
    this.closeFileMenu();
    window.open(this.host + file.original + '?company_id=' + this.company.id + `&filename=${file.filename}`, '_blank');
  }

  delete(target, file) {
    this.closeFileMenu();
    if (!this.selectedFiles.isSelected(file)) {
      this.selectedFiles.clear()
      this.selectedFiles.select(file)
    }


    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        msg: "Delete or move files to the trash?",
        btn_no: "Delete",
        btn_yes: "Move to Trash"
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        this.attachSubscriptions(
          forkJoin(this.selectedFiles.selected.map(x => this.fileService.deleteFile(x.id))).subscribe({
            next: (next) => {
              console.log("next", next)
            },
            complete: () => {
              console.log("complete")
              this.selectedFiles.selected.forEach((y,i) => {
                target.outputFolder.files.splice(target.outputFolder.files.findIndex(u => u.id == y.id), 1);
                if (i == this.selectedFiles.selected.length - 1) {
                  this.selectedFiles.clear()
                }
              })
            },
            error: (error) => {
              console.log(error)
              this.selectedFiles.clear()
            }
          })
        )
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          forkJoin(this.selectedFiles.selected.map(x => this.fileService.editFile(x.id, {"location": '/trash'}, this.company.id))).subscribe(resp => {
            resp.forEach(z => {
              target.outputFolder.files.splice(target.outputFolder.files.findIndex(u => u.id == z.id), 1);
            })
          })
        )
      }
    });
  }  

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  onDownProjectFiles($event, target) {
    if (target.outputFolder.pagination['pageCount'] >= target.outputFolder.page) { 
      this.getProjectFiles(target)
    }
  }

  getProjectFiles(target) {
    let loc = this.target.active_location || this.target.output_location || "/";
    let params = {summary: 1, 
      task_id: target.outputFolder.targetIds.task_id, 
      task_operation_id: target.outputFolder.targetIds.task_operation_id, 
      [this.liteFilter.location_type]: loc, 
      order_by: 'filename'
    }
    let filterData = {...this.liteFilter};
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if (element != 'location_type') {
        if (element == 'is_dir' && filterData[element] !== '') {
          params[element] = filterData[element]
        } else if (['is_content_url', 'part_of_count', 'consist_of_count', 'related_count'].includes(element) && filterData[element] == '0') {
          params[element] = filterData[element]
        } else if (["group_id", "channel_id", "no_channel_id", "operation_employee_id", "operation_partner_company_id", 'task_parameter_value_id', 'parameter_value_id', 'file_parameter_value_id'].includes(element) && filterData[element].length > 0) {
          params[element] = filterData[element].join(',')
        } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
          params[element] = filterData[element]
        } else if (filterData[element] != '0' && filterData[element] != '') {
          params[element] = filterData[element]
        }
      }
    });
    console.log("LiteFileManagerComponent params", params)
    this.attachSubscriptions(
      this.fileService.getFileDataWithTags(target.outputFolder.page, this.company.id, params, '20').pipe(
        tap(el => {
          if (target.outputFolder.page == 1) {
            target.outputFolder.pagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        let newFiles = [];
        resp.forEach(file => {
          if (file.parameterValuesToTask) {
            file.primaryTags = file.parameterValuesToTask.filter(x => !!x.is_primary)
          } else {
            file.primaryTags = []
          }
          if (!!file.is_dir && !!this.folders.filter(x => x.filename == file.filename && x.location == file.location).length) {
            this.folders.splice(this.folders.findIndex(x => x.filename == file.filename && x.location == file.location), 1, file);
          } else {
            newFiles.push(file)
          }
        })
        if (target.outputFolder.page == 1) {
          target.outputFolder.files = newFiles;
        } else {
          newFiles.forEach(f => {
            if (target.outputFolder.files.filter(u => u.id == f.id).length == 0) {
              target.outputFolder.files.push(f);
            }
          })
        }
        target.outputFolder.page++;
        target.outputFolder.is_load = false;
        console.log("openContext target.outputFolder", target.outputFolder)
        console.log("openContext crumbs", this.crumbs)
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  videoEditorVA(file) {
    this.closeFileMenu();
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, this.target.outputFolder.targetIds.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {

        let addVideos = [];
        let addAudios = [];
        let allFiles = [];
        if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
          this.selectedFiles.selected.forEach(preExFile => {
            let exFile = JSON.parse(JSON.stringify(preExFile, this.getCircularReplacer()));

            if (exFile) {
              if (exFile.task) {
                delete exFile.task;
              }
              if (exFile.taskChannelFiles) {
                delete exFile.taskChannelFiles;
              }
              if (exFile.createdEmployee) {
                delete exFile.createdEmployee;
              }
              if (exFile.meta) {
                delete exFile.meta;
              }
              if (exFile.taskOperation) {
                delete exFile.taskOperation;
              }
              allFiles.push(exFile)
            }
          })
        }
    
        allFiles.forEach(el => {
          if (this.understandFileType(el.content_type) == 'video') {
            if (!!el.meta_height && !!el.meta_width && !!el.preview1080) {
              addVideos.push(el)
            }
          } else if (this.understandFileType(el.content_type) == 'audio') {
            addAudios.push(el)
          }
        })
        let cstFile;
        if (addVideos && addVideos.length) {
          cstFile = {...addVideos[0]}
          addVideos.splice(0,1)
        } else {
          cstFile = {...file};
        }

        if (cstFile.task) {
          delete cstFile.task;
        }
        if (resp.chatsInfo) {
          delete resp.chatsInfo;
        }
        if (cstFile.taskChannelFiles) {
          delete cstFile.taskChannelFiles;
        }
        if (cstFile.createdEmployee) {
          delete cstFile.createdEmployee;
        }
        if (cstFile.meta) {
          delete cstFile.meta;
        }
        if (cstFile.taskOperation) {
          delete cstFile.taskOperation;
        }
    
        const dialogRef = this.dialog.open(VideoFfmpegEditorComponent, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          disableClose: true,
          data: {
            file: cstFile,
            company_id: this.company.id,
            target_company_id: this.target.company_id,
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            task: resp,
            work: !!this.target.outputFolder.targetIds.task_operation_id ? resp.operations.find(x => x.id == this.target.outputFolder.targetIds.task_operation_id) : undefined,
            addAudios: addAudios,
            addVideos: addVideos
          }
        });
        this.closeContext();
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
          })
        )
      })
    )

  }

  V5VideoEditor(file) {
    this.closeFileMenu();
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, this.target.outputFolder.targetIds.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {

        let addVideos = [];
        let addAudios = [];
        let allFiles = [];
        if (this.selectedFiles.isSelected(file.id) && this.selectedFiles.selected.length > 1) {
          this.selectedFiles.selected.forEach(preExFile => {
            let exFile = JSON.parse(JSON.stringify(preExFile, this.getCircularReplacer()));

            if (exFile) {
              if (exFile.task) {
                delete exFile.task;
              }
              if (exFile.taskChannelFiles) {
                delete exFile.taskChannelFiles;
              }
              if (exFile.createdEmployee) {
                delete exFile.createdEmployee;
              }
              if (exFile.meta) {
                delete exFile.meta;
              }
              if (exFile.taskOperation) {
                delete exFile.taskOperation;
              }
              allFiles.push(exFile)
            }
          })
        }
    
        allFiles.forEach(el => {
          if (this.understandFileType(el.content_type) == 'video') {
            if (!!el.meta_height && !!el.meta_width && !!el.preview1080) {
              addVideos.push(el)
            }
          } else if (this.understandFileType(el.content_type) == 'audio') {
            addAudios.push(el)
          }
        })
        let cstFile;
        if (addVideos && addVideos.length) {
          cstFile = {...addVideos[0]}
          addVideos.splice(0,1)
        } else {
          cstFile = {...file};
        }

        if (cstFile.task) {
          delete cstFile.task;
        }
        if (resp.chatsInfo) {
          delete resp.chatsInfo;
        }
        if (cstFile.taskChannelFiles) {
          delete cstFile.taskChannelFiles;
        }
        if (cstFile.createdEmployee) {
          delete cstFile.createdEmployee;
        }
        if (cstFile.meta) {
          delete cstFile.meta;
        }
        if (cstFile.taskOperation) {
          delete cstFile.taskOperation;
        }
    
        const dialogRef = this.dialog.open(VideoEditorV5Component, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          disableClose: true,
          data: {
            file: cstFile,
            company_id: this.company.id,
            target_company_id: this.target.company_id,
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            task: resp,
            work: !!this.target.outputFolder.targetIds.task_operation_id ? resp.operations.find(x => x.id == this.target.outputFolder.targetIds.task_operation_id) : undefined,
            addAudios: addAudios,
            addVideos: addVideos
          }
        });
        this.closeContext();
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
          })
        )
      })
    )

  }

  copy(file) {
    this.closeFileMenu();
    let copyData = [];
    if (this.selectedFiles.selected.length && this.selectedFiles.isSelected(file)) {
      this.selectedFiles.selected.forEach(x => {
        copyData.push(
          {
            company_id: x.company_id,
            id: x.id,
            taskChannelFiles: x.taskChannelFiles
          }
        )
      })
    } else {
      copyData.push({
        company_id: file.company_id,
        id: file.id,
        taskChannelFiles: file.taskChannelFiles
      })
    }

    this.sm.localStorageSetItem('copiedFiles', copyData);
    this.copiedFiles = copyData
  }
   
  videoEditorV6(file) {
    this.closeFileMenu();
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, this.target.outputFolder.targetIds.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {

        let addVideos = [];
        let addAudios = [];
        let allFiles = [];
        if (this.selectedFiles.isSelected(file) && this.selectedFiles.selected.length > 1) {
          this.selectedFiles.selected.forEach(preExFile => {
            let exFile = JSON.parse(JSON.stringify(preExFile, this.getCircularReplacer()));

            if (exFile) {
              if (exFile.task) {
                delete exFile.task;
              }
              if (exFile.taskChannelFiles) {
                delete exFile.taskChannelFiles;
              }
              if (exFile.createdEmployee) {
                delete exFile.createdEmployee;
              }
              if (exFile.meta) {
                delete exFile.meta;
              }
              if (exFile.taskOperation) {
                delete exFile.taskOperation;
              }
              allFiles.push(exFile)
            }
          })
        }
    
        allFiles.forEach(el => {
          if (this.understandFileType(el.content_type) == 'video') {
            if (!!el.meta_height && !!el.meta_width && !!el.preview1080) {
              addVideos.push(el)
            }
          } else if (this.understandFileType(el.content_type) == 'audio') {
            addAudios.push(el)
          }
        })
        let cstFile;
        if (addVideos && addVideos.length) {
          cstFile = {...addVideos[0]}
          addVideos.splice(0,1)
        } else {
          cstFile = {...file};
        }
        if (cstFile.task) {
          delete cstFile.task;
        }
        if (resp.chatsInfo) {
          delete resp.chatsInfo;
        }
        if (cstFile.taskChannelFiles) {
          delete cstFile.taskChannelFiles;
        }
        if (cstFile.createdEmployee) {
          delete cstFile.createdEmployee;
        }
        if (cstFile.meta) {
          delete cstFile.meta;
        }
        if (cstFile.taskOperation) {
          delete cstFile.taskOperation;
        }
    
        let modalData = {
          previusUrl: this.router.url,
          file: cstFile,
          company_id: this.company.id,
          target_company_id: this.target.company_id,
          company: this.company,
          user: this.user,
          imgRoute: this.imgRoute,
          activeLang: this.activeLang,
          host: this.host,
          task: resp,
          work: !!this.target.outputFolder.targetIds.task_operation_id ? resp.operations.find(x => x.id == this.target.outputFolder.targetIds.task_operation_id) : undefined,
          addAudios: addAudios,
          addVideos: addVideos
        }
    
        console.log('modalData', modalData)
        this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
        window.open(this.origin + `/video-editor?company_id=${this.company.id}`, '_blank');
      })
    )

  }

  settings(file?) {
    if (!file) {
      this.attachSubscriptions(
        this.fileService.getFileData(1, this.company.id, {is_dir: 1, task_id: this.target.outputFolder.targetIds.task_id, task_operation_id: this.target.outputFolder.targetIds.task_operation_id, location: this.crumbs.length > 1 ? this.crumbs[this.crumbs.length - 2].location : '/'}).pipe(
          map(x => x.body)
        ).subscribe(res => {
          if (!!res.length && res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)) {
            let targetFolder = res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)
            if (targetFolder) {
              this.settings(targetFolder)
            }
            console.log("archive targetFolder", "no file", targetFolder)
          }
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.company.id, this.target.outputFolder.targetIds.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(FileSettingsComponent, {
            data: {
              company_id: this.company.id,
              target_company_id: this.target.company_id,
              company: this.company,
              file: file,
              user: this.user,
              imgRoute: this.imgRoute,
              activeLang: this.activeLang,
              host: this.host,
              location: this.crumbs[this.crumbs.length - 1].location,
              task: resp,
              work: !!this.target.outputFolder.targetIds.task_operation_id ? resp.operations.find(x => x.id == this.target.outputFolder.targetIds.task_operation_id) : undefined,
              operationsValues: this.operationsValues
            }
          });
          this.closeContext();
        })
      )
    }
  }

  edit(file) {
    console.log(file)
    const dialogRef = this.dialog.open(RenameComponent, {
      data: {
        file: file,
        company: this.company
      }
    });
    this.closeContext();

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == 'Edit') {
    //       task.custom_id = result.data.custom_id;
    //     }
    //   })
    // )
  }

  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
    const isVisible = $event.target.visibilityState === 'visible';
    // console.log("&&&&", isVisible)
    if (isVisible) {
      if (this.sm.localStorageGetItem("copiedFiles")) {
        this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
      } else {
        this.copiedFiles = undefined;
      }
        // tab is visible
    } else {
        // tab is not-visible
    }
  }

  pasteHandler(e) {
    // console.log("FM component e", e)
    // console.log("FM component e.target.tagName", e.target.tagName)
    // console.log("FM component e.clip", e.clipboardData);
    // console.log("this.dialog.openDialogs[this.dialog.openDialogs.length - 1]", this.dialog.openDialogs[this.dialog.openDialogs.length - 1])
    // console.log("this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.dialogHasFm", this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.dialogHasFm)
    console.log("pasteHandler", e)
    if (["INPUT", "TEXTAREA"].includes(e.target.tagName) || !this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.dialogHasFm) {
      console.log("pasteHandler", 1)
      return
    }

    // if (!this.isWriteFolder()) {

    //   let x = "You can't upload files here."
    //   let y = ''

    //   if (this.foldersPerm.filter(u => !u.cant_write).length) {
    //     y = y + "You are allowed upload in folders: ";

    //     this.foldersPerm.filter(u => !u.cant_write).forEach((b, k) => {
    //       let name = b.name;
    //       if (k == this.foldersPerm.filter(u => !u.cant_write).length - 1) {
    //         name = name + ' or other nonsystem folders.'
    //       } else {
    //         name = name + ', '
    //       }
    //       y += name
    //     })
    //   }

    //   this.layoutService.showSnackBar({name: x}, marker(y), SnackBarItem)
    //   return
    // }

    // added && e.clipboardData.files && e.clipboardData.files.length
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));

    if (!e.clipboardData) {
      return
    };

    let items = e.clipboardData.items;
    let files = []

    if (items) {
      for (var i = 0; i < items.length; i++) {
        var blob = items[i].getAsFile();
        if (!!blob) {
          files.push(blob);
        }
      }
    }
    console.log("pasteHandler", files)

    if (files.length) {
      if (this.copiedFiles && this.copiedFiles.length) {
        const askDialogRef = this.dialog.open(AskPasteFilesComponent, {
          data: {
            company: this.company
          }
        });
  
        askDialogRef.afterClosed().subscribe(result => {
          if (!!result) {
            if (result.action == "reports") {
              this.paste(false, result.clear)
            } else {
              for (let index = 0; index < files.length; index++) {
                const element = files[index];
                // this.putFile(element);
                this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID});
              }
            }
          }
        })
      } else {
        for (let index = 0; index < files.length; index++) {
          const element = files[index];
          // this.putFile(element);
          this.fileService.reserveFiles$.next({file: element, is_folder: false, initPath: false, id: this.putFilesID});
        }
      }
    } else {
      if (this.copiedFiles && this.copiedFiles.length) {
        this.paste()
      }
    }
  }

  @HostListener('window:paste',['$event'])
  onKeyPress($event) {
    this.pasteHandler($event);
  }

  ngOnDestroy(): void {
    // this.target.active_location = ''
    if (this.subThird) {
      this.subThird.unsubscribe()
    }
    if (this.refreshSub) {
      this.refreshSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
