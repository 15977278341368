import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin, from, of } from 'rxjs';
import { concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-import-files-v2',
  templateUrl: './import-files-v2.component.html',
  styleUrls: ['./import-files-v2.component.scss']
})
export class ImportFilesV2Component extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isLoad: boolean = false;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;

  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public operations: any;

  public storages: any;
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImportFilesV2Component>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private ls: LoadingService,
    private parametersService: ParametersService,
    private fileService: FileService,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("ImportFilesV2Component", this.data)

    this.form = this.fb.group({
      company_id: this.data.company_id,
      channel_id: [[]],
      operation_id: '',
      location: '/',
      storage_code: 'eeur-storage-cf',
      is_to_process: 1,
      is_prefix_filename: 0,
      is_save_auto_post_data: 0,
      params: this.fb.group({
        thumbnail: 1,
        file: 1
      })
    })
    // parameter_value_id: '',

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.getOperations();
    this.getProfiles();
    // this.getAllApiParameterValues();

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    // this.attachSubscriptions(
    //   this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    // )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getStorages()

  }

  log() {
    console.log('form', this.form.value)
  }

  getStorages() {
    this.attachSubscriptions(
      this.fileService.getStorages(this.data.company.id).subscribe(resp => {
        this.storages = makeArray(resp)
      })
    )
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company_id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company_id).subscribe(resp => {
        this.operations = resp;
      })
    )
  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  } 

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.data.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.data.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (this.isSubmit) {
      return
    }

    this.isSubmit = true;
    console.log(this.form.value);

    if (!this.form.value.channel_id || !this.form.value.operation_id) {
      this.layoutService.showSnackBar({name: ''}, `Select channel and type of work`, SnackBarItem)
      this.isSubmit = false;
      return
    }
    if (!this.form.value.params.thumbnail && !this.form.value.params.file) {
      this.layoutService.showSnackBar({name: ''}, `Nothing to save, select file or thumbnail`, SnackBarItem)
      this.isSubmit = false;
      return
    }

    let lenSelection = this.data.taskCollection.length;
    let count = 0;

    from(this.data.taskCollection).pipe(
      concatMap((card:any) => {
        return this.taskService.getUrlsWithoutExp('1', this.data.company_id, {task_id: card.id}, '200').pipe(
          map(u => u.body),
          switchMap(cardChannels => {
            
            if (!!cardChannels && !!cardChannels.length && !!cardChannels.find(x => this.form.value.channel_id.includes(x.channel_id) && x.content_url)) {
              let sbmtData = []

              let job_id = 0;

              if (!!card.operations && !!card.operations.length && !!card.operations.find(j => j.operation_id == this.form.value.operation_id)) {
                // if (!!this.form.value.parameter_value_id) {

                // } else {
                // }
                job_id = card.operations.find(j => j.operation_id == this.form.value.operation_id).id
              }

              cardChannels.filter(x => this.form.value.channel_id.includes(x.channel_id) && x.content_url).forEach(channel => {
                sbmtData.push({
                  "path": `/api/get-data-queue/`,
                  "query": {company_id: this.data.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    company_id: this.form.value.company_id,
                    save_task_id: card.id,
                    save_task_channel_id: channel.id,
                    save_location: this.form.value.location,
                    save_storage_code: this.form.value.storage_code,
                    is_to_process: this.form.value.is_to_process,
                    save_task_operation_id: job_id,
                    is_prefix_filename: !!this.form.value.is_prefix_filename ? 1 : 0,
                    is_save_auto_post_data: !!this.form.value.is_save_auto_post_data ? 1 : 0,
                    params: {
                      thumbnail: !!this.form.value.params.thumbnail,
                      file: !!this.form.value.params.file 
                    }
                  }
                });
              })

              // return of({
              //   card: card,
              //   cardChannels: cardChannels,
              //   sbmtData: sbmtData
              // })
              return this.taskService.multiRequest(sbmtData).pipe(
                map(res => {
                  return {
                    card: card,
                    cardChannels: cardChannels,
                    sbmtData: sbmtData,
                    res: res
                  }
                })
              )
            } else {
              return of({
                card: card,
                cardChannels: cardChannels
              })
            }
        
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next Import files", next);
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: `Importing files to ${lenSelection} cards`,
        })
        count++;
      },
      complete: () => {
        console.log("complete Import files");
        this.isSubmit = false;
        this.dialogRef.close({event: "update"})
      },
      error: (error) => {
        this.isSubmit = false;
        console.log("error Import files", error)
      }
    })
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
