import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-task-status-edit',
  templateUrl: './task-status-edit.component.html',
  styleUrls: ['./task-status-edit.component.scss']
})
export class TaskStatusEditComponent extends BaseClass implements OnInit, OnDestroy {
  public statuses: any;
  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskStatusEditComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
  ) { super() }

  ngOnInit(): void {
    this.getTaskStatus();
    
    this.form = this.fb.group({
      status_id: [this.data.task.status_id, Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }


  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
        this.statuses = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!!this.data.multi) {
      forkJoin(this.data.collection.map(x => this.taskService.editTask(x, this.form.value, this.data.company_id))).subscribe(resp => {
        this.dialogRef.close({event: "update", status_id: this.form.value.status_id})
      })
    } else { 
      this.attachSubscriptions(
        this.taskService.editTask(this.data.task.id, this.form.value, this.data.company_id).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
